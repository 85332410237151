import { useEffect, useState } from 'react';
import redux, { IState, ReduxMapper } from '../../Redux/Redux';
import { IWorkbookTemplateSummary } from '../WorkbookTemplate/WorkbookTypes';
import OrganizationWorkbookTemplateSearchableList, { filterWorkbooks } from './OrganizationWorkbookTemplateSearchableList';
import { Server, _server } from '../../utils/Server';
import { toast } from 'react-toastify';
import { IOrgUserSummary } from '../../Types/User';
import { IOrganizationRelationship, IOrganizationRequestedReview, IReviewGroupMember } from './OrganizationTypes';
import { Button } from 'react-bootstrap';
import Icon, { IconType } from '../Icon/Icon';
import { FormatDate } from '../../utils/Tools';

interface IProps {
  orgId?: string;
  state: IState;
}

interface ISearch {
  workbook?: string;
}

const OrganizationsRequestedReviews = (props: IProps) => {
  const [reviews, setReviews] = useState<IOrganizationRequestedReview[]>([]);
  const [myGroups, setMyGroups] = useState<Record<string,boolean>>({});

  useEffect(() => {
    if (props.state.User) {
      init();
      redux.setOrgWorkbooks(props.orgId);
    }
  }, [props.state.User]);

  const init = () => {
    _server.postApi<IOrganizationRequestedReview[]>(`../OrgApi/ReviewsRequested`, { orgId: props.orgId }).then((x) => {
      if (x.Success) {
        setReviews(x.Value);
      } else {
        toast.error(x.Message, { autoClose: 30000 });
      }
    });

  _server.postApi<string[]>(`../OrgApi/OrgUsersReviewGroups`, { orgId: props.orgId, userId: props.state.User?.PublicId }).then((x) => {
    if (x.Success) {
      let value: Record<string, boolean> = {};
      x.Value.forEach(v=>{
        value[v] = true;
      });
      setMyGroups(value);
    } else {
      toast.error(x.Message, { autoClose: 30000 });
    }
  });
};

  const openNewRelationship = () => {
    toast.dark('Comming soon. Adding a new relationship');
  };

  return (
    <>
      <div>
        <h3>Requested Reviews</h3>
        {reviews
          ?.sort((a, b) => {
            
            if(myGroups[a.ReviewGroupTemplatePublicId] !== myGroups[b.ReviewGroupTemplatePublicId]) 
            return myGroups[a.ReviewGroupTemplatePublicId] ? -1:1;

            if((a.ApprovedAtUtc && b.ApprovedAtUtc) || (!a.ApprovedAtUtc && !b.ApprovedAtUtc))
            return a.RequestedAtUtc > b.RequestedAtUtc ? 1 : -1;
            return a.ApprovedAtUtc ? 1:-1;
          })
          .map((x, i) => {
            let approved = x.ApprovedAtUtc ? true : false;
            let canSign = myGroups[x.ReviewGroupTemplatePublicId];

            return (
              <div className={`bordered flex-between requested-reviews ${approved ? 'done':''}`} key={`rr-${i}`}>
                <div>
                  <strong>{x.WorkbookName}</strong>
                  <br />
                  <span className="small">{x.WorkbookTemplateName}</span>
                </div>
                <div>
                  <div>{x.SignatureName}</div>
                  {approved ? <span><Icon type={IconType.checkCircle} addClass='text-success'/> Approved at: {FormatDate(x.ApprovedAtUtc, 'date-time')}</span>
                  :<span>{FormatDate(x.RequestedAtUtc, 'date-time')}</span>}
                </div>
                <div>
                  {canSign ? <a className='fa-2x' href={x.DirectSignLink} title='click to sign' target={'_blank'}><Icon type={approved ? IconType.UserCheck : IconType.fileSignature}/></a>
                  : <span title='Not approved to sign this form.' className='text-mute fa-2x'><Icon type={approved ? IconType.UserCheck : IconType.fileSignature}/></span>}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default OrganizationsRequestedReviews;
