import { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { toast } from "react-toastify";
import { _server } from "../../../utils/Server";
import ConfigFormEditor from "../../FormManager/ConfigFormEditor";
import FormManagerLanding from "../../FormManager/FormManagerLanding";
import Workbook from "../Workbook";
import { IWorkbookTemplateConfigDetails } from "../WorkbookTypes";
import CopyToClipboard from "../../../utils/CopyToClipboard";
import Icon, { IconType } from "../../Icon/Icon";
import redux from "../../../Redux/Redux";

interface IWorkbookTemplateMangerProps extends RouteComponentProps<MatchParams>{

  }
  
  interface MatchParams {
      templateId?: string;
    }

const WorkbookTemplateManager = (props:IWorkbookTemplateMangerProps) =>{
    const [templateId, setTemplateId] = useState<string>();
    const [details, setDetails] = useState<IWorkbookTemplateConfigDetails>();
    const [showEditModal, setShowEditModal] = useState<boolean>();
    const [tabIndex, setTabIndex] = useState(0);

    useEffect(()=>{
        if(props.match.params.templateId)
        setTemplateId(props.match.params.templateId);
    },[props.match.params.templateId]);

    useEffect(()=>{
        setShowEditModal(tabIndex === 1);
},[tabIndex]);

    useEffect(()=>{
        if(templateId) Init();
    },[templateId]);

    const Init = ()=>{
        _server.getApi<IWorkbookTemplateConfigDetails>(`../WorkbookTemplate/Template/${templateId}`)
        .then(x=>{
            if(x.Success){
                setDetails(x.Value);
            } else {
                toast.error(x.Message,{autoClose:false});
            }
        });
    };

    const onTemplateUpdate = (x:any) =>{
        console.log(x)
        if(x?.Name && details) setDetails({...details,Name:x.Name});
    };

    return (
        <>
            {details && <>
                <div className="flex-between">
                <h2>{details.Name}</h2>
                <div className="text-center">
                    <CopyToClipboard valueToCopy={details.PublicId}>Public Id: {details.PublicId}</CopyToClipboard>
                    <br/>
                    <CopyToClipboard valueToCopy={details.Id}>Id: {details.Id}</CopyToClipboard>
                </div>
                <div>
                <button type="button" className="btn btn-default btn-xs" title={'Show menu'} onClick={()=>{redux.setShowNav(true)}} ><Icon type={IconType.bars} /> Menu</button>
                </div>
                </div>
                <hr/>
                <div>A workbook template is made up of several components, and when those components are put together a workbook template is fully formed.</div>
                <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                    <TabList>
                        <Tab>Preview</Tab>
                        <Tab>Main</Tab>
                        <Tab>Pages</Tab>
                        <Tab>Elements</Tab>
                        <Tab>Data Points</Tab>
                        <Tab>Form Groups</Tab>
                        <Tab>Milestones</Tab>
                        <Tab>Signers</Tab>
                        <Tab>Review Groups</Tab>
                        <Tab>Notifications</Tab>
                        <Tab>Sets</Tab>
                        <Tab>Calulations</Tab>
                        <Tab>Pdf</Tab>
                        <Tab>FAQ</Tab>
                        <Tab>Styles</Tab>
                    </TabList>
                        <TabPanel><Workbook Preview={true} WorkbookTemplateId={details.PublicId} /></TabPanel>
                        <TabPanel>      {showEditModal && (
        <ConfigFormEditor
                            type={"workbook-template"}
                            item={{ Id: details.PublicId, Name: details.Name, Type:"workbook-template" }}
                            canSave={true}
                            noEdit={false}
                            itemChildren={undefined}
                            onClose={() => {
                                setTabIndex(2);
                                setShowEditModal(false);
                            } }
                            onSave={onTemplateUpdate}
                            onRemove={onTemplateUpdate}
                            onUpdate={onTemplateUpdate} 
                            itemKey={undefined}        />
      )}</TabPanel>
                        <TabPanel><FormManagerLanding type={"workbook-template-page"} id={details.Id} /></TabPanel>
                        <TabPanel><FormManagerLanding type={"workbook-template-element"} id={details.Id} /></TabPanel>
                        <TabPanel><FormManagerLanding type={"workbook-template-data-point"} id={details.Id} /></TabPanel>
                        <TabPanel><FormManagerLanding type={"workbook-template-form-group"} id={details.Id} /></TabPanel>
                        <TabPanel><FormManagerLanding type={"workbook-template-milestone"} id={details.Id} /></TabPanel>
                        <TabPanel><FormManagerLanding type={"workbook-template-signer"} id={details.Id} /></TabPanel>
                        <TabPanel><FormManagerLanding type={"workbook-template-review-group"} id={details.Id} /></TabPanel>
                        <TabPanel><FormManagerLanding type={"workbook-template-notification"} id={details.Id} /></TabPanel>
                        <TabPanel><FormManagerLanding type={"workbook-template-set"} id={details.Id} /></TabPanel>
                        <TabPanel><FormManagerLanding type={"workbook-template-calculation"} id={details.Id} /></TabPanel>
                        <TabPanel><FormManagerLanding type={"workbook-template-pdf"} id={details.Id} /></TabPanel>
                        <TabPanel><FormManagerLanding type={"workbook-template-faq"} id={details.Id} /></TabPanel>
                        <TabPanel><FormManagerLanding type={"workbook-template-theme"} id={details.Id} /></TabPanel>
                </Tabs>
            </>}
        </>
    );
};

export default withRouter(WorkbookTemplateManager);