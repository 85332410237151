import { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { inflate } from 'zlib';
import { connectMap, IState } from '../../../Redux/Redux';
import { _server } from '../../../utils/Server';
import Icon, { IconType } from '../../Icon/Icon';
import Editor from '../../Tools/Editor';
import { IMilestone, ISigner, ISignRequest, IWorkbookInfo, IWorkbookReviewGroupInfo, IWorkbookTemplateReviewGroups } from '../WorkbookTypes';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Modal from '../../Modal/Modal';
import WorkbookShareManager from '../../Workbook/WorkbookShareManager';
import CopyToClipboard from '../../../utils/CopyToClipboard';

interface IApprovalRequestProps extends RouteComponentProps<any>, IState {
  milestone: IMilestone;
  signer: ISigner;
  info: IWorkbookInfo;
  onStartSign?: () => void;
  onRequest?: (signer: ISigner, mile: IMilestone, request: ISignRequest) => void;
}

interface IForm {
  textMessage?: string;
  phone?: string;
  message?: string;
  email?: string;
  sendLink?: boolean;
  sendEmail?: boolean;
  sendText?: boolean;
}

const ApprovalRequest = (props: IApprovalRequestProps) => {
  const [form, setForm] = useState<IForm>({});
  const [signRequest, setSignRequest] = useState<ISignRequest>();
  const [isSelf, setIsSelf] = useState<boolean>(false);
  const [reviewGroups, setReviewGroups] = useState<IWorkbookTemplateReviewGroups[]>([]);
  const [workbooksGroups, setWorkbooksGroups] = useState<IWorkbookReviewGroupInfo[]>([]);
  const [showShareWorkbook, setShowShareWorkbook] = useState<boolean>(false);

  useEffect(() => {
    setIsSelf(props.signer.IsSignerUser && !props.signer.IsSetBasedOnOwner && props.User?.PublicId === props.signer._ownerId);

    let groups: IWorkbookTemplateReviewGroups[] = [];

    props.signer.ReviewGroups?.forEach((x) => {
      let group = props.info.TemplateDic[x?.toLowerCase()];
      if (group) groups.push(group);
      console.log('Review Groups: ', x, group);
    });

    setReviewGroups(groups);
  }, [props.User, props.signer, props.info]);

  useEffect(() => {
    if (reviewGroups?.length > 0) {
      //call server to see if there any already or if the workbook should be shared...
      LoadReviewGroups();
    }
  }, [reviewGroups]);

  useEffect(() => {
    setForm({ sendEmail: true, message: defaultEmailMessageForSignRequest(), textMessage: defaultTextMessageForSignRequest() });
    setSignRequest(
      props.info.SignRequests.find(
        (x) => x.SignerId == props.signer.PublicId && x.MilestoneId == props.milestone.PublicId && (props.signer._ownerId === x.OwnerId || (!x.OwnerId && !props.signer._ownerId))
      )
    );
  }, [props.info]);

  const LoadReviewGroups = () =>{
    _server.postApi<IWorkbookReviewGroupInfo[]>(`../WorkbookApi/ReviewGroups`, { workbookId: props.info.WorkbookId, reviewGroups: reviewGroups.map((x) => x.PublicId) }).then((x) => {
      if (x.Success) {
        setWorkbooksGroups(x.Value ?? []);
      } else {
        toast.error(x.Message, { autoClose: 30000 });
      }
    });
  };

  const defaultEmailMessageForSignRequest = () => {
    return '<div>Hi, <br/><br/>I have been working hard on my project and need to get your approval. Please click this link [LINK] and you will be able to review and sign my forms.<br/><br/>Thank you,<br/></div>';
  };

  const defaultTextMessageForSignRequest = () => {
    return 'Hi, Please click the [LINK] so you can review and aprove. Thanks!';
  };

  const sendSignRequest = () => {
    let r: ISignRequest = {
      MilestoneId: props.milestone.PublicId,
      SignerId: props.signer.PublicId,
      WorkbookId: props.info.WorkbookId,
      WorkbookTemplateId: props.info.WorkbookTemplateId,
      RequestMessage: form.message,
      RequestTextMessage: form.textMessage,
      Sms: form.sendText && form.phone ? true : false,
      Email: form.email,
      Phone: form.phone,
      OwnerId: props.signer._ownerId,
    };

    if (form.sendText && (!r.Phone || r.Phone.length < 10)) {
      toast.warning(`Please provide a valid number.`);
      return;
    }
    if (form.sendEmail && (!r.Email || r.Email.length < 8 || r.Email.indexOf('@') < 0 || r.Email.indexOf('.') < 0)) {
      toast.warning(`Please provide a valid email.`);
      return;
    }
    if ((form.sendEmail && (r.RequestMessage?.indexOf('[LINK]') ?? -1) < 0) || (form.sendText && (r.RequestTextMessage?.indexOf('[LINK]') ?? -1) < 0)) {
      toast.error(`Your message must have "[LINK]"`);
      return;
    }

    _server.postApi<ISignRequest>(`../WorkbookApi/RequestApproval`, r).then((x) => {
      if (x.Success) {
        setSignRequest(x.Value);
        if (props.onRequest) props.onRequest(props.signer, props.milestone, x.Value);
      } else {
        toast.error(x.Message, { autoClose: false });
      }
    });
  };
  const sendSignRequestToReviewGroup = (orgId:string, reviewGroupPublicId: string) => {
    let r: ISignRequest = {
      MilestoneId: props.milestone.PublicId,
      SignerId: props.signer.PublicId,
      WorkbookId: props.info.WorkbookId,
      WorkbookTemplateId: props.info.WorkbookTemplateId,
      //RequestMessage: form.message,
      ReviewGroupRequest: true,
      ReviewGroupOrgPublicId:orgId,
      ReviewGroupTemplatePublicId:reviewGroupPublicId,
    };

    _server.postApi<ISignRequest>(`../WorkbookApi/RequestApproval`, r).then((x) => {
      if (x.Success) {
        setSignRequest(x.Value);
        if (props.onRequest) props.onRequest(props.signer, props.milestone, x.Value);
      } else {
        toast.error(x.Message, { autoClose: false });
      }
    });
  };

  const startReview = () => {
    let r: ISignRequest = {
      MilestoneId: props.milestone.PublicId,
      SignerId: props.signer.PublicId,
      WorkbookId: props.info.WorkbookId,
      WorkbookTemplateId: props.info.WorkbookTemplateId,
      Sms: false,
      OwnerId: props.signer._ownerId,
    };
    _server.postApi<ISignRequest>(`../WorkbookApi/RequestApproval`, r).then((x) => {
      if (x.Success) {
        setSignRequest(x.Value);
        if (props.onRequest) props.onRequest(props.signer, props.milestone, x.Value);
        if (props.onStartSign) props.onStartSign();
        props.history.push(`../SignRequest/${x.Value.PublicId}`);
      } else {
        toast.error(x.Message, { autoClose: false });
      }
    });
  };
  return (
    <>
      {!isSelf && !signRequest && (
        <div className="form-horizontal">
          {props.signer.OwnerInstructions && <div dangerouslySetInnerHTML={{ __html: props.signer.OwnerInstructions }}></div>}
          <Tabs>
            <TabList>
              {reviewGroups.length > 0 && <Tab>Review</Tab>}
              <Tab>Request Outside Review</Tab>
            </TabList>
            {reviewGroups.length > 0 && (
              <TabPanel>
                <h4>Review Time</h4>
                Well now that you are ready, let's get your review and approval done. Good news, for this approval we've setup a review group, just click <strong>request review now</strong> and your
                reviewers will be notified.
                <div>
                  {workbooksGroups.length > 0 ? (
                    <div>                      
                      {workbooksGroups.map((x, i) => {
                        return (
                          <div className="flex-between bordered" key={`sr-wbg-${i}`}>
                            <div><strong>{x.OrgName}</strong>
                            <br/> {x.ReviewGroupName}
                            </div>

                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={() => {
                                sendSignRequestToReviewGroup(x.OrgPublicId, x.ReviewGroupPublicId);
                              }}>
                              <Icon type={IconType.signature} /> Request Review Now
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="alert alert-info">
                      <h5>
                        <Icon type={IconType.warning} /> No Review Groups Found
                      </h5>
                      For the simplest review process share your workbook "{props.info.Name}" with your review team.
                    </div>
                  )}
                  
                  <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={() => {
                                setShowShareWorkbook(true)
                              }}>
                              <Icon type={IconType.share} /> Share Workbook
                            </button>
                </div>
              </TabPanel>
            )}
            <TabPanel>
              {
                <>
                  <h4>Request Approval</h4>
                  What is the best way to contact the person that needs to do the approval?
                </>
              }
              <div className="flex-centered request-sig-options">
                <div
                  className={`sig-option ${form.sendEmail ? 'selected' : ''}`}
                  onClick={() => {
                    setForm({
                      ...form,
                      sendEmail: !(form.sendEmail === true),
                    });
                  }}>
                  Email
                </div>
                <div
                  className={`sig-option ${form.sendText ? 'selected' : ''}`}
                  onClick={() => {
                    setForm({ ...form, sendText: !(form.sendText === true) });
                  }}>
                  Text Message
                </div>
                <div
                  className={`sig-option ${form.sendLink ? 'selected' : ''}`}
                  onClick={() => {
                    setForm({ ...form, sendLink: !(form.sendLink === true) });
                  }}>
                  Link
                </div>
              </div>
              <div>
                <div className="form-horizontal">
                  {form.sendEmail && (
                    <>
                      <div className="form-group">
                        <label className="col-sm-4 control-label" htmlFor="request-sig-email">
                          Email
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="email"
                            id={`request-sig-email`}
                            className="form-control"
                            maxLength={250}
                            value={form.email}
                            onChange={(e) => {
                              setForm({ ...form, email: e.target.value });
                            }}
                          />
                        </div>
                      </div>
                      {false && (
                        <div className="form-group">
                          <label className="col-sm-4 control-label" htmlFor="request-sig-email">
                            Message
                          </label>
                          <div className="col-sm-8">
                            <Editor
                              options={'simple'}
                              height={'short'}
                              value={form.message ?? ''}
                              changed={(v) => {
                                setForm({ ...form, message: v });
                              }}
                              hideExtend={true}
                            />
                            {form.message && form.message?.indexOf('[LINK]') === -1 && <span className="label label-danger">[LINK] is required to be part of the message.</span>}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {form.sendText && (
                    <>
                      <div className="form-group">
                        <label className="col-sm-4 control-label" htmlFor="request-sig-phone">
                          Phone Number
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="tel"
                            id={`request-sig-phone`}
                            className="form-control"
                            maxLength={15}
                            value={form.phone}
                            onChange={(e) => {
                              setForm({ ...form, phone: e.target.value });
                            }}
                          />
                        </div>
                      </div>
                      {false && (
                        <div className="form-group">
                          <label className="col-sm-4 control-label" htmlFor="request-sig-phone">
                            Text Message
                          </label>
                          <div className="col-sm-8">
                            <input
                              type="text"
                              id={`request-sig-phone`}
                              className="form-control"
                              maxLength={100}
                              value={form.textMessage}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  textMessage: e.target.value,
                                });
                              }}
                            />
                            {form.textMessage && form.textMessage?.indexOf('[LINK]') === -1 && <span className="label label-danger">[LINK] is required to be part of the message.</span>}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="col-sm-offset-4 col-sm-8">
                <button type="button" className="btn btn-secondary" onClick={sendSignRequest}>
                  <Icon type={IconType.send} /> Request
                </button>
              </div>
            </TabPanel>
          </Tabs>
          <div></div>
        </div>
      )}
      {!isSelf && signRequest && (
        <div>
          <div className="headroom-xl alert alert-success">
            <h3>Approval Request Processed</h3>
            <hr />
            {signRequest.EmailSent && (
              <div>
                <Icon type={IconType.mail} /> Email sent to <strong>{signRequest.Email}</strong>
              </div>
            )}
            {signRequest.TextSent && (
              <div>
                <Icon type={IconType.textMsg} /> Text message sent to <strong>{signRequest.Phone}</strong>
              </div>
            )}
            <div className="headroom-xl" />
            {!signRequest.ReviewGroupRequest && (
              <div className="flex-between">
                <div>
                  <strong>Approve now link:</strong> <CopyToClipboard valueToCopy={signRequest.DirectSignLink}>{signRequest.DirectSignLink}</CopyToClipboard>
                </div>
                
                <CopyToClipboard valueToCopy={signRequest.DirectSignLink}>Copy Link</CopyToClipboard>
                
              </div>
            )}
            {signRequest.ReviewGroupRequest && (
              <div className="">
                <h4>Your request is waiting to be processed</h4>
                By: {props.info.TemplateDic[signRequest.ReviewGroupTemplatePublicId ?? '']?.Name ?? ''}
                {/* By: {reviewGroups.find(x=>x. == signRequest.ReviewGroupOrgId)?.Name} */}
              </div>
            )}
          </div>
        </div>
      )}
      {isSelf && (
        <div>
          <h4>{props.User?.FirstName}, it's review time!</h4>
          It looks like you are ready to review and approve your workbook. {props.User?.FirstName}, when you are ready to start the process click the "Start Review" button. Don't worry its a fast
          process.
          <hr />
          <div className="headroom">
            <button type="button" className="btn btn-secondary" onClick={startReview}>
              <Icon type={IconType.fileAlt} /> Start Review
            </button>
          </div>
        </div>
      )}

      {showShareWorkbook && <Modal setModalOpen={setShowShareWorkbook} title={'Workbook Sharing'} size='l' onClose={()=>{LoadReviewGroups()}}>
          <WorkbookShareManager state={props} workbookName={props.info.Name} workbookPublicId={props.info.WorkbookId} close={()=>{
            setShowShareWorkbook(false);
          }} />
        </Modal>}
    </>
  );
};

export default connectMap()(withRouter(ApprovalRequest));
