import { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { toast } from "react-toastify";
import { Server, _server } from "../../utils/Server";
import WorkbookFormGroup from "./WorkbookFormGroup";
import {
  IDataValue,
  IElement,
  IFormGroup,
  IMilestone,
  IPage,
  ISignRequest,
  IWorkbookInfo,
} from "./WorkbookTypes";
import Icon, { IconType } from "../Icon/Icon";
import WorkbookMilestone from "./WorkbookMilestone";
import ImagePlus from "../Tools/Image";
import PdfViewer from "../Tools/Pdf/PdfViewer";
import { title } from "process";
import { canShow, regexReplace } from "./WorkbookFunctions";
import { formatVideoUrl } from "../../utils/Tools";

interface IWorkbookElementProps extends RouteComponentProps<MatchParams> {
  element: IElement;
  info: IWorkbookInfo;  
  onJumpToPage:(pageId:string)=>void;
  onValueChange: (
    key: string,
    setId: string | null,
    value: string[],
    files: File[] | null | undefined
  ) => void;
  onEditClick: (
    type: string,
    subType: string | null,
    key: string,
    callback: Function
  ) => void;
  onSignRequest: (request: ISignRequest) => void;
  preview: boolean;
  readOnly: boolean;
  setId?:string;
  setKey?:string;
}

interface MatchParams {}

const WorkbookElement = (props: IWorkbookElementProps) => {
  const [element, setElement] = useState<IElement>();
  const [show, setShow] = useState<boolean>(true);

  useEffect(() => {
    if (props.element) {
      let el = props.element;
      let showNow = canShow(props.info, el, props.setId, props.setKey);
      setShow(showNow);
      el._validPath = showNow;
      setElement(el);
    }
  }, [props.element?.PublicId, props.element?._validPath, props.info.Values]);

  const onValueChange = (
    key: string,
    setId: string | null,
    value: string[],
    files: File[] | null | undefined
  ) => {
    if (props.onValueChange) props.onValueChange(key, setId, value, files);
  };

  const buildType = () => {
    if (element && element.ElementType) {
      let x: any = props.info.TemplateDic[element.ElementType];
        switch (x?.WorkbookProperType ?? element.ElementType) {
          case "group":
            return (
              <WorkbookFormGroup
                readOnly={element.ReadOnly || props.readOnly}
                onEditClick={props.onEditClick}
                preview={props.preview}
                group={x}
                info={props.info}
                onValueChange={onValueChange}
                onJumpToPage={props.onJumpToPage}
                setId={props.setId}
                setKey={props.setKey}
              />
            );
            break;
          case "milestone":
            if (
              !props.info.Signer ||
              props.info.SignRequests.find((s) => s.MilestoneId === x.PublicId)
            ) {
              return (
                <WorkbookMilestone
                  readOnly={element.ReadOnly || props.readOnly}
                  onEditClick={props.onEditClick}
                  preview={props.preview}
                  onSignRequest={props.onSignRequest}
                  milestone={x}
                  info={props.info}
                  onJumpToPage={props.onJumpToPage}
                  onValueChange={onValueChange}
                  setId={null}
                />
              );
            }
            break;
          case "file":
            return (
              <>
                <div className="image" id={'parent-'+element.PublicId}>
                  <ImagePlus src={Server.FileUrl(element.ContentFile ?? "")} id={'x-'+element.PublicId} />
                </div>
              </>
            );
            break;
          case "pdf":
            return (
              <>
                <div className="pdf" id={'parent-'+element.PublicId}>
                  <PdfViewer
                    id={'x-'+element.PublicId}
                    source={Server.FileUrl(element.ContentFile ?? "")}
                    signRequested={() => {}}
                    viewOnly={true}
                  /> 
                </div>
              </>
            );
            break;
          case "video":
            if(element.ContentFile){ //video is hosted on our servers. 
            return (
              <>
            <video width="100%" height="100%" controls id={'x-'+element.PublicId}>
              <source src={Server.BuildUrl(`../file/x/${element.ContentFile}`)} type="video/mp4" />
              Your browser does not support the video tag.
            </video>

              </>
            );}
            let url = formatVideoUrl(element.Description);
            return (<>
            <div className="embeded-video">
            <iframe 
                        src={url} 
                        width='100%' 
                        height='100%' 
                      ></iframe>

            </div>
            </>);
            break;
          default:
            return (
              <>
                {element.Name} unmapped build type. Type:{" "}
                {element.WorkbookProperType}
              </>
            );
            break;
        }
    }
    return <></>;
  };

  const buildGroup = (x: IFormGroup) => {
    return <>Group: {x.Name}</>;
  };
  const buildMilestone = (x: IMilestone) => {
    return <>Milestone: {x.Name}</>;
  };
  const updated = (info: IWorkbookInfo) => {
    let p: IElement = info.TemplateDic[props.element.PublicId];
    setElement(p);
  };

  return (
    <>
      {element && (show || props.preview) && props.info && (
        <div className={`wb-element indent-${element.IndentLevel ?? '0'} `} id={'el-'+element.PublicId}>
          {props.preview && !show && (
            <div className="not-shown">Not Shown</div>
          )}
          {props.preview && (
            <div
              className="click edit-me"
              title={`Element: ${element.Name}`}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                
                props.onEditClick(
                  "workbook-template-element",
                  null,
                  element.PublicId,
                  updated
                );
              }}
            >
              <Icon type={IconType.edit} />
            </div>
          )}
          {element.Content && (
            <div
              id={'el-content'+props.element}
              className="headroom"
              dangerouslySetInnerHTML={{ __html: regexReplace(props.info, element.Content) }}
            ></div>
          )}
          
          {buildType()}
        </div>
      )}
    </>
  );
};

export default withRouter(WorkbookElement);

