import React, { useEffect, useState } from 'react'
// import { connect } from 'react-redux'
import './loading.css'
import { IState, ReduxMapper } from '../../Redux/Redux';

const Loading = (props:IState) => {
  const [progress, setProgress] = useState<number>();
  const [showProgress, setShowProgress] = useState<boolean>(false);

  useEffect(()=>{

    if(props.UploadProgress){
      setProgress(props.UploadProgress.loaded / props.UploadProgress.total * 100.0);
      setShowProgress(true);
    }
    else (setShowProgress(false))
  },[props.UploadProgress]);
  return(
    <div className="loading-wrapper">
    <div className="wrapper">
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="shadow"></div>
        <div className="shadow"></div>
        <div className="shadow"></div>
        {showProgress ? <div className='loading-progress flex-between'>
          Uploading... {progress?.toFixed(2)}%
        </div>
        :<span>{'Loading'}</span>}
    </div>
    </div>
  )
}

// const mapStateToProps = (reduxState) => {
//   return reduxState.main
// }

export default ReduxMapper(Loading);