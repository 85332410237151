import { group, info } from "console";
import { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { removeProps } from "react-select/dist/declarations/src/utils";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { toast } from "react-toastify";
import { _server } from "../../utils/Server";
import Editor from "../Tools/Editor";
import WorkbookInput from "./WorkbookInput";
import { IDataPoint, IElement, IFormGroup, IMilestone, IPage, IWorkbookInfo } from "./WorkbookTypes";
import Icon, { IconType } from "../Icon/Icon";
import { regexReplace } from "./WorkbookFunctions";

interface IWorkbookFormGroupProps extends RouteComponentProps<MatchParams> {
  group: IFormGroup;
  info: IWorkbookInfo;
  setId?: string;
  setKey?:string;
  onValueChange: (key:string,setId:string|null,value:string[],files:File[]|null|undefined)=>void;
  onEditClick:(type:string,subType:string|null,key:string,callback:Function)=>void;  
  onJumpToPage:(pageId:string)=>void;
  preview:boolean;
  readOnly?:boolean;
}

interface MatchParams {}

const WorkbookFormGroup = (props: IWorkbookFormGroupProps) => {
  const [formGroup, setFormGroup] = useState<IFormGroup>();

  useEffect(() => {
    if(props.group){
        setFormGroup(props.group);
    }
  }, [props.group?.PublicId]);

  useEffect(()=>{
    
  }, [props.info])

  const onValueChange = (key:string,setId:string|null,value:string[],files:File[]|null|undefined)=>{
    if(props.onValueChange) props.onValueChange(key, setId, value, files);
  };

  const buildForm = () =>{
    if(formGroup){
        return formGroup.Children.sort((a,b)=>{return (a.Order)>(b.Order)?1:-1;})
        .map((e, ie)=>{
          let x:IDataPoint = props.info.TemplateDic[e.Id];
            return <WorkbookInput 
            readOnly={props.readOnly}
            onEditClick={props.onEditClick}
            preview={props.preview}
            labelOverride={e.LabelOverride}
            key={`wbfi-${formGroup.PublicId}-${ie}`} info={props.info} onValueChange={onValueChange} input={x} setId={props.setId} setKey={props.setKey} />
        });
    }
    return <></>
  };
  const updated = (info:IWorkbookInfo)=>{
    let p:IFormGroup = info.TemplateDic[props.group.PublicId];
        setFormGroup(p);
  };

  return (
    <>
      {formGroup && props.info && 
      <div className="wb-group" id={'g-'+props.group}>
      {props.preview && <div className="click edit-me" title={`form group: ${formGroup.Name}`} onClick={(e)=>{e.stopPropagation(); e.preventDefault();props.onEditClick('workbook-template-form-group',null,formGroup.PublicId, updated);}}><Icon type={IconType.edit}/></div>}
        {formGroup.Header && <div id={'g-header'+props.group} dangerouslySetInnerHTML={{__html:regexReplace(props.info, formGroup.Header)}}></div>}
        <div className="form-horizontal">
            {buildForm()}
        </div>
        {formGroup.Footer && <div id={'g-footer'+props.group} dangerouslySetInnerHTML={{__html:regexReplace(props.info, formGroup.Footer)}}></div>}
      </div>}
    </>
  );
};

export default withRouter(WorkbookFormGroup);
