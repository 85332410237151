import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { _server } from "../../utils/Server";
import { IConfigFormEditorData, IConfigItemChildType, IConfigItemSummary } from "./FormManagerTypes";
import FormModal, { IFileUpload } from "./FormModal";



interface IConfigFromEditorProps {
    type:string;
    item:IConfigItemSummary;
    itemKey:string|number|undefined;
    canSave:boolean;
    noEdit?:boolean;
    onSave?:Function;
    onClose?:Function;
    onRemove?:Function;
    onUpdate?:Function;
    title?:string;
    itemChildren?:IConfigItemChildType[];
    dontRefreshOnSaveOrRemove?:boolean;
    managerControler?:string;
    noModal?:boolean;
}

const ConfigFormEditor = (props:IConfigFromEditorProps)=>{
    const[type, setType] = useState<string>(props.type);
    const [data, setData] = useState<IConfigFormEditorData>();
    const [showEditModal, setShowEditModal] = useState<boolean>(true);


    useEffect(()=>{
        console.log('Calling init');
        init();
    },[props.item, props.type]);

    const init = () =>{
        console.log('int called');
        getForm();
    };

    const getForm = (item?:IConfigItemSummary)=>{
        _server.postApi<IConfigFormEditorData>(`../${props.managerControler ?? 'FormManager'}/GetForm`, {...(item??props.item),Key:(item?.Key ?? props.itemKey)})
        .then(x=>{
            if(x.Success){
                setData(x.Value);
            } else {
                toast.error(`${x.Message}`, {autoClose:false});
            }
        });
    };

    const copyIt = ()=>{
        toast.dark('Copy called.');
        _server.postApi<IConfigItemSummary>(`../${props.managerControler ?? 'FormManager'}/Copy`,props.item)
        .then(x=>{
            if(x.Success){
                toast.success('Copied.');
                close();
                onUpdate(x.Value);
            }  else {
                toast.error(`${x.Message}`, {autoClose:false});
            }
        });
    };

    const save = (fields: Record<string, string[]>, files?:IFileUpload[])=>{
        let request = {Id:props.item.Id ?? -1, Type:props.type, Values:fields, Key:props.itemKey, SubType:props.item.SubType};
        let requestData = JSON.stringify(request);
        
        let filesToUpload:File[] = [];
        files?.forEach(x=>filesToUpload.push(x.file));

        _server.postApi<IConfigItemSummary>(`../${props.managerControler ?? 'FormManager'}/SaveForm`,{data:requestData}, filesToUpload)
        .then(x=>{
            if(x.Success && !x.Value.Failed){
                toast.success('Saved.');
                if(props.onSave) props.onSave(x.Value);
                onUpdate(x.Value);
                onSave();
                
                console.log('about to set fields');
                let sections = [...data?.Form.Sections ?? []];
                let values = data?.Values ?? {};
                sections.forEach(s=>{
                    s.Fields.forEach(f=>{
                        if(fields[f.Key]){
                            f.Value = fields[f.Key];
                            console.log('field set');
                            values[f.Key] = f.Value;
                        }
                    });
                });

                if(data) setData({...data, Values:values, Form:{...data.Form,Sections:sections}});
            }  else if (x.Success) {
                toast.warning(x.Value.Message, {autoClose:15000})   ;
            }else {
                toast.error(`${x.Message}`, {autoClose:false});
            }
        });

    };

    const onSave = () =>{
        //if(!props.dontRefreshOnSaveOrRemove) Redux.dispatch(setReloadStartConfig(true));
      };

    const remove = () =>{
        if(window.confirm(`Are you sure you want to delete "${props.item.Name}"?`)){
            _server.postApi<IConfigItemSummary>(`../${props.managerControler ?? 'FormManager'}/Remove`,props.item)
            .then(x=>{
                if(x.Success){
                    toast.success('Removed.');
                    if(props.onRemove) props.onRemove(props.item);
                    onUpdate();

    
                }  else {
                    toast.error(`${x.Message}`, {autoClose:false});
                }
            });
        }
    };

    const close = () =>{
        setShowEditModal(false);

        if(props.onClose) props.onClose();
    };

    const onUpdate = (item?:IConfigItemSummary) =>{
        if(!props.dontRefreshOnSaveOrRemove){
            //Redux.dispatch(setReloadStartConfig(true));
            console.log('OnPUdateCallingit')
            getForm(item);
        }
        if(props.onUpdate) props.onUpdate(item);
    };


    return (<>
        {showEditModal && data && <FormModal 
            save={save} 
            onUpdate={onUpdate}
            canSave={props.canSave} 
            sections={data?.Form.Sections ?? []} 
            linkToManager={data?.Form.LinkToManager}
            linkToManagerText={data?.Form.LinkToManagerLabelText}
            submitBtnText={data?.Form.SubmitBtnText}
            copyItem={data?.Form.CopyItem}
            copy={copyIt}
            show={showEditModal} 
            setShow={close} 
            title={props.title ?? "Editor"} 
            values={data?.Values ?? {}} 
            targetId={props.item?.Id ?? ""} 
            modalSize={props.item?.Id && props.itemChildren?.length ? "xl":"l"}            
            itemChildren={props.itemChildren}
            remove={remove}
            canRemove={props.canSave && (props.item?.Id ?? "-1") != "-1"}
            noEdit={props.noEdit ?? false}
            noModal={props.noModal}
            />}
    </>);
}

export default ConfigFormEditor;