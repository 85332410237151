import { applyMiddleware, createStore, combineReducers, Store, AnyAction } from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import { connect } from 'react-redux';
import { IWorkbookSummary, IWorkbookTemplateSummary } from '../Components/WorkbookTemplate/WorkbookTypes';
import { IUser } from '../Types/basic';
import { _server } from '../utils/Server';
import { IOrganizationInfo } from '../Components/Organizations/OrganizationTypes';
import { IOrgUserSummary, IUserSummary } from '../Types/User';

export interface IState {
  User?: IUser;
  BaseUser?: IUser;
  UsersWorkbooks?: IWorkbookSummary[];
  WorkbookTemplates?: IWorkbookTemplateSummary[];
  ShowLogin?: boolean;
  MyFiles?: IFileInfo[];
  OrgInfo?: IOrganizationInfo;
  OrgWorkbooks?: IWorkbookTemplateSummary[];
  OrgUsers?: IOrgUserSummary[];
  UploadProgress?: IProgressUpdate;
  HasNav?: boolean;
  ShowNav?: boolean;
}

export interface IFileInfo {
  PublicId: string;
  Name: string;
  Comments: string;
  Extension: string;
  ContentType: string;
  IsImage: boolean;
  IsPdf: boolean;
  IsVideo: boolean;
  SizeMB: number;
}

export interface IProgressUpdate {
  total: number;
  loaded: number;
}

export default class redux {
  static state: Store;
  static State() {
    if (!redux.state)
      redux.state =
        window.location.origin.indexOf('localhost') > -1 ? createStore(reducer, composeWithDevTools(applyMiddleware(promiseMiddleware))) : createStore(reducer, applyMiddleware(promiseMiddleware));

    return redux.state;
  }

  //======================================================
  //Place dispatch methods below this line; redux.yourMethod
  //======================================================

  static setUser(value: IUser | null) {
    return redux.state.dispatch({
      type: Actions.SetUser,
      value,
    });
  }
  static setBaseUser(value: IUser | null) {
    return redux.state.dispatch({
      type: Actions.SetBaseUser,
      value,
    });
  }

  static showLogin(value: boolean) {
    return redux.state.dispatch({
      type: Actions.ShowLogin,
      value,
    });
  }

  static setUsersWorkbooks = (value: IWorkbookSummary[]) => {
    return redux.state.dispatch({
      type: Actions.SetUsersWorkbooks,
      value,
    });
  };

  static uploadProgress(value: IProgressUpdate) {
    return redux.state.dispatch({
      type: Actions.SetWorkbookTemplates,
      value,
    });
  }

  static setTemplates = (value: IWorkbookTemplateSummary[]) => {
    return redux.state.dispatch({
      type: Actions.SetWorkbookTemplates,
      value,
    });
  };

  static setOrgInfo = (value: IOrganizationInfo) => {
    return redux.state.dispatch({
      type: Actions.SetOrgInfo,
      value,
    });
  };

  static setHasNav(value: boolean) {
    return redux.state.dispatch({
      type: Actions.SetHasNav,
      value,
    });
  }
  static setShowNav(value: boolean) {
    return redux.state.dispatch({
      type: Actions.SetShowNav,
      value,
    });
  }

  static loadMyFiles = (workbookId?: string, orgId?: string) => {
    _server.postApiQuite<IFileInfo[]>(`../File/MyFiles`, { workbookId, orgId }).then((x) => {
      if (x.Success) {
        return redux.state.dispatch({ type: Actions.SetMyFiles, value: x.Value });
      } else {
        console.log('failed to load my files...');
      }
    });
  };

  static setOrgWorkbooks(orgId: string | undefined) {
    _server.getApiQuiet<IWorkbookTemplateSummary[]>(`../OrgApi/OrgWorkbooks/${orgId}`).then((x) => {
      if (x.Success) {
        return redux.state.dispatch({ type: Actions.SetOrgWorkbooks, value: x.Value });
      } else {
        console.log('failed to Org Workbooks...');
      }
    });
  }
  static setOrgUsers(orgId: string | undefined) {
    _server.getApiQuiet<IOrgUserSummary[]>(`../OrgApi/OrgUsers/${orgId}`).then((x) => {
      if (x.Success) {
        return redux.state.dispatch({ type: Actions.SetOrgUsers, value: x.Value });
      } else {
        console.log('failed to Org Users...');
      }
    });
  }
}
enum Actions {
  Unknown,
  SetUsersWorkbooks,
  SetUser,
  SetBaseUser,
  SetWorkbookTemplates,
  ShowLogin,
  SetMyFiles,
  SetOrgInfo,
  SetOrgWorkbooks,
  SetOrgUsers,
  SetProgressUpdate,
  SetHasNav,
  SetShowNav,
}

export const AppReducer = (currentState: IState, action: AnyAction): IState => {
  let state = currentState ?? {};
  switch (action.type) {
    case Actions.SetUsersWorkbooks:
      return { ...state, UsersWorkbooks: action.value };
      break;
    case Actions.SetUser:
      return { ...state, User: action.value ?? undefined };
      break;
    case Actions.SetBaseUser:
      return { ...state, BaseUser: action.value ?? undefined };
      break;
    case Actions.SetWorkbookTemplates:
      return { ...state, WorkbookTemplates: action.value ?? [] };
      break;
    case Actions.ShowLogin:
      return { ...state, ShowLogin: action.value ?? false, UploadProgress: undefined };
      break;
    case Actions.SetMyFiles:
      return { ...state, MyFiles: action.value ?? [] };
      break;
    case Actions.SetOrgInfo:
      return { ...state, OrgInfo: action.value ?? [] };
      break;
    case Actions.SetHasNav:
      return { ...state, HasNav: action.value ?? [] };
      break;
    case Actions.SetShowNav:
      return { ...state, ShowNav: action.value ?? [] };
      break;
    case Actions.SetOrgUsers:
      return { ...state, OrgUsers: action.value ?? [] };
      break;
    case Actions.SetOrgWorkbooks:
      return { ...state, OrgWorkbooks: action.value ?? [] };
      break;
    case Actions.SetProgressUpdate:
      return { ...state, UploadProgress: action.value ?? undefined };
      break;
  }
  return state;
};

interface IStateAction {
  type: Actions;
  value: any;
}

export const connectMap = () => {
  const map = (x: any) => {
    return x.main;
  };
  return connect(map, {});
};

export const ReduxMapper = (component: any) => {
  const map = (x: any) => {
    return x.main;
  };
  return connect(map, {})(component);
};

const reducer = combineReducers({
  main: AppReducer,
});
