import { useEffect, useState } from 'react';
import redux, { IState, ReduxMapper } from '../../Redux/Redux';
import { IWorkbookTemplateSummary } from '../WorkbookTemplate/WorkbookTypes';
import OrganizationWorkbookTemplateSearchableList, { filterWorkbooks } from './OrganizationWorkbookTemplateSearchableList';
import { _server } from '../../utils/Server';
import { toast } from 'react-toastify';
import { IOrgUserSummary } from '../../Types/User';
import { IReviewGroupMember } from './OrganizationTypes';

interface IProps {
  orgId?:string;
  state:IState;
}

interface ISearch {
  workbook?: string;
}

const OrganizationWorkbookSignerAssignments = (props: IProps) => {
  const [workbooks, setWorkbooks] = useState<IWorkbookTemplateSummary[]>([]);
  const [workbook, setWorkbook] = useState<IWorkbookTemplateSummary>();
  const [search, setSearch] = useState<ISearch>({});
  const [groupMembers, setGroupMembers] = useState<Record<string, IReviewGroupMember[]>>({});

  useEffect(() => {
    if (props.state.User && props.state.User.IsAdmin) {
      init();
    }
  }, [props.state.User]);

  useEffect(() => {
    if (props.state.OrgWorkbooks) {
      if (search.workbook) {
        let text = search.workbook.toLowerCase();
        text += ' ' + search.workbook.replace(/[^0-9]/g, '');
        let lookFor: string[] = search.workbook
          .toLowerCase()
          .split(/[\s\,\t]/g)
          .filter((x) => x.length > 0);

        setWorkbooks(props.state.OrgWorkbooks?.filter((x) => filterWorkbooks(x, lookFor)) ?? []);
      } else {
        setWorkbooks(props.state.OrgWorkbooks);

        if (props.state.OrgWorkbooks.length == 1) {
          setWorkbook(props.state.OrgWorkbooks[0]);
        }
      }
    }
  }, [props.state.OrgWorkbooks, search]);

  useEffect(()=>{
    if(workbook){
//load members of these review groups...
_server.postApi<IReviewGroupMember[]>(`../OrgApi/WorkbookReviewGroupMembers`, {workbookId: workbook.PublicId, orgId:props.orgId})
.then(x=>{
  if(x.Success){
    let dic:Record<string, IReviewGroupMember[]> = {};
    x.Value.forEach(u=>{
      let list = dic[u.ReviewGroupPublicId] ?? [];
      list.push(u);
      dic[u.ReviewGroupPublicId] = list;
    });

    setGroupMembers(dic);

  } else {
    toast.error(x.Message, {autoClose:30000});
  }
});
    }
    else{
      //clear signers
    }
  }, [workbook]);

  const init = () => {
    if(!props.state.OrgWorkbooks) redux.setOrgWorkbooks(props.orgId);
  };

  return (
    <>
      {workbook && (
        <div>
          <h3>{workbook.Name}</h3>

          {workbook.WorkbookTemplateReviewGroups?.map((x,i)=>{
            let members:IReviewGroupMember[] = groupMembers[x.PublicId] ?? [];
            return <div key={`wts-${i}`}  className='bordered'>
                <div>
                    <h5>{x.Name}</h5>
                    {x.Description && <div className='description' dangerouslySetInnerHTML={{__html:x.Description}}/>}
                    <hr/>
                </div>
<div>
            {members.map((x,i)=>{
              return <div className='review-group-member-info' key={`rgmi-${i}`}>
                <div><span>{x.FirstName} {x.LastName}</span></div>
                <div><span className='small'>{x.Email}</span>               
                </div>
                
              </div>
            })}
  </div>
                
            </div>
          })}
        </div>
      )}
    </>
  );
};

export default OrganizationWorkbookSignerAssignments;
