import { Tooltip } from 'reactstrap';
import Icon, { IconType } from '../Components/Icon/Icon';
import { toast } from 'react-toastify';

interface IProps {
  children?: React.ReactNode;
  valueToCopy?: string | number;
  addClass?: string;
  hideCopyIcon?: boolean;
  toolTip?: string;
}

const CopyToClipboard = (props: IProps) => {
  const click = () => {
    let value: string = props.valueToCopy +'';
    window.navigator.clipboard.writeText(value);
    toast.dark(`${value} copied to clipboard`, {autoClose:3000});
  };

  return (
    <>
      <span className="copy" title={props.toolTip} onClick={click}>
        {!(props.hideCopyIcon ?? false) && (
          <>
            <Icon type={IconType.copy} />{' '}
          </>
        )}
        {props.children}
      </span>
    </>
  );
};

export default CopyToClipboard;
