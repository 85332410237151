import { useState } from "react";
import { connectMap, IState } from "../../Redux/Redux";
import Icon, { IconType } from "../Icon/Icon";
import './pointer.css'

interface IPointerProps extends IState{

}

const Pointer = (props: IPointerProps) => {
    const [show, setShow] = useState<boolean>(false);
  return (
    <>
      {show && <div className="look-down">
        <div className="look-here">
          <Icon type={IconType.arrowRight} />
        </div>{" "}
      </div>}
    </>
  );
};

export default connectMap()(Pointer);
