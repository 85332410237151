import { useEffect, useState } from "react";
import { removeProps } from "react-select/dist/declarations/src/utils";
import { toast } from "react-toastify";
import { connectMap, IState } from "../../../Redux/Redux";
import { _server } from "../../../utils/Server";
import { ICommentResponse } from "./MessageTypes";
import Comment from './Comment';
import { IWorkbookInfo } from "../WorkbookTypes";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import CommentWriter from "./CommentWriter";
import Icon, { IconType } from "../../Icon/Icon";

interface ICommentViewerProps extends IState{
    workbookId?:string;
    info?:IWorkbookInfo;
}

const CommentViewer = (props:ICommentViewerProps) =>{
    const [comments, setComments] = useState<ICommentResponse[]>([]);
    const [tabIndex, setTabIndex] = useState(0);

    useEffect(()=>{
        if(props.User){
            getComments();
        }
    }, [props.User]);
    
    const getComments = ()=>{
        _server.postApi<ICommentResponse[]>(`../CommunicationApi/Comments`, {workbookId: props.workbookId})
        .then(x=>{
            if(x.Success){
                setComments(x.Value ?? []);
            }else {
                toast.clearWaitingQueue();
                toast.error(x.Message, {autoClose:false});
            }
            
        });
    };

    const messageSent = (msg:ICommentResponse)=>{
        setComments([msg, ...comments])
    };

    return (<>
    <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
            <Tab>Messages</Tab>
            <Tab>Write Message</Tab>
        </TabList>
        <TabPanel>
            <h3>Workbook Messages</h3>
            <div className="comment-viewer-container">
                {comments.length > 0 && comments.map((x,i)=>{
                    return <Comment comment={x} info={props.info} key={`cv-c-${i}`} />
                })}
                {comments.length === 0 && <div className="headroom alert alert-info">
                        <h4><Icon type={IconType.warning}/> No messages!</h4>
                        There are no messages at this time.
                        <hr/>
                    </div>}
            </div>
        </TabPanel>
        <TabPanel>
                <h3>Send Comment</h3>
                <CommentWriter onSend={messageSent} sourceType={"Workbook"} workbookId={props.workbookId} />
        </TabPanel>
    </Tabs>
    </>);
};

export default connectMap()(CommentViewer);