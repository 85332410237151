import React, { useEffect, useState, useRef, useCallback } from "react";
//import pdf from "pdfjs"
import pdfjsLib from "pdfjs-dist/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import "./PdfStyle.css";
import PdfBasicToolbar from "./PdfBasicToolbar";
import PdfInput from "./PdfInput";
import { isPropertyAccessOrQualifiedName } from "typescript";
import { toast } from "react-toastify";
import PdfFooterToolbar from "./PdfFooterToolbar";
import { IFieldInfo, ISigner } from "./PdfTypes";

interface IPdfViewerProps {
  id: string;
  source?: string;
  values?: any;
  updateValues?: Function;
  close?: Function;
  fieldMetaData?:IFieldInfo[];
  signer?:ISigner;
  signRequested?:Function;
  downloadFormUrl?:string;
  viewOnly?:boolean;
  isSetupMode?:boolean;
  fieldSelected?:(field: IFieldInfo)=>void;
}

const PdfViewer = (props: IPdfViewerProps) => {
  const canvasRef = useRef<any>();
  pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

  const [pdfRef, setPdfRef] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const [scale, setScale] = useState(1.5);
  const [zoom, setZoom] = useState(1);
  const [pageAnnotations, setAnnotations] = useState<any[]>([]);
  const [formValues, setFormValues] = useState<any>(props.values ?? {});
  const [formValuesSet, setFormValuesSet] = useState<any>({});
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [signer, setSigner] = useState<ISigner>();
  const [missingFields, setMissingFields] = useState<Record<string,IFieldInfo>>({});
  
  let fmd = {};
  if(props.fieldMetaData){
    props.fieldMetaData.forEach(x=>{if(x.FormKey) fmd[x.FormKey] = x});
  }

  const [fieldsMetaData, setFieldsMetaData] = useState<any>(fmd);


  useEffect(()=>{
    setSigner(props.signer);
  }, [props.signer])

  const renderPage = useCallback(
    (pageNum, pdf = pdfRef) => {
      pdf &&
        pdf.getPage(pageNum).then(function (page) {
          let viewport = page.getViewport({ scale: scale });
          
          if(viewport.width > 1000){
            let w = viewport.width / scale;
            let s = 950 / w;
            setScale(scale);
            viewport = page.getViewport({ scale: s });
          }

          const canvas: any = canvasRef.current;
          if (!canvas) {
            return;
          }
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          const renderContext = {
            canvasContext: canvas.getContext("2d"),
            viewport: viewport,
          };
          page.render(renderContext);
          page.getAnnotations().then((items) => {
            setAnnotations(items);
          });
        });
    },
    [pdfRef, props.values]
  );

  useEffect(() => {
    renderPage(currentPage, pdfRef);
  }, [pdfRef, currentPage, renderPage]);

  useEffect(() => {
    const fullUrl = props.source ?? "";
    const loadingTask = pdfjsLib.getDocument(fullUrl);
    loadingTask.promise.then(
      (loadedPdf) => {
        setPdfRef(loadedPdf);
      },
      function (reason) {
        console.error(reason);
      }
    );
  }, [props.source]);

  const inputValueChanged = (id: string, value: string) => {
    console.log('Value Set: ',id, value);
    let newValues = { ...formValues, [id]: value };    
    setFormValues(newValues);

    let valuesSet = {...formValuesSet, [id]: value};
    setFormValuesSet(valuesSet);

  };

  const saveClick = (save: boolean, close: boolean) => {
    if(props.signer){
      //this is to submit signed stuff...
      if(save && props.updateValues){

        //was everything signed that needed to be?
        let signerFields = props?.fieldMetaData?.filter(x=> x.SignatureOwner === props.signer?.SignatureInfoId && x.Type !== 'Btn' && !x.Hidden) ?? [];

        let missing = signerFields.filter(x=> !formValuesSet[x.FormKey ?? '|#|'] && !formValues[x.FormKey ?? '|#|']);
        let missingDic:Record<string,IFieldInfo> = {};
        missing.forEach(x=>{missingDic[x.PublicId]=x});
        setMissingFields(missingDic);

        let valuesToSave = {...formValuesSet};

        signerFields.forEach(x=>{
          if(!valuesToSave[x.FormKey ?? '|#|']){
            valuesToSave[x.FormKey ?? '|#|'] = formValues[x.FormKey ?? '|#|'];
          }
        });

        if(missing.length > 0){
          toast.warning(`You have not filled out / signed all of the information you need to on this page.`);
          missing.forEach(x=>{
            console.log(`""${x.FormKey}""`, x.Label, x.Type, x.FormKey, x.Hidden);
            console.log(x);
            console.log(formValuesSet);
            console.log(formValues);
          })
          //if(!window.confirm('Are you sure you want to submit? You have not filled everything out yet.'))
          return;
        }

        props.updateValues(props.id, valuesToSave, close);
        setFormValuesSet({});
        return;
      }

      if(close && props.close){
        if(Object.keys(formValuesSet).length > 0 && !window.confirm(`If you close now your signature will not be saved. Are you sure you want to close?`)){
          return;
        }
        props.close();
      }
    }
    if (save && props.updateValues) {
      props.updateValues(props.id, formValues, close);
      return;
    }
    if (close && props.close) {
      props.close();
    }
  };

  const tabToNext = (index) => {
    for (let i = index + 1; i < pageAnnotations.length; i++) {
      let a = pageAnnotations[i];
      //if (a.hasAppearance && a.fieldType === "Tx") {
      if (a.fieldType === "Tx") {
        setTabIndex(i);
        return;
      }
    }
    if(pdfRef.numPages > currentPage){
      setTabIndex(0);
      setCurrentPage(currentPage+1);
    }
  };

  const signingRequested = (signatureOwner:string)=>{
    if(props.signRequested)
    props.signRequested(signatureOwner);
  }

  return (
    <>
      <div className="zpdf">
        <div className="zpdf-desk">
          <div className="zpdf-container">
            <PdfBasicToolbar
              page={currentPage}
              pages={pdfRef?.numPages ?? 0}
              viewOnly={props.viewOnly ?? false}
              changePage={(n) => {
                setCurrentPage(n);
              }}
              zoom={zoom}
              changeZoom={(n) => {
                setZoom(n);
              }}
              save={saveClick}
              isSigning={signer?true:false}
              downloadFormUrl={props.downloadFormUrl}
            />
            <div className="zpdf-viewer">
              <canvas className="zpdf-viewer-canvas" ref={canvasRef} />
              {!props.viewOnly && pageAnnotations.length > 0 &&
                pageAnnotations
                  //.filter((x) => x.hasAppearance && x.fieldType)
                  .filter((x) => x.fieldType)
                  .map((x, i) => {
                    let active = i === tabIndex;

                    return (
                      <PdfInput
                        index={i}
                        tabToNext={tabToNext}
                        hasFocus={active}
                        position={x.rect}
                        value={x.fieldValue}
                        exportValue={x.exportValue}
                        type={x.fieldType}
                        name={x.fieldName}
                        id={x.fieldName}
                        scale={scale}
                        valueUpdated={inputValueChanged}
                        values={formValues}
                        key={`page-input-${i}`}
                        fieldMetaData={fieldsMetaData[x.fieldName]}
                        canEdit={true}
                        signer={signer}       
                        requestSign={signingRequested}                 
                        isSetupMode={props.isSetupMode}
                        missingFields={missingFields}
                        fieldSelected={()=>{
                          if(props.fieldSelected) props.fieldSelected(x);
                        }}
                      />
                    );
                  })}
            </div>
            
            <PdfFooterToolbar
              page={currentPage}
              pages={pdfRef?.numPages ?? 0}
              changePage={(n) => {
                setCurrentPage(n);
              }}
              zoom={zoom}
              changeZoom={(n) => {
                setZoom(n);
              }}
              save={saveClick}
              isSigning={signer?true:false}
              downloadFormUrl={props.downloadFormUrl}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PdfViewer;
