import { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { toast } from "react-toastify";
import { Server, _server } from "../../utils/Server";
import Page from "./Page";
import PageThumbnail from "./PageThumbnail";
import {
  ICanShowElement,
  IDataPoint,
  IDataValue,
  IElement,
  IFormGroup,
  IFormItemSummary,
  IPage,
  ISigner,
  ISignRequest,
  ITheme,
  IWorkbookInfo,
  IWorkbookSaveRequest,
  MilestoneStates,
} from "./WorkbookTypes";
import "./WorkbookStyles.css";
import ConfigFormEditor from "../FormManager/ConfigFormEditor";
import { IConfigItemSummary } from "../FormManager/FormManagerTypes";
import React from "react";
import redux, { IState,connectMap } from '../../Redux/Redux';
import LoginOrRegister from "../User/LoginOrRegister";
import { FormatDate, LimitStringSize } from "../../utils/Tools";
import Icon, { IconType } from "../Icon/Icon";
import { buildTemplateDic, calculatePageAccess, workbookOnValueChanged } from "./WorkbookFunctions";
import CommentViewer from "./Messages/CommentViewer";
import Modal from "../Modal/Modal";
import { signOut } from "../Menu/Menu";
import ImagePlus from "../Tools/Image";
import { RunCalculations } from "./WorkbookCalculations";
import WorkbookInput from "./WorkbookInput";
import { WorkbookFaqContainer } from "./WorkbookFaqContainer";
import ConfettiExplosion from 'react-confetti-explosion';

interface IWorkbookProps extends RouteComponentProps<MatchParams>, IState {
  WorkbookTemplateId?: string;
  Preview?: boolean;
}

interface MatchParams {
  workbookId?: string;
  signRequestId?: string;
}

const Workbook = (props: IWorkbookProps) => {
  const [requestId, setRequestId] = useState<string>();
  const [workbookId, setWorkbookId] = useState<string>();
  const [workbookTemplateId, setWorkbookTemplateId] = useState<
    string | undefined
  >(props.WorkbookTemplateId);
  const [preview, setPreview] = useState<boolean>(props.Preview ?? false);
  const [info, setInfo] = useState<IWorkbookInfo>();
  const [page, setPage] = useState<IPage | undefined>();
  const [pageHistory, setPageHistory] = useState<string[]>([]);
  const [canContinue, setCanContinue] = useState<boolean>(true);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<IConfigItemSummary | undefined>();
  const [onEditCallback, setOnEditCallback] = useState<any>();
  const [signRequest, setSignRequest] = useState<ISignRequest>();
  const [signRequestSigner, setSignRequestSigner] = useState<ISigner>();
  const [showPageThumbnails, setShowPageThumbnails] = useState<boolean>();
  const [showComments, setShowComments] = useState<boolean>();
  const [showSignNotAllowed, setShowSignNotAllowed] = useState<boolean>();
  const [showSignerInstructionsModal, setShowSignerInstructionsModal] = useState<boolean>();
  const [showAllDataPoints, setShowAllDataPoints] = useState<boolean>();
  const [isCallingInfo, setIsCallingInfo] = useState<boolean>();
  const [css, setCss] = useState<string>();
  const [backgroudImage, setBackgroundImage] = useState<string>();
  const [showFaq, setShowFaq] = useState<boolean>(false);
  const [showDonePage, setShowDonePage] = useState<boolean>(false);


  useEffect(() => {
    if (
      props.WorkbookTemplateId &&
      workbookTemplateId != props.WorkbookTemplateId
    ) {
      setWorkbookTemplateId(props.WorkbookTemplateId);
    }
    if (
      props.match.params.workbookId &&
      workbookId !== props.match.params.workbookId
    ) {
      setWorkbookId(props.match.params.workbookId);
    }
  }, [props.WorkbookTemplateId, props.match.params.workbookId]);

  useEffect(() => {
    if (info) {
      //console.log('Values Changed - Calculating Page Access');
      setInfo(calculatePageAccess(info, preview, 'base'));
    }
  }, [info?.Values]);

  useEffect(() => {

    redux.setHasNav(true);
    if (info) {
      if (onEditCallback?.callback) onEditCallback.callback(info);
    }

    if(info && info.Template?.Template.StyleTheme){
      let style:ITheme = info.TemplateDic[info.Template.Template.StyleTheme];
      setCss(style?.Css)
      
    } else { 
      setCss(undefined);
    }

    if (info?.Name) {
      document.title = info.Name;
    }
  }, [info]);

  useEffect(() => {
    if (workbookId || workbookTemplateId) {
      if(props.User && ((!info || info.WorkbookId !== workbookId || info.WorkbookTemplateId !== workbookTemplateId))){
        getInfoFromServer('useEffect');
        return;
      }
    }
    if(!info && requestId){
      getInfoFromServer('useEffect - Sign Request');
    }
  }, [workbookId, workbookTemplateId, requestId, props.User]);
  useEffect(() => {
    //console.log("User changed", props.User);
  }, [props.User]);

  useEffect(() => {
    setRequestId(props.match?.params?.signRequestId);
  }, [props.match.params.signRequestId]);


  const getInfoFromServer = (caller:string) => {
    if(isCallingInfo) return;
    setIsCallingInfo(true);
    console.log('caller', caller);
    _server
      .postApi<IWorkbookInfo>(
        requestId ? `../SignRequestApi/Info` : `../WorkbookApi/Info`,
        {
          workbookId,
          workbookTemplateId,
          preview,
          signRequestId: requestId,
        }
      )
      .then((x) => {
        setIsCallingInfo(false);
        if (x.Success) {
          let isSignRequest = false;
          buildTemplateDic(x.Value);

          x.Value.SignRequests?.forEach((c) => {
            if (c.PublicId) x.Value.TemplateDic[c.PublicId] = c;
            if (c.PublicId == requestId && c.SignerId) {
              setSignRequestSigner(x.Value.TemplateDic[c.SignerId]);
              setSignRequest(c);
              setShowSignerInstructionsModal(true);
              isSignRequest = true;
            }
          });
          let startPage:IPage|undefined;
          if (isSignRequest) {
            if(x.Value.SignerNotAllowedToSign){
              setShowSignNotAllowed(true);
            } else {
              startPage = x.Value.TemplateDic[x.Value.ParentPages[0]];
            }
          } else if (x.Value.LastPages && x.Value.LastPages.length > 0) {
            setPageHistory(x.Value.LastPages);
            startPage = x.Value.TemplateDic[x.Value.LastPages[0]];
          } else if(x.Value.ParentPages[0] && !preview){
            startPage = x.Value.TemplateDic[x.Value.ParentPages[0]];
          }
          if(!preview && x.Value.MarkedDoneAtUtc) {
            setShowPageThumbnails(true);
            setShowDonePage(true);
          }
          else {  
            let showThumbnails = !startPage ? true : false;
            if(!preview) setShowPageThumbnails(showThumbnails);
            if(!preview) setPage(startPage);
          }
          setInfo(x.Value);
          
        } else {
          toast.error(x.Message, { autoClose: 30 });
        }

      })
      .catch((x)=>{
        //oops something went wrong.
        console.log('Error', x);
        setIsCallingInfo(false);
        toast.error('Something went wrong!', {autoClose:false});
      });
  };

  const onSelctPage = (p: IPage) => {
    setPage(p);
    setPageHistory([...pageHistory, p.PublicId]);
    setShowPageThumbnails(false);
  };

  const onJumpToPage = (pageId:string)=>{
    let page = info?.TemplateDic[pageId];
    if(page){
      setPage(page);
      setPageHistory([...pageHistory, page.PublicId]);
      setShowPageThumbnails(false);
    }
  };

  const onBackPage = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    let history = [...pageHistory];
    history.pop();
    let p = history.pop();
    let pg:IPage|undefined = p ? info?.TemplateDic[p] : undefined;
    while(pg && !pg._validPath && history.length > 0){
      p = history.pop();
      pg = p ? info?.TemplateDic[p] : undefined;
    }
    setPage(pg);
    if (pg && p) setPageHistory([...history, p]);
    else {
      setPageHistory(history);
      setShowPageThumbnails(true);
    }
  };

  const onNextPage = (p: IPage) => {
    setPageHistory([...pageHistory, p.PublicId]);
    setPage(p);
    window.scrollTo({top: 0, behavior: 'smooth'});

    //server set last page...
    if (!requestId && info?.WorkbookId) {
      _server
        .postApi<any>(`../WorkbookApi/SetPage`, {
          workbookId: info?.WorkbookId,
          PageId: p.PublicId,
        })
        .then((x) => {});
    }
  };

  const onValueChanged = (
    key: string,
    setId: string | null,
    value: string[],
    files: File[] | null | undefined,
    setGroupKey?: string,    
  ) => {
    if (!props.Preview && info) {
      let r: IWorkbookSaveRequest = {
        SetId: setId ?? undefined,
        SetGroupKey: setGroupKey,
        DataPointId: key,
        Value: value,
        WorkbookId: info.WorkbookId,
        WorkbookTemplateId: info.WorkbookTemplateId,
      };
      workbookOnValueChanged(info, r, files ?? []).then(x=>{   
        RunCalculations(x,'base');
        setInfo(x);
      });
    } else {
      let valueContainer = info?.Values ?? {};
      let valuesDic = valueContainer[setId ? setId : 'base'] ?? {};
      
      valuesDic[key] = { Value: value[0] };
      valueContainer[setId ? setId : 'base']=valuesDic;
      if (info) setInfo({ ...info, Values: { ...valueContainer } });

      console.log("Values changed: ", valueContainer);
    }
  };

  const onSignRequest = (request: ISignRequest) => {
    let requests = info?.SignRequests?.filter((x) => x.Id !== request.Id && !x.RemovedAtUtc) ?? [];
    if(request)
    requests?.push(request);
    if (info){
    
    RunCalculations(info,'base');
      setInfo({
        ...info,
        WorkbookId: request.WorkbookId ?? info.WorkbookId,
        WorkbookTemplateId:
          request.WorkbookTemplateId ?? info.WorkbookTemplateId,
        SignRequests: requests,
      });
    }
  };

  const onEditClick = (
    type: string,
    subType: string | null,
    key: string,
    callback: Function
  ) => {
    setEditItem({
      Type: type,
      SubType: subType ?? undefined,
      Id: key,
      Name: "",
    });
    setShowEditModal(true);
    setOnEditCallback({ callback: callback });
  };

  const onTemplateUpdate = () => {
     getInfoFromServer("OnTemplateUpdate");
  };

  const buildThumbnail = (pg: IPage, info: IWorkbookInfo, level: number, hasMilestone?:boolean, used?:Record<string,boolean>) => {
    if(!pg) return <></>;

    let pagesUsed:Record<string,boolean> = used ?? {};
    let pages:IPage[] = [];
    let hasMileStoneOnEnter = (hasMilestone ? true : false);
    if(pg._nextPagesAll){
    for(let i = 0; i < (pg._nextPagesAll?.length ?? 0); i++){
      let p = pg._nextPagesAll[i];      
      if(!p) continue;
      if(pagesUsed[p.PublicId]) continue;
      pagesUsed[p.PublicId] = true;
      p._notYet = hasMileStoneOnEnter;
      pages.push(p);
      if(p._milestones?.length > 0 && p._validPath && p._milestonesState !== MilestoneStates.Completed){
        hasMilestone = true;
      }
    }
  }
    return (
      <>
        {(preview || (pg._validPath && pg._canAccess)) && (
          <PageThumbnail
            preview={preview}
            page={pg.PublicId}
            activePage={page?.PublicId == pg.PublicId}
            info={info}
            onSelect={onSelctPage}
            onEditClick={onEditClick}
            notAccessableYet={pg._notYet}
          />
        )}
        {pages.map((x, i) => {
          //if(x._milestones.length > 0) toast.info(`${x.Name} has milestones`)
          return (
            <React.Fragment key={x.PublicId}>
              {buildThumbnail(x, info, level + 1, hasMilestone, pagesUsed)}
            </React.Fragment>
          );
        })}
      </>
    );
  };

  const downloadPdf = ()=>{
    //download forms
    let name = info?.Name ?? '';
    name = name.replace(/[^0-9A-Za-z\-\_]/g, '');
    const maxLength = 30;
    if(name.length > maxLength) name = info?.Template.Name.replace(/[^0-9A-Za-z\-\_]/g, '') ?? '';

    let url = Server.BuildUrl(`../wb/${info?.WorkbookId}/${name}`);
    window.open(url);
  };

  const buildPagesThumbnails = () => {
    if (info && info.TemplateDic) {
      return (
        <div>
          {info.ParentPages.map((x) => {
            return info.TemplateDic[x];
          }).filter(x=>x).map((x: IPage, i: number) => {
            return (
              <div
                className="page-thumbnail-container"
                key={`tn-${x.PublicId}`}
              >
                {buildThumbnail(x, info, 1)}
              </div>
            );
          })}
        </div>
      );
    }
  };

  const onDone = (undo:boolean)=>{
    
    if(info){

      let r: IWorkbookSaveRequest = {
        Done:true,
        Undo:undo,
        WorkbookId: info.WorkbookId,
        WorkbookTemplateId: info.WorkbookTemplateId,
      };
      workbookOnValueChanged(info, r, []).then(x=>{   
        let i = {...info};
        i.MarkedDoneAtUtc = undo ? "" : "tada";
        setInfo(i);
      });

    }
  };
  
  const onPublish = (undo:boolean)=>{

    if(info){

      let r: IWorkbookSaveRequest = {
        Publish:true,
        Undo:undo,
        WorkbookId: info.WorkbookId,
        WorkbookTemplateId: info.WorkbookTemplateId,
      };
      workbookOnValueChanged(info, r, []).then(x=>{   
          let i = {...info};
          i.PublishedAtUtc = undo ? "" : "tada";
          setInfo(i);
      });

    }

  };

  return (
    <div className={`workbook ${preview ? "preview" : ""} ${signRequest ? "signing" : ""}`} style={{backgroundImage:backgroudImage ? `url('${backgroudImage}')` : `none`}}>      
      {css && <style>{css}</style>}
        {!props.User && <div>
            {requestId ? <>
          <div className="signer-header">
            <h3>{signRequestSigner?.Name}'s Request to Review & Approve</h3>
            {signRequest?.ApprovedAtUtc && (
              <div className="alert alert-success">
                <Icon type={IconType.check} addClass="text-info" /> Reviewed &
                Signed On: {FormatDate(signRequest.ApprovedAtUtc)}
              </div>
            )}
          </div>
           :
          <>
            <LoginOrRegister show={props.User ? false : true} lite={true}>
              <h3>Request to Review & Approve</h3>
              You will need to sign-in or register before you can review any
              documents.
              {signRequestSigner?.Instructions && (
                <div dangerouslySetInnerHTML={{__html:signRequestSigner.Instructions}}/>
              )}
              {signRequest?.ApprovedAtUtc && (
                <div className="alert alert-success">
                  <Icon type={IconType.check} addClass="text-info" /> Reviewed &
                  Signed On: {FormatDate(signRequest.ApprovedAtUtc)}
                </div>
              )}
            </LoginOrRegister>
            </></>
            :<></>
          }
        </div>}
        
      {info && (
        <>
          <>
            <div className="header">
              <div className="workbook-header flex-between no-wrap">
                {!requestId ? (
                  <div className="flex-between no-wrap" >
                    {info.Template.Template.ThumbnailImage && <ImagePlus src={Server.FileUrl(info.Template.Template.ThumbnailImage, 100)} className={'workbook-logo'}  />}
                  <div className="title"><h1>{info.Template.Name}</h1>
                    {info.Name && <div className="small">{LimitStringSize(info.Name, 70)}</div>}
                  </div>
                  </div>
                ) : (
                  <div className="signer-header">
                    <h3>
                      {signRequestSigner?.Name}'s Request to Review & Approve
                    </h3>
                    {signRequest?.ApprovedAtUtc && (
                      <div className="alert alert-info">
                        <Icon type={IconType.check} addClass="text-info" />{" "}
                        Reviewed & Signed On:{" "}
                        {FormatDate(signRequest.ApprovedAtUtc)}
                      </div>
                    )}
                  </div>
                )}
                  <div className="header-menu">
                  {info?.WorkbookId && <div className="btn-group">
                      {props.User?.IsAdmin && !props.Preview && <button type="button" className="btn btn-default btn-xs" onClick={()=>{setShowAllDataPoints(true)}} ><Icon type={IconType.searchPlus} /></button>}
                    {props.User?.IsAdmin && <button type="button" className="btn btn-default btn-xs" onClick={()=>{setPreview(!preview)}} ><Icon type={IconType.edit} /> </button>

                    }
                      {info && <button type="button" className="btn btn-default btn-xs" title={'Home'} onClick={()=>{window.location.href = Server.BuildUrl(`../`)}} ><Icon type={IconType.home} /></button>}
                      {info && <button type="button" className="btn btn-default btn-xs" title={'Workbook Cover Page'} onClick={()=>{window.location.href = Server.BuildUrl(`../Workbook/Start/${info.WorkbookId}`)}} ><Icon type={IconType.cog} /></button>}
                      {info.Template.Faqs?.length > 0 && <button type="button" className="btn btn-default btn-xs" onClick={()=>{setShowFaq(true)}} ><Icon type={IconType.questionCircle} /> FAQs</button>}
                      {info.Template.Template.AllowDownloadOfPdf && !signRequest && <button type="button" className="btn btn-default btn-xs" onClick={()=>{downloadPdf()}} ><Icon type={IconType.download} /> Download</button>}
                      {signRequest && <button type="button" className="btn btn-default btn-xs" onClick={()=>{setShowSignerInstructionsModal(true)}} ><Icon type={IconType.signature} /> Instructions</button>}
                      {!info.Template.Template.HideMessage && <button type="button" className="btn btn-default btn-xs" onClick={()=>{setShowComments(true)}} ><Icon type={IconType.sms} /> Messages</button>}
                      <button type="button" className="btn btn-default btn-xs" title={'Show menu'} onClick={()=>{redux.setShowNav(true)}} ><Icon type={IconType.bars} /> Menu</button>
                    </div>}
                    {preview && <div>{info.WorkbookTemplateId}</div>}
                  </div>
              </div>
            </div>
            {!showDonePage && <>
            {(showPageThumbnails || !page) && <div>{buildPagesThumbnails()}
            
            {info && info.MarkedDoneAtUtc && <div className="headroom flex-between">
                  <span></span>
                      <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      setShowPageThumbnails(false);
                      setShowDonePage(true);
                    }}
                  >
                    <Icon type={IconType.finished} /> Done
                  </button>
                  <span></span>
                </div>}
            
            </div>}
            {!showPageThumbnails && page && (
              <Page
                info={info}
                page={page.PublicId}
                firstPage={pageHistory[0] ?? page.PublicId}
                onBack={onBackPage}
                onNext={onNextPage}
                onJumpToPage={onJumpToPage}
                canContinue={canContinue}
                onValueChange={onValueChanged}
                onEditClick={onEditClick}
                onSignRequest={onSignRequest}
                preview={preview}
                readOnly={ info.MarkedDoneAtUtc ? true : 
                  signRequest && signRequest.ApprovedAtUtc ? true : false
                }
                showPages={()=>{setShowPageThumbnails(true)}}
                showDonePage={()=>{setShowDonePage(true)}}
              />
            )}
            </>}
            {showDonePage && <>
              <div className="wb-page" >
              <div className="wb-page-body done-page">
                {info.Template.Template.DoneHeader && <div className="done-page-header" dangerouslySetInnerHTML={{__html:info.Template.Template.DoneHeader}}></div>}

                { info.Template.Template.MarkAsDone && <div className="done-mark-as-done">
                  {info.MarkedDoneAtUtc ? <div className="flex-between"><h3><Icon type={IconType.finished} /> Finsihed!</h3> <button type="button" className="btn btn-default" onClick={()=>{onDone(true);}}><Icon type={IconType.undoAlt}/> {info.Template.Template.MarkAsDoneUndoBtnText ?? "Not Finished"}</button> </div>
                  : 
                  <button type="button" className="btn btn-secondary" onClick={()=>{onDone(false);}}><Icon type={IconType.finished}/> {info.Template.Template.MarkAsDoneBtnText ?? "Finish"}</button>}
                  {info.MarkedDoneAtUtc && <div className="flex-between"><ConfettiExplosion /><ConfettiExplosion force={2} /><ConfettiExplosion duration={4000} /></div>}
                  {!info.MarkedDoneAtUtc && info.Template.Template.MarkAsDoneDescription && <div className="done-description" dangerouslySetInnerHTML={{__html:info.Template.Template.MarkAsDoneDescription}}></div>}
                  {info.MarkedDoneAtUtc && info.Template.Template.MarkAsDoneDescriptionUndo && <div className="done-description" dangerouslySetInnerHTML={{__html:info.Template.Template.MarkAsDoneDescriptionUndo}}></div>}
                </div>}
                { info.Template.Template.MarkAsPublished && <div className="done-mark-as-publish">
                  {info.PublishedAtUtc ? <div className="flex-between"><h3><Icon type={IconType.finished} /> Published!</h3><button type="button" className="btn btn-default" onClick={()=>{onPublish(false);}}><Icon type={IconType.undoAlt}/> {info.Template.Template.MarkAsPublishedUndoBtnText ?? "Un-publish"}</button></div>
                    :
                  <button type="button" className="btn btn-secondary"  onClick={()=>{onPublish(false);}}><Icon type={IconType.finished}/> {info.Template.Template.MarkAsPublishedBtnText ?? "Publish"}</button>}
                  {info.PublishedAtUtc && <div className="flex-between"><ConfettiExplosion /><ConfettiExplosion /><ConfettiExplosion /></div>}
                  {!info.PublishedAtUtc && info.Template.Template.MarkAsPublishedDescription && <div className="done-description" dangerouslySetInnerHTML={{__html:info.Template.Template.MarkAsPublishedDescription}}></div>}
                  {info.PublishedAtUtc && info.Template.Template.MarkAsPublishedDescriptionUndo && <div className="done-description" dangerouslySetInnerHTML={{__html:info.Template.Template.MarkAsPublishedDescriptionUndo}}></div>}
                </div>}
                {info.Template.Template.DoneFooter && <div className="done-page-header" dangerouslySetInnerHTML={{__html:info.Template.Template.DoneFooter}}></div>}

                <div className="headroom flex-between">
                  <span></span>
                      <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      setShowPageThumbnails(true);
                      setShowDonePage(false);
                    }}
                  >
                    <Icon type={IconType.pages} /> View Workbook Pages
                  </button>
                  <span></span>
                </div>
              </div>
              </div>
            </>}
          </>
        </>
      )}

      {showComments && info && <Modal size="xl" setModalOpen={setShowComments} title={`Workbook Messages`}>
        <CommentViewer info={info} workbookId={info.WorkbookId}/>
        </Modal>}

        {showSignNotAllowed && <Modal setModalOpen={setShowEditModal} title={"Error"} noClose={true} size="l" >
            <div className="alert alert-danger"><h2><Icon type={IconType.warning}/> Approval & Signing Not Permited</h2>
            Hi {props.User?.FirstName}, it looks like you are not permited to approve this request. You can logout and into a different user to begin the approval process. 
            </div>
            <div className="headroom">
              <button type="button" className="btn btn-secondary" onClick={()=>{signOut(true)}}><Icon type={IconType.signOut}/> Sign out</button>
            </div>

          </Modal>}

        {showSignerInstructionsModal && props.User && <Modal setModalOpen={setShowSignerInstructionsModal} title={"Review & Approve"} noClose={true} size="l" >
            <div ><h2><Icon type={IconType.UserCheck}/> Approval & Signing</h2>
            Hi {props.User?.FirstName}, 
            <br/><br/>Thanks for your help, you will be reviewing "{info?.Name}". When you are ready press the start button below.  
            </div>

            {signRequestSigner?.Instructions && <div dangerouslySetInnerHTML={{__html:signRequestSigner.Instructions}}></div>}
            <div className="headroom">
              {props.User && <button type="button" className="btn btn-secondary" onClick={()=>{setShowSignerInstructionsModal(false)}}><Icon type={IconType.signature}/> Start</button>}
            </div>

          </Modal>}
        {showAllDataPoints && props.User && <Modal setModalOpen={setShowAllDataPoints} title={"Data Points"} noClose={false} size="l" >
            <div ><h3><Icon type={IconType.searchPlus}/> All Data Points</h3> 
            </div>

            <div className="form-horizontal">
              {info?.Template.DataPoints.map((x,i)=>{
                return <WorkbookInput key={`alldp-${i}`} 
                  input={x} 
                  info={info} 
                  onValueChange={onValueChanged} 
                onEditClick={onEditClick} 
                preview={preview} />
              })}
            </div>

          </Modal>}

          

      {preview && editItem && showEditModal && (
        <ConfigFormEditor
          type={editItem.Type ?? ""}
          item={editItem}
          itemKey={info?.WorkbookTemplateId}
          canSave={true}
          noEdit={false}
          itemChildren={undefined}
          onClose={() => {
            setShowEditModal(false);
          }}
          onSave={onTemplateUpdate}
          onRemove={onTemplateUpdate}
          onUpdate={onTemplateUpdate}
        />
      )}

      {showFaq && info && <Modal setModalOpen={setShowFaq} title={`${info.Template.Name} FAQs.`} size={"l"} >
          <div className="faq-modal-body">
            <WorkbookFaqContainer faqs={info.Template.Faqs} />
          </div>
        </Modal>}
    </div>
  );
};

export default connectMap()(withRouter(Workbook));
