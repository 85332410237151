import { toast } from "react-toastify";
import { IState } from "../../../Redux/Redux";
import { IOrgUserSummary, IOrgUserSummaryUser } from "../../../Types/User";
import { _server } from "../../../utils/Server";
import { IOrgUserRole } from "../OrganizationTypes";
import { useEffect, useState } from "react";
import { FormatDate } from "../../../utils/Tools";
import Icon, { IconType } from "../../Icon/Icon";


interface IProps{
    orgId?:string;
    state:IState;
    user:IOrgUserSummaryUser;
}

const UsersOrganizationRoleManager = (props:IProps)=>{
    const [userRole, setUserRole] = useState<IOrgUserRole>();
    const [ready, setReady] = useState<boolean>(false);

    useEffect(() => {
        if (props.state.User) {
          init();
        }
      }, [props.state.User]);
    
      const init = () => {
        _server.postApi<IOrgUserRole>(`../OrgApi/GetUsersRole`, {orgId:props.orgId, userId:props.user.PublicId})
        .then(x=>{
            if(x.Success){
                setUserRole(x.Value);
                setReady(true);
            } else {
                toast.error(x.Message, {autoClose:30000});
            }
        });
      };

      const changeUserRole = (action:'add'|'remove'|'promote'|'demote'|'un-remove')=>{

        let rqst:any = {
            userId:props.user.PublicId,
            orgId:props.orgId,
            removed:action === 'remove' ? true:action === 'un-remove' ? false: null,
            isAdmin:action === 'promote' ? true:action === 'demote' ? false:null,
            approved:action !== 'remove',
        };
        _server.postApi<IOrgUserRole>(`../OrgApi/SetUsersRole`, rqst)
        .then(x=>{
            if(x.Success){
                setUserRole(x.Value);
                setReady(true);
            } else {
                toast.error(x.Message, {autoClose:30000});
            }
        });
      };

    return (<>
        {ready && <>
            <div className="form-horizontal">
                {userRole&&<>
                
                    <div className="form-group">
                    {userRole?.IsAdmin && <div className="alert alert-info"><h3>Admin User</h3></div>}
                </div></>}
                <div className="form-group">
                    <label className="control-label col-sm-4">Request Org User Roleship</label>
                    <div className="col-sm-6 form-control">{FormatDate(userRole?.RequestedAtUtc, 'date')} </div>
                </div>
                <div className="form-group">
                    <label className="control-label col-sm-4">Approved On</label>
                    <div className="col-sm-6 form-control">{FormatDate(userRole?.ApprovedAtUtc, 'date')}</div>
                </div>
                {<div className="form-group">
                    <label className="control-label col-sm-4">Removed At</label>
                    <div className="col-sm-6 form-control">{FormatDate(userRole?.RemovedAtUtc, 'date')}</div>
                </div>}
                <div className="form-group">
                    
                <div className="col-sm-6 col-sm-offset-4">
                    {!userRole && <button type="button" className="btn btn-success" onClick={()=>{changeUserRole('add')}}><Icon type={IconType.new}/> Add</button>}
                    {userRole && !userRole.RemovedAtUtc  && !userRole.IsAdmin && <button type="button" className="btn btn-info" onClick={()=>{changeUserRole('promote')}}><Icon type={IconType.admin}/> Make Admin</button>}
                    {userRole && !userRole.RemovedAtUtc  && userRole.IsAdmin && <button type="button" className="btn btn-warning" onClick={()=>{changeUserRole('demote')}}><Icon type={IconType.admin}/> Demote</button>}
                    {userRole && !userRole.RemovedAtUtc && <button type="button" className="btn btn-danger" onClick={()=>{changeUserRole('remove')}}><Icon type={IconType.delete}/> Remove</button>}
                    {userRole && userRole.RemovedAtUtc && <button type="button" className="btn btn-warning" onClick={()=>{changeUserRole('un-remove')}}><Icon type={IconType.new}/> Restore</button>}
                </div>
                </div>

            </div>
        </>}
    </>);

    
};

export default UsersOrganizationRoleManager;