import { send } from "process";
import { useState } from "react";
import { toast } from "react-toastify";
import { Server } from "../../utils/Server";
import { isValidEmail, isValidPhone } from "../../utils/Tools";
import Icon, { IconType } from "../../Components/Icon/Icon";
import OrgPageHeader from "../../Components/Organizations/OrgPageHeader";
import { IState, ReduxMapper } from "../../Redux/Redux";

interface IContactUsProps extends IState {} 


const ContactUs = (props: IContactUsProps) => {
  const [showSent, setShowSent] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({});
  const [hasErrors, setHasErrors] = useState<boolean>(false);

  const sendContactUs = () => {
    let errors = false;
    let fd = formData;

    if (!fd.name) {
      fd.nameError = true;
      errors = true;
    } else fd.nameError = false;

    if (!fd.message) {
      fd.messageError = true;
      errors = true;
    } else fd.messageError = false;

    // if (!fd.subject) {
    //   fd.subjectError = true;
    //   errors = true;
    // }else fd.subjectError = false;

    let emailValid = fd.email && isValidEmail(fd.email);
    let phoneValid = fd.phone && isValidPhone(fd.phone);
    fd.emailError = !emailValid;
    fd.phoneError = !phoneValid;

    if (!emailValid && !phoneValid) {
      errors = true;
    }

    if (errors) {
      setFormData(fd);
      setHasErrors(true);
      console.log("Has errors");
      return;
    }

    setHasErrors(false);

    let rqst = {
      //Subject: formData.subject,
      Message: formData.message ?? "Message",
      Phone: formData.phone ?? "Phone Number",
      Email: formData.email ?? "bob@example.com",
      Name: formData.name ?? "bob snert",
    };

    console.log("Sending...", rqst);
    Server.instance.postApi<any>(`../Contact/ContactUs`, rqst).then((x) => {
      if (x.Success) {
        setShowSent(true);
      }
    });
  };

  return (
    <>
      <OrgPageHeader state={props} />
      {!showSent && (
        <>
          <h3>Contact Us</h3>
          Let us know how we can help, it is our desire to provide the best
          service possible. Currently use are using an early release version of
          zFairs Project App, let us know how we can make it better.
          <div className="alert alert-info">
            <Icon type={IconType.exclamationTriangle} /> Please note that if you
            use a school email our response will likely be blocked.{" "}
          </div>
          <hr />
          <div className="">
            <div className="form-group">
              <label htmlFor="form-name">Name</label>
              <input
                id="form-name"
                value={formData.name}
                onChange={(e) => {
                  setFormData({ ...formData, name: e.target.value });
                }}
                type="text"
                maxLength={100}
                className="form-control"
                placeholder="What shall we call you?"
                autoFocus={true}
              />
              {hasErrors && formData.nameError && (
                <span className="hasError">Please provide your name.</span>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="form-phone">Phone</label>
              <input
                id="form-phone"
                value={formData.phone}
                onChange={(e) => {
                  setFormData({ ...formData, phone: e.target.value });
                }}
                type="tel"
                maxLength={100}
                className="form-control"
                placeholder="Phone number?"
              />
              {hasErrors && formData.phoneError && (
                <span className="hasError">
                  Please provide a valid phone number.
                </span>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="form-email">Email</label>
              <input
                id="form-email"
                value={formData.email}
                onChange={(e) => {
                  setFormData({ ...formData, email: e.target.value });
                }}
                type="email"
                maxLength={100}
                className="form-control"
                placeholder="Email?"
              />
              {hasErrors && formData.emailError && (
                <span className="hasError">
                  Please provide a valid email address.
                </span>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="form-message">Tell us about it</label>
              <textarea
                id="form-message"
                rows={10}
                value={formData.message}
                onChange={(e) => {
                  setFormData({ ...formData, message: e.target.value });
                }}
                maxLength={3000}
                className="form-control"
                placeholder="Tell us how we can help, give as much detail as possible."
              />
              {hasErrors && formData.messageError && (
                <span className="hasError">
                  Please tell us how we can help or improve.
                </span>
              )}
            </div>
            <div className="form-group">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={sendContactUs}
              >
                <Icon type={IconType.send} /> Send
              </button>
            </div>
          </div>
        </>
      )}
      {showSent && (
        <>
          <h3>Message Sent!</h3>
          Hi {formData.name},
          <br />
          <br />
          We've sent your message and will get back to you as fast as possible. In the mean time you can 
          check out our wiki page at <a href="https://wiki.zfairs.com/index.php?title=Category:Project_App" target="_blank">https://wiki.zfairs.com/index.php?title=Category:Project_App</a>
          <br />
          <br />
          Thank you,
          <br />
          zFairs ISEF App Support Team
          <br />
        </>
      )}
    </>
  );
};

export default ReduxMapper(ContactUs);