import { ICalculation, IWorkbookInfo } from "./WorkbookTypes";


export const RunCalculations = (info:IWorkbookInfo, setId:string) =>
{
    //return;
    let Values = info.Values;
    if (!Values) {
        Values = {};
        info.Values = Values;
    };
    if (!info.Template.DataPointCalculations) info.Template.DataPointCalculations = [];
    if (!Values[setId]) Values[setId] = {};
    SetValueOfEachCalculation(info, setId);
    SetValueOfCalculatedFields(info, setId);
    //info.Values = {...info.Values};
}

export const SetValueOfEachCalculation=(info:IWorkbookInfo, setId:string)=>
{
    if (info.Template.DataPointCalculations == null)
        info.Template.DataPointCalculations = [];

    for(let i = 0; i < info.Template.DataPointCalculations.length; i++)
    {
        let calculation = info.Template.DataPointCalculations[i];
        if(calculation.PublicId === 'c7515cfc-abce-4c11-b2af-418a957509ff'){
            //debugger;
            console.log('some value yoyu care about...')
        }
        switch (calculation.BasicFunction)
        {
                case "true/false":
                    CalculateTrueFalse(info, calculation, setId);
                    break;
                case "combine":
                    CalculateCombine(info, calculation, setId);
                    break;
                case "equalTo":
                    CalculateEqualTo(info, calculation, setId);
                    break;
                case "equalToSome":
                    CalculateEqualToSome(info, calculation, setId);
                break;
                case "notEqualTo":
                    CalculateNotEqualTo(info, calculation, setId);
                break;
                case "notEqualToSome":
                    CalculateNotEqualToSome(info, calculation, setId);
                break;
        }
    }
}

export const CalculateNotEqualToSome=(info:IWorkbookInfo, calculation:ICalculation, setId:string)=> 
{
    let value = false;
    let values = info.Values[setId] ?? {};
    if (calculation.CompareToValue == null) calculation.CompareToValue = '';

    value = calculation.DataPoints?.find(x => calculation.CompareToValue &&
        calculation.CompareToValue?.toLowerCase() !== values[x]?.Value?.toLowerCase()) ? true:false;

    calculation.CalculatedValue = value.toString();
}

export const CalculateEqualToSome=(info:IWorkbookInfo, calculation:ICalculation, setId:string)=> 
{
    let value = false;
    let values = info.Values[setId] ?? {};
    if (calculation.CompareToValue == null) calculation.CompareToValue = '';

    value = calculation.DataPoints?.find(x => calculation.CompareToValue &&
        calculation.CompareToValue?.toLowerCase() === values[x]?.Value?.toLowerCase()) ? true:false;

    calculation.CalculatedValue = value.toString();
}

export const CalculateNotEqualTo=(info:IWorkbookInfo, calculation:ICalculation, setId:string)=> 
{
    let value = true;
    let values = info.Values[setId] ?? {};
    if (calculation.CompareToValue == null) calculation.CompareToValue = '';

    calculation.DataPoints?.forEach(x =>{
        if(calculation.CompareToValue?.toLowerCase() === values[x]?.Value?.toLowerCase()){
            value = false;
        }
    });

    calculation.CalculatedValue = value.toString();
}

export const CalculateEqualTo=(info:IWorkbookInfo, calculation:ICalculation, setId:string)=> 
{
    let value = true;
    let values = info.Values[setId] ?? {};

    calculation.DataPoints?.forEach(x =>{
        if(calculation.CompareToValue?.toLowerCase() !== values[x]?.Value?.toLowerCase()){
            value = false;
        }
    });

    calculation.CalculatedValue = value.toString();
}

export const CalculateCombine=(info:IWorkbookInfo, calculation:ICalculation, setId:string)=> 
{
    let value = '';
    let values = info.Values[setId] ?? {};
    
    let valuesToCombine = calculation.DataPoints?.map(x => values[x]?.Value).filter(x=>x) ?? [];
    value = valuesToCombine.join(calculation.Delimiter ?? " ");

    calculation.CalculatedValue = value;
}

export const CalculateTrueFalse=(info:IWorkbookInfo, calculation:ICalculation, setId:string)=> 
{
    let value = true;
    let values = info.Values[setId] ?? {};

    // let trueValues = calculation.TrueWhenTrue?.map(x => values[x]?.Value).filter(x => x) ?? [];
    // let falseValues = calculation.TrueWhenFalse?.map(x => values[x]?.Value).filter(x => x) ?? [];

    
    let trueValues = calculation.TrueWhenTrue?.map(x => values[x]?.Value) ?? [];
    let falseValues = calculation.TrueWhenFalse?.map(x => values[x]?.Value) ?? [];

    if(trueValues.length > 0){
        if (calculation.TrueWhenTrueOr)
        {
            let pass = trueValues.find(x=>ValueTruthy(x));
            if (!pass) value = false;
        }
        else
        {
            let pass = trueValues.filter(x=>ValueTruthy(x)).length === trueValues.length;
            if (!pass) value = false;
        }
    }

    if(falseValues.length > 0){
        if (calculation.TrueWhenFalseOr)
        {
            let pass = falseValues.find(x=>!ValueTruthy(x));
            if (!pass) value = false;
        }
        else
        {
            let pass = falseValues.filter(x => !ValueTruthy(x)).length === falseValues.length;
            if (!pass) value = false;
        }
    }

    calculation.CalculatedValue = value.toString();
}

export const ValueTruthy=(value:string)=>
{
    if(value === undefined || value === null) return false;
    value = ((value??'')+'').toUpperCase();
    if(!value) return false;
    if(value === "N") return false;
    if(value === "NO") return false;
    if(value === "0") return false;
    if(value === "FALSE") return false;
    if(value === "F") return false;
    return true;
}

export const SetValueOfCalculatedFields=(info:IWorkbookInfo, setId:string)=>
{
    let Values = info.Values;
    let values = Values[setId];

    console.log(`Calculation Set Value OF Calculated Fields`)
    for(let i = 0; i < info.Template.DataPointCalculations.length; i++)
    {
        let calculation = info.Template.DataPointCalculations[i];
        //console.log(`Calculation ${calculation.Name}`)

        let points = info.Template.DataPoints?.filter(x => x.IsComputed && x.Calculation == calculation.PublicId) ?? [];
        
        if (points && points.length > 0)
        {
            points.forEach(x =>
            {
                values[x.PublicId] = {Type:"calculated", Value:calculation.CalculatedValue};
            });
        }
    }

    let myPoints = info.Template.DataPoints.filter(x=>x.IsComputed && x.ValueOf);
    for(let i = 0; i < myPoints.length; i++)
    {
        let point = myPoints[i];
        values[point.PublicId] = {Type:"calculated",
            Value: values[point.ValueOf ?? '']?.Value ?? ""};
    }

}

