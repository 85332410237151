import React from 'react';

interface IconProps {
  type: IconType
  addClass?: string
}


export enum IconType {
  new = 'fal fa-sparkles',
  admin = 'fa fa-user-crown',
  org = 'fal fa-people-group',
  requiredFiles = 'fad fa-file-exclamation',
  magic = 'fad fa-wand-magic',
  map = 'fad fa-map-signs',
  here = 'fal fa-thumbtack',
  save = 'fal fa-save',
  sign = "fal fa-signature",
  checkSquareO = 'fa fa-check-square-o',
  squareO = 'fa fa-square-o',
  info = "fal fa-info-square",
  start = 'fal fa-hourglass-start',
  delete = 'fal fa-trash-alt',
  trashO = 'fa fa-trash-o',
  send = 'fal fa-paper-plane',
  file = 'far fa-file',
  fileAlt = 'fal fa-file-alt',
  edit = 'fad fa-edit',
  check = 'fas fa-check',
  circle = 'far fa-circle',
  checkCircle = 'fad fa-check-circle',
  questionCircle = 'far fa-question-circle',
  plusCircle = 'far fa-plus-circle',
  timesCircle = 'fas fa-times-circle',
  timesCircleLite = 'fal fa-times-circle',
  timesSquare = 'fal fa-times-square',
  chevronUp = 'fa fa-chevron-up',
  chevronDown = 'fa fa-chevron-down',
  chevronRight = 'fa fa-chevron-right',
  chevronLeft = 'fa fa-chevron-left',
  commentDots = 'fas fa-comment-alt-dots',
  comment = 'fa fa-comment-alt',
  dropbox = 'fab fa-dropbox',
  googleDrive = 'fab fa-google-drive',
  bars = 'fa fa-bars',
  signOut = 'fad fa-sign-out',
  signIn = 'fad fa-sign-in',
  settings = 'fas fa-cog',
  cog = 'fa fa-cog',
  manage = 'fad fa-cogs',
  work = 'fad fa-person-digging',
  user = 'fa fa-user',
  userO = 'fa fa-user-o',
  userCircle = 'fal fa-user-circle',
  userSlash = 'far fa-user-slash',
  users = 'fa fa-users',
  usersRegular = 'far fa-users',
  usersCog = 'fal fa-users-cog',
  userPlus = 'far fa-user-plus',
  userPlusDuo = 'fad fa-user-plus',
  userPlusSolid = 'fas fa-user-plus',
  help = 'fad fa-user-headset',
  lock = 'fad fa-lock-alt',
  personBooth = 'fad fa-person-booth',
  phone = 'fas fa-phone',
  phoneRotary = 'fas fa-phone-rotary',
  exclamation = 'fas fa-exclamation',
  question = 'fas fa-question',
  plus = 'far fa-plus',
  minus = 'far fa-minus',
  microphone = 'fa fa-microphone',
  microphoneSlash = 'fa fa-microphone-slash',
  microphoneSolid = 'fas fa-microphone',
  microphoneSlashSolid = 'fas fa-microphone-slash',
  videoSlash = 'fa fa-video-slash',
  video = 'fa fa-video',
  expandWide = 'far fa-expand-wide',
  arrows = 'fal fa-arrows-alt',
  volumeMute = 'fa fa-volume-mute',
  ban = 'fa fa-ban',
  sirenOn = 'fa fa-siren-on',
  userClock = 'far fa-user-clock',
  chalkboard = 'fal fa-chalkboard',
  mousePointer = 'fa fa-mouse-pointer',
  pen = 'fa fa-pen-fancy',
  cursor = 'fas fa-i-cursor',
  rectangle = 'far fa-rectangle-landscape',
  signature = 'fas fa-signature',
  clearBoard = 'fa fa-hand-sparkles',
  fileImage = 'far fa-file-image',
  palette = 'fas fa-palette',
  qrCode = 'far fa-qrcode',
  angleUp = 'fas fa-angle-up',
  angleDown = 'fas fa-angle-down',
  link = 'fal fa-link',
  unlink = 'fa fa-unlink',
  search = 'fas fa-search',

  searchMinus = 'fas fa-search-minus',
  searchPlus = 'fas fa-search-plus',
  windowClose = 'far fa-window-close',
  //share = 'fas fa-share',
  share = 'fad fa-share-all',
  shareSquareSolid = 'fas fa-share-square',
  shareSquareRegular = 'far fa-share-square',
  folder = 'fal fa-folder',
  folderOpen = 'fal fa-folder-open',
  arrowToBottom = 'far fa-arrow-to-bottom',
  arrowRight = 'fas fa-arrow-alt-right',
  arrowLeft = 'fas fa-arrow-alt-left',
  image = 'fal fa-image',
  filePdf = 'fal fa-file-pdf',
  fileVideo = 'far fa-file-video',
  fileWord = 'fal fa-file-word',
  fileExcel = 'fal fa-file-excel',
  fileSignature = 'fad fa-file-signature',
  fileSignatureRegular = 'far fa-file-signature',
  redoAlt = 'fa fa-redo-alt',
  undoAlt = 'fa fa-undo-alt',
  refresh = 'fa fa-undo-alt',
  slash = 'far fa-slash',
  exclamationTriangle = 'fal fa-exclamation-triangle',
  warning = 'fal fa-exclamation-triangle',
  clone = 'far fa-clone',
  highlighter = 'far fa-highlighter',
  readMe = 'fab fa-readme',
  handshake = 'far fa-handshake',
  download = 'far fa-download',
  thumbsUp = 'fas fa-thumbs-up',
  hourglassHalf = 'fas fa-hourglass-half',
  cloudDownload = 'far fa-cloud-download',
  envelope = 'far fa-envelope',
  ellipsisH = 'fa fa-ellipsis-h',
  store = 'fad fa-store',
  meteor = 'fad fa-meteor',
  eye = 'fad fa-eye',
  shown = 'fad fa-eye',
  eyeNo = 'fad fa-eye-slash',
  hidden = 'fad fa-eye-slash',
  arrowAltCircleLeft = 'far fa-arrow-alt-circle-left',
  home = 'fal fa-home-alt',
  copy = 'far fa-copy',
  mail = 'fa fa-envelope-o',
  letter = 'fa fa-envelope-o',
  textMsg = 'fa fa-comments-alt',
  trashSolid = 'fa fa-trash',
  text = 'far fa-text',
  sms = 'fad fa-sms',
  upload = 'fal fa-upload',
  folderPlus = 'fad fa-folder-plus',
  find = "fas fa-search",
  expand = "fas fa-expand",
  compress = "fas fa-compress",
  UserCheck = "fas fa-user-check",
  expandDown = 'fa fa-angle-down',
  expandUp = 'fa fa-angle-up',
  up = "fa fa-arrow-up",
  down = "fa fa-arrow-down",
  close = 'fal fa-times-square',
  exit = 'fal fa-times-square',
  film = 'fad fa-film',
  pages = 'fa-thin fa-grid-dividers',
  filter = 'fa-thin fa-filters',
  finished = "fa-duotone fa-flag-checkered",
} 

const Icon = (props: IconProps) => {
  return <i className={props.type + ' ' + props.addClass} />
}

export default Icon