import { useEffect, useState } from 'react';
import redux, { IState, ReduxMapper } from '../../Redux/Redux';
import { IWorkbookTemplateSummary } from '../WorkbookTemplate/WorkbookTypes';
import OrganizationWorkbookTemplateSearchableList, { filterWorkbooks } from './OrganizationWorkbookTemplateSearchableList';
import { _server } from '../../utils/Server';
import { toast } from 'react-toastify';
import { IOrgUserSummary } from '../../Types/User';
import { IOrganizationRelationship, IOrganizationTreeResponse, IReviewGroupMember } from './OrganizationTypes';
import { Button } from 'react-bootstrap';
import Icon, { IconType } from '../Icon/Icon';

interface IProps {
  orgId?:string
  state: IState;
}

interface ISearch {
  workbook?: string;
}

const OrganizationsOrgTree = (props: IProps) => {
  const [tree, setTree] = useState<IOrganizationTreeResponse>();

  useEffect(() => {
    if (props.state.User) {
      init();
    }
  }, [props.state.User]);

  const init = () => {
    _server.postApi<IOrganizationTreeResponse>(`../OrgApi/OrganizationTree`, {orgId:props.orgId}).then((x) => {
      if (x.Success) {
        setTree(x.Value);
      } else {
        toast.error(x.Message, { autoClose: 30000 });
      }
    });
  };

  const openNewRelationship = () => {
    toast.dark('Comming soon. Adding a new relationship');
  };

  const mapOrgs = (orgs: IOrganizationRelationship[], keyPrefix: string) => {
    return (
      <>
        {orgs?.map((x, i) => {
          return (
            <div key={`${keyPrefix}-wts-${i}`} className="bordered">
              <div>
                <div className='flex-between'>
                <h5>{x.Name}</h5>
<a href={`../Organization/${x.PublicId}`} className='btn btn-info btn-xs'>Open</a>
                </div>
                {x.Description && <div className="description" dangerouslySetInnerHTML={{ __html: x.Description }} />}
                <hr />
              </div>
              <div>...</div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <>
      <div>
        <div>
          <div className="flex-between">
            <h3>Parent Organization</h3>
            <div>
              <button type="button" className="btn btn-success" onClick={openNewRelationship}>
                <Icon type={IconType.plus} /> Add
              </button>
            </div>
          </div>
          {tree && tree.ParentOrgs?.length > 0 && <div>{mapOrgs(tree.ParentOrgs, 'pt')}</div>}
        </div>
        <hr />
        <div>
          <div className="flex-between">
            <h3>Child Organization</h3>
            <div>
              <button type="button" className="btn btn-success" onClick={openNewRelationship}>
                <Icon type={IconType.plus} /> Add
              </button>
            </div>
          </div>
          {tree && tree.Children?.length > 0 && <div>{mapOrgs(tree.Children, 'sub')}</div>}
        </div>
      </div>
    </>
  );
};

export default OrganizationsOrgTree;
