
import imgDefault from "../../Assets/default_thumbnail_image.jpg";

interface IImagePlusProps{
    src?:string|null|undefined;
    alt?:string|null|undefined;
    className?:string;
    id?:string;
}

const ImagePlus = (props:IImagePlusProps) =>{
    return (
        <img 
            id={props.id}
            className={props.className}
            alt={props.alt??''}
            src={props.src ?? imgDefault}
            onError={(x) => {
                const currentTarget = x.currentTarget;
                currentTarget.src = imgDefault;
                currentTarget.alt = `Default Image: ` + currentTarget.alt;
              }}
        />
    );
};

export default ImagePlus;