import { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import redux, { connectMap, IState } from '../../Redux/Redux';
import { Server, _server } from '../../utils/Server';
import { FormatDate } from '../../utils/Tools';
import Icon, { IconType } from '../Icon/Icon';
import { IMine } from '../../Pages/Landing/UserLanding';
import Modal from '../Modal/Modal';
import InviteOwner from './Owners/InviteOwner';
import { buildTemplateDic, workbookOnValueChanged } from './WorkbookFunctions';
import WorkbookInput from './WorkbookInput';
import { IDataPoint, IDataValue, IOwner, IWorkbookInfo, IWorkbookSaveRequest, IWorkbookSummary } from './WorkbookTypes';
import WorkbookShareManager from '../Workbook/WorkbookShareManager';

interface IWorkbookStartProps extends RouteComponentProps<MatchParams>, IState {}

interface MatchParams {
  templateId?: string;
  workbookId?: string;
}

const WorkbookStart = (props: IWorkbookStartProps) => {
  const [info, setInfo] = useState<IWorkbookInfo>();
  const [workbookId, setWorkbookId] = useState<string>();
  const [templateId, setTemplateId] = useState<string>();
  const [signIn, setSignIn] = useState<boolean>();
  const [showAddWorkbookOwner, setShowAddWorkbookOwner] = useState<boolean>();
  const [showManageSharing, setShowManageSharing] = useState<boolean>();

  useEffect(() => {
    setWorkbookId(props.match.params.workbookId);
    setTemplateId(props.match.params.templateId);
  }, [props.match.params.templateId, props.match.params.workbookId]);

  useEffect(() => {
    if (props.User) {
      let signInValue = signIn;
      setSignIn(false);
      if (signInValue) {
        //props.history.push(`../../../`);
        //onNew();
      }

      _server.getApi<IMine>(`../WorkbookApi/Mine`).then((x) => {
        if (x.Success) {
          redux.setUsersWorkbooks(x.Value.MyWorkbooks);
          redux.setTemplates(x.Value.Templates);
        } else {
          toast.error(x.Message, { autoClose: false });
        }
      });
    }
  }, [props.User]);

  useEffect(() => {
    if ((workbookId || templateId) && (info?.WorkbookId !== workbookId || info?.Template != templateId)) {
      _server.postApi<IWorkbookInfo>(`../workbookApi/info`, { workbookId: workbookId, workbookTemplateId: templateId }).then((x) => {
        if (x.Success) {
          let ix = x.Value;
          buildTemplateDic(ix);
          setInfo(ix);
        } else {
          toast.error(x.Message, { autoClose: false });
        }
      });
    }
  }, [workbookId, templateId]);

  const onValueChanged = (key: string, setId: string | null, value: string[], files: File[] | null | undefined) => {
    if (!props.User) {
      toast.info('Please sign-in or register to start your own workbook.');
      redux.showLogin(true);
      return;
    }
    if (info) {
      let r: IWorkbookSaveRequest = {
        SetId: setId ?? undefined,
        DataPointId: key,
        Value: value,
        WorkbookId: info.WorkbookId,
        WorkbookTemplateId: info.WorkbookTemplateId,
      };
      workbookOnValueChanged(info, r, files ?? []).then((x) => {
        setInfo(x);
      });
    }
  };

  const onRemoveWorkbook = () => {
    if (!window.confirm(`${props.User?.FirstName}, are you sure you want to delete your "${info?.Name}" workbook?`)) {
      return;
    }

    _server.postApi<IWorkbookInfo>(`../workbookApi/remove`, { workbookId: info?.WorkbookId, workbookTemplateId: info?.WorkbookTemplateId }).then((x) => {
      if (x.Success) {
        toast.success('Workbook removed.');
        props.history.push('../../../');
      } else {
        toast.error(x.Message, { autoClose: false });
      }
    });
  };

  const onOpen = () => {
    if (info?.WorkbookId) {
      props.history.push(`../../../workbook/${info.WorkbookId}`);
    }
  };
  const onNew = () => {
    if (!props.User) {
      toast.info('Please sign-in or register so we can get started.');
      setSignIn(true);
      redux.showLogin(true);
    } else {
      let r: IWorkbookSaveRequest = {
        WorkbookTemplateId: templateId ?? '',
      };

      _server.postApi<IWorkbookSaveRequest>(`../WorkbookApi/Save`, r).then((x) => {
        if (x.Success) {
          setWorkbookId(x.Value.WorkbookId);
          props.history.push(`../../../workbook/${x.Value.WorkbookId}`);
        } else {
          toast.error(x.Message, { autoClose: false });
        }
      });
    }
  };

  const onBack = () => {
    props.history.push(`../../../`);
  };

  const onEdit = (x: IWorkbookSummary, e: any) => {
    e.stopPropagation();
    e.preventDefault();
    let url = `../../../workbook/start/${x.PublicId}`;
    props.history.push(url);
  };

  const removeOwner = (owner: IOwner) => {
    if (!window.confirm(`Are you sure you want to remove "${owner.FirstName}" from your "${info?.Name}" workbook?`)) {
      return;
    }

    toast.dismiss();
    _server.postApi<IWorkbookSaveRequest>(`../WorkbookApi/RemoveOwner`, { workbookId: info?.WorkbookId, userId: owner.PublicId }).then((x) => {
      if (x.Success) {
        if (info) {
          toast.info(`${owner.FirstName} has been removed.`);
          setInfo({ ...info, Owners: info.Owners.filter((x) => x.PublicId !== owner.PublicId) });
          setWorkbookId(x.Value.WorkbookId);
        }
      } else {
        toast.error(x.Message, { autoClose: false });
      }
    });
  };

  const onOpen2 = (x: IWorkbookSummary) => {
    let url = `../../../workbook/${x.PublicId}`;
    props.history.push(url);
  };

  return (
    <>
      {info && (
        <div className="wb-page">
          <div className="wb-page-body-2">
            {info.WorkbookId && (
              <>
                <div className="flex-between">
                  <h2>{info.Name}</h2>
                  <div>
                    <button type="button" className="btn btn-default" onClick={onBack}>
                      <Icon type={IconType.exit} /> Exit
                    </button>
                    <button type="button" className="btn btn-secondary" onClick={onOpen}>
                      <Icon type={IconType.folderOpen} /> Open Workbook
                    </button>
                  </div>
                </div>
                <hr />
              </>
            )}

            <div className="wb-page-body-content">
              {info.Template?.Template?.Content && <div dangerouslySetInnerHTML={{ __html: info.Template.Template.Content }}></div>}
              <div className="form-horizontal">
                <div className="form-group">
                  <div className="col-sm-offset-6 col-sm-6 col-md-offset-4 col-md-8">
                    <strong>Workbook: {info.Template.Name}</strong>
                  </div>
                </div>
                {props.User &&
                  info.Template.DataPoints.filter((x) => x.WorkbookSpecialType == 'WorkbooksName').map((x, i) => {
                    // {info.Template.DataPoints.filter(x=>x.WorkbookSpecialType).map((x,i)=>{
                    return (
                      <WorkbookInput
                        info={info}
                        input={x}
                        key={`wbfi-x-${i}`}
                        onValueChange={onValueChanged}
                        onEditClick={function (type: string, subType: string | null, key: string, callback: Function): void {}}
                        preview={false}
                      />
                    );
                  })}

                {info.WorkbookId && info.Owners && (
                  <div className="headroom owners">
                    <div className="form-group">
                      <label className={`control-label col-lg-4 col-sm-12`}>{info.Owners?.length > 1 ? <>Owners</> : <>Owner</>}</label>
                      <div className={`col-lg-8 col-sm-12`}>
                        {info.Owners.map((x, i) => {
                          return (
                            <div className="owner" key={`wbo-ii-${i}`}>
                              {x.PrimaryOwner && (
                                <span title="Primary Workbook Owner">
                                  <Icon type={IconType.admin} />{" "}
                                </span>
                              )}
                              {x.FirstName} {x.LastName} {x.Email}
                              {!x.PrimaryOwner && (
                                <span
                                  title="click to remove workbook owner."
                                  className="click text-danger"
                                  onClick={() => {
                                    removeOwner(x);
                                  }}>
                                  {" "}<Icon type={IconType.trashO} />
                                </span>
                              )}
                            </div>
                          );
                        })}

                        {(info.Template.Template.MaxNumberOfOwners > info.Owners.length || !info.Template.Template.MaxNumberOfOwners) && (
                          <div className="headroom">
                            <button
                              type="button"
                              className="btn btn-default"
                              onClick={() => {
                                setShowAddWorkbookOwner(true);
                              }}>
                              <Icon type={IconType.userPlus} /> Add Workbook Owner
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                <div className="form-group">
                  <div className="col-lg-offset-4 col-lg-8">
                    {info.WorkbookId && (
                      <>
                        <button type="button" className="btn btn-secondary huge" onClick={onOpen}>
                          <Icon type={IconType.folderOpen} /> Open Workbook
                        </button>
                        <button
                          type="button"
                          className="btn btn-info huge"
                          onClick={() => {
                            setShowManageSharing(true);
                          }}>
                          <Icon type={IconType.share} /> Share
                        </button>
                      </>
                    )}
                    {!info.WorkbookId && (
                      <button type="button" className="btn btn-secondary huge" onClick={onNew}>
                        <Icon type={IconType.new} /> Start a New workbook
                      </button>
                    )}
                  </div>
                </div>

                <div className="flex-middle">
                  {(props.UsersWorkbooks?.find((x) => !x.RemovedAtUtc && x.TemplatePublicId?.toLowerCase() === props.match.params.templateId?.toLowerCase()) ?? false) && (
                    <div className="other-started-workbooks">
                      <div className="center">
                        <h3>Hey {props.User?.FirstName}</h3>
                        <h4>Look at these other workbooks you already have.</h4>
                      </div>
                      <div className="workbook-container workbook-preview-container">
                        {props.UsersWorkbooks?.filter((x) => !x.RemovedAtUtc && x.TemplatePublicId?.toLowerCase() === props.match.params.templateId?.toLowerCase()).map((x, i) => {
                          let template = props.WorkbookTemplates?.find((t) => t.PublicId === x.TemplatePublicId);
                          //return <div className="workbook-preview" key={`u-wb-p-${i}`} onTouchEnd={()=>{onOpen2(x)}} onMouseUp={()=>{onOpen2(x)}}>
                          return (
                            <div className="workbook-preview" key={`u-wb-p-${i}`} onTouchEnd={() => {}} onMouseUp={() => {}}>
                              <div
                                className="wb-tile-edit click"
                                onTouchEnd={(e) => {
                                  onEdit(x, e);
                                }}
                                onMouseUp={(e) => {
                                  onEdit(x, e);
                                }}>
                                <Icon type={IconType.cog} addClass="fa-2x" />
                              </div>

                              {template?.ThumbnailImage && (
                                <div className="wb-thumbnail">
                                  <img src={Server.BuildUrl(`../file/x/${template.ThumbnailImage}?x=200x125`)} />
                                </div>
                              )}
                              <h3>{x.Name}</h3>
                              {/* <h4>{x.TemplateName}</h4> */}
                              <div>Last Update: {FormatDate(x.LastModifyAtUtc)}</div>
                              {x.RemovedAtUtc && <div className="top-of-tile">Deleted at {FormatDate(x.RemovedAtUtc)}</div>}

                              <div className="workbook-tile-button">
                                <button
                                  type="button"
                                  className="btn btn-default"
                                  onClick={() => {
                                    onOpen2(x);
                                  }}>
                                  <Icon type={IconType.folderOpen} /> Open
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {info.WorkbookId && (
            <div className="headroom flex-between footer">
              <button type="button" className="btn btn-default" onClick={onRemoveWorkbook}>
                <Icon type={IconType.trashO} /> Delete Workbook
              </button>
              <button type="button" className="btn btn-secondary" onClick={onOpen}>
                <Icon type={IconType.folderOpen} /> Open Workbook
              </button>
            </div>
          )}
          {!info.WorkbookId && (
            <div className="headroom flex-between footer">
              <button type="button" className="btn btn-default" onClick={onBack}>
                <Icon type={IconType.exit} /> Exit
              </button>
              <button type="button" className="btn btn-default" onClick={onNew}>
                <Icon type={IconType.new} /> Start a New workbook
              </button>
            </div>
          )}
        </div>
      )}

      {showAddWorkbookOwner && info && (
        <Modal setModalOpen={setShowAddWorkbookOwner} title={'Invite others'} size="l">
          <InviteOwner info={info} />
        </Modal>
      )}

      {showManageSharing && info && (
        <Modal setModalOpen={setShowManageSharing} title={`Manage Workbook Sharing`} size="l">
          <WorkbookShareManager
            state={props}
            workbookPublicId={info.WorkbookId}
            workbookName={info.Name}
            close={() => {
              setShowManageSharing(false);
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default connectMap()(withRouter(WorkbookStart));
