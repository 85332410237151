import { useEffect, useState } from "react";
import { IState } from "../../Redux/Redux";
import { _server } from "../../utils/Server";
import { toast } from "react-toastify";
import { IOrgSharedInfo } from "../WorkbookTemplate/WorkbookTypes";
import { FormatDate } from "../../utils/Tools";
import Icon, { IconType } from "../Icon/Icon";

interface IProps{
    state:IState;
    workbookName:string;
    workbookPublicId:string;
    close?:()=>void;
}

interface IForm{
    orgId?:string;
}

const WorkbookShareManager = (props:IProps) =>{
    const [shared, setShared] = useState<IOrgSharedInfo[]>([]);
    const [form, setForm] = useState<IForm>({});

    useEffect(()=>{
        if(props.workbookPublicId){
            LoadShared();
        }
    },[props.workbookPublicId]);

const LoadShared = ()=>{
    _server.getApi<IOrgSharedInfo[]>(`../WorkbookApi/Shared/${props.workbookPublicId}`)
    .then((x)=>{
        if(x.Success){
            setShared(x.Value);
        }
        else {
            toast.error(x.Message, {autoClose:30});
        }
    });
};
const Remove = (share:IOrgSharedInfo)=>{

    if(!window.confirm(`${props.state.User?.FirstName} are you sure you want to remove access from ${share.Name}?`)){
        return;
    }

    _server.postApi<boolean>(`../WorkbookApi/ShareRemove`, {workbookId:props.workbookPublicId, shareId:share.PublicId})
    .then((x)=>{
        if(x.Success){
            LoadShared();
            toast.info(`We have removed access to your workbook from ${share.Name}`);
        }
        else {
            toast.error(x.Message, {autoClose:30});
        }
    });
};

const Close = () =>{
    if(props.close){
        props.close();
    }
};

const Share = ()=>{

    if(!form.orgId || form.orgId.length < 4){
        toast.warning('Please provide a valid share key')
        return;
    }

    if(!window.confirm(`${props.state.User?.FirstName} are you sure you want to grant access?`)){
        return;
    }

    _server.postApi<boolean>(`../WorkbookApi/Share`, {workbookId:props.workbookPublicId, orgId:form.orgId})
    .then((x)=>{
        if(x.Success){
            LoadShared();
            toast.info(`We have given access to your workbook.`);
            setForm({});
        }
        else {
            toast.error(x.Message, {autoClose:30000});
        }
    });
};

    return (<>
        <div>
            <div className="flex-between">
                <h3>Shared With</h3>
            </div>
            <div className="shared-with-list">
                {shared.length > 0 && shared.map((x,i)=>{
                    return <div className="shared-with-info" key={`swl-i-${i}`}>
                        <div className="flex-between">
                            
                        <strong>{x.Name}</strong>
                        <div>
                            <span className="click text-danger" onClick={()=>{Remove(x)}}><Icon type={IconType.trashO}/></span>
                        </div>
                            </div>
                        Shared On: {FormatDate(x.CreatedAtUtc, 'date-time')}
                        {x.Description && <div dangerouslySetInnerHTML={{__html:x.Description}} />}

                    </div>
                })}
                {shared.length == 0 && <div className="alert alert-info">
                        <h4>Not shared.</h4>
                        Currently this workbook has not been shared with any organization. 
                    </div>}
            </div>
            <div>
                <hr/>
                <div className="form-horizontal">
                    <h3>Share Your Workbook</h3>
                    Share it with your teacher, school, or fair. 
                    <div className="form-group">
                        <label className="control-label col-sm-4" htmlFor="org-id">Organization Id</label>
                        <div className="col-sm-6">
                            <input
                                type="text"
                                maxLength={100}
                                id="org-id"
                                className="form-control"
                                value={form.orgId ?? ''}
                                onChange={(e)=>{
                                    setForm({...form, orgId:e.target.value});
                                }}
                                placeholder="Organization Id or Share Key"
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col-sm-6 col-sm-offset-4">
                            <button type="button" className="btn btn-secondary" onClick={Share}>
                                <Icon type={IconType.share} /> Submit
                            </button>
                            {props.close && <button type="button" className="btn btn-default" onClick={Close}>
                                <Icon type={IconType.close} /> Close
                            </button>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
};


export default WorkbookShareManager;