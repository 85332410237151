import React, { useState, useRef } from "react";
import Select from "react-select";
import { useEffect } from "react";
import { FieldTypes, FileUploadData, Selectable, UiFormField, UiFormSection } from "./FormManagerTypes";
import Editor from "../Tools/Editor";
import InputGradeRange from "../Tools/InputGradeRange";
import EasyFileInput from "../Tools/EasyFileInput";
import LabelHtmlRender from "./LabelHtmlRender";

interface FormSectionProps {
  section: UiFormSection;
  groupIds: Record<string, UiFormField[]>;
  formErrors: Record<string, UiFormField|undefined>;
  formValues: Record<string, string[]>;
  handleFieldChange: (key: string, value: string[]) => void;
  handleFileChange: (key: string, file?: File) => void;
  handleSelectChangeWithCall: (
    field: UiFormField,
    key: string,
    value: string[]
  ) => void;
  projectFileChanged: Function;
  idCalledInApi: string;
  targetId?: string;
  canSave: boolean;
  onInputBlur:(field:UiFormField)=>void;
}

const FormSection = (props: FormSectionProps) => {
  const [clearImageInfo, setClearImageInfo] = useState(false);
  const [fileLoaded, setFileLoaded] = useState(false);
  const [removedProjectFiles, setRemovedProjectFiles] = useState<string[]>([]);
  const [addedProjectFiles, setAddedProjectFiles] = useState<string[]>([]);

  const getReturnByFieldType = (field: UiFormField, confirm: boolean) => {
    let jsx: JSX.Element;
    let defaultThing: string = "";
    if (field.DefaultValue) {
      defaultThing = field.DefaultValue;
    }
    if (field.Value?.[0]) {
      defaultThing = field.Value[0];
    }

    switch (field.Type) {
      case FieldTypes.Password:
        jsx = (
          <React.Fragment key={field.Key}>
            <input
              name={confirm ? field.Key + "confirm" : field.Key}
              id={confirm ? field.Key + "confirm" : field.Key}
              type="password"
              className={`form-control `}
              onBlur={()=>{props.onInputBlur(field)}}
              value={
                confirm ? props.formValues[field.Key + "confirm"] ?? ''
                : props.formValues[field.Key] ?? field.Value
              }
              placeholder={field.Placeholder}
              onChange={(e) =>
                props.handleFieldChange(
                  confirm ? field.Key + "confirm" : field.Key,
                  [e.target.value]
                )
              }
              readOnly={field.ReadOnly}
              required={field.Required}
              maxLength={field.MaxLength}
            />
            {field.AdditionalInformation && (
              <div
                dangerouslySetInnerHTML={{ __html: field.AdditionalInformation }}
              ></div>
            )}
            {!confirm && field._errors && field._errors.length > 0 && <div className="alert alert-info">
              <ul>
              {field._errors.map((e,i)=>{
              return <li className="" key={`fel-${i}-${field.Key}`}>{e}</li>
            })}</ul></div>}
          </React.Fragment>
        );
        break;
      case FieldTypes.Textbox:
        jsx = (
          <React.Fragment key={field.Key}>
            <input
              name={confirm ? field.Key + "confirm" : field.Key}
              id={confirm ? field.Key + "confirm" : field.Key}
              onBlur={()=>{props.onInputBlur(field)}}
              type="text"
              className={`form-control `}
              value={
                props.formValues[confirm ? field.Key + "confirm" : field.Key] ??
                field.Value
              }
              placeholder={field.Placeholder}
              onChange={(e) =>
                props.handleFieldChange(
                  confirm ? field.Key + "confirm" : field.Key,
                  [e.target.value]
                )
              }
              readOnly={field.ReadOnly}
              required={field.Required}
              maxLength={field.MaxLength}
            />
            {field.AdditionalInformation && (
              <div
                dangerouslySetInnerHTML={{ __html: field.AdditionalInformation }}
              ></div>
            )}
          </React.Fragment>
        );
        break;
      case FieldTypes.AlertInfo:
        jsx = (
          <React.Fragment key={field.Key}>
            <div
              className="alert alert-info"
              dangerouslySetInnerHTML={{ __html: field.Value[0] }}
            ></div>
          </React.Fragment>
        );
        break;
      case FieldTypes.Alert:
        jsx = (
          <React.Fragment key={field.Key}>
            <div
              className="alert alert-default"
              dangerouslySetInnerHTML={{ __html: field.Value[0] }}
            ></div>
          </React.Fragment>
        );
        break;
      case FieldTypes.Textarea:
        jsx = (
          <React.Fragment key={field.Key}>
            <textarea
              name={field.Label}
              id={field.Key}
              onBlur={()=>{props.onInputBlur(field)}}
              className={`form-control`}
              value={props.formValues[field.Key] ?? field.Value}
              placeholder={field.Placeholder}
              onChange={(e) =>
                props.handleFieldChange(field.Key, [e.target.value])
              }
              readOnly={field.ReadOnly}
              required={field.Required}
              maxLength={field.MaxLength}
              rows={3}
            ></textarea>
            {field.AdditionalInformation && (
              <div
                dangerouslySetInnerHTML={{ __html: field.AdditionalInformation }}
              ></div>
            )}
          </React.Fragment>
        );
        break;
      case FieldTypes.Checkbox:
        jsx = (
          <React.Fragment key={field.Key}>
            <input
              name={field.Label}
              id={field.Key}
              type="checkbox"
              className={`form-check-input`}
              style={{ marginTop: "16px", marginLeft: "4px" }}
              checked={
                props.formValues[field.Key]
                  ? props.formValues[field.Key]?.[0]?.toLowerCase() === "true"
                  : field.Value?.[0]?.toLowerCase() === "true"
              }
              onChange={(e) =>
                props.handleFieldChange(field.Key, [`${e.target.checked}`])
              }
              readOnly={field.ReadOnly}
              disabled={field.ReadOnly}
              required={field.Required}
            />
          </React.Fragment>
        );
        break;
      case FieldTypes.DropDown:
        let initialValue = props.formValues[field.Key] ?? defaultThing;
        jsx = (
          <React.Fragment key={field.Key}>
            <>
              <select
                name={field.Label}
                id={field.Key}
                onBlur={()=>{props.onInputBlur(field)}}
                className={`form-control`}
                value={initialValue}
                placeholder={field.Placeholder}
                onChange={(e) =>
                  props.handleSelectChangeWithCall(field, field.Key, [
                    e.target.value,
                  ])
                }
                required={field.Required}
                disabled={field.ReadOnly}
              >
                <option value="<null value>">select</option>
                {field?.ValueOptions?.map((opt, i) => {
                  return (
                    <option value={opt.Value} key={`dd-k-${i}`}>
                      {opt.Name}
                    </option>
                  );
                })}
                {field?.NotListSpecial && (
                  <option value="<NOT LISTED>">Not Listed</option>
                )}
              </select>
            </>
            {field.AdditionalInformation && (
              <div
                dangerouslySetInnerHTML={{ __html: field.AdditionalInformation }}
              ></div>
            )}
          </React.Fragment>
        );
        // props.handleFieldChange(field.Key, initialValue) //todo:  is this going to cause a loop?  Because the change with call will cause re render?
        break;
      case FieldTypes.PhoneNumber:
        jsx = (
          <React.Fragment key={field.Key}>
            <input
              name={field.Label}
              id={field.Key}
              onBlur={()=>{props.onInputBlur(field)}}
              type="phone"
              className={`form-control`}
              value={props.formValues[field.Key] ?? field.Value}
              placeholder={field.Placeholder}
              onChange={(e) =>
                props.handleFieldChange(field.Key, [e.target.value])
              }
              readOnly={field.ReadOnly}
              required={field.Required}
              maxLength={field.MaxLength}
            />
            {field.AdditionalInformation && (
              <div
                dangerouslySetInnerHTML={{ __html: field.AdditionalInformation }}
              ></div>
            )}
          </React.Fragment>
        );
        break;
      case FieldTypes.Date:
        jsx = (
          <React.Fragment key={field.Key}>
            <input
              name={field.Label}
              id={field.Key}
              onBlur={()=>{props.onInputBlur(field)}}
              type="date"
              className={`form-control`}
              value={props.formValues[field.Key] ?? field.Value}
              placeholder={field.Placeholder}
              onChange={(e) =>
                props.handleFieldChange(field.Key, [e.target.value])
              }
              readOnly={field.ReadOnly}
              required={field.Required}
            />{field.AdditionalInformation && <div dangerouslySetInnerHTML={{__html:field.AdditionalInformation}}></div>}
          </React.Fragment>
        );
        break;
      case FieldTypes.Picture:
        jsx = <img src="" alt="pic" />;
        break;
      case FieldTypes.Header:
        jsx = (
          <h4
            dangerouslySetInnerHTML={{
              __html: buildLabelAndSearchForLink(field.Label),
            }}
          ></h4>
        );
        break;
      case FieldTypes.MultiSelect:
        let valueOptions: Selectable[] =
          field?.ValueOptions?.map((opt) => {
            return { label: opt.Name, value: opt.Value };
          }) ?? [];
        jsx = (
          <>
            <div className="">
              <Select
                name={field.Label}
                inputId={field.Key}
                onBlur={()=>{props.onInputBlur(field)}}
                
                onChange={(values: any) => {
                  if (field.ReadOnly) {
                    return;
                  }
                  if(values?.map){
                  let mappedValues = values.map((x) => x.value as string);
                  props.handleSelectChangeWithCall(
                    field,
                    field.Key,
                    mappedValues ?? []
                  );
                  } else {
                    props.handleSelectChangeWithCall(
                      field,
                      field.Key,
                      [values?.value]
                    );
                  }
                }}
                value={
                  props.formValues[field.Key]?.map((x) =>
                    valueOptions.find((y) => y.value === x)
                  ) ??
                  field.Value?.map((x) =>
                    valueOptions.find((y) => y.value === x)
                  )
                }
                options={valueOptions}
                className="multi-select"
                isMulti={field.MaxLength > 1 ? true:false}
                isClearable={true}
                menuPlacement={'auto'}
                classNamePrefix='cool'
              />
              {field.NotListSpecial && (
                <>
                  <div>
                    <button
                      type="button"
                      className="btn btn-xs btn-info headroom"
                      onClick={(e) =>
                        props.handleSelectChangeWithCall(field, field.Key, [
                          "<NOT LISTED>",
                        ])
                      }
                    >
                      Add a {field.NotListSpecial} that is not listed
                    </button>
                  </div>
                </>
              )}
              {field.AdditionalInformation && <div dangerouslySetInnerHTML={{__html:field.AdditionalInformation}}></div>}
            </div>
              
          </>
        );
        break;
      case FieldTypes.ProfileImage:
        jsx = (
          <React.Fragment key={field.Key}>
            <div className="">
              input type not supported: profile image
            </div>
          </React.Fragment>
        );
        break;
      case FieldTypes.ProjectFile:
        jsx = (
          <React.Fragment key={field.Key}>
            input type not supported: project file
          </React.Fragment>
        );
        break;
      case FieldTypes.ReCaptcha:
        return (
          <React.Fragment key={field.Key}>
            input type not supported: recaptcha
          </React.Fragment>
        );
        break;
      case FieldTypes.Color:
        jsx = (
          <React.Fragment key={field.Key}>
            <input
              name={confirm ? field.Key + "confirm" : field.Key}
              id={confirm ? field.Key + "confirm" : field.Key}
              type="Color"
              onBlur={()=>{props.onInputBlur(field)}}
              className={`form-control `}
              value={
                props.formValues[confirm ? field.Key + "confirm" : field.Key] ??
                field.Value ?? '#ffffff'
              }
              placeholder={field.Placeholder}
              onChange={(e) =>
                props.handleFieldChange(
                  confirm ? field.Key + "confirm" : field.Key,
                  [e.target.value]
                )
              }
              readOnly={field.ReadOnly}
              required={field.Required}
              maxLength={field.MaxLength}
            />
            {field.AdditionalInformation && (
              <div
                dangerouslySetInnerHTML={{ __html: field.AdditionalInformation }}
              ></div>
            )}
          </React.Fragment>
        );
        break;
      case FieldTypes.HTML:
        jsx = (
          <React.Fragment key={field.Key}>
            <Editor
              options={"staff-simple"}
              height={"short"}
              value={GetValue(field) ?? ""}
              onblur={()=>{props.onInputBlur(field)}}
              changed={(v) => {
                props.handleFieldChange(field.Key, [v]);
              }}
              readOnly={field.ReadOnly}
            />
            {field.AdditionalInformation && (
              <div
                dangerouslySetInnerHTML={{ __html: field.AdditionalInformation }}
              ></div>
            )}
          </React.Fragment>
        );
        break;
      case FieldTypes.HTMLFull:
        jsx = (
          <React.Fragment key={field.Key}>
            input type not supported: grapejs
          </React.Fragment>
        );
        break;
      case FieldTypes.DateTime:
        jsx = (
          <React.Fragment key={field.Key}>
            <input
              name={field.Label}
              id={field.Key}
              onBlur={()=>{props.onInputBlur(field)}}
              type="datetime-local"
              className={`form-control`}
              value={props.formValues[field.Key] ?? field.Value}
              placeholder={field.Placeholder}
              onChange={(e) =>
                props.handleFieldChange(field.Key, [e.target.value])
              }
              readOnly={field.ReadOnly}
              required={field.Required}
            />{field.AdditionalInformation && <div dangerouslySetInnerHTML={{__html:field.AdditionalInformation}}></div>}
          </React.Fragment>
        );
        break;
        case FieldTypes.Time:
          jsx = (
            <React.Fragment key={field.Key}>
              <input
                name={field.Label}
                id={field.Key}
                type="time"
                onBlur={()=>{props.onInputBlur(field)}}
                className={`form-control`}
                value={props.formValues[field.Key] ?? field.Value}
                placeholder={field.Placeholder}
                onChange={(e) =>
                  props.handleFieldChange(field.Key, [e.target.value])
                }
                readOnly={field.ReadOnly}
                required={field.Required}
              />{field.AdditionalInformation && <div dangerouslySetInnerHTML={{__html:field.AdditionalInformation}}></div>}
            </React.Fragment>
          );
          break;
      case FieldTypes.Number:
        jsx = (
          <React.Fragment key={field.Key}>
            <input
              name={field.Label}
              id={field.Key}
              type="number"
              onBlur={()=>{props.onInputBlur(field)}}
              className={`form-control`}
              value={props.formValues[field.Key] ?? field.Value}
              placeholder={field.Placeholder}
              onChange={(e) =>
                props.handleFieldChange(field.Key, [e.target.value])
              }
              readOnly={field.ReadOnly}
              required={field.Required}
              onWheel={(e)=>{
                  e.preventDefault(); 
                  e.stopPropagation();
                  let target:any = e.target;
                  if(target.blur) target.blur();
                  return false;
                }
              }
            />

            {field.AdditionalInformation && (
              <div
                dangerouslySetInnerHTML={{ __html: field.AdditionalInformation }}
              ></div>
            )}
          </React.Fragment>
        );
        break;
      case FieldTypes.GradeRange:
        jsx = (
          <React.Fragment key={field.Key}>
            <InputGradeRange
              onValueChange={(v) => {
                props.handleFieldChange(field.Key, [v]);
              }}
              value={GetValue(field) ?? ""}
              forLow={field.Key}
              readOnly={field.ReadOnly}
              delimiter={"|"}
              onBlur={()=>{props.onInputBlur(field)}}
            />
            {field.AdditionalInformation && (
              <div
                dangerouslySetInnerHTML={{ __html: field.AdditionalInformation }}
              ></div>
            )}
          </React.Fragment>
        );
        break;
      case FieldTypes.File:
        jsx = (
          <React.Fragment key={field.Key}>
            <EasyFileInput
              exts={field.FileExts}
              htmlId={field.Key}
              onSelectFile={(f) => {
                props.handleFileChange(field.Key, f);
              }}
              onSelect={(v) => {
                props.handleFileChange(field.Key);
                props.handleFieldChange(field.Key, [v]);
              }}
              value={GetValue(field) ?? ""}
              valueOptions={field.ValueOptions ?? undefined}
              readOnly={field.ReadOnly}
            />{field.AdditionalInformation && <div dangerouslySetInnerHTML={{__html:field.AdditionalInformation}}></div>}
          </React.Fragment>
        );
        break;
      default:
        jsx = (
          <React.Fragment key={field.Key}>
            <input
              name={field.Label}
              id={field.Label}
              type="text"
              className={`form-control`}
              value={props.formValues[field.Key] ?? field.Value}
              placeholder={field.Placeholder}
              onChange={(e) =>
                props.handleFieldChange(field.Key, [e.target.value])
              }
              readOnly={field.ReadOnly}
              required={field.Required}
              maxLength={field.MaxLength}
            />
          </React.Fragment>
        );
        break;
    }
    return jsx;
  };

  const GetValue = (field: UiFormField): string | null => {
    //props.formValues[field.Key] ?? field.Value
    let v = props.formValues[field.Key];
    if (v && v[0]) return v[0];
    else if (field.Value && field.Value[0]) return field.Value[0];

    return null;
  };

  const buildLabelAndSearchForLink = (label: string) => {
    if (!label || label.length < 10) return label;
    let letters = label
      .replaceAll("{|br|}", "<br/>")
      .replaceAll("\r\n", "<br/>");

    let stack: any = [];
    let parts: string[] = [];
    let startIndex = 0;

    let makeLink = (value: string) => {
      let values = value.split("|");
      return `<a href=${values[0]} target="_blank" rel="noopener noreferrer">${
        values.length > 1 ? values[1] : values[0]
      }</a>`;
      return value;
    };

    for (var i = 0; i < letters.length; i++) {
      if (
        letters[i] == "[" &&
        (letters[1 + i] == "h" || letters[1 + i] == "H") &&
        (letters[2 + i] == "t" || letters[2 + i] == "T") &&
        (letters[3 + i] == "t" || letters[3 + i] == "T") &&
        (letters[4 + i] == "p" || letters[4 + i] == "p")
      ) {
        var s = i;
        var found = false;
        for (var e = i + 5; i < letters.length; e++) {
          if (letters[e] == "]") {
            stack.push({ A: s, B: e });

            parts.push(letters.substr(startIndex, i - startIndex));
            parts.push(makeLink(letters.substr(i + 1, e - i - 1)));
            console.log(parts);

            startIndex = e + 1;
            i = e;
            found = true;

            break;
          }
        }
        if (!found) break; //Not valid nothing to do here.
      }
    }

    if (startIndex < letters.length) {
      parts.push(letters.substr(startIndex));
    }
    return parts.join(" ");

    let arrayOfStrings = label.split(" ");
    let linkified = arrayOfStrings.map((word) => {
      if (word.includes("http") && word.includes("|")) {
        let brokenOut = word.split("|");
        return `<a href=${brokenOut[0].slice(
          1
        )} target="_blank" rel="noopener noreferrer">${brokenOut[1].slice(
          0,
          -1
        )}</a>`;
      }
      return word;
    });
    return linkified.join(" ");
  };

  const mapFieldsFromForm = (field: UiFormField, i: number) => {
    if (!field.Show) return null;
    if (field.GroupId) {
      if (field.IsParent) {
        return (
          <div
            className={`form-group ${
              props.groupIds[field.GroupId]?.find(
                (x) => props.formErrors[x.Key]
              )
                ? "has-error"
                : ""
            }`}
            key={`${field.Key}-${i}`}
          >
            {/* <div className={`form-group has-error`} key={`${field.Key}-${i}`}> */}
            <label className="col-md-4 control-label" htmlFor={field.Key}>
              {
                <LabelHtmlRender
                  customText={buildLabelAndSearchForLink(field.Label)}
                />
              }
              {field.Required && "*"}
            </label>
            <div className="col-md-8 flex">
              {props.groupIds[field.GroupId]?.map((gIdField, j) =>
                getReturnByFieldType(gIdField, false)
              )}
            </div>
          </div>
        );
      } else {
        return <React.Fragment key={`${field.Key}-${i}`}></React.Fragment>;
      }
    }
    if (field.Type === FieldTypes.Checkbox) {
      return (
        <div
          className={`inline-label form-group 
        ${props.formErrors[field.Key] ? "has-error" : ""}`}
          key={`${field.Key}-${i}`}
        >
          <div
            className={`col-md-${field.BodyBootstrapSize} col-md-offset-${field.HeaderBootstrapSize}`}
          >
            {getReturnByFieldType(field, false)}
            <label className="control-label text-left" htmlFor={field.Key}>
              {
                <LabelHtmlRender
                  customText={buildLabelAndSearchForLink(field.Label)}
                />
              }
              {field.Required && "*"}
            </label>
            {field.AdditionalInformation && (
              <div
                dangerouslySetInnerHTML={{ __html: field.AdditionalInformation }}
              ></div>
            )}
          </div>
        </div>
      );
    }
    return (
      <React.Fragment key={`${field.Key}-${i}-mfff`}>
        <div
          className={`
        form-group 
        ${props.formErrors[field.Key] ? "has-error" : ""}`}
          key={`${field.Key}-${i}`}
        >
          {field.Type !== FieldTypes.Header && (
            <label
              className={`col-md-${field.HeaderBootstrapSize} control-label`}
              htmlFor={field.Key}
            >
              {
                <LabelHtmlRender
                  customText={buildLabelAndSearchForLink(field.Label)}
                />
              }
              {field.Required && "*"}
            </label>
          )}
          <div className={`col-md-${field.BodyBootstrapSize}`}>
            {getReturnByFieldType(field, false)}
          </div>
        </div>
        {field.Confirm && (
          <div
            className={`
              form-group 
              ${props.formErrors[field.Key + "confirm"] ? "has-error" : ""}`}
            key={`${field.Key}confirm-${i}`}
          >
            {field.Type !== FieldTypes.Header && (
              <label
                className={`col-md-${field.HeaderBootstrapSize} control-label`}
                control-label
                htmlFor={field.Key + "confirm"}
              >
                Confirm{" "}
                {
                  <LabelHtmlRender
                    customText={buildLabelAndSearchForLink(field.Label)}
                  />
                }
                {field.Required && "*"}
              </label>
            )}
            <div className={`col-md-${field.BodyBootstrapSize}`}>
              {getReturnByFieldType(field, true)}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  return (
    <div>
      {(props.section.Label || props.section.HtmlBody) && (
        <>
          {props.section.Label && <h4>{props.section.Label}</h4>}

          {props.section.HtmlBody && (
            <div
              dangerouslySetInnerHTML={{ __html: props.section.HtmlBody }}
            ></div>
          )}
          <hr />
        </>
      )}
      <div>
        {props.section.Fields?.map((field, j) => mapFieldsFromForm(field, j))}
      </div>
    </div>
  );
};

export default FormSection;
