import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { RouteComponentProps, withRouter } from "react-router";
import { toast } from "react-toastify";
import { checkEmailValid, isValidEmail, isValidPhone } from "../../utils/Tools";
import { Server, _server } from "../../utils/Server";
import Icon, { IconType } from "../Icon/Icon";
import Login from "./Login";
import ConfigFormEditor from "../FormManager/ConfigFormEditor";
import redux, { connectMap, IState, ReduxMapper } from "../../Redux/Redux";
import { IConfigItemSummary } from "../FormManager/FormManagerTypes";
import Users from "./Users";import OrgPageHeader from "../../Components/Organizations/OrgPageHeader";

interface IProfileProps extends IState {

}

const Profile = (props: IProfileProps) => {


  const RegisterUpdated = (user:IConfigItemSummary)=>{

  };

  return (
    <>
    <OrgPageHeader state={props} />
 {props.User && <div className="user-registration">
        <div >
                  <ConfigFormEditor
                type={"users"}
                item={{Name:'', Type:'users', Id:props.User.PublicId, Key:props.User.PublicId}}
                itemKey={props.User.PublicId}
                canSave={true}
                noEdit={false}
                itemChildren={undefined}
                onClose={() => {
                }}
                onSave={RegisterUpdated}
                onRemove={RegisterUpdated}
                onUpdate={RegisterUpdated}
                managerControler={`NonAdminDataManager`}
                noModal={true}
        />
        </div>
        </div>}
    </>
  );
};

export default ReduxMapper(Profile);
