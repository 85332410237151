
import { stringify } from "querystring";
import redux from "../Redux/Redux";
import { emailRegex, urlRegex, usPhoneRegex } from "./Constants";
import { Server } from "./Server";
export const checkEmailValid = (email: string) => {
  return emailRegex.test(email.toLowerCase());
}

export const checkPhoneValid = (phoneNum: string) => {
  return usPhoneRegex.test(phoneNum);
}

export const checkUrlValid = (url: string) => {
  return urlRegex.test(url);
}


export const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}


export const formatVideoUrl = (url) => {
  let alteredUrl:string;
  if (url.includes('.be/')) {
    // shortened youtube link
    alteredUrl = `https://youtube.com/embed/${url.split('.be/')[1]}`;
  } else if (url.includes('watch?v=')) {
    //long youtube link
    alteredUrl = url.replace('watch?v=', 'embed/');
  } else {
    alteredUrl = url;
  }

  let value = alteredUrl;
  let charArray: any[] = [];
  let inqueryString = false;

  for (let i = 0; i < value.length; i++) {
    if (value[i] === '?') {
      if (!inqueryString) {
        inqueryString = true;
      } else {
        charArray.push('&');
        continue;
      }
    }
    charArray.push(value[i]);
  }
  value = charArray.join('');
  alteredUrl = value;

  if (alteredUrl.indexOf('&v=') > -1) {
    alteredUrl = `https://youtube.com/embed/${alteredUrl.split('&v=')[1]}`
  }
  if (alteredUrl.indexOf('?v=') > -1) {
    alteredUrl = `https://youtube.com/embed/${alteredUrl.split('?v=')[1]}`
  }

  if (alteredUrl.toLowerCase().indexOf('google.com') > 0 && alteredUrl.toLowerCase().indexOf('/view')) {
    alteredUrl = alteredUrl.replace('/view', '/preview');
  }

  if (alteredUrl.toLowerCase().indexOf('youtube') > 0 && alteredUrl.indexOf('&') > 0) {
    alteredUrl = alteredUrl.substring(0, alteredUrl.indexOf('&'));
  }

  if(alteredUrl.toLowerCase().indexOf('youtube') > -1 && alteredUrl.toLowerCase().indexOf('http:') > -1){
    alteredUrl = 'https'+alteredUrl.substr(4);
  }

  return alteredUrl;
}

export const scrollToTop = () => window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

export const formatDateForInput = (date: string) => {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

export const ToDate=(value:string|number)=>{
  
  let currentDate = new Date();
  let timeOffsetInMS:number = currentDate.getTimezoneOffset() * 60000;

  if(typeof value === "string" && value.indexOf('/Date(')>-1){ 
    let date2 = new Date(parseInt(value.substring(6)))
    date2.setTime(date2.getTime() - timeOffsetInMS);

    return date2;
  
  } else {
  let date2 = new Date(value);
  date2.setTime(date2.getTime() - timeOffsetInMS);
  
  console.log('ToDate', date2, currentDate);
  
  return date2;
}
};

export const FormatDate=(value:string|Date|undefined, format?:'input'|'date'|'date-time')=>{
  if(!value) return undefined;
  let date = (value instanceof Date) ? value : ToDate(value);
  if(!date) return undefined;

  let hours = date.getHours();
  let min:string|number = date.getMinutes();
  let pm = false;
  if(hours > 11) pm = true;
  if(hours === 0) hours = 12;
  else if (hours>12) hours -=12;
  if(min < 10) min = '0'+min;

  let noTime = date.getSeconds() === 0 && date.getHours() === 0 && date.getMinutes() === 0 && date.getMilliseconds() === 0; 

  switch(format ?? 'date-time'){
    case 'input':
      return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
      break;
      case 'date':
        return `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`;
        break;
        case 'date-time':
          default:
            if(noTime) return `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`;
          return `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()} ${hours}:${min} ${pm?'pm':'am'}`;
          break;
  }
  
};

export const LimitStringSize = (str:string, maxLength:number)=>{
  if(str && str.length > maxLength){
    return str.substring(0, maxLength-3) + '...';
  }

  return str;
};

export const isValidEmail = (email?:string) =>{
  if(!email || email.indexOf("@") === -1 || email.indexOf(".") === -1) return false;

  return true;
};
export const isValidPhone = (phone?:string) =>{
  if(!phone || phone.length < 10) return false;
  
  return true;
};

export const isValidPassword = (password:string, password2:string, errors:string[], e:any) =>{
  if (password) {
    if (password != password2) {
      errors.push(`The passwords you provided do not match`);
      e.match = true;
    }

    if (8 > password.length) {
      errors.push(`Password must be at least 8 charaters`);
      e.password = true;
    }
    if (!password.match(/[A-Z]/g) || !password.match(/[a-z]/g)) {
      errors.push(
        `Password must have at least one upper and lower case letter`
      );
      e.password = true;
    }
    if (!password.match(/[0-9]/g)) {
      errors.push(`Password must have at least one number.`);
      e.password = true;
    }
    if (
      !password.match(
        /[\!\@\#\$\%\^\&\*\:\;\'\?\<\>\,\.\|\[\]\{\}\=\_\+\(\)]/g
      )
    ) {
      errors.push(
        `Password must have at least one special character. Such as: !@#$%^&*()=+{}[].`
      );
      e.password = true;
    }
  } else {
    errors.push(`Password must be provided.`);
    e.password = true;
  }

  return errors.length === 0;
}

