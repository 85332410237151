export interface IConfigFormData {
    IsStaff:boolean;
    Title: string;
    Description?: string;
    Items:IConfigItemSummary[];
    ItemsChildTypes?:IConfigItemChildType[];
    NoEdit?:boolean;
    ConfigGroups?:string[];
}

export interface IConfigItemChildType{
    Name:string;
    Type:string;
    Key:string;
}

export interface IConfigItemSummary{
    FilePath?: string;
    Name:string;
    SortBy?:string;
    Id?:string;
    Key?:string;
    Type?:string;
    SubType?:string;
    Description?:string;
    Color?:string;
    Failed?:boolean;
    Message?:string;
    NoEdit?:boolean;
}

export interface IConfigFormEditorData{    
    Id?:string;
    Type?:string;
    Values?:Record<string, string[]>;
    Form:IFormInfo;
}

export interface IFormInfo{
  Sections:UiFormSection[];
  LinkToManager?:string;
  LinkToManagerLabelText?:string;
  CopyItem?:boolean;
  SubmitBtnText?:string;
}

export interface UiFormData {
  targetId: string;
  Tabs: UiFormTab[];
  NewRecord: boolean;
  FirstTimeLogin:boolean;
  ByPassRequired:boolean;
  NextRecordPath: string;
  Requirements: ParticipantRequirement[] | null;
  CanSave:boolean;  
  SubForm:UiFormData;
  VerifyEmail:boolean;
  PageTitle:string;
  role:SecurityRole;
  BackTo:UiBackTo;
}

export interface UiBackTo
{
    ClassName:string;
    Label:string;
    Path:string;
}

export interface SavePersonFormResponse{
  errors: string[],
  targetId: string;
  role: SecurityRole;
  redirectTo:string;
}

export interface UiFormTab {
  Label: string;
  HtmlBody:string;
  Active: boolean;
  Order: number;
  Sections: UiFormSection[];
  Show: boolean;  
  SpecialType:string;
  DataPoint:string;
  DataPoint2:string;
  targetId: string;
}

export interface UiFormSection {
  Label: string;
  Active: boolean;
  Order: number;
  ReadOnly: boolean;
  Fields: UiFormField[];
  HtmlBody:string;
}

export interface UiFormField {
  DefaultValue: string | null;
  GroupId: string | null;
  Order: number;
  Confirm: boolean;
  Required: boolean;
  ReadOnly: boolean;
  Label: string;
  Placeholder: string;
  Key: string;
  Value: string[];
  Files: IFileDetails[];
  FileType:string;
  FileExts:string;
  Type: FieldTypes;
  ValueOptions: UiValuePairs[] | null;
  MaxLength: number;
  IsParent: boolean;
  OnChangeCallUpdate: Record<string,string> // key = api call, value = key of input to update
  OnChangeCallUpdateQueryString:string;
  // OnChangeCall: string;
  // OnChangeUpdate: string;
  RequireMinLength: number;
  RequireAltCase: boolean;
  RequireNumber: boolean;
  RequireSpecialChar: boolean;
  SpecialChars: string[]|null;
  HeaderBootstrapSize:number;
  BodyBootstrapSize:number;
  ValidationRegularExpression:string;
  Show:boolean;
  NotListSpecial:string;
  NotListedSchoolsDataKey:string;
  AdditionalInformation:string;
  _errors?:string[];
}

export interface IFileDetails{
  IsImage:boolean;
  IsPdf:boolean;
  Caption:string;
  Key:string;
  Extension:string;
  Name:string;
  FlipKey?:number;
  File?:File;
  Base64Url?:string;
}

export enum FieldTypes {
  Textbox = 0,
  Textarea = 1,
  Checkbox = 2,
  DropDown = 3,
  PhoneNumber = 4,
  Date = 5,
  Picture = 6,
  Header = 7,
  Button = 8,
  MultiSelect = 9,
  ProfileImage = 10,
  ProjectFile = 11,
  Password = 12,
  AlertInfo = 13,
  Alert = 14,
  ReCaptcha = 15,
  Color = 16,
  HTML = 17,
  DateTime = 18,
  Number = 19,
  GradeRange = 20,
  File = 21,
  Time = 22,
  HTMLFull = 23,
}

export interface UiValuePairs {
  Value: string;
  Name: string;
}

export interface ParticipantRequirement{
  description:string,
  label:string,
  inputType: RequirementInputType,
  value: string,
  sort:number,
  key:string,
}

export enum RequirementInputType{
  checkbox = 0,
  yesNoDropdown =1,
  displayOnly=2,
  SignatureTyped=3,
}

export interface FileUploadData {
      caption: string;
      dataUrl: string;
      filename: string;
    }

    
export interface Tag {
    FairId: number; // 1
    Id: number; // 2
    Type: string; // "person"
    Value: string; // "Best Judge"
  }
  
  export interface PasswordRequirements {
    MinLength: number;
    RequireAltCase: boolean;
    RequireNumber: boolean;
    RequireSpecialChar: boolean;
    specialChars: string;
  }
  
  export enum SecurityRole {
      Yoda = 100,
      Admin = 10,
      Staff = 7,
      DistrictCoordinator = 6,
      Judge = 5,
      Volunteer = 3,
      Teacher = 1,
      Student = 0,
      Attendee = -100,
      Unknown = -1000
  }

  export interface Selectable {
    label: string;
    value: number|string;
    options?:Selectable[]
  }
  
  export interface ColumnData {
    value: string;
    id: string;
  }

  export const isFieldRegexSatisfied = (
    field: UiFormField,
    formValues: Record<string, string[]>
  ) => {
    if (!field.ValidationRegularExpression) return true;
    
    let value = field.Value ? field.Value[0] : null;
    if (formValues[field.Key] && formValues[field.Key][0])
      value = formValues[field.Key][0];
  
    if (!value) return true;
  
    var regex = new RegExp(field.ValidationRegularExpression, `g`);
    if (regex && value.match(regex)) return true;
    else return false;
  };
  
  export const isFieldSatisfied = (
    field: UiFormField,
    formValues: Record<string, string[]>
  ) => {
    if (!field.Required) return true;
    if (
      field.Type === FieldTypes.Alert ||
      field.Type === FieldTypes.AlertInfo ||
      field.Type === FieldTypes.Header
    )
      return true;
  
    if (field.Type === FieldTypes.ReCaptcha) {
      let w: any = window;
      let value = w.x_reCaptcha;
      if (value) {
        formValues[field.Key] = [value, "Added By Extra..."];
      }
      console.log("reCaptcah Value", value);
    }
  
    if (field.Type === FieldTypes.Checkbox) {
      //must be true...
      if (formValues[field.Key]) {
        //new value set, is this value valid?
        if (formValues[field.Key]?.find((x) => x === "true" || x==="True")) return true;
        return false;
      }
      if (field.Value && field.Value[0] === "true" || field.Value[0] ==="True") return true;
      return false;
    }
    if(field.Type === FieldTypes.ProfileImage || field.Type === FieldTypes.ProjectFile){
      if(field.Files && field.Files.length > 0) return true;
    }
    if(field.Type === FieldTypes.MultiSelect){
      let valueArray = formValues[field.Key] ?? field.Value ?? [];
      let value = valueArray[0];
      if(!value) return false;
      return true;
    }
    if (formValues[field.Key]) {
      //new value set, is this value valid?
      if (formValues[field.Key]?.find((x) => x)) return true;
      else return false;
    }
    if (field.Value && field.Value[0]) return true;
    return false;
  };
  
  export const isConfirmSatisfied = (
    field: UiFormField,
    formValues: Record<string, string[]>
  ) => {
    if (!field.Confirm) return true;
  
    if (formValues[field.Key] || formValues[field.Key + "confirm"]) {
      try{
      return formValues[field.Key + "confirm"] && formValues[field.Key]  && formValues[field.Key][0] === formValues[field.Key + "confirm"][0];
      }catch{
        return false;
      }
    }
    return true;
  };

  export const isPasswordValid = (
    field: UiFormField,
    formValues: Record<string, string[]>) => {
      let errors: string[] = [];
    if (!field.Confirm) {
    } else if (
      field.Type === FieldTypes.Password &&
      formValues[field.Key]
    ) {
      let value = formValues[field.Key][0];
      if (field.RequireMinLength > value.length)
        errors.push(
          `${field.Label} must be at least ${field.RequireMinLength} characters long.`
        );
      if (
        field.RequireAltCase &&
        (!value.match(/[A-Z]/g) || !value.match(/[a-z]/g))
      )
        errors.push(
          `${field.Label} must have both an uppercase and lowercase letter.`
        );
      if (
        field.RequireNumber &&
        (!value.match(/[A-Za-z]/g) || !value.match(/[0-9]/g))
      )
        errors.push(
          `${field.Label} must have at least one number and one letter.`
        );
      if (
        field.RequireSpecialChar &&
        field.SpecialChars &&
        !field.SpecialChars.find((x) => value.indexOf(x) > -1)
      )
        errors.push(`${field.Label} must have a special charater.`);
    }

    field._errors = errors;

    return errors;
  }

  export interface IFieldGoodResponse {
    Good:boolean;
    RegExPass:boolean;
    RequiredPass:boolean;
    ConfirmPass:boolean;
    PasswordRequirements:boolean;
    Message?:string;
  }

  export const isFieldGood=(field:UiFormField, formValues: Record<string, string[]>)=>{
    let regex = isFieldRegexSatisfied(field, formValues);
    let required = isFieldSatisfied(field, formValues);
    let confirm = isConfirmSatisfied(field, formValues);
    let passwordRequirements = isPasswordValid(field, formValues);

    let response:IFieldGoodResponse = {
      Good: regex && required && confirm && passwordRequirements.length === 0,
      RegExPass: regex,
      ConfirmPass:confirm,
      PasswordRequirements:passwordRequirements.length === 0,
      RequiredPass:required,      
    } 

    if(response.Good) return response;

    let msg:string[] = [];

    passwordRequirements.forEach(x=>{msg.push(x);});
    //build message
    if(!required) msg.push(`${field.Label} is required.`);
    else if(!regex) msg.push(`${field.Label} is not valid please provide valid data.`);
    else if (!confirm) msg.push(`${field.Label} needs to be confirmed.`);

    //set message
    response.Message = msg.join(' ');

    return response;
  }