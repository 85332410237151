import { useEffect, useState } from 'react';
import redux, { IState, ReduxMapper } from '../../Redux/Redux';
import { IOrgUserSummary } from '../../Types/User';
import { FormatDate } from '../../utils/Tools';
import Icon, { IconType } from '../Icon/Icon';
import { Server, _server } from '../../utils/Server';
import Modal from '../Modal/Modal';
import UserSignerAccessManager from './Signers/UserSignerAccessManager';
import UsersOrganizationRoleManager from './Users/UsersOrganizationRoleManager';
import { toast } from 'react-toastify';
import CopyToClipboard from '../../utils/CopyToClipboard';

interface IProps {
  onSelect?: IOrgUserSummary;
  orgId?:string;
  state:IState;
}

interface ISearch {
  users?: string;
}

const OrganizationUserSearchableList = (props: IProps) => {
  const [orgUsers, setOrgUsers] = useState<IOrgUserSummary[]>([]);
  const [search, setSearch] = useState<ISearch>({});
  const [openSigner, setOpenSigner] = useState<boolean>();
  const [openContext, setOpenContext] = useState<boolean>(false);
  const [openManageUserRole, setOpenManageUserRole] = useState<boolean>(false);
  const [activeOrgUser, setActiveOgrUser] = useState<IOrgUserSummary>();

  useEffect(() => {
    if (props.state.User) {
      init();
    }
  }, [props.state.User]);

  const init = () => {
    redux.setOrgUsers(props.orgId);
  };

  useEffect(() => {
    if (props.state.OrgUsers) {
      if (search.users) {
        let text = search.users.toLowerCase();
        text += ' ' + search.users.replace(/[^0-9]/g, '');
        let lookFor: string[] = search.users
          .toLowerCase()
          .split(/[\s\,\t]/g)
          .filter((x) => x.length > 0);

        setOrgUsers(props.state.OrgUsers.filter((x) => filterUsers(x, lookFor)));
      } else {
        setOrgUsers(props.state.OrgUsers);
      }
    }
  }, [props.state.OrgUsers, search]);

const openContextMenu = (user:IOrgUserSummary)=>{
  setActiveOgrUser(user);
  setOpenContext(true);
};

const loginAs = () =>{
  _server.postApi<string[]>(`../OrgApi/LoginAs`, {orgId:props.orgId, userId:activeOrgUser?.User.PublicId})
  .then((x) => {
    if (x.Success) {
      toast.success(`You've signed in as ${activeOrgUser?.User.FirstName}!`);
      window.location.href = Server.BuildUrl(`../`);
    } else {
      toast.error(`Failed to sign in as ${activeOrgUser?.User.FirstName}`, {autoClose:30000});
    }
  });
};

  return (
    <>
      <div>
        <div className="search-bar">
          <div className="form-horizontal">
            <div className="form-group">
              <label className="control-label col-sm-3" htmlFor="org-user-info-search">
                Search
              </label>
              <div className="col-sm-7">
                <input
                  type={'text'}
                  maxLength={500}
                  value={search.users ?? ''}
                  onChange={(e) => {
                    setSearch({ ...search, users: e.target.value });
                  }}
                  className="form-control"
                  id="org-user-info-search"
                  autoFocus={true}
                />
              </div>
            </div>
          </div>
          <div className="flex-between">
            <div>
              Users: {orgUsers?.length ?? 0} of {props.state.OrgUsers?.length ?? 0}{' '}
            </div>
          </div>
        </div>
        {orgUsers.map((r, i) => {
          let x = r.User;
          let isOrgUser = x.UserApprovedAtUtc && !x.UserRemovedAtUtc;
          return (
            <div className="list-row" key={`od-u-lr-${i}`}>
              <div className="user-info">
                <h4>
                  <div className='flex-between'>
                    <span>{x.LastName}, {x.FirstName} {x.PreferredName && <>({x.PreferredName})</>}</span>
                    {isOrgUser && <span className="click" onClick={() => {
                    openContextMenu(r);
                  }}>
                    <Icon type={IconType.edit} />
                  </span>}
                  </div>
                  
                </h4>
                {isOrgUser ? <><div>{x.Email}</div>
                <div>
                  {x.Phone}{' '}
                  {x.AllowSms && x.Phone && (
                    <>
                      <Icon type={IconType.sms} addClass="text-success" />
                    </>
                  )}
                </div>
                {x.IsAdmin && <div><span className='label label-default'><Icon type={IconType.admin}/> Admin </span></div>}</>
                : <div>
                  <span className='label label-warning click' title={`Not a member of "${props.state.OrgInfo?.Name ?? 'Your Organization'}"!`}><Icon type={IconType.warning} /> Not a member</span>
                  </div>}
              </div>
              <div className="workbook-list">
                {r.Workbooks?.filter((x) => !x.RemovedAtUtc && x.PublicId).map((p, pi) => {
                  return (
                    <div key={`od-u-lr-${i}-p-${pi}`} className="workbook-summary">
                      <div className="flex-between no-wrap">
                        <strong>{p.Name}</strong>
                        <a target={'_blank'} href={Server.BuildUrl(`../workbook/${p.PublicId}`)}>
                          <Icon type={IconType.shareSquareSolid} />
                        </a>
                      </div>
                      <div>
                      <CopyToClipboard valueToCopy={p.PublicId}>Id: {p.PublicId}</CopyToClipboard>
                      </div>
                      <div>{p.TemplateName} </div>
                      <div>
                        Started: {FormatDate(p.CreatedAtUtc, 'date')} Last: {FormatDate(p.LastModifyAtUtc, 'date')} Changes: {p.NumberOfUpdates ?? 0}
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="text-right grow">
                <CopyToClipboard valueToCopy={x.PublicId}>Id: {x.PublicId}</CopyToClipboard>
                <br />
                <CopyToClipboard valueToCopy={x.Id}>Id: {x.Id}</CopyToClipboard>
              </div>
            </div>
          );
        })}
      </div>

      {openContext && activeOrgUser && <Modal setModalOpen={setOpenContext} title={`${activeOrgUser.User.FirstName} ${activeOrgUser.User.LastName}`} size='m'>
          <div>
            <div>
              
            </div>
            <div>Select an option below.</div>
            <button type={"button"} className='btn btn-default' onClick={()=>{setOpenSigner(true);}}>
              <Icon type={IconType.fileSignature} /> Manage Review / Signer Access
            </button>
            <br/>
            <button type={"button"} className='btn btn-default' onClick={()=>{setOpenManageUserRole(true);}}>
              <Icon type={IconType.admin} /> Manage Organization User Role
            </button>
            <br/>
            <button type={"button"} className='btn btn-default' onClick={()=>{loginAs();}}>
              <Icon type={IconType.signIn} /> Login As
            </button>
            
          </div>
        </Modal>}

        {openSigner && activeOrgUser && <Modal setModalOpen={setOpenSigner} title={`Signer Access for: ${activeOrgUser.User.FirstName} ${activeOrgUser.User.LastName}`} size='l'>
          <div>
            <UserSignerAccessManager state={props.state} userPublicId={activeOrgUser.User.PublicId} orgId={props.orgId} />
          </div>
        </Modal>}

        {openManageUserRole && activeOrgUser && <Modal setModalOpen={setOpenManageUserRole} title={`Manage User Role For: ${activeOrgUser.User.FirstName} ${activeOrgUser.User.LastName}`} size='l'>
          <div>
            <UsersOrganizationRoleManager state={props.state} user={activeOrgUser.User} orgId={props.orgId} />
          </div>
        </Modal>}

    </>
  );
};

export default OrganizationUserSearchableList;


export const filterUsers = (value: IOrgUserSummary, search: string[]) => {
  for (let i = 0; i < search.length; i++) {
    let x = search[i];

    if (value.User.FirstName?.toLowerCase().indexOf(x) > -1) return true;
    if (value.User.LastName?.toLowerCase().indexOf(x) > -1) return true;
    if (value.User.Email?.toLowerCase().indexOf(x) > -1) return true;
    if (
      value.User.Phone?.replace(/[^0-9]/g, '')
        .toLowerCase()
        .indexOf(x) > -1
    )
      return true;
    if (value.User.Race?.toLowerCase().indexOf(x) > -1) return true;
    if (value.User.Ethnicity?.toLowerCase().indexOf(x) > -1) return true;
    if (value.User.PublicId?.toLowerCase().indexOf(x) > -1) return true;
    if (value.User.Id + '' === x) return true;

    var found = false;
    value.Workbooks?.forEach((w) => {
      if (w.Name?.toLowerCase().indexOf(x) > -1) found = true;
      if (w.PublicId?.toLowerCase().indexOf(x) > -1) found = true;
      if (w.TemplateName?.toLowerCase().indexOf(x) > -1) found = true;
      if (w.TemplatePublicId?.toLowerCase().indexOf(x) > -1) found = true;
    });

    return found;
  }
};