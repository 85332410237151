import { toast } from "react-toastify";
import { IState, ReduxMapper } from "../../Redux/Redux";
import { _server } from "../../utils/Server";
import { useEffect, useState } from "react";
import { IOrganizationInfo, IUsersOrganizationInfo } from "../../Components/Organizations/OrganizationTypes";
import OrgPageHeader from "../../Components/Organizations/OrgPageHeader";
import UsersOrganzations from "../../Components/User/UsersOrganizations";

interface IProps extends IState{
    orgId?:string;
}

const UsersOrganizationManager = (props:IProps) =>{
    const [org, setOrg] = useState<IOrganizationInfo>();

    useEffect(()=>{},[]);
    useEffect(()=>{
        setOrg(props.OrgInfo);
    },[props.OrgInfo, props.OrgInfo]);


    return (<>
        <OrgPageHeader state={props} org={org} titlePostfix={''} />
        <div>
            {org && <UsersOrganzations state={props} org={org} />}
        </div>
    </>);
    
};

export default ReduxMapper(UsersOrganizationManager);
