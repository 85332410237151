import { IEvent } from 'fabric/fabric-impl';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import redux, { connectMap, IState } from '../../Redux/Redux';
import User from '../../Types/User';
import { Server } from '../../utils/Server';
import { UiValuePairs } from '../FormManager/FormManagerTypes';
import Icon, { IconType } from '../Icon/Icon';
import Modal from '../Modal/Modal';
import { ExpandableImg, LinkToView } from './ExpandableDiv';

interface IFileInfo {
  type: 'pdf' | 'image' | 'video' | 'text' | 'other';
  path: string;
  ext: string;
  urlSource?: string;
}

interface IReduxFileOptions {
  workbookId?: string;
  orgId?: string;
}

interface IEasyFileInputProps extends IState {
  onSelect?: (value: string) => void;
  onSelectFile?: (file: File) => void;
  htmlId?: string;
  exts?: string;
  value?: string;
  onFocus?: (e: Event | FocusEvent) => void;
  dataType?: string;
  valueOptions?: UiValuePairs[];
  optionsFromRedux?: IReduxFileOptions;
  readOnly?: boolean;
}
const EasyFileInput = (props: IEasyFileInputProps) => {
  const [info, setFileInfo] = useState<IFileInfo>();
  const [showInput, setShowInput] = useState<boolean>(props?.value ? false : true);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showOptions, setShowOptions] = useState<boolean>();
  const [loadImagesInReduxCalled, setLoadImagesInReduxCalled] = useState<boolean>();

  useEffect(() => {
    let fileName = props.value ? `${props.value}${props.dataType ? (props.value?.indexOf('?') > -1 ? '&' : '?') : ''}${props.dataType ?? ''}` : undefined;
    let fi = getFileInfo(fileName);
    if (fi && (!info?.urlSource || (info?.urlSource && fi?.path != info.path))) {
      setFileInfo(fi);
      return;
    }
    fi = getFileInfo(props.value ?? '');
    if (fi && (!info?.urlSource || (info?.urlSource && fi?.path != info.path))) {
      setFileInfo(fi);
      return;
    }
  }, [props.value, props.dataType]);

  useEffect(() => {
    if (showOptions && !loadImagesInReduxCalled && props.optionsFromRedux) {
      //load images...
      redux.loadMyFiles(props.optionsFromRedux.workbookId, props.optionsFromRedux.orgId);
      setLoadImagesInReduxCalled(true);
    }
  }, [showOptions]);

  const selectExistingFile = (x: UiValuePairs) => {
    let fi = getFileInfo(x.Value);
    if (props.onSelect) props.onSelect(x.Value);
    setFileInfo(fi ?? undefined);
    setShowOptions(false);
  };

  const removeFile = () => {
    if (window.confirm(`${props.User?.FirstName ?? 'Hi'}, are you sure you want to remove this file?`)) {
      setFileInfo(undefined);
      if (props.onSelect) props.onSelect('');
    }
  };

  const onChangeFile = async (e) => {
    if (e.target.files) {
      let file = await isFileValid(e.target.files);

      if (!file) {
        e.target.files = null;
        if (fileInputRef.current) fileInputRef.current.value = '';
        return;
      }

      setFileInfo(file.fileInfo);
      setShowInput(false);
      setShowOptions(false);
      if (props.onSelectFile) props.onSelectFile(file.file);
    }
  };

  const isFileValid = async (files: FileList) => {
    if (!files) return;
    let file = files[0];

    let size = file.size / 1024 / 1024;
    let name = file.name;
    let fileInfo = getFileInfo(file.name);
    if (!fileInfo) return null;

    if (fileInfo?.type === 'video') {
      //to big
      if (size > 1024) {
        toast.warning(`The file "${name}" is too large. The max file size is 1 GB`);
        return null;
      }
    } else if (fileInfo?.type === 'pdf') {
      //to big
      if (size > 50) {
        toast.warning(`The file "${name}" is too large. The max file size is 50 MB`);
        return null;
      }
    } else if (size > 20) {
      //to big
      toast.warning(`The file "${name}" is too large. The max file size is 20 MB`);
      console.log(fileInfo);
      return null;
    }

    fileInfo.urlSource = await readURL(file);

    return { file, fileInfo };
  };

  const getFileInfo = (filePath: string | undefined, fileName?: string) => {
    if (!filePath || typeof filePath !== typeof ' ') return null;
    console.log(fileName, filePath);
    let ext = filePath.substring(filePath.lastIndexOf('.')).toLowerCase();
    if (!ext && fileName) {
      ext = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();
    }
    let isPdf = ext === '.pdf';
    let isImage = ext === '.jpg' || ext === '.jpeg' || ext === '.png' || ext === '.tif' || ext === '.tiff' || ext === '.gif';
    let isVideo = ['.mov', '.mp4'].find((x) => x === ext);
    let isText = ['.csv', '.xml', '.txt'].find((x) => x === ext);
    let type: 'pdf' | 'image' | 'video' | 'text' | 'other' = isPdf ? 'pdf' : isImage ? 'image' : isVideo ? 'video' : isText ? 'text' : 'other';

    let result: IFileInfo = { type: type, path: filePath, ext: ext };
    return result;
  };

  const readURL = (file) => {
    return new Promise<string>((res, rej) => {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => res(e.target?.result + '');
      reader.onerror = (e) => rej(e);
      reader.readAsDataURL(file);
    });
  };

  const showFile = () => {
    let name = props.valueOptions?.find((x) => x.Value === info?.path)?.Name ?? info?.path ?? 'Unknown File Name';
    if (info?.type === 'image') {
      return (
        <div className="easy-file-image x">
          {/* <img src={info.urlSource ?? Server.GetUrl(`../file/x/${info.path}&x=100`)} className="thumbnail-1" />  */}
          <LinkToView type="image" urlSource={info.urlSource ?? info.path} name={name} addClass="thumbnail-1" hasThumbnail={true} />
          {!props.readOnly && (
            <span
              className="click bumper"
              onClick={() => {
                setShowOptions(true);
              }}>
              <Icon type={IconType.edit} addClass={'fa-2x'} />
            </span>
          )}
          {!props.readOnly && (
            <span
              className="click bumper"
              onClick={() => {
                removeFile();
              }}>
              <Icon type={IconType.trashO} addClass={'fa-2x'} />
            </span>
          )}
        </div>
      );
    } else if (info?.type === 'pdf') {
      return (
        <div className="easy-file-image xx">
          {info.urlSource ? (
            <>
              <strong>{info.path}</strong>
              <span
                className="click"
                onClick={() => {
                  setShowOptions(true);
                }}>
                <Icon type={IconType.edit} addClass={'fa-2x'} />
              </span>
            </>
          ) : (
            <>
              {/* <img src={info.urlSource ?? Server.GetUrl(`../file/x/${info.path}&x=100&tn=1`)} className="thumbnail-1" />  */}
              <LinkToView type="pdf" urlSource={info.path} name={name} addClass="thumbnail-1" hasThumbnail={true} />
              {!props.readOnly && (
                <span
                  className="click bumper"
                  onClick={() => {
                    setShowOptions(true);
                  }}>
                  <Icon type={IconType.edit} addClass={'fa-2x'} />
                </span>
              )}
              {!props.readOnly && (
                <span
                  className="click bumper"
                  onClick={() => {
                    removeFile();
                  }}>
                  <Icon type={IconType.trashO} addClass={'fa-2x'} />
                </span>
              )}
            </>
          )}
        </div>
      );
    } else {
      // if (info?.urlSource){
      return (
        <div className="flex-between">
          <strong>{name}</strong>
          {!props.readOnly && (
            <span
              className="click bumper"
              onClick={() => {
                setShowOptions(true);
              }}>
              <Icon type={IconType.edit} />
            </span>
          )}
          {!props.readOnly && (
            <span
              className="click bumper"
              onClick={() => {
                removeFile();
              }}>
              <Icon type={IconType.trashO} addClass={'fa-2x'} />
            </span>
          )}
        </div>
      );
    }
  };

  const nameOrFile = (x: UiValuePairs) => {
    let file = getFileInfo(x.Value, x.Name);
    if (file && file.path) {
      switch (file.type) {
        case 'image':
          return (
            <div className="easy-file-image">
              <img src={Server.GetUrl(`../file/x/${file.path}&x=50`)} className="thumbnail-1" />
            </div>
          );
          return (
            <div className="flex-between">
              <div className="thumbnail-1 click">
                <ExpandableImg
                  imageSrc={[{ alt: x.Name, path: `../file/x/${file?.path}&x=100` }]}
                  name={x.Name}
                  onClick={() => {
                    selectExistingFile(x);
                  }}
                />
              </div>
              <span className="bumper">{x.Name}</span>
            </div>
          );
        case 'pdf':
          return (
            <div className="easy-file-image">
              {file.urlSource ? (
                <>
                  <strong>{file.path}</strong>
                </>
              ) : (
                <>
                  <img src={file.urlSource ?? Server.GetUrl(`../file/x/${file.path}&x=50&tn=1`)} className="thumbnail-1" />
                </>
              )}
            </div>
          );
        // return <div className="flex-between">
        //   <div className="thumbnail-1 click"><ExpandableImg imageSrc={[{alt:x.Name, path:`../file/x/${file.path}&x=100&tn=1`}]} name={x.Name} onClick={()=>{selectExistingFile(x)}}  /></div>
        //   <span className="bumper">{x.Name}</span>
        // </div>
        //return <LinkToView type="pdf" urlSource={file.path}  name={x.Name}/>
        case 'video':
          return <LinkToView type="video" urlSource={file.path} name={x.Name} />;
        case 'text':
          break;
        case 'other':
          break;
      }
    }
    return <strong>{x.Name}</strong>;
  };

  return (
    <>
      <div>
        {showOptions || !info ? (
          <>
            {!info && (
              <button
                type="button"
                onFocus={(e: any) => {
                  if (props.onFocus) props.onFocus(e);
                }}
                className="btn btn-secondary btn-xs"
                onClick={() => {
                  setShowOptions(true);
                }}>
                <Icon type={IconType.upload} /> Select File
              </button>
            )}
            {info && (
              <div className="flex-between">
                <strong>{info?.path ?? 'Unknown Name'}</strong>
                <span
                  className="click bumper"
                  onClick={() => {
                    setShowOptions(false);
                  }}>
                  <Icon type={IconType.close} />
                </span>
              </div>
            )}
          </>
        ) : (
          <>{showFile()}</>
        )}
      </div>
      {showOptions && (
        <Modal setModalOpen={setShowOptions} title={'Existing Files'} size="m">
          <div className="existing-file-container">
            {props.valueOptions?.map((x, i) => {
              let fi = getFileInfo(x.Value);
              return (
                <div key={`efo-${i}`} className="existing-file-item flex-between">
                  {nameOrFile(x)}{' '}
                  <button
                    type="button"
                    className="btn btn-secondary btn-xs"
                    onClick={() => {
                      selectExistingFile(x);
                    }}>
                    <Icon type={IconType.save} />
                  </button>
                </div>
              );
            })}

            {props.optionsFromRedux &&
              props.MyFiles?.map((x, i) => {
                //let fi = getFileInfo(, x.Name);
                return (
                  <div key={`eford-${i}`} className="existing-file-item flex-between">
                    {nameOrFile({ Name: x.Name, Value: `${x.PublicId}?${x.Extension}` })}{' '}
                    <button
                      type="button"
                      className="btn btn-secondary btn-xs"
                      onClick={() => {
                        selectExistingFile({ Name: x.Name, Value: `${x.PublicId}?${x.Extension}` });
                      }}>
                      <Icon type={IconType.save} />
                    </button>
                  </div>
                );
              })}

            <div>
              <h4>Upload File</h4>
              Not finding the file you need, upload one.
              <hr />
              <input id={`${props.htmlId}`} type="file" className="form-control" ref={fileInputRef} onChange={onChangeFile} accept={props.exts} />
              <hr />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default connectMap()(EasyFileInput);
