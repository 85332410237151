import { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { toast } from "react-toastify";
import { Server, _server } from "../../utils/Server";
import Icon, { IconType } from "../Icon/Icon";
import ImagePlus from "../Tools/Image";
import { IPage, IWorkbookInfo } from "./WorkbookTypes";

interface IPageThumbnailProps extends RouteComponentProps<MatchParams> {
  page: string;
  info: IWorkbookInfo;
  onSelect?: (page:IPage)=>void;
  preview:boolean;
  onEditClick:(type:string,subType:string|null,key:string,callback:Function)=>void;
  activePage?:boolean;
  notAccessableYet?:boolean;
}

interface MatchParams {}

const PageThumbnail = (props: IPageThumbnailProps) => {
  const [page, setPage] = useState<IPage>();

  useEffect(() => {
    if ((props.page && !page) || props.page != page?.PublicId) {
        let p:IPage = props.info.TemplateDic[props.page];
        setPage(p);
      }
  }, [props.page]);

  const select = ()=>{    
    if((props.preview || !props.notAccessableYet) && props.onSelect && page) props.onSelect(page);
  };

  const updated = (info:IWorkbookInfo)=>{
    if(!info){
      console.log('WHy was I called');
      return;
    }
    let p:IPage = info.TemplateDic[props.page];
        setPage(p);
        console.log('Page updated!', p);
  };

  return (
    <>
      {page && props.info && 
      <div className={`wb-page-thumbnail click ${props.preview ? 'preview':''} ${props.activePage ? 'active':''}  ${props.notAccessableYet ? 'not-yet':''}`} onClick={select}>
      {props.preview && !page._validPath && <div className="not-shown">Not Shown</div>}
      {page._milestones?.length > 0 && <div className={`milestone ms${page._milestonesState}`}>Milestone</div>}
        {props.preview && !page._canAccess && <div className="access-blocked">Locked</div>}
        {props.preview && page.skipToNextWhenNotShown && <div className="skip-not-show" title="This page will skip to the next valid if it is not shown."><Icon type={IconType.arrowRight} /></div>}
        {props.preview && (page.showWhenFalse?.length > 0 || page.showWhenTrue?.length > 0) && <div className="filter"><Icon type={IconType.filter} /></div>}
        <div className="image">
            <ImagePlus src={page?.ThumbnailPath ?? Server.FileUrl(page?.ThumbnailImage ?? '')} />
        </div>
        <h3>{page?.SetId && props.preview && <><Icon type={IconType.bars} addClass={'text-danger'}/></>}{page?.Name}</h3>
        <div className="description" dangerouslySetInnerHTML={{__html:page?.Preview ?? ''}}></div>
        {props.preview && <div className="click edit-me" onClick={(e)=>{e.stopPropagation(); e.preventDefault();props.onEditClick('workbook-template-page',null,page.PublicId, updated);}}><Icon type={IconType.edit}/></div>}
        <div className="stats">
          {(page._requiredDataPoints?.length ?? 0) > 0 && (page._missingDataPoints?.length ?? 0) > 0 && <span>Missing {page._missingDataPoints?.length} of {page._requiredDataPoints?.length}</span>}
          {(page._requiredDataPoints?.length ?? 0) > 0 && (page._missingDataPoints?.length ?? 0) === 0 && <span>100%</span>}
          </div>
      </div>}
    </>
  );
};

export default withRouter(PageThumbnail);
