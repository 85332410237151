import { useEffect, useState } from 'react';
import { IState } from '../../Redux/Redux';
import { _server } from '../../utils/Server';
import { IOrgUserRole, IOrganizationInfo, IUsersOrganizationInfo } from '../Organizations/OrganizationTypes';
import { toast } from 'react-toastify';
import { FormatDate } from '../../utils/Tools';
import Icon, { IconType } from '../Icon/Icon';

interface IProps {
  state: IState;
  org?: IOrganizationInfo;
}
interface IForm {
  orgId?: string;
}

const UsersOrganzations = (props: IProps) => {
  const [info, setInfo] = useState<IUsersOrganizationInfo>();
  const [form, setForm] = useState<IForm>({});
  const [partOfThisOrg, setPartOfThisOrg] = useState<boolean>(true);

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    _server.getApi<IUsersOrganizationInfo>(`../UserApi/MyOrgs/${props.org?.PublicId ?? ''}`).then((x) => {
      if (x.Success) {
        setInfo(x.Value);
        let org = x.Value.Orgs.filter((o) => o.OrgPublicId === props.org?.PublicId || o.OrgPublicId === props.state.OrgInfo?.PublicId).find((o) => !o.RemovedAtUtc);
        setPartOfThisOrg(org ? true : false);
      } else {
        toast.error(x.Message, { autoClose: 30000 });
      }
    });
  };

  const removeFromOrg = (org: IOrgUserRole) => {
    if (!window.confirm(`${props.state.User?.FirstName} are you sure you want to remove yourself from this organization?`)) {
      return;
    }
    let rqst: any = {
      orgId: org.OrgPublicId,
    };
    _server.postApi<IOrgUserRole>(`../UserApi/RemoveFromOrg`, rqst).then((x) => {
      if (x.Success) {
        init();
      } else {
        toast.error(x.Message, { autoClose: 30000 });
      }
    });
  };

  const joinOrg = () => {
    if (!form.orgId) {
      toast.warning('Please provide an organization key');
    }
    _server.postApi<IOrgUserRole>(`../UserApi/JoinOrg`, { orgId: form.orgId }).then((x) => {
      if (x.Success) {
        setForm({});
        init();
      } else {
        toast.error(x.Message, { autoClose: 30000 });
      }
    });
  };
  const joinThisOrg = () => {
    _server.postApi<IOrgUserRole>(`../UserApi/JoinOrg`, {}).then((x) => {
      if (x.Success) {
        init();
      } else {
        toast.error(x.Message, { autoClose: 30000 });
      }
    });
  };

  return (
    <>

        <div className='alert alert-info'>
            <h4>Organizations</h4>
            <p>
            You can share your accout with other organizations, this allows these organization to see and access your information, your workbooks, and help manage your account.
            </p>
         <p>
         <strong>You do not have to share your account with an organization to provide access to one of your workbooks. You can share your individual workbook with an organization.</strong>
         </p>
         <p>
         Please note: In order to be part of an organizations staff, review member, or admin you must share your accouth with your organization. You can share your account with multiple organizations.
         </p>



        </div>

      {info?.Orgs?.filter(x=>!x.IsPrimary).map((x, i) => {
        let removed = x.RemovedAtUtc ? true : false;
        return (
          <div className={`bordered flex-between ${removed ? 'mute' : ''} `} key={`uoio-${i}`}>
            {removed && <div className="removed-banner">Removed on {FormatDate(x.RemovedAtUtc, 'date')}</div>}
            <div>
              <strong>{x.OrgName}</strong>
              <div>
                <a href={`https://${x.Domain}`} title={`Go to home page. ${x.Domain}`}>{x.Domain}</a>
              </div>
              {x.IsAdmin && (
                <div>
                  <span className="label label-default">
                    <Icon type={IconType.admin} /> Admin Account
                  </span>
                </div>
              )}
              <div>Requested: {FormatDate(x.RequestedAtUtc, 'date')}</div>
              <div>Approved: {FormatDate(x.ApprovedAtUtc, 'date')}</div>
            </div>
            <div>
              {!removed && !x.IsPrimary && (
                <div>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      removeFromOrg(x);
                    }}>
                    <Icon type={IconType.delete} /> Remove
                  </button>
                </div>
              )}
              { x.IsPrimary && <div>
                <Icon type={IconType.checkCircle}/> Primary Organization
                </div>
                
              }
            </div>
          </div>
        );
      })}

      {!partOfThisOrg && (
        <div className="bordered">
          <h3>{props.state.OrgInfo?.Name}</h3>
          Join our organization, we'll be happy to have you.
          {props.state.OrgInfo?.Description && <div dangerouslySetInnerHTML={{ __html: props.state.OrgInfo.Description }}></div>}
          <div>
            <button
              type="button"
              className="btn btn-success"
              onClick={() => {
                joinThisOrg();
              }}>
              <Icon type={IconType.UserCheck} /> Join Now
            </button>
          </div>
        </div>
      )}

      <hr />
      <div className="bordered form-horizontal">
        <h3>Looking to Join a Different Organization?</h3>
        Do you want to join another organization, or atleast attach your account to another organization? Then provide provide the organizations id or share key.
        <div className="form-group">
          <label className="control-label col-sm-4" htmlFor="org-id">
            Organization Id
          </label>
          <div className="col-sm-6">
            <input
              type="text"
              maxLength={100}
              id="org-id"
              className="form-control"
              value={form.orgId ?? ''}
              onChange={(e) => {
                setForm({ ...form, orgId: e.target.value });
              }}
              placeholder="Organization Id or Share Key"
            />
          </div>
        </div>
        <div className="form-group">
          <div className="col-sm-6 col-sm-offset-4">
            <button type="button" className="btn btn-secondary" onClick={joinOrg}>
              <Icon type={IconType.share} /> Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UsersOrganzations;
