import { info } from "console";
import { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { toast } from "react-toastify";
import { _server } from "../../utils/Server";
import Editor from "../Tools/Editor";
import Select from "react-select";
import {
  IDataPoint,
  IElement,
  IFormGroup,
  IMilestone,
  IPage,
  IWorkbookInfo,
} from "./WorkbookTypes";
import Icon, { IconType } from "../Icon/Icon";
import EasyFileInput from "../Tools/EasyFileInput";
import { Selectable } from "../FormManager/FormManagerTypes";
import { SyntheticEvent } from "react-draft-wysiwyg";
import { colors } from "react-select/dist/declarations/src/theme";

interface IWorkbookFormGroupProps extends RouteComponentProps<MatchParams> {
  input: IDataPoint;
  info: IWorkbookInfo;
  setId?: string;
  setKey?: string;
  onValueChange: (
    key: string,
    setId: string | null,
    value: string[],
    files: File[] | null | undefined
  ) => void;
  onEditClick:(type:string,subType:string|null,key:string,callback:Function)=>void;
  preview:boolean;
  readOnly?:boolean;
  labelOverride?:string;
}

interface MatchParams {}

const WorkbookInput = (props: IWorkbookFormGroupProps) => {
  const [input, setInput] = useState<IDataPoint>();
  const [newValue, setNewValue] = useState<string>();
  const [savedValue, setSavedValue] = useState<string>();
  const [valueType, setValueType] = useState<string>();
  const [fileUploaded, setFileUploaded] = useState<boolean>();

  useEffect(() => {
    if (props.input?.PublicId) {
      setInput(props.input);
      let values = props.info.Values[props.setId ? props.setId : 'base'];
      let valueX = values ? values[`${props.input.PublicId}${props.setKey??''}`] : null;

      let value = valueX?.Value ?? "";
      setNewValue(value);
      setSavedValue(value);
      setValueType(valueX?.Type);
    }
  }, [props.input?.PublicId]);

  useEffect(()=>{
    if(fileUploaded || props.input.IsComputed){
      let values = props.info.Values[props.setId ? props.setId : 'base'];
      let valueX = values ? values[`${props.input.PublicId}${props.setKey??''}`] : null;
      if(valueX && valueX.Type){
        let value = valueX?.Value ?? "";
        setNewValue(value);
        setSavedValue(value);
        setValueType(valueX?.Type);


        setFileUploaded(false);
        //console.log(`Values updated ${props.input.Name}: ${value}`);
      }
    }
  },[props.info.Values])

  const onValueChange = (
    key: string,
    setId: string | null,
    value: string[],
    files: File[] | null | undefined
  ) => {
    if (props.onValueChange) {
      props.onValueChange(key, setId, value, files);
      setSavedValue(value[0]);
      if(files && files.length > 0){
        setFileUploaded(true);
      }
    }
  };


  const onFocus = (e:Event|SyntheticEvent|FocusEvent) =>{
    //console.log('Focus', e);
    const el = (e.target as Element);
    const elFg = el?.closest(".form-group");
    if(elFg) elFg.scrollIntoView({ behavior: 'smooth', block: 'center' });
    
    
    const inputEl = (e.target as any);
    const typesToAutoShow = ['date','datetime-local', 'month', 'weeek', 'time'];
    if(inputEl && inputEl.type && inputEl.showPicker && typesToAutoShow.find(x=>x == inputEl.type.toLowerCase())){
      inputEl.showPicker();
    }
    //console.log(elFg)
    //el?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const onChecked = (checked:boolean)=>{
    setNewValue(checked+'');
    onValueChange(
        props.input.PublicId,
        props.setId ?? null,
        [checked+''],
        undefined
      );
  }

  const onStringValueChange = (value: string) => {
    setNewValue(value);
  };

  const onBlur = (e:any, value?:any) => {
    if(props.input.Type === "Textbox" &&  newValue){
      let pass = true;
      let reg = props.input.RegExFieldValidator;
      if(reg && reg.length > 0){
        let regex = new RegExp(reg, "g");
        if(!regex.test(newValue)){
          pass = false;
        }
      }
      

      reg = props.input.RegExCustomFieldValidator;
      if(reg && reg.length > 0){
        let regex = new RegExp(reg, "g");
        if(!regex.test(newValue)){
          pass = false;
        }
      }

      if(!pass){
        toast.error(props.input.RegExValidationFailedMessage ?? `The value you provided ${newValue} is not valid.`);
        e.target?.focus();        
        return;
      }
    }

    if(value !== undefined){
      onValueChange(
        props.input.PublicId,
        props.setId ?? null,
        [value ?? ""],
        undefined
      );

      setSavedValue(value);   
      setNewValue(value);
    }
    else if (newValue !== savedValue) {
      onValueChange(
        props.input.PublicId,
        props.setId ?? null,
        [newValue ?? ""],
        undefined
      );

      setSavedValue(newValue);      
    }
  };

  const buildInput = (x: IDataPoint) => {
    // if(x.IsComputed){
    //   return <span className="form-control-static">{newValue} </span>
    // }
    switch (x.Type.toLowerCase()) {
      case "html":
        return (props.readOnly || x.IsComputed ? <div className="form-control-static" dangerouslySetInnerHTML={{__html:newValue ?? ''}}></div> :
          <Editor
            options={"simple"}
            height={"short"}
            value={newValue ?? ""}
            onblur={onBlur}
            onfocus={onFocus}
            changed={(v) => {
              onStringValueChange(v);
            }}
            readOnly={props.readOnly}
          />
        );
        case "htmlfull":
          return (props.readOnly || x.IsComputed ? <div className="form-control-static" dangerouslySetInnerHTML={{__html:newValue ?? ''}}></div> :
            <Editor
              options={"staff-simple"}
              height={"normal"}
              value={newValue ?? ""}
              onblur={onBlur}
              onfocus={onFocus}
              changed={(v) => {
                onStringValueChange(v);
              }}
              readOnly={props.readOnly}
            />
          );
        break;
            case "checkbox":
                return <input type="checkbox" 
                    id={x.PublicId} 
                    checked={newValue?.toLowerCase() === 'true'}
                    onChange={(e)=>{onChecked(e.target.checked)}}
                    onBlur={onBlur}
                    onFocus={(e)=>{
                      onFocus(e);
                      onChecked(e.target.checked);
                    }}
                    readOnly={props.readOnly || x.IsComputed}
                    disabled={props.readOnly || x.IsComputed}
                    />
            break;
    
            case "textbox":
                return <input type="text" 
                    id={x.PublicId} 
                    value={newValue}
                    onChange={(e)=>{setNewValue(e.target.value)}}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    maxLength={x.MaxLength ?? 250}
                    className={`form-control`}
                    readOnly={props.readOnly || x.IsComputed}
                    placeholder={x.Placeholder ?? undefined}
                    />
            break;
            case "datetime":
                return <input type="datetime-local" 
                    id={x.PublicId} 
                    value={newValue}
                    onChange={(e)=>{
                      setNewValue(e.target.value);
                      console.log(e.eventPhase);
                    }}
                    onBlur={onBlur}
                    onFocus={(e)=>{
                      onFocus(e);}
                    }
                    maxLength={x.MaxLength ?? 250}
                    className={`form-control`}
                    readOnly={props.readOnly || x.IsComputed}
                    placeholder={x.Placeholder ?? undefined}
                    />
            break;
            case "date":
                return <input type="date" 
                    id={x.PublicId} 
                    value={newValue}
                    onChange={(e)=>{setNewValue(e.target.value)}}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    maxLength={x.MaxLength ?? 250}
                    className={`form-control`}
                    readOnly={props.readOnly || x.IsComputed}
                    placeholder={x.Placeholder ?? undefined}
                    />
            break;
            case "time":
                return <input type="time" 
                    id={x.PublicId} 
                    value={newValue}
                    onChange={(e)=>{setNewValue(e.target.value)}}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    maxLength={x.MaxLength ?? 250}
                    className={`form-control`}
                    readOnly={props.readOnly || x.IsComputed}
                    placeholder={x.Placeholder ?? undefined}
                    />
            break;
            case "password":
                return <input type="password" 
                    id={x.PublicId} 
                    value={newValue}
                    onChange={(e)=>{setNewValue(e.target.value)}}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    maxLength={x.MaxLength ?? 250}
                    className={`form-control`}
                    readOnly={props.readOnly || x.IsComputed}
                    placeholder={x.Placeholder ?? undefined}
                    />
            break;
            case "color":
                return <input type="color" 
                    id={x.PublicId} 
                    value={newValue}
                    onChange={(e)=>{setNewValue(e.target.value)}}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    maxLength={x.MaxLength ?? 250}
                    className={`form-control`}
                    readOnly={props.readOnly || x.IsComputed}
                    placeholder={x.Placeholder ?? undefined}
                    />
            break;
            case "url":
                return <input type="url" 
                    id={x.PublicId} 
                    value={newValue}
                    onChange={(e)=>{setNewValue(e.target.value)}}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    maxLength={x.MaxLength ?? 250}
                    className={`form-control`}
                    readOnly={props.readOnly || x.IsComputed}
                    placeholder={x.Placeholder ?? undefined}
                    />
            break;
            case "email":
                return <input type="email" 
                    id={x.PublicId} 
                    value={newValue}
                    onChange={(e)=>{setNewValue(e.target.value)}}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    maxLength={x.MaxLength ?? 250}
                    className={`form-control`}
                    readOnly={props.readOnly || x.IsComputed}
                    placeholder={x.Placeholder ?? undefined}
                    />
            break;
            case "phone":
            case "phonenumber":
                return <input type="tel" 
                    id={x.PublicId} 
                    value={newValue}
                    onChange={(e)=>{setNewValue(e.target.value)}}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    maxLength={x.MaxLength ?? 50}
                    className={`form-control`}
                    readOnly={props.readOnly || x.IsComputed}
                    placeholder={x.Placeholder ?? undefined}
                    />
            break;
            case "number":
                return <input type="number" 
                    id={x.PublicId} 
                    value={newValue}
                    onWheel={(e)=>{
                        e.preventDefault(); 
                        e.stopPropagation();
                        let target:any = e.target;
                        if(target.blur) target.blur();
                        return false;
                      }
                    }


                    onChange={(e)=>{setNewValue(e.target.value)}}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    maxLength={x.MaxLength ?? 50}
                    className={`form-control`}
                    readOnly={props.readOnly || x.IsComputed}
                    placeholder={x.Placeholder ?? undefined}
                    />
            break;
            case "textarea":
                return <textarea
                    id={x.PublicId} 
                    value={newValue}
                    onChange={(e)=>{setNewValue(e.target.value)}}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    maxLength={x.MaxLength ?? 5000}
                    rows={3}
                    className={`form-control`}
                    readOnly={props.readOnly || x.IsComputed}
                    placeholder={x.Placeholder ?? undefined}
                    ></textarea>
            break;
            case "dropdown":
            return <select
            id={x.PublicId} 
            value={newValue ?? " "}
            onChange={(e)=>{
              setNewValue(e.target.value);
              onValueChange(x.PublicId, props.setId ?? null, [e.target.value], undefined);
            }}
            // onBlur={onBlur}
                    onFocus={onFocus}
            placeholder="select"
            className={`form-control`}
            disabled={props.readOnly || x.IsComputed}
            
            >
              <option value={" "}>{x.Placeholder ?? 'Select'}</option>
              {x.Options?.map((o,i)=>{
                return <option key={`${x.PublicId}-sel-${i}`}>{o}</option>
              })}
            </select>
              break;
              case "file":
                return <>
                  <EasyFileInput
                  readOnly={props.readOnly}
                  htmlId={x.PublicId}
                  exts={".pdf,.txt,.csv,.png,.jpg,.gif"}
                  value={newValue}
                  dataType={valueType}
                  onFocus={onFocus}
                  onSelect={(value:string)=>{
                    onValueChange(x.PublicId, props.setId ?? null, [value], undefined);
                  }                  }
                  onSelectFile={(file:File)=>{
                    onValueChange(x.PublicId, props.setId ?? null, [file.name], [file]);}}
                  optionsFromRedux={{workbookId:props.info.WorkbookId}}
                  />

                </>
              break;
              case "picture":
                return <>
                  <EasyFileInput
                  readOnly={props.readOnly}
                  htmlId={x.PublicId}
                  exts={".jpg,.png,.gif,.jpeg"}
                  value={newValue}
                  onFocus={onFocus}
                  dataType={valueType}
                  onSelect={(value:string)=>{
                    onValueChange(x.PublicId, props.setId ?? null, [value], undefined);
                  }                  }
                  onSelectFile={(file:File)=>{
                    onValueChange(x.PublicId, props.setId ?? null, [file.name], [file]);}}
                  optionsFromRedux={{workbookId:props.info.WorkbookId}}
                  />

                </>
              break;
              case "multiselect":
                let valueOptions: Selectable[] =
                x?.Options?.map((opt) => {
                  return { label: opt, value: opt };
                }) ?? [];
                return <>
                   <Select
                    name={x.PublicId}
                    inputId={x.PublicId}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onChange={(values: any) => {
                        let mappedValues = (values ?? []).map((x) => x.value as string);
                        let jsonValue = JSON.stringify(mappedValues);
                        setNewValue(jsonValue)
                    } }
                    value={getArrayFromJson(newValue).map(x=>{return { value: x, label: x }})}
                    options={x.Options?.map(x => {
                      return { label: x, value:x };
                    })}
                    className="multi-select"
                    isMulti={true}
                    isDisabled={props.readOnly}
                    isClearable={true}
                    menuPlacement={'auto'}
                    placeholder={x.Placeholder ?? undefined}
                    classNamePrefix='cool'  />
                </>
                break;
            case "signature":
              return                 <input type="text" 
              id={x.PublicId} 
              value={newValue}
              onFocus={onFocus}
              className={`form-control`}
              readOnly={true}
              placeholder={x.Placeholder ?? undefined}
              />
              break;
                default:
        return <>Unknown input type: {x.Type}</>;
        break;
    }
  };

  const getArrayFromJson = (value?:string)=>{
    if(value && value.indexOf("[") > -1) {
        let result:string[] = JSON.parse(value);
        return result
      }
      let empty:string[] = [];
      return empty
  };
  const updated = (info:IWorkbookInfo)=>{
    let p:IDataPoint = info.TemplateDic[props.input.PublicId];
        setInput(p);
  };

  const buildInputGroup = () => {
    if (input) {
      let inlineLabel = ["checkbox"].find(
        (y) => y === input.Type.toLowerCase()
      ) ? true : false;

      return (
        <div className="form-group" key={`fg-input-${input.PublicId}`}>
        {props.preview && <div className="click edit-me" onClick={(e)=>{e.stopPropagation(); e.preventDefault();props.onEditClick('workbook-template-data-point',null,input.PublicId, updated);}}><Icon type={IconType.edit}/></div>}
         {!inlineLabel && <label
            className={`control-label col-lg-4 col-sm-12`}
            htmlFor={input.PublicId}
          >
            {props.labelOverride ?? input.Label ?? input.Name}{input.Required && <><span className={input.RequiredNotEnforced ? 'soft-require':''}>*</span></>}
          </label>}

          <div className={`col-lg-8 col-sm-12 ${inlineLabel ? 'col-sm-offset-4':''}`}>
            {buildInput(input)}         
            {inlineLabel && <label
            className={`control-label`}
            htmlFor={input.PublicId}
          >
            {input.Required && <>*</>}{props.labelOverride ?? input.Label ?? input.Name}
          </label>}
            {input.Description && !props.readOnly && (
              <div className="input-description"
                dangerouslySetInnerHTML={{ __html: input.Description }}
              ></div>
            )}
            {props.preview && input.IsComputed && input.Calculation &&<div>{input.Calculation} {props.info.TemplateDic[input.Calculation]?.Name}: {props.info.TemplateDic[input.Calculation]?.Value}</div>}
            {props.preview && input.IsComputed && input.ValueOf &&<div>Value of: {input.ValueOf} {props.info.TemplateDic[input.ValueOf]?.Name}</div>}
            {props.preview && input.IsComputed && input.Type && input.Signer && input.Signer.length > 0 &&<div>Signers Data: {input.Type} {props.info.TemplateDic[input.Type]?.Name}</div>}
            {props.preview && input.IsComputed && input.ValueFromOwner &&<div>Value from owner: {input.ValueFromOwner}</div>}
          </div>
        </div>
      );
    }
    return <></>;
  };

  return <>{input && props.info && <>{buildInputGroup()}  
  </>}</>;
};

export default withRouter(WorkbookInput);
