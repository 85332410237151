import { Switch, Route, BrowserRouter } from 'react-router-dom';
import AboutThisApp from './Pages/BasicApp/AboutThisApp';
import ContactUs from './Pages/BasicApp/ContactUs';
import GettingStarted from './Pages/BasicApp/GettingStarted';
import ReleaseNotes from './Pages/BasicApp/ReleaseNotes';
import FormManagerLanding from './Components/FormManager/FormManagerLanding';
import UserLanding from './Pages/Landing/UserLanding';
import PrivacyPolicy from './Pages/Legal/PrivacyPolicy';
import OrganizationsManager from './Components/Organizations/Admin/OrganizationsManager';
import help from './Components/User/help';
import Login from './Components/User/Login';
import Profile from './Components/User/Profile';
import RegisterUser from './Components/User/RegisterUser';
import Users from './Components/User/Users';
import InviteAccept from './Components/WorkbookTemplate/Owners/InviteAccept';
import WorkbookTemplateManager from './Components/WorkbookTemplate/Setup/WorkbookTemplateManager';
import Workbook from './Components/WorkbookTemplate/Workbook';
import WorkbookStart from './Components/WorkbookTemplate/WorkbookStart';
import TermsOfSerive from './Pages/Legal/TermsOfService';
import OrganizationDashboard from './Pages/Organizations/OrganizationDashboard';
import UsersOrganizationManagerPage from './Pages/Organizations/UsersOrganizationManagerPage';
import OrganizationWork from './Pages/Organizations/OrganizationWork';


export default (
<BrowserRouter>
  <Switch>
  <Route path="/app/TermsOfService" component={TermsOfSerive} />
  <Route path="/app/PrivacyPolicy" component={PrivacyPolicy} />
  <Route path="/app/Start" component={GettingStarted} />
  <Route path="/app/About" component={AboutThisApp} />

      {/* User Routes */}
    <Route path="/app/user/register" component={RegisterUser} />
    <Route path="/user/register" component={RegisterUser} />
    <Route path="/app/user/profile" component={Profile} />
    <Route path="/user/profile" component={Profile} />
    <Route path="/app/user/login" component={Login} />
    <Route path="/app/user/help" component={help} />
    <Route path="/user/organization" component={UsersOrganizationManagerPage} />



    {/* Organization */}
    <Route path="/admin/organization" component={OrganizationsManager} />
    <Route path="/organization/work" component={OrganizationWork} />
    <Route path="/organization/:orgId" component={OrganizationDashboard} />
    <Route path="/organization" component={OrganizationDashboard} />


      {/* Logged in routes*/}


    <Route path='/app/form/:type/:subType/:key' component={()=><FormManagerLanding />} />
    <Route path='/app/form/:type/:subType' component={()=><FormManagerLanding />} />
    <Route path='/app/form/:type' component={()=><FormManagerLanding />} />
    <Route path='/app/setup/:type/:subType/:key' component={()=><FormManagerLanding />} />

    <Route path="/app/admin/template/:templateId" component={WorkbookTemplateManager} />
    <Route path="/app/admin/users" component={Users} />
    

      {/* Fallback... */}
    {/* <Route path='/' component={NotFound}/> */}
    <Route path='/app/contactUs' component={ContactUs}/>
    <Route path='/app/releaseNotes' component={ReleaseNotes}/>
    
    <Route path='/workbook/start/:workbookId' component={WorkbookStart}/>
    <Route path='/workbook/join/:workbookId' component={InviteAccept}/>
    <Route path='/workbook/new/:templateId' component={WorkbookStart}/>
    <Route path='/workbook/:workbookId' component={Workbook}/>
    <Route path='/app/signRequest/:signRequestId' component={Workbook}/>
    <Route path='/signRequest/:signRequestId' component={Workbook}/>
    
    <Route path="/app/landing" component={UserLanding} />
    <Route path='/' component={UserLanding}/>
  </Switch>
</BrowserRouter>
)