import { group, info } from "console";
import { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { removeProps } from "react-select/dist/declarations/src/utils";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { toast } from "react-toastify";
import { _server } from "../../utils/Server";
import Editor from "../Tools/Editor";
import WorkbookInput from "./WorkbookInput";
import {
  IDataPoint,
  IElement,
  IFormGroup,
  IMilestone,
  IPage,
  ISigner,
  ISignRequest,
  IWorkbookInfo,
} from "./WorkbookTypes";
import Icon, { IconType } from "../Icon/Icon";
import Modal from "../Modal/Modal";
import ApprovalRequest from "./Approvals/ApprovalRequest";
import { FormatDate } from "../../utils/Tools";
import { connectMap, IState } from "../../Redux/Redux";
import Approve from "./Approvals/Approve";
import { propTypes } from "react-bootstrap/esm/Image";
import CommentWriter from "./Messages/CommentWriter";
import { regexReplace } from "./WorkbookFunctions";

interface IWorkbookMilestoneProps
  extends RouteComponentProps<MatchParams>,
    IState {
  milestone: IMilestone;
  info: IWorkbookInfo;
  setId: string | null;
  onValueChange: (
    key: string,
    setId: string | null,
    value: string[],
    files: File[] | null | undefined
  ) => void;
  onEditClick: (
    type: string,
    subType: string | null,
    key: string,
    callback: Function
  ) => void;
  onSignRequest: (request: ISignRequest) => void;
  onJumpToPage: (pageId: string) => void;
  preview: boolean;
  readOnly?: boolean;
}

interface MatchParams {}

const WorkbookMilestone = (props: IWorkbookMilestoneProps) => {
  const [milestone, setMilestone] = useState<IMilestone>();
  const [showApproveRequest, setShowApproveRequest] = useState<boolean>();
  const [activeSigner, setActiveSigner] = useState<ISigner | undefined>();
  const [activeRequest, setActiveRequest] = useState<ISignRequest>();
  const [showResponseModal, setShowResponseModal] = useState<boolean>(false);
  const [isForApproval, setIsForApproval] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [isOwner, setIsOwner] = useState<boolean>(false);

  useEffect(() => {
    if (props.milestone) {
      setMilestone(props.milestone);
    }
  }, [props.milestone?.PublicId]);

  useEffect(() => {}, [props.info]);

  useEffect(()=>{
    if(props.User && props.info?.Owners){
      let owner = props.info.Owners.find(x=>x.PublicId === props.User?.PublicId);
      let userIsAdmin = props.User.IsAdmin;
      setIsOwner((userIsAdmin || owner)?true:false);
    }else {
      setIsOwner(false);
    }

  },[props.User, props.info?.Owners]);

  const onValueChange = (
    key: string,
    setId: string | null,
    value: string[],
    files: File[] | null | undefined
  ) => {
    if (props.onValueChange) props.onValueChange(key, setId, value, files);
  };

  const updated = (info: IWorkbookInfo) => {
    let p: IMilestone = info.TemplateDic[props.milestone.PublicId];
    setMilestone(p);
  };

  const requestSignature = (mile: IMilestone, signer: ISigner) => {
    if (
      !props.preview &&
      !milestoneRequirementsMet(
        mile,
        props.info,
        props.setId ? props.setId : "base"
      ).Pass
    ) {
      toast.warning(
        "You have not fillout all the required information yet. Please go back and make sure all required fields have been completed."
      );
      return;
    }

    setActiveSigner(signer);
    setShowApproveRequest(true);
  };

  const removeApproval = (request:ISignRequest, signer:ISigner)=>{

    if(request.ApprovedAtUtc){
      if(!window.confirm(`${props.User?.FirstName ?? 'Hi'}, are you sure you want to remove this approval?`)) return;

    } else {
      if(!window.confirm(`${props.User?.FirstName ?? 'Hi'}, are you sure you want to cancel this request?`)) return;

    }


    request.WorkbookId = props.info.WorkbookId;
    _server
    .postApi<ISignRequest>(`../WorkbookApi/RequestApprovalRemoval`,request )
    .then((x) => {
      if (x.Success) {
        if(milestone) approveRequestSent(signer, milestone, x.Value);
        toast.dark(request.ApprovedAtUtc ? `Removed.`: `Request canceled`);
      } else {
        toast.error(x.Message, { autoClose: false });
      }
    });
  };

  const buildMilestone = (mile: IMilestone) => {
    let missingRequirements = !props.preview
      ? milestoneRequirementsMet(
          mile,
          props.info,
          props.setId ? props.setId : "base"
        )
      : { Pass: true, Pages: [] };
    let readyToBeApproved = props.preview || missingRequirements.Pass;
    let approvers = mile.Approvers ?? [];
    let dic: Record<string, boolean> = {};
    approvers.forEach((x) => {
      dic[x] = true;
    });
    let requests =
      props.info.SignRequests?.filter(
        (x) => x.SignerId && dic[x.SignerId] && x.MilestoneId === mile.PublicId && !x.RemovedAtUtc
      ) ?? [];
    let requested = requests.length > 0;
    let approved =
      !requests.find((x) => !x.ApprovedAtUtc) && requests.length > 0;

    return (
      <div
        className={`wb-milestone ${requested ? "requested" : ""} ${
          approved ? "approved" : ""
        }`}
      >
        {readyToBeApproved ? (
          <div></div>
        ) : (
          <div className="alert alert-warning">
            <Icon type={IconType.warning} /> Still some items missing!
            <ul>
              {missingRequirements.Pages.map((p, i) => {
                return (
                  <li key={`missing-i-k-${i}`}>
                    Page{" "}
                    <span
                      className="label label-warning click"
                      onClick={() => {
                        props.onJumpToPage(p.PublicId);
                      }}
                    >
                      {p.Name}
                    </span>{" "}
                    is missing {p.Fields.length} fields.
                    <ul>
                      {p.Fields.map((f,fi)=>{
                        return <li key={`missing-i-k-${i}-${fi}`}>{f.Label}</li>
                      })}
                    </ul>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {mile.Header && (
          <div  id={'m-header-'+props.milestone}
            className="header"
            dangerouslySetInnerHTML={{ __html: regexReplace(props.info, mile.Header) }}
          />
        )}
        <h2>{mile.Name}</h2>
        {buildApprovers(mile, requests, readyToBeApproved)}
        {mile.Footer && (
          <div  id={'m-footer-'+props.milestone}
            className="footer"
            dangerouslySetInnerHTML={{ __html: regexReplace(props.info, mile.Footer) }}
          />
        )}
      </div>
    );
  };

  const buildApprovers = (
    mile: IMilestone,
    requests: ISignRequest[],
    readyToBeApproved: boolean
  ) => {
    if (mile.Approvers?.length > 0) {
      let listOfSigners: ISigner[] = [];
      mile.Approvers.forEach((xx, xi) => {
        let xsigner: ISigner = props.info.TemplateDic[xx];
        if (xsigner.IsSignerUser || xsigner.IsSetBasedOnOwner) {
          props.info.Owners.filter(x=> true || !props.info.Signer || x.PublicId === props.User?.PublicId) // the logic in this is bad, how will this work for group parent signers. 
          .forEach((o) => {
            listOfSigners.push({
              ...xsigner,
              Name: `${xsigner.Name} ${xsigner?.IsSetBasedOnOwner ? 'for':''} ${o.FirstName} ${o.LastName}`,
              PublicId: `${xsigner.PublicId}`,
              _ownerId: o.PublicId
            });
          });
        } else {
          listOfSigners.push(xsigner);
        }
      });
      return listOfSigners.map((x, i) => {
        let signer: ISigner = x;
        let rqst: ISignRequest | undefined = requests.find((r) => r.SignerId === x.PublicId && !r.RemovedAtUtc && (r.OwnerId === x._ownerId || (!x._ownerId && !r.OwnerId)) );
        let isSigner = props.info.Signer === rqst?.SignerId;
        return (
          <div className="signer" key={`wb-ms-s-${i}`}>
            {props.preview && (
              <div
                className="click edit-me"
                title={`Signer: ${signer.Name}`}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  props.onEditClick(
                    "workbook-template-signer",
                    null,
                    signer.PublicId,
                    updated
                  );
                }}
              >
                <Icon type={IconType.edit} />
              </div>
            )}
            {!isSigner && (
              <>
                <h5>{signer.Name}</h5>
                {(rqst?.PublicId && !rqst.RemovedAtUtc) ? (
                  <>
                    {rqst.RequestedAtUtc && (
                      <div>Requested at: {FormatDate(rqst.RequestedAtUtc)}</div>
                    )}
                    {(rqst.FirstName || rqst.LastName) && (
                      <div>
                        {rqst.FirstName} {rqst.LastName}
                      </div>
                    )}
                    {rqst.Email && <div>{rqst.Email}</div>}
                    {rqst.Phone && (
                      <div>
                        {rqst.Phone} {rqst.Sms && <Icon type={IconType.sms} />}
                      </div>
                    )}
                    {!rqst.ApprovedAtUtc && (
                      <div>
                      <button
                        type="button"
                        className="btn btn-default btn-xs"
                        onClick={() => {
                          requestSignature(mile, signer);
                        }}
                      >
                        <Icon type={IconType.signature} /> See Request
                      </button>
                        <button
                          type="button"
                          className="btn btn-default btn-xs "
                          onClick={() => {
                            if(rqst) removeApproval(rqst, signer);
                          }}
                        >
                          <Icon type={IconType.trashO} addClass="text-danger" /> Cancel
                        </button>
                      </div>
                    )}
                    {/* {!readyToBeApproved && (
                      <div>
                        <Icon type={IconType.warning} /> A few items are missing
                        before you can approve!
                      </div>
                    )} */}
                  </>
                ) : (
                  <>
                    {readyToBeApproved && (
                      <div>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => {
                            requestSignature(mile, signer);
                          }}
                        >
                          <Icon type={IconType.signature} /> Request Review
                        </button>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
            {isSigner && !rqst?.ApprovedAtUtc && (
              <>
                <h3>{signer?.Name} Request to Review & Approve</h3>
                {signer?.Instructions && (
                  <div
                    dangerouslySetInnerHTML={{ __html: signer.Instructions }}
                  ></div>
                )}

                <div className="alert alert-danger">
                  {props.User?.FirstName}, if you are ready to approve,
                  sign-off. You can do so by pressing the{" "}
                  <strong>approve</strong> button.
                  <div>
                    <div className="headroom">
                      {readyToBeApproved && (
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => {
                            openResponseModel(true, signer, rqst);
                          }}
                        >
                          <Icon type={IconType.signature} /> Approve
                        </button>
                      )}

                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={() => {
                          openResponseModel(false, signer, rqst);
                        }}
                      >
                        <Icon type={IconType.textMsg} /> Comment
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
            {rqst?.ApprovedAtUtc && (
              <div className="flex center">
                  <div className="alert alert-info">
                    Approved:{" "}
                    <Icon type={IconType.check} addClass="text-primary" />{" "}
                    {FormatDate(rqst.ApprovedAtUtc)}                    
                  </div>
                  {(isOwner || props.User?.PublicId === rqst.SignedByUserPublicId) && rqst && <>
                      <button type={"button"}  className="btn btn-default" onClick={()=>{removeApproval(rqst ?? {}, signer)}}><Icon type={IconType.warning}/> Remove Approval</button>
                    </>}
              </div>
            )}
            {isSigner && rqst?.ApprovedAtUtc && (
              <div className="alert alert-success">
                <h4>{props.User?.FirstName}, Thank you!</h4>
                You have finished the review and approval for this request.
              </div>
            )}
          </div>
        );
      });
    }
    return "how";
  };

  const approveRequestSent = (
    signer: ISigner,
    mile: IMilestone,
    request: ISignRequest
  ) => {
    props.onSignRequest(request);
  };

  const openResponseModel = (
    approve: boolean,
    signer: ISigner,
    request?: ISignRequest
  ) => {
    setIsForApproval(approve);
    setActiveSigner(signer);
    setActiveRequest(request);
    setShowResponseModal(true);
    setTabIndex(approve ? 0 : 1);
  };

  const onSign = (request: ISignRequest) => {
    props.onSignRequest(request);
    setShowResponseModal(false);
    if (activeSigner?.IsSignerUser) {
      props.history.push(`../workbook/${props.info.WorkbookId}`);
    }
  };

  return (
    <>
      {milestone && props.info && (
        <div className="wb-group"  id={'m-'+props.milestone}>
          {props.preview && (
            <div
              className="click edit-me"
              title={`Milestone: ${milestone.Name}`}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                props.onEditClick(
                  "workbook-template-milestone",
                  null,
                  milestone.PublicId,
                  updated
                );
              }}
            >
              <Icon type={IconType.edit} />
            </div>
          )}
          {props.milestone && buildMilestone(props.milestone)}
        </div>
      )}

      {showApproveRequest && (
        <Modal
          setModalOpen={setShowApproveRequest}
          title={`${activeSigner?.Name} Approval Request`}
          size={"l"}
        >
          {activeSigner && (
            <ApprovalRequest
              milestone={props.milestone}
              signer={activeSigner}
              info={props.info}
              onRequest={approveRequestSent}
              onStartSign={() => {
                setShowApproveRequest(false);
              }}
            />
          )}
        </Modal>
      )}

      {showResponseModal && (
        <Modal
          setModalOpen={setShowResponseModal}
          title={isForApproval ? `Electronic Approval` : `Comment`}
          size={"l"}
        >
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
          >
            <TabList>
              <Tab>
                <Icon type={IconType.fileSignature} /> Sign & Approve
              </Tab>
              <Tab>
                <Icon type={IconType.textMsg} /> Comment
              </Tab>
            </TabList>
            <TabPanel>
              {activeRequest && activeSigner && (
                <Approve
                  info={props.info}
                  signer={activeSigner}
                  request={activeRequest}
                  onSign={onSign}
                />
              )}
            </TabPanel>
            <TabPanel>
              {activeRequest && activeSigner && (
                <CommentWriter
                  mileStone={milestone}
                  signRequest={activeRequest}
                  workbookId={props.info.WorkbookId}
                  signer={activeSigner}
                  sourceType={"Workbook"}
                />
              )}
            </TabPanel>
          </Tabs>
        </Modal>
      )}
    </>
  );
};

export default connectMap()(withRouter(WorkbookMilestone));

interface IMilestoneRequirementsNotMent {
  Pass: boolean;
  Pages: IPageRequirements[];
}

interface IPageRequirements {
  Name: string;
  PublicId: string;
  Fields: IDataPoint[];
}

export const milestoneRequirementsMet = (
  mile: IMilestone,
  info: IWorkbookInfo,
  setId: string
) => {
  let startPage: IPage = info.TemplateDic[mile.StartPage];
  let result: IMilestoneRequirementsNotMent = { Pages: [], Pass: true };
  if (startPage) {
    let pages = getAllValidPages(startPage, {}, info, mile); //get all valid pages
    let dic: Record<string, IDataPoint> = {};
    let dataPoints: IDataPoint[] = [];

    pages.forEach((x) => {
      let points = getAllDataPoints(x, info);
      let missingPageRequirements: IPageRequirements = {
        Fields: [],
        Name: x.Name,
        PublicId: x.PublicId,
      };
      points.forEach((p) => {
        if (!dic[p.PublicId]) {
          dic[p.PublicId] = p;
          let pageSetId = x.SetId ?? setId;
          if(!pageSetId) pageSetId = 'base';
          let value = info.Values[pageSetId][`${p.PublicId}${x._setGroupKey ?? ''}`];
          if (value === undefined || !value.Value || !value.Value.length) {
            dataPoints.push(p);
            missingPageRequirements.Fields.push(p);
          } else if (p.Type.toLowerCase() === "checkbox") {
            if (value.Value?.toLowerCase() !== "true") {
              dataPoints.push(p);
              missingPageRequirements.Fields.push(p);
            }
          } else {
            if (!value.Value) {
              dataPoints.push(p);
              missingPageRequirements.Fields.push(p);
            }
          }
        }
      });
      if (missingPageRequirements.Fields.length > 0) {
        result.Pages.push(missingPageRequirements);
      }
    });
    // do all data points have a value?
    result.Pass = dataPoints.length === 0;
  }
  return result;
};

export const getAllDataPoints = (page: IPage, info: IWorkbookInfo) => {
  let elements: IElement[] = page.Elements.map((x) => {
    return info.TemplateDic[x.Id];
  });
  let groups: IFormGroup[] = elements
    .filter((x) => x._validPath)
    .map((x) => {
      return info.TemplateDic[x.ElementType];
    })
    .filter((x: IFormGroup) => x && x.Children && x.Children.length > 0);
  let points: IDataPoint[] = [];
  groups.forEach((g) => {
    let dataPoints: IDataPoint[] = g.Children.map((x) => {
      return info.TemplateDic[x.Id];
    });
    dataPoints
      .filter((x) => x && x.Required)
      .forEach((x) => {
        points.push(x);
      });
  });
  return points;
};

export const getAllValidPages = (
  page: IPage,
  startDic: Record<string, IPage> | undefined,
  info: IWorkbookInfo,
  milestone?: IMilestone
) => {
  let pagesResults: IPage[] = [];
  let pagesDic: Record<string, IPage> = startDic ?? {};
  if (page._validPath && page._canAccess && !pagesDic[page.PublicId]) {
    pagesResults.push(page);
    pagesDic[page.PublicId] = page;
  }

  let preFilteredPages: IPage[] = page.ChildrenPages?.map((x, i) => {
    return info.TemplateDic[x.Id];
  });

  let pages = preFilteredPages.filter(
    (x: IPage) => x && x._validPath && x._canAccess
  );
  pages.forEach((p) => {
    if (!pagesDic[p.PublicId]) pagesDic[p.PublicId] = p;
  });

  let skippedPages = preFilteredPages.filter(
    (x) => x && !x._validPath && x.skipToNextWhenNotShown
  );
  let skippedPage = skippedPages.pop();
  while (skippedPage) {
    if (skippedPage.ChildrenPages) {
      let spPages = skippedPage.ChildrenPages.map((x, i) => {
        return info.TemplateDic[x.Id];
      });
      let sPages = spPages.filter(
        (x: IPage) => x && x._validPath && !pagesDic[x.PublicId]
      );
      sPages.forEach((s) => {
        if (!pagesDic[s.PublicId]) {
          pages.push(s);
          pagesDic[s.PublicId] = s;
        }
      });
      let sSkippedPages = spPages.filter(
        (x) =>
          x &&
          !x._validPath &&
          x.skipToNextWhenNotShown &&
          !pagesDic[x.PublicId]
      );
      sSkippedPages.forEach((s) => skippedPages.push(s));
    }
    skippedPage = skippedPages.pop();
  }

  let dic: Record<string, IPage> = {};
  let result: IPage[] = [];

  pagesResults.forEach((x) => {
    if (!dic[x.PublicId]) {
      dic[x.PublicId] = x;
      result.push(x);
    }
  });
  pages.forEach((x) => {
    if (!dic[x.PublicId]) {
      dic[x.PublicId] = x;
      result.push(x);
    }
  });

  let endFound = false;
  for (let i = 0; i < result.length; i++) {
    if (hasMilestone(result[i], info, milestone)) {
      endFound = true;
      break;
    }
  }

  if (!endFound) {
    result.forEach((r) => {
      let ps = getAllValidPages(r, pagesDic, info, milestone);
      ps.forEach((x) => {
        if (!dic[x.PublicId]) {
          dic[x.PublicId] = x;
          result.push(x);
        }
      });
    });
  }

  return result;
};

export const hasMilestone = (
  page: IPage,
  info: IWorkbookInfo,
  milestone?: IMilestone
) => {
  let elements: IElement[] = page.Elements.map((x) => {
    return info.TemplateDic[x.Id];
  });

  let result = elements.find(
    (x) =>
      (x && x.ElementType === milestone?.PublicId) ||
      (!milestone &&
        info.TemplateDic[x.ElementType]?.WorkbookProperType === "milestone")
  )
    ? true
    : false;

  return result;
};
