import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import { checkEmailValid } from '../../utils/Tools';
import { Server, _server } from '../../utils/Server';
import { ITemplate, ITemplateElement, ITemplatePage, Template, TemplatePage, TemplatePageType } from '../../Types/Template';
import { v4 } from 'uuid';
import { IProject } from '../../Types/Project';
import { IMenuItem, IUser } from '../../Types/basic';
import Icon, { IconType } from '../Icon/Icon';
import redux, { connectMap, IState } from '../../Redux/Redux';
import { IConfigItemSummary } from '../FormManager/FormManagerTypes';
import ConfigFormEditor from '../FormManager/ConfigFormEditor';
import { IOrganizationInfo } from '../Organizations/OrganizationTypes';

interface IMenuProps extends IState {
  menuItems: IMenuItem[];
  close: Function;
}

const Menu = (props: IMenuProps) => {
  const [showMenu, setShowMenu] = useState<boolean>();
  const [user, setUser] = useState<IUser>();
  const [template, setTemplate] = useState<Template>();
  const [project, setProject] = useState<IProject>();
  const [currentPageId, setCurrentPageId] = useState<string>();
  const [org, setOrg] = useState<IOrganizationInfo>();

  useEffect(() => {
    setTemplate(Server.selectedTemplate ?? undefined);
    setProject(Server.selectedProject ?? undefined);
    let url = window.location.pathname.toLowerCase();
    let pageIndex = url.indexOf('/page/');
    if (pageIndex > 0) {
      let sub = url.substr(pageIndex + 6);
      if (sub.indexOf('/') > 0) sub = sub.substr(0, sub.indexOf('/'));
      setCurrentPageId(sub);
    }
  }, []);

  useEffect(() => {
    setUser(props?.User);
  }, [props?.User]);

  useEffect(() => {
    setOrg(props.OrgInfo);
  }, [props.OrgInfo]);

  const close = () => {
    props.close();
  };

  const loginToBase = () => {
    _server.getApi(`../UserApi/BackToBase`).then((x) => {
      if (x.Success) {
        props.close();
        toast.info(`Hold on`);
        window.location.href = Server.BuildUrl(`../Organization`);
      } else {
        toast.error(x.Message, { autoClose: 30000 });
      }
    });
  };

  const RegisterUpdated = (user: IConfigItemSummary) => {};

  const goTo = (relUrl: string) => {
    return Server.BuildUrl(relUrl);
  };

  return (
    <>
      <div className="menu">
        <div className="flex-between">
          <h3>Navigation Menu</h3>
          <span onClick={close} className="icon-btns larger" title="close menu">
            <Icon type={IconType.timesSquare} />
          </span>
        </div>
        <hr />
        <div className="menu-section-container flex-evenly">
          <div className="menu-section">
            <h5>Site Quick Links</h5>
            <ul className="menu-links">
              <li>
                <a href="/" title="click to go home">
                <Icon type={IconType.home} /> Home
                </a>
              </li>

              {org && org.AllowSubOrgs && org.UserRelationship?.ReviewGroups?.length > 0 && (
                <li><a 
                  title={'Go to my work'}
                  href={goTo('../organization/work')}>
                  <Icon type={IconType.work} /> Work
                  </a>
                </li>
              )}
              {org && org.AllowSubOrgs && org.UserRelationship?.Admin && (
                <li><a 
                  title={'Go to Manage My Organization'}
                  href={goTo('../organization')}>
                  <Icon type={IconType.manage} /> Manage Organization
                  </a>
                </li>
              )}
              {org && org.AllowSubOrgs && (
                <li><a 
                  title={'Go to my organizations'}
                  href={goTo('../user/organization')}>
                  <Icon type={IconType.org} /> My Organizations
                  </a>
                </li>
              )}
              {/* <li>Help</li> */}
              {user && user.IsAdmin && (
                <>
                  <li>
                    <a href="/app/form/workbook-template" title="manage templates">
                    <Icon type={IconType.work} /> Workbook Templates
                    </a>
                  </li>
                  <li>
                    <a href={Server.BuildUrl('../admin/organization')} title="manage organizations">
                    <Icon type={IconType.org} /> Organizations Management
                    </a>
                  </li>
                  <li>
                    <a href="/app/admin/users" title="users">
                    <Icon type={IconType.users} /> Users
                    </a>
                  </li>
                </>
              )}
            </ul>
          </div>

          {props.OrgInfo?.Pages && props.OrgInfo.Pages.length > 0 && (
            <div>
              <h5>Pages</h5>
              <div className="menu-section">
                <ul className="menu-links">
                  {props.OrgInfo.Pages.map((x, i) => {
                    return (
                      <li key={`m-p-${i}`} className={''}>
                        <a href={_server.makeUrl(x.Path)}>{x.MenuName}</a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          )}

          {project && template && (
            <div className="menu-section">
              <h5>{project.name ?? template.name}</h5>
              <ul className="menu-links">
                <li key={`page-overview`}>
                  <a href={`/app/projects/${project.id}`}>
                    <Icon type={IconType.map} /> Project Overview
                  </a>
                </li>
                {template.pages?.map((p: TemplatePage, i: number) => {
                  if (p.id === currentPageId) {
                    return (
                      <li key={`page-${i}`} className="active">
                        <Icon type={IconType.here} /> {p.name}
                      </li>
                    );
                  }
                  return (
                    <li key={`page-${i}`}>
                      <a href={`/app/projects/${project.id}/page/${p.id}`}>{p.name}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          {props.menuItems && props.menuItems.length > 0 && (
            <div className="menu-section">
              <ul>
                {props.menuItems.map((x: IMenuItem, i: number) => {
                  return <li key={`menu-key-${i}`}>{x.name}</li>;
                })}
              </ul>
            </div>
          )}
          <div className="menu-section">
            <h5>User Info</h5>
            <ul className="menu-links">
              {!user && (
                <li>
                  <span
                    className="click"
                    title="click to sign-in"
                    onClick={() => {
                      redux.showLogin(true);
                      close();
                    }}>
                    <Icon type={IconType.signIn} /> Sign-In
                  </span>
                </li>
              )}
              {!user && (
                <li>
                  <span
                    className="click"
                    title="click to register"
                    onClick={() => {
                      redux.showLogin(true);
                      close();
                    }}>
                    <Icon type={IconType.userPlus} /> Register
                  </span>
                </li>
              )}
              {user && (
                <>
                  <li>
                    <a
                      onClick={() => {
                        signOut();
                      }}>
                      Sign-Out <Icon type={IconType.signOut} /> <span className="small text-muted">{user.Email}</span>
                    </a>
                  </li>
                  <li>
                    <a href={`/user/profile`}>
                      <Icon type={IconType.UserCheck} /> View Profile
                    </a>
                  </li>
                </>
              )}
              {/* {user && <li>Profile <span className="small text-muted">{user.FirstName} {user.LastName}</span></li>} */}
              {user && user.IsAdmin && (
                <li>
                  <span className="small text-muted">Admin User</span>
                </li>
              )}
              {props.BaseUser && (
                <li>
                  <a
                    onClick={() => {
                      loginToBase();
                    }}>
                    Base User <Icon type={IconType.signIn} />{' '}
                    <span className="small text-muted">
                      {props.BaseUser.FirstName} {props.BaseUser.LastName}
                    </span>
                  </a>
                </li>
              )}
            </ul>
            <h5>General</h5>
            <ul className="menu-links">
              <li>
                <a href="/app/About" title="click go to the about this app page">
                  <Icon type={IconType.info} /> About this App
                </a>
              </li>
              <li>
                <a href="/app/Start" title="click go to getting started page">
                  <Icon type={IconType.start} /> Getting Started
                </a>
              </li>
              <li>
                <a href="/app/contactUs" title="click go to contact us page">
                  <Icon type={IconType.send} /> Contact Us
                </a>
              </li>

              <li>
                <a href="/app/releaseNotes" title="click go to the release notes">
                  <Icon type={IconType.new} /> Release Notes (New Features)
                </a>
              </li>
            </ul>
            <h5>Boring Stuff</h5>
            <ul className="menu-links">
              <li>
                <a href="/app/TermsOfService" title="click go to terms of service">
                  <Icon type={IconType.fileSignature} /> Terms of Service
                </a>
              </li>

              <li>
                <a href="/app/PrivacyPolicy" title="click go to the privacy policy">
                  <Icon type={IconType.fileAlt} /> Privacy Policy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default connectMap()(Menu);

export const signOut = (refresh?: boolean) => {
  Server.instance.getApi(`../UserApi/Logout`).then((x) => {
    if (x.Success) {
      if (refresh) {
        window.location.reload();
      } else {
        window.location.href = '/';
      }
    } else {
      toast.error(`Error signing out. ${x.Message}`);
    }
  });
};
