import { info } from "console";
import { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { toast } from "react-toastify";
import { isReturnStatement } from "typescript";
import { Server, _server } from "../../utils/Server";
import Pointer from "../Tools/pointer";
import Icon, { IconType } from "../Icon/Icon";
import WorkbookElement from "./WorkbookElement";
import { canShow, getPagesMilestones, NewGuid, regexReplace, setPagesMissingDataPoints } from "./WorkbookFunctions";
import { milestoneRequirementsMet } from "./WorkbookMilestone";
import { IElement, IMilestone, IPage, ISet, ISigner, ISigners, ISignRequest, ITheme, IWorkbookInfo } from "./WorkbookTypes";

interface IPageProps extends RouteComponentProps<MatchParams> {
  page: string;
  firstPage: string;
  info: IWorkbookInfo;
  setId?:string;
  canContinue: boolean;  
  onJumpToPage:(pageId:string)=>void;
  showDonePage?: ()=>void;
  onBack?: () => void;
  onNext?: (p: IPage) => void;
  onValueChange?: (
    key: string,
    setId: string | null,
    value: string[],
    files: File[] | null | undefined,
    setGroupKey?: string,
  ) => void;
  onEditClick: (
    type: string,
    subType: string | null,
    key: string,
    callback: Function
  ) => void;
  onSignRequest: (request: ISignRequest) => void;
  showPages?:()=>void;
  preview: boolean;
  readOnly?: boolean;
}

interface MatchParams {}

const Page = (props: IPageProps) => {
  const [page, setPage] = useState<IPage>();
  const [firstPage, setFirstPage] = useState<IPage>();
  const [set, setSet] = useState<ISet>();
  const [setKey, setSetKey] = useState<string>();
  const [numberOfSets, setNumberOfSets] = useState<number>(0);
  const [css, setCss] = useState<string>();
  const [backgroudImage, setBackgroundImage] = useState<string>();

  useEffect(() => {
    if ((props.page && !page) || props.page != page?.PublicId) {
      let p: IPage = props.info.TemplateDic[props.page];
      if(!p) {
        if(props.onBack) props.onBack();
        return;
      }
      setPage(p);
      setFirstPage(props.info.TemplateDic[props.firstPage]);
    }
    if(props.page){
      let p: IPage = props.info.TemplateDic[props.page];
      if(!p) {
        if(props.onBack) props.onBack();
        return;
      }
      let set:ISet = p.SetId ? props.info.TemplateDic[p.SetId] : undefined;

      if(set && !set.OwnersAreSet){
        setSet(set);
      } else {
        setSet(undefined);
        setSetKey(undefined);
        
      }
    }
  }, [props]);

  useEffect(()=>{
    if(props.page){
      let page:IPage = props.info.TemplateDic[props.page];
      if(!page) return;
      if(page.BackgroundImage){
        let url = Server.FileUrl(page.BackgroundImage);
        setBackgroundImage(url);
      } else {
        setBackgroundImage(undefined);
      }

      if(page.StyleTheme){
        let style:ITheme = props.info.TemplateDic[page.StyleTheme];
        setCss(style?.Css)
        
      } else {
        setCss(undefined);
      }
    }else{
      setCss(undefined);
      setBackgroundImage(undefined);
    }
  },[props.page])

  const onValueChanged = (
    key: string,
    setId: string | null,
    value: string[],
    files: File[] | null | undefined
  ) => {
    let xSetId = setId ? setId : set?.PublicId ?? page?.SetId;
    let xSetKey = setKey ?? page?._setGroupKey;
    if (page && props.onValueChange) props.onValueChange(key, xSetId ?? null, value, files, xSetKey);
  };

  const buildElements = () => {
    return page?.Elements.sort((a, b) => {
      return (a.Order ?? 9999) > (b.Order ?? 9999) ? 1 : -1;
    })
      .map((x) => props.info.TemplateDic[x.Id])
      .map((x, i) => {
        return (
          <WorkbookElement
            element={x}
            key={`pwbe-${i}`}
            info={props.info}
            setId={set?.PublicId ?? props.setId ?? page.SetId}
            setKey={setKey ?? page._setGroupKey}
            onValueChange={onValueChanged}
            onEditClick={props.onEditClick}
            onSignRequest={props.onSignRequest}
            onJumpToPage={props.onJumpToPage}
            preview={props.preview}
            readOnly={(props.readOnly ? true : false) || page.ReadOnly}
          />
        );
      });
  };

  const buildSet = ()=>{
    if(set)
    return <div className="set-element">
      {props.preview && (
      <div
        className="click edit-me"
        title="edit set"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          props.onEditClick(
            "workbook-template-set",
            null,
            set.PublicId,
            updated
          );
        }}
      >
        <Icon type={IconType.edit} />
      </div>
    )}
      {set.Header && <h3>{set.Header}</h3>}
      {set.Content && <div dangerouslySetInnerHTML={{__html:set.Content}}></div>}
      <hr/>
        {setKey ? <>
        <div className="set-elements">
        {buildElements()}
        </div>
        <div className="headroom close-set">
        <div className="headroom"><button type="button" className="btn btn-secondary" onClick={()=>{setSetKey(undefined)}}><Icon type={IconType.close}/> {set.CloseLabel ?? 'Close'}</button></div>
        </div>
        </>
        :
        <>
        <div className="set-list">
          {buildSetList()}
        </div>
      
        </>}
      
      {/* {set.Limit && <div>{numberOfSets} of {set.Limit}</div>} */}
    </div>;
  };

  const buildSetList = () => {
    let values = props.info.Values[set?.PublicId ?? ''];
  
    if(values){
      console.log("values", values);
    let dataKeys = Object.keys(values).filter(x=>x && x.length === 72).map(x=> { return {key:x.substring(36), dataPoint:x.substring(0,36), fullId:x}});
    let hash:any = {};
    let hashDeleted: any = {};
    let setKeys:string[] = [];
    dataKeys.forEach(x=>{
      if(!hash[x.key]){
        hash[x.key] = true;
        setKeys.push(x.key);
      }
      if(x.dataPoint === set?.SetDeletedWhenDataPointTrue){
        hashDeleted[x.key] = true;
      }
    });

    //setNumberOfSets(setKey?.length ?? 0);
    
    console.log("keys", dataKeys);
      return (<>
        {setKeys.filter(x=>!hashDeleted[x]).map((x,i)=>{
          return <div className="set" key={`sk-${i}`}>
            <h5>{values[`${set?.SetNameFromDataPoint}${x}`]?.Value ?? `Unknown Set`}</h5>
            <div>
              <button type="button" className="btn btn-xs btn-default" onClick={()=>{setSetKey(x)}}><Icon type={IconType.edit}/> {set?.EditLabel ?? 'Edit'}</button>
              {set && set.SetDeletedWhenDataPointTrue && <button type="button" className="btn btn-xs btn-default" onClick={()=>{
                if(window.confirm('Are you sure you want to delete this?')){
                  if(props.onValueChange) props.onValueChange(set?.SetDeletedWhenDataPointTrue ?? '', set?.PublicId ?? null, ['true'], undefined, x)
                }
              }}><Icon type={IconType.trashO}/> Remove</button>}
            </div>
            </div>
        })}
        {(!set?.Limit || (set.Limit > 0 && (setKey?.length ?? 0) < set.Limit)) && <div className="headroom"><button type="button" className="btn btn-secondary" onClick={()=>{setSetKey(NewGuid())}}><Icon type={IconType.plus}/> {set?.AddLabel ?? 'Add'}</button></div>}  
      </>);
    }
    return <>
      {(!set?.Limit || (set.Limit > 0 && (setKey?.length ?? 0) < set.Limit)) && <div className="headroom"><button type="button" className="btn btn-secondary" onClick={()=>{setSetKey(NewGuid())}}><Icon type={IconType.plus}/> {set?.AddLabel ?? 'Add'}</button></div>}  
    </>
  };

  const onNext = (p: IPage) => {
    if(!canContinue()) return;

    if (p.confirmNextButtonText) {
      if (!window.confirm(p.confirmNextButtonText)) {
        return;
      }
    }

    if (props.onNext) props.onNext(p);
  };
  
  const canContinue = ()=>{
    if (page && !props.preview) {
      let missingDPs =
        page._missingDataPoints?.filter(
          (x) => x.Required && !x.RequiredNotEnforced && !page.ReadOnly
        ) ?? [];

        if(page.SetId){
          console.log('Missing: ', page.SetId, missingDPs);
          setPagesMissingDataPoints(page, props.info, page.SetId);
          missingDPs =
          page._missingDataPoints?.filter(
            (x) => x.Required && !x.RequiredNotEnforced
          ) ?? [];
        }

      if (missingDPs.length > 0) {
        toast.warning("Please fill out all the required fields on this page. ");
        return;
      }

      let setId = props.setId ? props.setId : (page.SetId ? page.SetId : 'base');
      let milestones = getPagesMilestones(props.info,page,setId,true);

      
      let signers:ISigners[] = [];

      milestones.forEach(m=>{
        m.Approvers?.map(s=>{
          let signer:ISigner = props.info.TemplateDic[s];

          let listOfSigners: ISigner[] = [];
          if(signer?.IsSignerUser || signer?.IsSetBasedOnOwner){
            //it's a set...
            props.info.Owners
            .forEach((o) => {
              listOfSigners.push({
                ...signer,
                Name: `${signer.Name} ${signer?.IsSetBasedOnOwner ? 'for':''} ${o.FirstName} ${o.LastName}`,
                PublicId: `${signer.PublicId}`,
                _ownerId: o.PublicId
              });
            });
          }
          else{
            listOfSigners.push(signer);
          }

          listOfSigners.forEach((s)=>{
            let request = props.info.SignRequests.find(r=>r.SignerId === s.PublicId && r.MilestoneId === m.PublicId && (r.OwnerId === s._ownerId || (!r.OwnerId && !s._ownerId)));
            if(!request || !request.ApprovedAtUtc){
              signers.push(signer);
            }
          });
          // let request = props.info.SignRequests.find(r=>r.SignerId === s && r.MilestoneId === m.PublicId);

          // if(!request || !request.ApprovedAtUtc){
          //   signers.push(signer);
          // }
        });
      });

      if(milestones.find(x=>!milestoneRequirementsMet(x, props.info, setId).Pass)){
        console.log(setId);
        toast.warning("You have not completed the milestones on this page.");
        return false;
      }

      if(signers.length > 0){
        toast.warning("Not everyone has approved your workbook. Please request the missing approvals.");
        console.log(signers);
        return false;
      }    

    }

    return true;
  };

  const showDonePage = () =>{
    if(!canContinue()){
      return;
    }

    if(props.showDonePage) props.showDonePage();
  };

  const buildNextPageButtons = () => {
    if (page && props.info) {
      let pagesDic: Record<string, boolean> = {};
      let preFilteredPages: IPage[] = page.ChildrenPages?.sort((a, b) => {
        return a.Order > b.Order ? 1 : -1;
      }).map((x, i) => {
        return props.info.TemplateDic[x.Id];
      });

      let pages = preFilteredPages.filter((x: IPage) => x && x._validPath);
      pages.forEach((p) => {
        if (!pagesDic[p.PublicId]) pagesDic[p.PublicId] = true;
      });

      let skippedPages = preFilteredPages.filter(
        (x) => x && !x._validPath && x.skipToNextWhenNotShown
      );
      let skippedPage = skippedPages.pop();
      while (skippedPage) {
        if (skippedPage.ChildrenPages) {
          let spPages = skippedPage.ChildrenPages.sort((a, b) => {
            return a.Order > b.Order ? 1 : -1;
          }).map((x, i) => {
            return props.info.TemplateDic[x.Id];
          });
          let sPages = spPages.filter(
            (x: IPage) => x && x._validPath && !pagesDic[x.PublicId]
          );
          sPages.forEach((s) => {
            if (!pagesDic[s.PublicId]) {
              pages.push(s);
              pagesDic[s.PublicId] = true;
            }
          });
          let sSkippedPages = spPages.filter(
            (x) =>
              x &&
              !x._validPath &&
              x.skipToNextWhenNotShown &&
              !pagesDic[x.PublicId]
          );
          sSkippedPages.forEach((s) => skippedPages.push(s));
        }
        skippedPage = skippedPages.pop();
      }

      return pages.map((x: IPage, i: Number) => {
        let btnText =
          pages.length > 1
            ? x.selectPageButtonText ?? x.nextButtonText
            : x.nextButtonText;
        if (!btnText) btnText = "Next";

        let icon = !x._canAccess ? (
          <Icon type={IconType.lock} />
        ) : (
          <Icon type={IconType.arrowRight} />
        );
        return (
          <button
            key={`npb-k-${i}`}
            type="button"
            className={`btn ${x._canAccess ? "btn-secondary" : "btn-danger"}`}
            disabled={!props.canContinue}
            onClick={() => {
              onNext(x);
            }}
          >
            {!x._canAccess && <>{icon}</>} {btnText}{" "}
            {x._canAccess && <>{icon}</>}
          </button>
        );
      });
    }
  };
  const updated = (info: IWorkbookInfo) => {
    let p: IPage = info.TemplateDic[props.page];
    setPage(p);
  };

  const previewPageTrail = (p: IPage, level: number) => {
    return (
      <div
        className={`page-crumb ${
          p.PublicId === page?.PublicId ? "active" : ""
        }`}
      >
        <div
          className={`name ${p.PublicId === page?.PublicId ? "active" : ""}`}
        >
          {!p._validPath && <Icon type={IconType.hidden} />}
          {!p._canAccess && <Icon type={IconType.lock} />}
          {p.Name}
        </div>
        <div className="children">
          {p._nextPagesAll?.map((x, i) => {
            return (
              <div key={`ppt-${level}-${i}`}>
                {previewPageTrail(x, level + 1)}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <>
     
      {page && props.info && (
        <>
        <div className="wb-page" id={'p'+props.page}>
          {props.preview && (
            <div
              className="click edit-me"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                props.onEditClick(
                  "workbook-template-page",
                  null,
                  page.PublicId,
                  updated
                );
              }}
            >
              <Icon type={IconType.edit} />
            </div>
          )}
          <div className="wb-page-body" style={{backgroundImage:backgroudImage ? `url('${backgroudImage}')` : `none`}}>
            {page.Header && (
              <div id={'p-header'+props.page} dangerouslySetInnerHTML={{ __html: regexReplace(props.info, page.Header) }}></div>
            )}
            {!set ? <>{buildElements()}</>:<>{buildSet()}</>}
            {page.Footer && (
              <div id={'p-footer'+props.page} dangerouslySetInnerHTML={{ __html: regexReplace(props.info, page.Footer) }}></div>
            )}
          </div>
        </div>
        
        <div className="footer flex-between">
        <div>
          {props.onBack && (
            <button
              type="button"
              className="btn btn-default"
              onClick={() => {
                if (props.onBack) props.onBack();
              }}
              tabIndex={-1}
            >
              <Icon type={IconType.arrowLeft} /> Back
            </button>
          )}
        </div>
        <div>   
              <Pointer />
          <button
              type="button"
              className="btn btn-default"
              tabIndex={-1}
              onClick={() => {
                if (props.showPages) props.showPages();
              }}
            >
              <Icon type={IconType.pages} /> Pages
            </button></div>
        <div>{buildNextPageButtons()}
        {page.NextPageIsDonePage &&           <button
            type="button"
            className={`btn btn-primary`}
            disabled={!props.canContinue}
            onClick={() => {
              showDonePage();
            }}
          >
            {page.nextButtonText ?? "Finish"} {" "}
            <Icon type={IconType.finished} />
          </button>}
        </div>
      </div>

      </>
      )}
      {css && <style>{css}</style>}
    </>
  );
};

export default withRouter(Page);
