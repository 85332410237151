import { useEffect, useState } from 'react';
import redux, { IState, ReduxMapper } from '../../Redux/Redux';
import { IOrgUserSummary } from '../../Types/User';
import { FormatDate } from '../../utils/Tools';
import Icon, { IconType } from '../Icon/Icon';
import { Server } from '../../utils/Server';
import { IWorkbookTemplateSummary } from '../WorkbookTemplate/WorkbookTypes';
import CopyToClipboard from '../../utils/CopyToClipboard';

interface IProps {
  onSelect?: (IWorkbookTemplateSummary)=>void;
  state:IState;
  orgId?:string;
}

interface ISearch {
  workbook?: string;
}

const OrganizationUserSearchableList = (props: IProps) => {
  const [workbooks, setWorkbooks] = useState<IWorkbookTemplateSummary[]>([]);
  const [search, setSearch] = useState<ISearch>({});

  useEffect(() => {
    if (props.state.User) {
      init();
    }
  }, [props.state.User]);

  const init = () => {
    redux.setOrgWorkbooks(props.orgId);
  };

  useEffect(() => {
    if (props.state.OrgWorkbooks) {
      if (search.workbook) {
        let text = search.workbook.toLowerCase();
        text += ' ' + search.workbook.replace(/[^0-9]/g, '');
        let lookFor: string[] = search.workbook
          .toLowerCase()
          .split(/[\s\,\t]/g)
          .filter((x) => x.length > 0);

        setWorkbooks(props.state.OrgWorkbooks?.filter((x) => filterWorkbooks(x, lookFor)) ?? []);
      } else {
        setWorkbooks(props.state.OrgWorkbooks);
      }
    }
  }, [props.state.OrgWorkbooks, search]);

  const workbookSelected = (workbook:IWorkbookTemplateSummary) =>{
    if(props.onSelect) {
      props.onSelect(workbook);
    }
  };

  return (
    <>
      <div>
        {(props.state.OrgWorkbooks?.length ?? 0) > 10 && (
          <div className="search-bar">
            <div className="form-horizontal">
              <div className="form-group">
                <label className="control-label col-sm-3" htmlFor="org-workbook-search">
                  Search
                </label>
                <div className="col-sm-7">
                  <input
                    type={'text'}
                    maxLength={500}
                    value={search.workbook ?? ''}
                    onChange={(e) => {
                      setSearch({ ...search, workbook: e.target.value });
                    }}
                    className="form-control"
                    id="org-workbook-search"
                    autoFocus={true}
                  />
                </div>
              </div>
            </div>
            <div className="flex-between">
              <div>
                Workbooks: {workbooks?.length ?? 0} of {props.state.OrgWorkbooks?.length ?? 0}{' '}
              </div>
            </div>
          </div>
        )}
        <div className="workbook-template-list">
          {workbooks.map((r, i) => {
            let x = r;
            return (
              <div className="list-row" key={`od-u-lr-${i}`}>
                <div className="workbook-info">
                  <div className="flex-between">
                    <h4>{x.Name}</h4>
                    <div>{x.ThumbnailImage && <img src={Server.BuildUrl(`../File/${x.ThumbnailImage}/50`)} />}</div>
                  </div>
                  <div>
                <CopyToClipboard valueToCopy={x.PublicId}>Id: {x.PublicId}</CopyToClipboard>
                <br />
                <CopyToClipboard valueToCopy={x.Id}>Id: {x.Id}</CopyToClipboard>
                  </div>
                  {x.Description && (
                    <>
                      <hr />
                      <div dangerouslySetInnerHTML={{ __html: x.Description }} />
                    </>
                  )}
                  {props.onSelect && <>
                  <hr/>
                  <div>
                    <button type='button' className='btn btn-secondary' onClick={()=>{workbookSelected(x)}} ><Icon type={IconType.folderPlus}/> Select</button>
                  </div>
                  </>}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default OrganizationUserSearchableList;

export const filterWorkbooks = (value: IWorkbookTemplateSummary, search: string[]) => {
  for (let i = 0; i < search.length; i++) {
    let x = search[i];

    if (value.Name?.toLowerCase().indexOf(x) > -1) return true;
    if (value.Description?.toLowerCase().indexOf(x) > -1) return true;
    if (value.PublicId?.toLowerCase().indexOf(x) > -1) return true;
    if (value.ThumbnailImage?.toLowerCase().indexOf(x) > -1) return true;

    var found = false;

    return found;
  }
};
