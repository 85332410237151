import React, { useEffect, useState } from 'react';
import redux, { IState, ReduxMapper } from '../../Redux/Redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { IOrgUserSummary, IUserSummary } from '../../Types/User';
import { IWorkbookTemplateSummary } from '../../Components/WorkbookTemplate/WorkbookTypes';
import Icon, { IconType } from '../../Components/Icon/Icon';
import { FormatDate } from '../../utils/Tools';
import { Server, _server } from '../../utils/Server';
import OrganizationUserSearchableList from '../../Components/Organizations/OrganizationUserSearchableList';
import OrganizationWorkbookTemplateSearchableList from '../../Components/Organizations/OrganizationWorkbookTemplateSearchableList';
import OrganizationWorkbookSignerAssignments from '../../Components/Organizations/OrganizationWorkbookSignerAssignments';
import { RouteComponentProps, withRouter } from 'react-router';
import OrganizationsOrgTree from '../../Components/Organizations/OrganizationsOrgTree';
import OrganizationsRequestedReviews from '../../Components/Organizations/OrganizationsRequestedReviews';
import { IOrganizationInfo } from '../../Components/Organizations/OrganizationTypes';
import { toast } from 'react-toastify';
import OrgPageHeader from '../../Components/Organizations/OrgPageHeader';

interface IProps extends RouteComponentProps<MatchParams>, IState {
  orgId?: string;
}

interface MatchParams {
  orgId?: string;
}

interface ISearch {
  users?: string;
}

const OrganizationWork = (props: IProps) => {
  const [org, setOrg] = useState<IOrganizationInfo>();

  useEffect(() => {
    if (props.User) {
      init();
    }
  }, [props.User]);

  useEffect(() => {
    //redux.setOrgId(props.match.params.orgId);
    if (props.match.params.orgId) {
      loadOrg(props.match.params.orgId);
    }
  }, [props.match.params.orgId]);

  const init = () => {};

  const loadOrg = (orgId: string) => {
    _server.getApi<IOrganizationInfo>(`../OrgApi/Info/${orgId}`).then((x) => {
      if (x.Success) {
        setOrg(x.Value);
      } else {
        toast.error(x.Message, { autoClose: 30000 });
      }
    });
  };

  return (
    <>
      <div className="org-dashboard">
        <OrgPageHeader state={props} org={org} titlePostfix={'Dashboard'} />
        <div className="body">
          <Tabs>
            <TabList>
              <Tab>
                <Icon type={IconType.work} /> Pending Reviews
              </Tab>
              <Tab><Icon type={IconType.org} />  Review Boards / Signers</Tab>
              <Tab>
                <Icon type={IconType.map} /> Org Tree
              </Tab>
            </TabList>
            <TabPanel>
              <OrganizationsRequestedReviews state={props} orgId={props.match.params.orgId} />
            </TabPanel>
            <TabPanel>
              <OrganizationWorkbookSignerAssignments state={props} orgId={props.match.params.orgId} />
            </TabPanel>
            <TabPanel>
              <OrganizationsOrgTree state={props} orgId={props.match.params.orgId} />
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default ReduxMapper(withRouter(OrganizationWork));
