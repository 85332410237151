import { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { toast } from "react-toastify";
import { Server } from "../../utils/Server";
import { isValidEmail, isValidPassword, isValidPhone } from "../../utils/Tools";
import Icon, { IconType } from "../Icon/Icon";
import Login from "./Login";

interface IHelpProps extends MatchProps  {

}

interface MatchProps extends RouteComponentProps{
    
}

const Help = (props:IHelpProps)=>{
    const [formData, setFormData] = useState<any>({});
    const [showRecoverySent, setShowRecoverySent] = useState<boolean>(false);
    const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
    const [showSignIn, setShowSignIn] = useState<boolean>(false);


    useEffect(()=>{
        let code = Server.GetQueryStringValue("code");
        if(code) {
            Server.instance.postApi(`../userApi/RecoverUserVerifyCode`, {fullCode:code})
            .then(x=>{
                if(x.Success){
                    setShowRecoverySent(true);
                    setShowChangePassword(true);
                }
                else {
                    toast.error(`${x.Message ?? 'Oh no! Something went wrong.' }`, {autoClose:false});
                }
            });
        }
    },[]);

    const SendRecoveryEmail = () =>{
        let request = {email:formData.email,phone:formData.phone};
        if(!isValidEmail(request.email) && !isValidPhone(request.phone)){
            if(!isValidEmail(request.email)) toast.warning(`The email you provided is not valid.`);
            if(!isValidPhone(request.phone)) toast.warning(`The mobile phone number you provided is not valid.`);
            return;
        }

        Server.instance.postApi(`../userApi/RecoverUser`, request)
        .then(x=>{
            if(x.Success){
                setShowRecoverySent(true);
            }
            else {
                toast.error(`${x.Message ?? 'Oh no! Something went wrong.' }`, {autoClose:false});
            }
        });
    };

const TryRecoveryCode = ()=>{
    let request = {shortCode:formData.shortCode, fullCode:Server.GetQueryStringValue("code")};
    if(!request.shortCode && !request.fullCode){
        toast.error(`The code you provided is not valid`, {autoClose:15000});
        return;
    }
    Server.instance.postApi(`../userApi/RecoverUserVerifyCode`, request)
    .then(x=>{
        if(x.Success){
            setShowChangePassword(true);
        }
        else {
            toast.error(`${x.Message ?? 'Oh no! Something went wrong.' }`, {autoClose:false});
        }
    });
};



const ChangePasswords = ()=>{
    let request = {password:formData.password, password2:formData.password2};
    let errors = [];
    if(!isValidPassword(request.password, request.password2, errors, {})){
        toast.error(errors.join(), {autoClose:15000});
        return;
    }
    Server.instance.postApi(`../userApi/ChangePassword`, request)
    .then(x=>{
        if(x.Success){
            setShowSignIn(true);
        }
        else {
            toast.error(`${x.Message ?? 'Oh no! Something went wrong.' }`, {autoClose:false});
        }
    });
};

    return (
        <>
       {!showRecoverySent && !showChangePassword && !showSignIn && <div>
            <h3>Sign-in Help</h3>
            <p>We can help you recover your account, by using your email or phone number. We will send you a message or a code you can enter to then reset your password.</p>
            <hr/>
            <div className="form-group">                
              <label htmlFor="register-email">Email</label>
              <input
                id="register-email"
                type="email"
                className="form-control"
                maxLength={250}
                autoFocus={true}
                value={formData.email}
                onChange={(e) => {
                  setFormData({ ...formData, email: e.target.value });
                }}
              ></input>
            </div>
            <div className="form-group">                
              <label htmlFor="register-phone">Mobile Phone Number</label>
              <input
                id="register-phone"
                type="tel"
                className="form-control"
                maxLength={20}
                value={formData.phone}
                onChange={(e) => {
                  setFormData({ ...formData, phone: e.target.value });
                }}
              ></input>
            </div>
            
            <div className="form-group">
              <div>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={SendRecoveryEmail}
                >
                  <Icon type={IconType.help} /> Recover Sign-in
                </button>
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={() => props.history.push("/app/user/login")}
                >
                  <Icon type={IconType.signIn} /> Sign-in
                </button>
              </div>
              {/* help link goes here */}
            </div>
        </div>}

        {showRecoverySent && !showChangePassword && !showSignIn && <div>
            <h3>Recoving Message Sent</h3>
            We've sent a recover message you should have it soon. 
            {formData.email && <>Please check your spam or juck mail folders, if you do not see the message.</>}
            <hr/>
            <div>
            <div className="form-group">                
              <label htmlFor="register-code">Recovery Code</label>
              <input
                id="register-code"
                type="text"
                className="form-control"
                maxLength={25}
                autoFocus={true}
                value={formData.shortCode}
                onChange={(e) => {
                  setFormData({ ...formData, shortCode: e.target.value });
                }}
              ></input>
            </div>
            <div className="form-group">                
              <div>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={TryRecoveryCode}
                >
                  <Icon type={IconType.lock} /> Unlock
                </button>
              </div>
            </div>
            </div>
            </div>}
        
            {showRecoverySent && showChangePassword && !showSignIn && <div>
            <h3>Change Password</h3>
            Now it's time to set a new password so you can sign-in. 
            <hr/>
            <div>
            <div className="form-group">                
              <label htmlFor="register-pass1">Password</label>
              <input
                id="register-pass1"
                type="password"
                className="form-control"
                maxLength={25}
                autoFocus={true}
                value={formData.password}
                onChange={(e) => {
                  setFormData({ ...formData, password: e.target.value });
                }}
              ></input>
            </div>
            <div className="form-group">                
              <label htmlFor="register-pass2">Confirm Password</label>
              <input
                id="register-pass2"
                type="password"
                className="form-control"
                maxLength={25}
                value={formData.password2}
                onChange={(e) => {
                  setFormData({ ...formData, password2: e.target.value });
                }}
              ></input>
            </div>
            <div className="form-group">                
              <div>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={ChangePasswords}
                >
                  <Icon type={IconType.save} /> Set Password
                </button>
              </div>
            </div>
            </div>
            </div>}
        
        {showRecoverySent && showChangePassword && showSignIn && <div>
        <h3>Sign-In</h3>
        Now it's time to sign in with your new password. 
        <hr/>
        <div>
            <Login/>
        </div>
        </div>}
        
        </>
    );
};

export default withRouter(Help);


