import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { IReleaseNote, IReleaseNotesResponse } from "../../Types/basic";
import { Server, _server } from "../../utils/Server";
import Icon, { IconType } from "../../Components/Icon/Icon";
import { v4 } from "uuid";
import Modal from "../../Components/Modal/Modal";
import Editor from "../../Components/Tools/Editor";
import OrgPageHeader from "../../Components/Organizations/OrgPageHeader";
import { IState, ReduxMapper } from "../../Redux/Redux";


interface IReleaseNotesProps extends IState {}

const ReleaseNotes = (props: IReleaseNotesProps) => {
    const [showNoteModel, setShowNoteModel] = useState<boolean>(false);
    const [note, setNote] = useState<IReleaseNote>(BlankNote());
    const [notes, setNotes] = useState<IReleaseNote[]>([]);
    const [isAdmin, setIsAdmin]= useState<boolean>(false);


    useEffect(()=>{
        GetNotesFromServer();
    },[]);

    function BlankNote(){
        return {Id:0,Notes:'',PublicId:v4(),At:''}
    };

    const GetNotesFromServer=()=>{
        Server.instance.getApi<IReleaseNotesResponse>(`../ReleaseNotes/Notes`)
        .then(x=>{
            if(x.Success){
                setNotes(x.Value.Notes);
                setIsAdmin(x.Value.IsAdmin);
            } else {
                toast.error(`${x.Message}`, {autoClose:false});
            }
        });
    };

    const SelectNote = (n: IReleaseNote)=>{
        setNote(n);
        setShowNoteModel(true);
    };

    const SaveNote = ()=>{

        if(!note.Notes){
            toast.dark('You need to add notes to send up!');
            return;
        }

        _server.postApi<IReleaseNote>(`../ReleaseNotes/SaveNote`, note)
        .then(x=>{
            if(x.Success){
                setNotes([x.Value,...notes?.filter(n=>n.PublicId !== x.Value.PublicId) ?? []]);
                setNote(BlankNote());
                setShowNoteModel(false);
            } else {
                toast.error(`${x.Message}`, {autoClose:false});
            }
        });
    };

  return (
    <>
    <OrgPageHeader state={props} />
      <div className="flex-between"><h3>Release Notes</h3>
      {isAdmin && <button type="button" className="btn btn-secondary" onClick={()=>{SelectNote(BlankNote());}}><Icon type={IconType.new}/> New</button>}
      </div>
      We are contining to make the Project App better and better with
      inmprovements each week. Checkback here frequently to see what has
      changed.
      <hr />

      <div className="release-notes-container">
        {notes.length === 0 && <>No release notes to show at this time</>}
        {notes.map((n,i)=>{
            return (<div className="release-note" key={`${n.PublicId}`}>
                <div className="release-note-body" dangerouslySetInnerHTML={{__html:n.Notes}}></div>
                
               <div className="release-note-at flex-between"><span>{n.At}</span>
                     {isAdmin && <button type="button" className="btn btn-xs btn-default text-info" onClick={()=>{SelectNote(n);}}><Icon type={IconType.edit}/></button>        }
                </div>
            </div>);
        })}

      </div>

      {showNoteModel &&         <Modal
          setModalOpen={setShowNoteModel}
          title={'Release Notes'}
          size="xl"
                  >                    

              <div>
                  <h4>Release Notes.</h4>
                  Fillout the notes below and click save!
                  <hr/>
                  <div className="form-horizontal">
                    <div className="form-group">
                        <label htmlFor="release-notes-notes">Notes</label>
                        
                        <Editor value={note.Notes} options={"staff-simple"} changed={(x)=>{setNote({...note,Notes:x})}} height={"short"} />
                    </div>
                    <div className="form-group">
                        <button type="button" className="btn btn-secondary" onClick={SaveNote}><Icon type={IconType.save}/> Save</button>
                    </div>
                  </div>
              </div>
        </Modal>}
    </>
  );
};

export default ReduxMapper(ReleaseNotes);
