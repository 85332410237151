import { useEffect, useState } from 'react';
import redux, { IState } from '../../Redux/Redux';
import { IOrganizationInfo } from './OrganizationTypes';
import { Server } from '../../utils/Server';
import Icon, { IconType } from '../Icon/Icon';
import { LimitStringSize } from '../../utils/Tools';

interface IProps {
  state: IState;
  org?: IOrganizationInfo;
  titlePostfix?: string;
  homeRelativeUrl?: string;
}

const OrgPageHeader = (props: IProps) => {
  const [org, setOrg] = useState<IOrganizationInfo>();

  useEffect(() => {
    setOrg(props.org ?? props.state.OrgInfo);
  }, [props.org, props.state.OrgInfo]);

  useEffect(() => {
    return () => {
      redux.setHasNav(false);
    };
  }, []);

  useEffect(() => {
    redux.setHasNav(org ? true : false);
  }, [org]);

  const goHome = () => {
    goTo(props.homeRelativeUrl ?? `../`);
  };

  const goTo = (relUrl: string) => {
    window.location.href = Server.BuildUrl(relUrl);
  };

  return (
    <>
      <div className="header flex-between stickey basic-page-header">
        <div>
          {org && (
            <>
              <h2 className="click flex" onClick={goHome}>
                {org.Logo && <img className="logo" src={Server.BuildUrl(`../File/${org.Logo}/200x50`)} title={`${org.Name}'s Logo`} />}
                <div className='text-centered'>
                  {!org.ShowJustLogoInHeader && <>{LimitStringSize(org.Name, 400)}</>}
                  {props.titlePostfix && <>{props.titlePostfix}</>}
                </div>
              </h2>
            </>
          )}
        </div>
        <div className="btn-group">
          {props.state.User ? (
            <>
              {org && org.AllowSubOrgs && org.UserRelationship?.ReviewGroups?.length > 0 && (
                <button
                  type="button"
                  className="btn btn-default btn-xs"
                  title={'Go to my work'}
                  onClick={() => {
                    goTo('../organization/work');
                  }}>
                  <Icon type={IconType.work} /> Work
                </button>
              )}
              {org && org.AllowSubOrgs && (org.UserRelationship?.Admin || props.state.User.IsAdmin) && (
                <button
                  type="button"
                  className="btn btn-default btn-xs"
                  title={'Go to Manage My Organization'}
                  onClick={() => {
                    goTo('../organization');
                  }}>
                  <Icon type={IconType.manage} /> Organization
                </button>
              )}
              {org && org.AllowSubOrgs && (
                <button
                  type="button"
                  className="btn btn-default btn-xs"
                  title={'Go to my organizations'}
                  onClick={() => {
                    goTo('../user/organization');
                  }}>
                  <Icon type={IconType.org} /> My Organizations
                </button>
              )}
            </>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-default btn-xs"
                title={'sign in'}
                onClick={() => {
                  redux.showLogin(true);
                }}>
                <Icon type={IconType.signIn} /> Sign In
              </button>
              <button
                type="button"
                className="btn btn-primary btn-xs"
                title={'sign in'}
                onClick={() => {
                  redux.showLogin(true);
                }}>
                <Icon type={IconType.signIn} /> Sign up for free
              </button>
            </>
          )}
          <button
            type="button"
            className="btn btn-default btn-xs"
            title={'Show menu'}
            onClick={() => {
              redux.setShowNav(true);
            }}>
            <Icon type={IconType.bars} /> Menu
          </button>
        </div>
      </div>
    </>
  );
};

export default OrgPageHeader;
