import { join } from "path";
import { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { toast } from "react-toastify";
import redux, { connectMap, IState } from "../../../Redux/Redux";
import { _server } from "../../../utils/Server";
import Icon, { IconType } from "../../Icon/Icon";
import { IInviteOwnerResponse, IWorkbookInfo } from "../WorkbookTypes";

interface IInviteOwner extends RouteComponentProps<MatchParams>,IState {
    info:IWorkbookInfo
}
interface MatchParams {
    workbookId?:string;
  }

const InviteAccept = (props:IInviteOwner)=>{
    const [needsLogin, setNeedsLogin] = useState<boolean>();
    const [workbookId, setWorkbookId] = useState<string>();
    const [info, setInfo] = useState<IWorkbookInfo>();

    useEffect(()=>{
        if(props.match.params.workbookId)
        {
            setWorkbookId(props.match.params.workbookId);
        }
    },[props.match?.params?.workbookId]);

    useEffect(()=>{
        if(workbookId){
            init();
        }
    },[workbookId]);

    useEffect(()=>{
        if(needsLogin && props.User){
            onJoin();
            setNeedsLogin(false);
        }
    },[props.User]);

    const onJoin = ()=>{
        if(!props.User){
            setNeedsLogin(true);
            redux.showLogin(true);
            return;
        }
        _server.postApi<boolean>(`../WorkbookApi/AcceptInvite`, {workbookId:workbookId})
        .then(x=>{
            if(x.Success){
                //you have joined the team.
                toast.success(`${props.User?.FirstName}, you have been added to this workbook.`);
                props.history.push(`../../Workbook/Start/${workbookId}`);
            } else {
                toast.error(x.Message, {autoClose:false});
            }
        })
    };

    const init = ()=>{
        _server.postApi<IWorkbookInfo>(`../WorkbookApi/InviteInfo`, {workbookId:workbookId})
        .then(x=>{
            if(x.Success){
                setInfo(x.Value);
                //props.history.push(`../Workbook/Start/${workbookId}`);
            } else {
                toast.error(x.Message, {autoClose:false});
            }
        });
    };

    return (<div className="accept-invite">

    <>
        {!props.User && <div className="alert alert-warning">
            <h3><Icon type={IconType.signIn}/> Welcome</h3>
            Hi, you have been asked to join a workbook. You must have an account and be signed-in before you can join. 
            <div className="headroom">
                <button type={'button'} className="btn btn-secondary" onClick={()=>{redux.showLogin(true);}} ><Icon type={IconType.signIn}/> Sign-In or Register</button>
            </div>
        </div>}

        {info && <div className="alert alert-info">
                
            <h3>Hi {props.User?.FirstName} Welcome <Icon type={IconType.signIn}/></h3>
            You have been invited to join <strong>{info.Name}</strong> workbook. The other members of this workbook include {info.Owners.map((x,i)=>{

                return <span key={`on-k-${i}`}>{x.FirstName}{info.Owners.length > i+1 ? ',':'. '}{" "}</span>
                })}

                
            <div className="headroom">
                <button type={'button'} className="btn btn-secondary" onClick={()=>{onJoin();}} ><Icon type={IconType.plus}/> Join Workbook</button>
            </div>


            </div>}


        
        </>
    </div>);
};

export default connectMap()(withRouter(InviteAccept));
