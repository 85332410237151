import { useEffect, useState } from 'react';
import redux, { connectMap, IState } from '../../Redux/Redux';
import Modal from '../Modal/Modal';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Icon, { IconType } from '../Icon/Icon';
import { Server, _server } from '../../utils/Server';
import { IUser } from '../../Types/basic';
import { toast } from 'react-toastify';
import { isValidEmail, isValidPhone } from '../../utils/Tools';
import RegisterUser from './RegisterUser';
import Register from './Register';
import ConfigFormEditor from '../FormManager/ConfigFormEditor';
import { IConfigItemSummary } from '../FormManager/FormManagerTypes';
import { isCallExpression } from 'typescript';

interface ILoginOrRegisterProps extends IState {
  show: boolean;
  lite: boolean;
  children?: React.ReactNode;
  canClose?: boolean;
}

interface IForm {
  Email?: string;
  Phone?: string;
  SMS?: boolean;
  FirstName?: string;
  LastName?: string;
  ZipCode?: string;
  Password?: string;
  ConfirmPassword?: string;
  AcceptTermsOfService?: boolean;
  AcceptPrivacy?: boolean;
}
interface IFormSignIn {
  Username?: string;
  Password?: string;
  Email?: string;
  Phone?: string;
}
interface ILoginResponse {
  loggedIn: boolean;
  user: IUser;
}
const LoginOrRegister = (props: ILoginOrRegisterProps) => {
  const [show, setShow] = useState<boolean>();
  const [form, setForm] = useState<IForm>({});
  const [formSignIn, setFormSignIn] = useState<IFormSignIn>({});
  const [showSignInHelp, setShowSignInHelp] = useState<boolean>();
  const [showRecoverySent, setShowRecoverySent] = useState<boolean>();
  const [tabIndex, setTabIndex] = useState(0);
  const [registered, setRegistered] = useState<IConfigItemSummary>();

  useEffect(() => {
    setShow(props.show || props.ShowLogin);
  }, [props.show, props.ShowLogin]);

  useEffect(() => {
    if (!show) {
      redux.showLogin(false);
    }
  }, [show]);

  const onSignInClick = () => {
    if (!isValidPhone(formSignIn.Username) && !isValidEmail(formSignIn.Username)) {
      toast.warning(`Please provide a valid username.`);
      return;
    }

    _server.postApi<ILoginResponse>('../UserApi/Login', formSignIn).then((x) => {
      if (x.Success) {
        redux.setUser(x.Value.user);
        redux.showLogin(false);
        setShow(false);
        const terms = window.location.search?.toLowerCase().substring(1).split('&');
        if(terms && terms.length > 0){
          const register = terms.find(x=>x==='register') ? true: false;
          const signin = terms.find(x=>x==='signin') ? true: false;
    
          if(register || signin){
            window.location.href = window.location.origin
          }
        }
      } else {
        toast.error(x.Message);
      }
    });
  };

  const SendRecoveryEmail = () => {
    if (!isValidEmail(formSignIn.Email) && !isValidPhone(formSignIn.Phone)) {
      if (!isValidEmail(formSignIn.Email)) toast.warning(`The email you provided is not valid.`);
      if (!isValidPhone(formSignIn.Phone)) toast.warning(`The miblie phone number you provided is not valid.`);
      return;
    }

    Server.instance.postApi(`../UserApi/RecoverUser`, formSignIn).then((x) => {
      if (x.Success) {
        setShowRecoverySent(true);
        setShowSignInHelp(false);
        if (formSignIn.Email) toast.info(`We've set an email to ${formSignIn.Email} with instructions to reset your password.`, { autoClose: false });
        if (formSignIn.Phone) toast.info(`We've set an text to ${formSignIn.Phone} with instructions to reset your password.`, { autoClose: false });
      } else {
        toast.error(`${x.Message ?? 'Oh no! Something went wrong.'}`, {
          autoClose: false,
        });
      }
    });
  };

  const onRegister = (username: string) => {
    setTabIndex(0);
    setFormSignIn({ Username: username });
  };

  const RegisterUpdated = (user: IConfigItemSummary) => {
    setRegistered(user);
    if ((props.OrgInfo?.OAuths?.length ?? 0) > 0) setTabIndex(1);
    else setTabIndex(0);
    //redux.showLogin(true);
  };

  const buildOAuthButtons = () => {
    return props.OrgInfo?.OAuths.map((x, i) => {
      return (
        <div className="o-auth-btn-container" key={`oabc-${i}`}>
          <button
            type={'button'}
            className="btn btn-block btn-secondary"
            onClick={() => {
              callSSO(x);
            }}>
            {x}
          </button>
        </div>
      );
    });
  };

  const callSSO = (provider: string) => {
    let url = _server.makeUrl(`../AuthApi/Login?providerName=${provider}&redirectTo=${window.location.href}`);
    window.location.href = url;
  };

  return (
    <>
      {props.show && show && (
        <Modal setModalOpen={setShow} size="l" title="Sign-in" noClose={props.canClose ? false : true}>
          {props.children && <div className="headroom">{props.children}</div>}
          <div className="headroom">
            <div className="flex-between">
              <div className="grow">
                <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                  <TabList>
                    {(props.OrgInfo?.OAuths?.length ?? 0) > 0 && (
                      <Tab>
                        <Icon type={IconType.magic} /> Let's do this
                      </Tab>
                    )}
                    <Tab>
                      <Icon type={IconType.signIn} /> Sign-In
                    </Tab>
                    <Tab>
                      <Icon type={IconType.userPlus} /> Register
                    </Tab>
                  </TabList>
                  {(props.OrgInfo?.OAuths?.length ?? 0) > 0 && (
                    <TabPanel>
                      <div className="sso-container">
                        <div className="center">
                          <h2>Sign-in / Register</h2>
                          <strong>with a single click</strong>
                        </div>
                        <div className="headroom"></div>
                        {buildOAuthButtons()}
                      </div>
                    </TabPanel>
                  )}
                  <TabPanel>
                    {!showSignInHelp && (
                      <div className="form-horizontal">
                        {registered && (
                          <div className="headroom">
                            <h3>Hi {registered.Name},</h3>Now that you have registered please sign-in your new account.<div className="headroom"></div>
                          </div>
                        )}
                        <div className="form-group">
                          <label className="control-label col-sm-4" htmlFor="sign-in-Username">
                            Email
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              maxLength={150}
                              placeholder={`email or phone number`}
                              onChange={(e) => {
                                setFormSignIn({
                                  ...formSignIn,
                                  Username: e.target.value,
                                });
                              }}
                              value={formSignIn.Username ?? ''}
                              className="form-control"
                              id="sign-in-Username"
                              autoFocus={true}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-sm-4" htmlFor="sign-in-Password">
                            Password
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="password"
                              maxLength={50}
                              placeholder={`password`}
                              onChange={(e) => {
                                setFormSignIn({
                                  ...formSignIn,
                                  Password: e.target.value,
                                });
                              }}
                              value={formSignIn.Password ?? ''}
                              className="form-control"
                              id="sign-in-Password"
                              onKeyUp={(e) => {
                                if (e.key === 'Enter') onSignInClick();
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-sm-offset-4 col-sm-6 flex-between">
                            <button type="button" className="btn btn-secondary" onClick={onSignInClick} disabled={!formSignIn.Password || !formSignIn.Username}>
                              <Icon type={IconType.signIn} /> Sign-In
                            </button>
                            <button
                              type="button"
                              className="btn btn-default"
                              onClick={() => {
                                setShowSignInHelp(true);
                              }}>
                              <Icon type={IconType.help} /> Sign-in Help
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {showSignInHelp && (
                      <div className="form-horizontal">
                        <div className="headroom">
                          <h3>Sign-in Help</h3>
                          <p>We can help you recover your account, by using your email or phone number. We will send you a message or a code you can enter to then reset your password.</p>
                          <hr />
                        </div>
                        <div className="form-group">
                          <label className="control-label col-sm-4" htmlFor="sign-in-Email">
                            Email
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              maxLength={150}
                              placeholder={`email`}
                              onChange={(e) => {
                                setFormSignIn({
                                  ...formSignIn,
                                  Email: e.target.value,
                                });
                              }}
                              value={formSignIn.Email ?? ''}
                              className="form-control"
                              id="sign-in-Email"
                              autoFocus={true}
                              onKeyUp={(e) => {
                                if (e.key === 'Enter') SendRecoveryEmail();
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-sm-4" htmlFor="sign-in-Phone">
                            Phone
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="tel"
                              maxLength={50}
                              placeholder={`phone number`}
                              onChange={(e) => {
                                setFormSignIn({
                                  ...formSignIn,
                                  Phone: e.target.value,
                                });
                              }}
                              value={formSignIn.Phone ?? ''}
                              className="form-control"
                              id="sign-in-Phone"
                              onKeyUp={(e) => {
                                if (e.key === 'Enter') SendRecoveryEmail();
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-sm-offset-4 col-sm-6 flex-between">
                            <button type="button" className="btn btn-secondary" onClick={SendRecoveryEmail} disabled={!formSignIn.Email && !formSignIn.Phone}>
                              <Icon type={IconType.send} /> Submit
                            </button>
                            <button
                              type="button"
                              className="btn btn-default"
                              onClick={() => {
                                setShowSignInHelp(false);
                              }}>
                              <Icon type={IconType.signIn} /> Sign-in
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </TabPanel>
                  <TabPanel>
                    {/* <Register onRegister={onRegister} /> */}
                    <div>
                      <ConfigFormEditor
                        type={'users'}
                        item={{ Name: '', Type: 'users' }}
                        itemKey={''}
                        canSave={true}
                        noEdit={false}
                        itemChildren={undefined}
                        onClose={() => {}}
                        onSave={RegisterUpdated}
                        onRemove={RegisterUpdated}
                        onUpdate={RegisterUpdated}
                        managerControler={`NonAdminDataManager`}
                        noModal={true}
                      />
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default connectMap()(LoginOrRegister);
