import { useState } from "react";import { IState, ReduxMapper } from "../../Redux/Redux";
import OrgPageHeader from "../../Components/Organizations/OrgPageHeader";

interface IProps extends IState{

}

const GettingStarted = (props:IProps) => {
  const [name, setName] = useState("zFairs Project App");

  return (
    <>
    <OrgPageHeader state={props} />
      <div className="document">
        <h3>Getting Started</h3>
        <h5>Creating an Account</h5>
        <ol>
          <li>
            Click the Menu in the top right and then Register OR Click the New
            Project tile and walking through the prompts there
          </li>
          <li>
            Select the Template that Best Applies to what you are trying to
            accomplish
            <div>Be sure to enter an email you have easy access to</div>
          </li>
        </ol>
        <h5>Start a New Project</h5>
        <ol>
          <li>Click the New Project tile</li>
          <li>
            Select the appropriate template and walk through the Project Setup
            steps
          </li>
          <li>
            Walk through the process flow
            <div>
              Be sure to take full advantage of a Brainstorming Session or two,
              the Notes, Files and Sources tabs as well any Template Specific
              features your sponsor is providing
            </div>
          </li>
        </ol>
        <h5>Project App Terms</h5>
        <ul>
          <li>
            <span className="term">Template</span> The full set of pages, page elements, videos and related
            configurations associated with any one process / Curriculum set.
            Users will select which Template they are using just prior to
            beginning to enter information about any new projects they start and
            their project, along with its associated data, will show on their
            list of existing projects. Users can have multiple projects using
            the same template but any one project will only ever use a single
            template.
          </li>
          <li>
          <span className="term">Page</span> Each tile on the Pages subtab inside of each project counts
            as a page. A page is a piece of a template and contains elements
            that will get information from or display information to the user.
          </li>
          <li>
          <span className="term">Element</span> Elements are contained inside of a Page and are the fields
            or information received from or presented to a user.
          </li>
          <li>
          <span className="term">Notes</span> The "Notes" sub tab in the project app is a quick reference
            list of things the user wants to document. They can be as extensive
            or short as desired.
          </li>
          <li>
          <span className="term">Files</span> All of the files (images, PDFs, Word Docs, etc.) submitted
            to the app can be found on the Files sub tab.
          </li>
          <li>
          <span className="term">Sources</span> The Sources sub tab will be a collection of all the cited
            sources for the applicable project.
          </li>
        </ul>
      </div>
    </>
  );
};

export default ReduxMapper(GettingStarted);
