import { IFaq } from "./WorkbookTypes";

interface IWorkbookFaqContainerProps{
    faqs:IFaq[];
}

export const WorkbookFaqContainer = (props:IWorkbookFaqContainerProps) =>{
      return <>
        {props.faqs?.sort((a,b)=>{return a.SortOrder > b.SortOrder ? 1:-1;}).map((x,i)=>{
            return <div key={`faq-${i}`} className={'faq'}>
                <div className="faq-question">{x.Name}</div>
                {x.Answer && <div className="faq-answer" dangerouslySetInnerHTML={{__html:x.Answer}}></div>}
            </div>
        })}
    </>
};