import { useState } from "react";
import { toast } from "react-toastify";
import { connectMap, IState } from "../../../Redux/Redux";
import { _server } from "../../../utils/Server";
import { isValidEmail, isValidPhone } from "../../../utils/Tools";
import Icon, { IconType } from "../../Icon/Icon";
import { IInviteOwnerResponse, IWorkbookInfo } from "../WorkbookTypes";
import CopyToClipboard from "../../../utils/CopyToClipboard";

interface IInviteOwner extends IState {
    info:IWorkbookInfo
}


interface IForm{
    Phone?:string; 
    Email?:string; 
    FirstName?:string; 
    LastName?:string; 
    Sms?:boolean; 
    WorkbookId?:string;
}

const InviteOwner = (props:IInviteOwner)=>{
    const [form, setForm] = useState<IForm>({});
    const [invited, setInvited] = useState<IInviteOwnerResponse>();

    const sendInvite = ()=>{
        let errors:string[] = [];
        if ((!isValidPhone(form.Phone)) && !isValidEmail(form.Email)) {
            errors.push('You need a valid email or phone number');


        } else if(form.Sms && !isValidPhone(form.Phone)) {
            errors.push('Phone');            
        }
        
        if (!form.FirstName && !form.LastName) errors.push('Name');

        if(errors.length > 0 ){
            toast.warning(<>Please provide all the required information. <ul>{errors.map((x,i)=>{
                return <li key={`e-${i}`}>{x}</li>
            })}</ul></>);
            return;
        }

        //send to server...
        _server.postApi<IInviteOwnerResponse>(`../workbookApi/Invite`, {...form, WorkbookId:props.info.WorkbookId})
        .then(x=>{
            if(x.Success){
                setInvited(x.Value);
            } else {
                toast.error(x.Message, {autoClose:false});
            }
        })
    };
    
    return (<>
    {invited && <>
        <h3>Hey {props.User?.FirstName},</h3>
        We sent an invite to {form.FirstName ?? ''} {form.LastName ?? ''}.

        {invited.AccountFoundAndLinked && <div className="alert alert-success">
            <h4><Icon type={IconType.exclamation} /> Workbook owner linked</h4>
            We were able to find {form.FirstName ?? ''} {form.LastName ?? ''}'s acount; they have been made an owner of this workbook.
            </div>}

        <div className="headroom-xl alert alert-success">
                <div className="flex-between">
                  <div>
                    <strong>Invite Link:</strong>{" "}
                    <>{invited.Link}</>
                  </div>
                  
                <CopyToClipboard valueToCopy={invited.Link}>Copy Link</CopyToClipboard>
                </div>
              </div>
   </>}
    {!invited && <>
        <h2>Hi {props.User?.FirstName},</h2>
        You can invite others to be part of this workbook, that will allow them to fill it out with you. 

        <div className="alert alert-warning">
            <h4><Icon type={IconType.warning}/> Warning</h4>
            Only invite people you know. Do not intvite those that need to sign or approve your workbook. They'll be invited when you request their approval.
        </div>
        <h4>Who are you inviting?</h4>
        <div className="form-horizontal">
            <div className="form-group">
                <label className="col-sm-4 control-label" htmlFor="FirstName">First Name</label>
                <div className="col-sm-6">
                    <input 
                    type={'text'}
                    id={'iv-oth-FirstName'}
                    maxLength={250}
                    className='form-control'
                    onChange={(e)=>{setForm({...form,FirstName:e.target.value})}}
                    value={form.FirstName ?? ''}
                    />
                </div>
            </div>
            <div className="form-group">
                <label className="col-sm-4 control-label" htmlFor="LastName">Last Name</label>
                <div className="col-sm-6">
                    <input 
                    type={'text'}
                    id={'iv-oth-LastName'}
                    maxLength={250}
                    className='form-control'
                    onChange={(e)=>{setForm({...form,LastName:e.target.value})}}
                    value={form.LastName ?? ''}
                    />
                </div>
            </div>
            <div className="form-group">
                <label className="col-sm-4 control-label" htmlFor="Email">Email</label>
                <div className="col-sm-6">
                    <input 
                    type={'email'}
                    id={'iv-oth-Email'}
                    maxLength={250}
                    className='form-control'
                    onChange={(e)=>{setForm({...form,Email:e.target.value})}}
                    value={form.Email ?? ''}
                    />
                </div>
            </div>
            <div className="form-group">
                <label className="col-sm-4 control-label" htmlFor="Phone">Phone</label>
                <div className="col-sm-6">
                    <input 
                    type={'tel'}
                    id={'iv-oth-Phone'}
                    maxLength={250}
                    className='form-control'
                    onChange={(e)=>{setForm({...form,Phone:e.target.value})}}
                    value={form.Phone ?? ''}
                    />
                </div>
            </div>
            <div className="form-group">
                <div className="col-sm-6 col-sm-offset-4">
                    <input 
                    type={'checkbox'}
                    id={'iv-oth-Sms'}
                    onChange={(e)=>{setForm({...form,Sms:e.target.checked})}}
                    checked={form.Sms ? form.Sms : false}
                    />
                    
                <label className="control-label" htmlFor="Sms">Send text message (SMS)</label>
                </div>
            </div>
            <div className="form-group">
                <div className="col-sm-6 col-sm-offset-4">
                    <button type="button" className="btn btn-secondary" onClick={()=>{sendInvite()}} ><Icon type={IconType.userPlus} /> Add Workbook Owner</button>
                </div>
            </div>
        </div>
        </>}
    </>);
};

export default connectMap()(InviteOwner);