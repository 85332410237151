import { useEffect, useState } from "react";

interface IInputGradeRangeProps {
  onValueChange: Function;
  value?: string;
  delimiter: string;
  forLow?: string;
  forHigh?: string;
  readOnly?:boolean;
  onBlur?:Function;
  type?:'full-pipes';
  clickToEdit?:boolean;
}

interface IGrades {
  value: number;
  label: string;
}

interface IGradeRange {
  low:number;
  high:number;
}



const InputGradeRange = (props: IInputGradeRangeProps) => {
  const [gradeList, setGradeList] = useState<IGrades[]>([]);
    const [ready, setReady] = useState<boolean>(false);
    const [range,setRange] = useState<IGradeRange>();
  const [gradeRange, setGradeRange] = useState<number[]>([]);

  useEffect(() => {
    let gs: IGrades[] = [];
    gs.push({ label: "Kindergarten", value: 0 });
    gs.push({ label: "1st", value: 1 });
    gs.push({ label: "2nd", value: 2 });
    gs.push({ label: "3th", value: 3 });
    gs.push({ label: "4th", value: 4 });
    gs.push({ label: "5th", value: 5 });
    gs.push({ label: "6th", value: 6 });
    gs.push({ label: "7th", value: 7 });
    gs.push({ label: "8th", value: 8 });
    gs.push({ label: "9th", value: 9 });
    gs.push({ label: "10th", value: 10 });
    gs.push({ label: "11th", value: 11 });
    gs.push({ label: "12th", value: 12 });

    setGradeList(gs);

    init();
  }, [props.value]);



  useEffect(()=>{
    if(!range) return;
    let a:number[] = [];
    let g = range.low;
    while(g <= range.high){
      a.push(g);
      g++;
    }
    setGradeRange(a);
  },[range, range?.high, range?.low]);

  const getRange = (value:string|undefined, type:"full-pipes"|undefined, delimiter:string|undefined) => {
    if(typeof(value) != typeof(' ')) return;

    if(type === 'full-pipes'){
      let grades:number[] = value?.split('|').filter(x=>x.length > 0).map(x=>+x) ?? [];
      // console.log(value, grades);
      // console.log(grades)
      return ({low:grades[0] ?? -1, high:grades[grades.length -1] ?? -1})
    } else {      
      let values = value?.split(delimiter ?? '|') ?? ['0','12'];
      let low: number = values[0] === 'l' ? -1 : +values[0];
      let high: number = values[1] === 'h' ? -1 : +values[1];
      return ({low,high});
    }
  };

  const init = () =>{
    setRange(getRange(props.value, props.type, props.delimiter));
    setReady(true);
  };

  const selectGrade = (g:number) =>{
    let r = {...range??  {high:-1, low:-1}};
    if(r.high == -1) r.high = g;
    if(r.low == -1)  r.low = g;

    //is between
    if(g>=r.low && g<=r.high){
      //between making smaller
      r.low = g;
      r.high = g;      
    } else if(g<=r.low){
      r.low = g;
    } else if (g>=r.high){
      r.high = g;
    }
    setRange(r);
    let v = getValue(r);
    props.onValueChange(v);
  };

  const onBlur = ()=>{
    if(props.onBlur) props.onBlur();
  };

  const getValue = (r:IGradeRange) =>{

    if(props.type === 'full-pipes'){
      let a:string[] = [];
      let g = r.low;
      while(g <= r.high){
        a.push(g+'');
        g++;
      }

      return a.join('|') +'|';
    }

    return `${r.low > -1 ? r.low : "l"}|${
      r.high > -1 ? r.high : "h"
    }`;

  };

  return (
    <>
    <div className="grade-range-input">
      {gradeList && gradeList.length > 0 && <>{gradeList.map((g,i)=>{
      let selected = gradeRange.find(y=>y===g.value);
      return <div className={`grade ${selected || selected === 0 ? 'active' : ''} click`} key={`grg-${i}`} onClick={()=>{selectGrade(g.value)}} >{g.label}</div>
    })}</>}
    </div>
    </>
  );
};

export default InputGradeRange;
