import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { RouteComponentProps, withRouter } from "react-router";
import { toast } from "react-toastify";
import { checkEmailValid, isValidEmail, isValidPhone } from "../../utils/Tools";
import { Server, _server } from "../../utils/Server";
import Icon, { IconType } from "../Icon/Icon";
import Login from "./Login";
import ConfigFormEditor from "../FormManager/ConfigFormEditor";
import redux from "../../Redux/Redux";
import { IConfigItemSummary } from "../FormManager/FormManagerTypes";

const RegisterUser = (props: RouteComponentProps) => {
  const [formErrors, setFormErrros] = useState<any>({});
  const [formData, setFormData] = useState<any>({});
  // const [email, setEmail] = useState<string>('');
  // const [firstName, setFirstName] = useState<string>('');
  // const [lastName, setLastName] = useState<string>('');
  // const [password, setPassword] = useState<string>('');
  // const [password2, setPassword2] = useState<string>('');
  // const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
  const [showSignIn, setShowSignIn] = useState<boolean>(false);
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [registered, setRegistered] = useState<IConfigItemSummary>();

  useEffect(()=>{
    ValidateForm(!showErrors);
  },[formData]);

  const handleSubmit = () => {
    let rqst = {
      Email: formData.email,
      FirstName: formData.firstName,
      LastName: formData.lastName,
      Password: formData.password,
      Phone: formData.phone
    };

    setShowErrors(true);
    let errors: string[] = ValidateForm(false);

    if (errors && errors.length > 0) {
      errors.forEach((e) => toast.warning(e));
      return;
    }
    _server
      .postApi("../UserApi/Register", rqst)
      .then((x) => {
        if (x.Success) {
          setShowSignIn(true);
        } else {
          toast.warning(x.Message);
        }
      })
      .catch((err) => console.error(err));
  };

  const ValidateForm = (okayIfEmpty:boolean) => {
    let errors: string[] = [];
    let e: any = {};
    const { acceptTerms, email, firstName, lastName, password, password2, phone } =
      formData;
    if (!acceptTerms && !okayIfEmpty) {
      errors.push(`You must select terms to register.`);
      e.terms = true;
    }

    if(!okayIfEmpty)
    if (!isValidEmail(email)) {
      errors.push(`Email is required.`);
      e.email = true;
    }

    if(phone && !isValidPhone(phone)){
      errors.push(`Phone number is not valid.`);
      e.phone = true;
    }

    if(!okayIfEmpty)
    if (!firstName) {
      errors.push("First name is required.");
      e.firstName = true;
    }
    if(!okayIfEmpty)
    if (!lastName) {
      errors.push("Last name is required.");
      e.lastName = true;
    }

    if(!okayIfEmpty)
    if (password) {
      if (password != password2) {
        errors.push(`The passwords you provided do not match`);
        e.match = true;
      }

      if (8 > password.length) {
        errors.push(`Password must be at least 8 charaters`);
        e.password = true;
      }
      if (!password.match(/[A-Z]/g) || !password.match(/[a-z]/g)) {
        errors.push(
          `Password must have at least one upper and lower case letter`
        );
        e.password = true;
      }
      if (!password.match(/[0-9]/g)) {
        errors.push(`Password must have at least one number.`);
        e.password = true;
      }
      if (
        !password.match(
          /[\!\@\#\$\%\^\&\*\:\;\'\?\<\>\,\.\|\[\]\{\}\=\_\+\(\)]/g
        )
      ) {
        errors.push(
          `Password must have at least one special character. Such as: !@#$%^&*()=+{}[].`
        );
        e.password = true;
      }
    } else {
      errors.push(`Password must be provided.`);
      e.password = true;
    }

    setFormErrros(e);
    return errors;
  };

  const RegisterUpdated = (user:IConfigItemSummary)=>{
    setRegistered(user);
    setShowSignIn(true);
    //redux.showLogin(true);
  };

  return (
    <>
    <div className="user-registration">
      {!showSignIn && (
        <div >
                  <ConfigFormEditor
                type={"users"}
                item={{Name:'', Type:'users'}}
                itemKey={''}
                canSave={true}
                noEdit={false}
                itemChildren={undefined}
                onClose={() => {
                }}
                onSave={RegisterUpdated}
                onRemove={RegisterUpdated}
                onUpdate={RegisterUpdated}
                managerControler={`NonAdminDataManager`}
                noModal={true}
        />
        </div>
      )}
      {showSignIn && (
        <div>
          <h3>
            <Icon type={IconType.user} /> Hi {registered?.Name ?? `Hi`}, you Account has been Created!
          </h3>
          {registered?.Name ?? `Hi`}, now that you have created an account it's time to sign in so you can
          get started.
          <hr />
          <Login />
        </div>
      )}
      </div>
    </>
  );
};

export default withRouter(RegisterUser);
