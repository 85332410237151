import React, { useState, useEffect, useCallback, Component, useRef } from "react";
import { Editor as Wysiwyg, SyntheticEvent } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState, Modifier } from "draft-js";
import "./react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import Icon, { IconType } from "../Icon/Icon";
import { toast } from "react-toastify";
import { ExpandableImg } from "../Tools/ExpandableDiv";
import { _server } from "../../utils/Server";
import { UiValuePairs } from "../FormManager/FormManagerTypes";
import { AddOnFlexBetween, AddStar } from "./EditorAddons";

interface IEditorProps {
  value: string;
  options: "simple" | "full" |"staff-simple";
  changed:(value:string)=>void;
  height: "normal" | "short";
  onblur?:Function;
  onfocus?:Function;
  readOnly?:boolean;
  hideExtend?:boolean;
}

const Editor = (props: IEditorProps) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    if (props.value) {
      let blocksFromHtml = htmlToDraft(props.value);
      let { contentBlocks, entityMap } = blocksFromHtml;
      let content = ContentState.createFromBlockArray(contentBlocks, entityMap);
      setEditorState(EditorState.createWithContent(content));
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, []);

  const onEditorStateChanged = (state) => {
    setEditorState(state);
    props.changed(toHtml(state));
  };

  const onBlur = ()=>{
    if(props.onblur) props.onblur();
  };
  const onFocus = (e:Event|SyntheticEvent)=>{
    if(props.onfocus) props.onfocus(e);
  };



  const okayStyle = (style:string)=>{
    if(!style) return true;
    if(style.indexOf('bgcolor') == 0) return false;
    
    if(props.options === "staff-simple") return true;
    if(style.indexOf('color') == 0) return false;
    if(style.indexOf('fontsize') == 0) return false;
    if(style.indexOf('fontfamily') == 0) return false;
    return true;
  };

  const toHtml = (state) => {
    let draft = convertToRaw(state.getCurrentContent());
    let reload = false;
    draft.blocks.forEach(b=>{
      
    if(b.inlineStyleRanges){
        let x = b.inlineStyleRanges.length;
        b.inlineStyleRanges = b.inlineStyleRanges.filter(x=>okayStyle(x.style));      
        if(x != b.inlineStyleRanges.length) reload = true;
      }
    });
    //console.log(draft);
    let html = draftToHtml(draft);    
    if(reload) {
      let blocksFromHtml = htmlToDraft(html);
      let { contentBlocks, entityMap } = blocksFromHtml;
      let content = ContentState.createFromBlockArray(contentBlocks, entityMap);
      setEditorState(EditorState.createWithContent(content));
    }

    return html;
  };

  const getOptions = () => {

    switch (props.options) {
      case "full":
        return {
        options: ["inline",
        "blockType",
        //"fontSize",
        // "fontFamily",
        "list",
        "link",
        "textAlign",
        //"image",
        "colorPicker",
        "remove",
        "history",        
      ],
        inline: {
          options: ['bold', 'italic', 'underline'],
        },
        list: {options: ['unordered', 'ordered'], inDropdown:true},
        textAlign: {inDropdown:true, options: ['left', 'center', 'right']},
        };
        break;

      case "simple":
        return {
          options: ["inline",
          // "fontSize",
          "list",
          "textAlign",],
          inline: {
            options: ['bold', 'italic', 'underline'],
          },
          fontSize: {
          },
          list: {options: ['unordered', 'ordered'], inDropdown:false},
          textAlign: {inDropdown:false, options: ['left', 'center', 'right']}
          };
        break;
        break;

      case "staff-simple":
        return {
          options: ["inline",
          "blockType",
          "fontSize",
          "fontFamily",
          "colorPicker",
          "link",
          "list",
          "textAlign",],
          inline: {
            options: ['bold', 'italic', 'underline'],
            inDropdown:true,
          },
          fontSize: {
          },
          list: {options: ['unordered', 'ordered'], inDropdown:true},
          textAlign: {inDropdown:true, options: ['left', 'center', 'right']},          
          customElements:{
            
          }
          };
        break;
    }
  };

  const getHeightClass = ()=>{
    if(props.height === 'short'){
      return 'editor-short';
    }
    return '';
  }

  return (
    <>
    <div className={expanded ? 'wysiwg-expanded':''} onMouseDown={(e)=>{setExpanded(false); e.stopPropagation()}}>
      <div className="wysiwg-container" onMouseDown={(e)=>{e.stopPropagation();}}>
      <Wysiwyg
        editorState={editorState}
        wrapperClassName="wrapper-class"
        editorClassName={`editor-class ${getHeightClass()}`}
        toolbarClassName="toolbar-class"
        toolbar={getOptions()}
        //toolbarCustomButtons={props.options === 'full' ? [<ImagePro />] : []}
        onTab={(event) => {
          
        }}
        onEditorStateChange={onEditorStateChanged}
        onBlur={(e)=>{onBlur()}}
        onFocus={(e)=>{onFocus(e)}}
        readOnly={props.readOnly ?? false}
        // toolbarOnFocus={!expanded}
        // toolbarHidden={true}
        toolbarCustomButtons={
//           props.options === "staff-simple" ? 
//           [<>
//             {!expanded && !props.hideExtend && <span className="click" onClick={()=>{setExpanded(true)}}><Icon type={IconType.expand}/> Expand</span>}
//             {expanded && <span className="click" onClick={()=>{setExpanded(false)}}><Icon type={IconType.expandDown}/> Collapse</span>}
//             </>,
//             <AddOnFlexBetween />
//           ]
// :
          [
          <><span className="expanded-show click" onClick={()=>{setExpanded(false)}}><Icon type={IconType.expandDown}/> Collapse</span><span className="expanded-hide click" onClick={()=>{setExpanded(true)}}><Icon type={IconType.expand}/> Expand</span></>
          ]}
      />
      {expanded && <span className="click" onClick={()=>{setExpanded(false)}}><Icon type={IconType.expandDown}/> Collapse</span>}
      </div>
      </div>
    </>
  );
};

export default Editor;

interface IFileInfo {
  type: "pdf" | "image" | "video" | "text" | "other";
  path: string;
  ext: string;
  urlSource?: string;
}

interface IFilePickerProps{
  fileTypes:'image'|'pdf'|'video'|'all';
  valueSelected:Function;
}

interface IfilePickerResponse {
  files:UiValuePairs[];
}

export const FilePicker = (props:IFilePickerProps) =>{
  const [info, setFileInfo] = useState<IFileInfo>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showOptions, setShowOptions] = useState<boolean>();
  const [files, setFiles] = useState<UiValuePairs[]>([]);

  useEffect(()=>{
    Init();
  },[props.fileTypes]);

  const Init = () =>{

    _server.getApi<IfilePickerResponse>(`../file/clientImages`)
    .then(x=>{
      if(x.Success){
      setFiles(x.Value.files);
      }
      else {
        toast.warning(x.Message);
      }
    });

  };
  const selectExistingFile= (x:UiValuePairs)=>{
    let fi = getFileInfo(x.Value);
    if(props.valueSelected) props.valueSelected(x.Value);
    setFileInfo(fi ?? undefined);
    setShowOptions(false);
  }

  const onChangeFile = async(e) => {
    if (e.target.files) {
      let file = await isFileValid(e.target.files);

      if (!file) {
        e.target.files = null;
        if (fileInputRef.current) fileInputRef.current.value = "";
        return;
      }

      _server.postApi<IfilePickerResponse>(`../File/SaveClientFile`,{},[file.file])
      .then(x=>{
        if(x.Success){
          setFiles(x.Value.files);
        }
        else {
          toast.warning(x.Message);
        }
      });
      
      setFileInfo(file.fileInfo);
      setShowOptions(false);
    }
  };

  const isFileValid = async (files: FileList) => {
    if (!files) return;
    let file = files[0];

    let size = file.size / 1024 / 1024;
    let name = file.name;
    let fileInfo = getFileInfo(file.name);
    if (!fileInfo) return null;

    
    if (fileInfo?.type === 'video') {
      //to big
      if (size > 1024) {
        toast.warning(`The file "${name}" is too large. The max file size is 1 GB`);
        return null;
      }
    } else if (fileInfo?.type === 'pdf') {
      //to big
      if (size > 50) {
        toast.warning(`The file "${name}" is too large. The max file size is 50 MB`);
        return null;
      }
    } else if (size > 20) {
      //to big
      toast.warning(`The file "${name}" is too large. The max file size is 20 MB`);
      console.log(fileInfo);
      return null;
    }
    
    //fileInfo.urlSource = 
    await readURL(file);

    return { file, fileInfo };
  };

  const getFileInfo = (filePath: string | undefined) => {
    if (!filePath || typeof(filePath) !== typeof(' ')) return null;
    
    let ext = filePath.substring(filePath.lastIndexOf(".")).toLowerCase();
    let isPdf = ext === ".pdf";
    let isImage =
      ext === ".jpg" ||
      ext === ".jpeg" ||
      ext === ".png" ||
      ext === ".tif" ||
      ext === ".tiff" ||
      ext === ".gif";
    let isVideo = [".mov", ".mp4"].find((x) => x === ext);
    let isText = [".csv", ".xml", ".txt"].find((x) => x === ext);
    let type:"pdf" | "image" | "video" | "text" | "other" = isPdf
      ? "pdf"
      : isImage
      ? "image"
      : isVideo
      ? "video"
      : isText
      ? "text"
      : "other";

    let result: IFileInfo = { type: type, path: filePath, ext: ext };
    return result;
  };

  const readURL = (file) => {
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.onload = (e) => res(e.target?.result);
      reader.onerror = (e) => rej(e);
      reader.readAsDataURL(file);
    });
  };

  const nameOrFile = (x: UiValuePairs) => {
    let file = getFileInfo(x.Value);
    if (file && file.path) {
      switch (file.type) {
        case "image":
          return <div className="flex-between">
            <div className="thumbnail-1 click"><ExpandableImg imageSrc={[{alt:x.Name, path:file.path}]} name={x.Name} onClick={()=>{selectExistingFile(x)}}  /></div>
            <span className="bumper">{x.Name}</span>
          </div>
        case "pdf":
        case "video":
        case "text":
        case "other":
         return <div className="flex-between">
            <div className="thumbnail-1 click"><div> onClick={()=>{selectExistingFile(x)}} {x.Name}</div></div>
            <span className="bumper">{x.Name}</span>
          </div>
          break;
      }
    }
    return <strong>{x.Name}</strong>;
  };

  return (<>
        <div className="existing-file-container">
        {files.map((x,i)=>{
          return <div key={`efo-${i}`} className="existing-file-item flex-between">
            {nameOrFile(x)} <button type="button" className="btn btn-secondary btn-xs" onClick={()=>{
              selectExistingFile(x);
            }} ><Icon type={IconType.save}/></button>
          </div>
        })}

          
        <div>
          <h4>Upload File</h4>
          Not finding the file you need, upload one.
          <hr/>
          <input
            type="file"
            className="form-control"
            ref={fileInputRef}
            onChange={onChangeFile}
            accept={'.jpg,.png,.jpeg'}
          />
          </div>


        </div>
  </>);

}; 