import { useState } from 'react';
import OrgPageHeader from '../../Components/Organizations/OrgPageHeader';
import { IState, ReduxMapper } from '../../Redux/Redux';

interface IProps extends IState {}

const PrivacyPolicy = (props: IProps) => {
  const [name, setName] = useState('zFairs Project App');

  return (
    <>
      <OrgPageHeader state={props} />
      <div className="document">
        <h4>Privacy Policy</h4>
        <hr />
        <p>
          Protecting your private information is our priority. This Statement of Privacy applies to {name}, and Illuminating Software llc and governs data collection and usage. For the purposes of
          this Privacy Policy, unless otherwise noted, all references to Illuminating Software include project.zfairs.com. The Illuminating Software llc application is a EdTech application. By using
          the Illuminating Software llc application, you consent to the data practices described in this statement.
        </p>
        <p>
          <h5>Collection of your Personal Information</h5>
          In order to better provide you with products and services offered, Illuminating Software llc may collect personally identifiable information, such as your:
          <ul>
            <li>First and Last Name</li>
            <li>E-mail Address</li>
            <li>Phone Number</li>
            <li>Postal Code</li>
          </ul>
             
        </p>
        <p>
          Illuminating Software llc may also collect anonymous demographic information, which is not unique to you, such as your:  
          <ul>
            <li>Age</li>
            <li>Gender</li>
            <li>Race</li>
            <li>Postal Code</li>
            <li>IP Address</li>
          </ul>
             
        </p>
        <p>
          We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use
          certain products or services. These may include: (a) registering for an account; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special
          offers from selected third parties; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services. To wit,
          we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or
          non-personal information in the future.    
        </p>
        <p>
          <h5>Use of your Personal Information</h5>
          Illuminating Software llc collects and uses your personal information to operate and deliver the services you have requested.  
        </p>
        <p>
          Illuminating Software llc may also use your personally identifiable information to inform you of other products or services available from Illuminating Software llc and its affiliates.    
        </p>
        <p>
          <h5>Sharing Information with Third Parties</h5>
          Illuminating Software llc does not sell, rent or lease its customer lists to third parties.    
        </p>
        <p>
          Illuminating Software llc may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All
          such third parties are prohibited from using your personal information except to provide these services to Illuminating Software, and they are required to maintain the confidentiality of
          your information.    
        </p>
        <p>
          Illuminating Software llc may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the
          edicts of the law or comply with legal process served on Illuminating Software llc or the site; (b) protect and defend the rights or property of Illuminating Software; and/or (c) act under
          exigent circumstances to protect the personal safety of users of Illuminating Software, or the public.    
        </p>
        <p>
          <h5>Right to Deletion</h5>
          Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:
          <ul>
            <li>Delete your personal information from our records; and </li>
            <li>Direct any service providers to delete your personal information from their records.</li>
          </ul>
             
        </p>
        <p>
          Please note that we may not be able to comply with requests to delete your personal information if it is necessary to:
          <ul>
            <li>
              Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a
              good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us;
            </li>
            <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity;</li>
            <li>Debug to identify and repair errors that impair existing intended functionality;</li>
            <li>Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law;</li>
            <li>Comply with the California Electronic Communications Privacy Act;</li>
            <li>
              Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when our deletion
              of the information is likely to render impossible or seriously impair the achievement of such research, provided we have obtained your informed consent;
            </li>
            <li>Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us;</li>
            <li>Comply with an existing legal obligation; or </li>
            <li>Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information.</li>
              
          </ul>
        </p>
        <p>
          <h5>Children Under Thirteen </h5>
          Illuminating Software llc collects personally identifiable information from children under the age of thirteen. Illuminating Software collects this information for the following reason(s):
          Using the app and Non-Personally Identifiable Statistics.    
        </p>
        <p>
          COPPA the Children’s Online Privacy Protection Act protects the privacy rights of children under the age of 13 and FERPA Family Educational Rights and Privacy Act are core tenets of
          Illuminating Software LLC privacy policy. Illuminating Software LLC is committed to COPPA and FERPA compliance with a safe, secure, and private online experience for all users. The
          sponsoring organization (client) is responsible to adhere to COPPA, FERPA, and all other federal and state privacy and data protection laws.
        </p>
        <p>Illuminating Software LLC stores all data within the continental United States in our secure geo redundant data centers. Our data centers are SOC II and PCI compliant.</p>
        <p>
          If you are under the age of thirteen, you must ask your parent or guardian for permission to use this application. If you are a parent and you have questions regarding our data collection
          practices, please contact us using the information provided at the end of this Statement of Privacy.
        </p>
        <p>
          <h5>E-mail Communications </h5>
          From time to time, Illuminating Software llc may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general
          communication.    
        </p>
        <p>
          <h5>Changes to this Statement</h5>
          Illuminating Software llc reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending
          a notice to the primary email address specified in your account, by placing a prominent notice on our application, and/or by updating any privacy information. Your continued use of the
          application and/or Services available after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that
          Policy.    
        </p>
        <p>
          <h5>Contact Information</h5>
          Illuminating Software llc welcomes your questions or comments regarding this Statement of Privacy. If you believe that Illuminating Software has not adhered to this Statement, please contact
          Illuminating Software at:    
        </p>
        <p>
          <strong>Illuminating Software llc</strong>
          <br />
          Email Address: info@ISEF.app <br />
          Telephone number: 800-252-0903 <br /> 
        </p>
        <p>Effective as of August 22, 2023    </p>
      </div>
    </>
  );
};

export default ReduxMapper(PrivacyPolicy);
