import { FormatDate } from "../../../utils/Tools";
import { IWorkbookInfo } from "../WorkbookTypes";
import { ICommentResponse } from "./MessageTypes";

interface ICommentProps {
  comment: ICommentResponse;
  info?:IWorkbookInfo;
}

const Comment = (props: ICommentProps) => {
  return (
    <>
      <div className="message-container">
        <div className="flex-between header">
          <span>From: {props.comment.Author}</span>
          <span>{FormatDate(props.comment.CreatedAtUtc, "date")}</span>
        </div>
        <div className="message-body">{props.comment.Message}</div>
      </div>
    </>
  );
};

export default Comment;
