import { useState } from "react";
import { toast } from "react-toastify";
import { connectMap, IState } from "../../../Redux/Redux";
import { _server } from "../../../utils/Server";
import { FormatDate } from "../../../utils/Tools";
import Icon, { IconType } from "../../Icon/Icon";
import { IMilestone, ISigner, ISignRequest } from "../WorkbookTypes";
import { ICommentRequest, ICommentResponse } from "./MessageTypes";
import Comment from './Comment';

interface ICommentWriterProps extends IState {
    signRequest?:ISignRequest,
    mileStone?:IMilestone,
    workbookId?:string,
    signer?:ISigner,
    sourceType:"Unknown"| "System"| "User"| "Organization"| "Workbook";
    onSend?:(msg:ICommentResponse)=>void;
}

interface IForm{
    message?:string
}

const CommentWriter = (props:ICommentWriterProps) =>{
    const [form, setForm] = useState<IForm>({});
    const [sent, setSent] = useState<boolean>(false);
    const [messageResponse, setMessageResponse] = useState<ICommentResponse>();

    const sendComment = () =>{
        if(!form.message)
        {
            toast.warning(`${props.User?.FirstName ?? 'Frank'}, you must provide a comment.`);
            return;
        }
        let request:ICommentRequest = {
            message:form.message,
            workbookId:props.workbookId ?? '',
            links:[],
            SourceType:props.sourceType,
        };

        if(props.mileStone) request.links?.push(props.mileStone.PublicId);
        if(props.signRequest) request.links?.push(props.signRequest.PublicId ?? '');
        if(props.signer) request.links?.push(props.signer.PublicId);
        
        _server.postApi<ICommentResponse>(`../CommunicationApi/Comment`, request)
        .then(x=>{
            if(x.Success){
                setSent(true);
                setMessageResponse(x.Value);
                if(props.onSend) props.onSend(x.Value);
            }else {
                toast.clearWaitingQueue();
                toast.error(x.Message, {autoClose:false});
            }
        });
    };

    return (<>
        {!sent && <div className="headroom form-horiztonal">
            <div><label className="control-label" htmlFor="cm-ta">Comment</label></div>
            <div>
                <textarea 
                id={'cm-ta'}
                rows={5} 
                value={form.message} 
                className="form-control"
                maxLength={10000}
                onChange={(e)=>{setForm({...form,message:e.target.value});}}/>

            </div>
            <div className="headroom">
                <button type="button" className="btn btn-secondary" onClick={sendComment}><Icon type={IconType.send}/> Send</button>
            </div>
            
            </div>}
            {messageResponse && <Comment comment={messageResponse}  />}
    </>);
};

export default connectMap()(CommentWriter);