import React, { useState, useEffect } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { toast } from 'react-toastify';
import { Server } from '../../utils/Server';
import Icon, { IconType } from '../Icon/Icon';
import Modal from '../Modal/Modal';
import FormManagerLanding from './FormManagerLanding';
import { IConfigItemChildType, isFieldGood, isFieldRegexSatisfied, isFieldSatisfied, UiFormField, UiFormSection } from './FormManagerTypes';
import FormSection from './FormSection';

interface IFormModalProps {
  save: Function;
  remove?: Function;
  onUpdate?: Function;
  canSave: boolean;
  canRemove?: boolean;
  sections: UiFormSection[];
  linkToManager?: string;
  linkToManagerText?: string;
  submitBtnText?: string;
  copyItem?: boolean;
  copy?:()=>void;
  show: boolean;
  setShow: Function;
  title: string;
  values: Record<string, string[]>;
  projectFileChanged?: Function;
  targetId: string;
  modalSize: 'm' | 'l' | 'xl' | 'xxl' | 'full';
  modalNoClose?: boolean;
  noEdit?: boolean;
  itemChildren?: IConfigItemChildType[];
  noModal?: boolean;
}

export interface IFileUpload {
  file: File;
  Key: string;
}

const FormModal = (props: IFormModalProps) => {
  const [formErrors, setFormErrors] = useState<Record<string, UiFormField | undefined>>({});
  const [values, setValues] = useState<Record<string, string[]>>({});
  const [sections, setSections] = useState<UiFormSection[]>([]);
  const [noEdit, setNoEdit] = useState<boolean>(false);
  const [files, setFiles] = useState<IFileUpload[]>([]);

  useEffect(() => {
    if (props.canSave && props.targetId && +props.targetId > 0 && props.noEdit) setNoEdit(true);
    else setNoEdit(false);
  }, []);

  useEffect(() => {
    let xSections = props.sections;

    xSections.forEach((x) => {
      x.Fields.forEach((f) => {
        let value = props.values[f.Key];
        if (value) {
          f.Value = value;
        }
      });
    });

    //setFormErrors({});
    setValues(props.values);
    setFiles([]);
    setSections(xSections);
  }, [props.sections, props.values]);

  const projectFileChanged = (x, y, z) => {
    if (props.projectFileChanged) props.projectFileChanged(x, y, z);
  };

  const handleFieldChange = (key: string, value: string[]) => {
    let xValues = { ...values };
    xValues[key] = value;
    setValues(xValues);

    let xSections = [...sections];

    xSections.forEach((x) => {
      x.Fields.forEach((f) => {
        if (f.Key === key) {
          f.Value = value;
        }
      });
    });

    setSections(xSections);
  };

  const fieldOnBlur = (field: UiFormField) => {
    let errors = { ...formErrors };
    let isValid = isFieldGood(field, values);
    errors[field.Key] = isValid.Good ? undefined : field;

    setFormErrors(errors);
  };

  const handleFileChange = (key: string, file?: File) => {
    let xValues = { ...values };
    xValues[key] = [file?.name ?? ''];
    setValues(xValues);

    let xSections = [...sections];

    xSections.forEach((x) => {
      x.Fields.forEach((f) => {
        if (f.Key === key) {
          f.Value = [file?.name ?? ''];
        }
      });
    });

    setSections(xSections);
    let fs = files.filter((x) => x.Key !== key);
    if (file) fs.push({ file: file, Key: key });
    setFiles(fs);
  };

  const handleSelectChangeWithCall = (field: UiFormField, key: string, value: string[]) => {
    console.error('HandleSelectedChangeWithCall -> Not fully implemented.');
    handleFieldChange(key, value);
  };

  const canSave = () => {
    let sections = props.sections;
    let missingFields: string[] = [];
    let foundProblems = false;
    let errors = { ...formErrors };
    sections.forEach((sect) => {
      sect.Fields.forEach((field) => {
        let valid = isFieldGood(field, values);
        if (!valid.Good) {
          foundProblems = true;
          missingFields.push(valid.Message ?? '');
          errors[field.Key] = field;
        } else {
          errors[field.Key] = undefined;
        }
      });
    });
    if (foundProblems) {
      toast.warning(
        <>
          Opps, Some fields need some help.
          <ul>
            {missingFields.map((x, i) => {
              return <li key={`missing-field-${i}`} dangerouslySetInnerHTML={{ __html: x }}></li>;
            })}
          </ul>
        </>
      );
    }
    console.log('can save');
    setFormErrors(errors);
    return !foundProblems;
  };

  const save = () => {
    console.log('saving form modal!');
    //form field requirements meet?
    let pass = canSave();
    if (!pass) {
      return;
    }
    console.log('saving form modal!$$');
    if (props.canSave) props.save(values, files);
  };

  const copyIt = () => {
    if (window.confirm(`Are you sure you want to copy this?`)) {
      if (props.copy) props.copy();
    }
  };

  const remove = () => {
    console.log('removing from form modal!');

    if (props.canRemove && props.remove) {
      props.remove(values);
    }
  };

  const mapForms = () => {
    if (props.targetId !== '-1' && props.itemChildren?.length) {
      return (
        <Tabs>
          <TabList>
            <Tab>Main</Tab>
            {props.itemChildren.map((x, i) => {
              return <Tab key={`tsfms-${i}`}>{x.Name}</Tab>;
            })}
          </TabList>
          <TabPanel>{mapMainForm()}</TabPanel>
          {props.itemChildren.map((x, i) => {
            return <TabPanel key={`tsfmsp-${i}`}>{mapForm(x)}</TabPanel>;
          })}
        </Tabs>
      );
    } else {
      return mapMainForm();
    }
  };

  const mapForm = (child: IConfigItemChildType) => {
    return <FormManagerLanding type={`${child.Type}`} subType={child.Key} id={`${props.targetId}`} onUpdate={props.onUpdate} isChild={true} />;
  };

  const mapMainForm = () => {
    return (
      <div className="form-horizontal">
        {props.sections.map((x, i) => {
          if (noEdit) x.Fields?.forEach((f) => (f.ReadOnly = true));
          return (
            <FormSection
              key={`x-sec-${i}`}
              section={x}
              onInputBlur={fieldOnBlur}
              groupIds={{}}
              formValues={values}
              handleFieldChange={handleFieldChange}
              handleFileChange={handleFileChange}
              handleSelectChangeWithCall={handleSelectChangeWithCall}
              idCalledInApi={props.targetId ?? 'really'}
              targetId={props.targetId ?? 'HI'} //{props.match.params.targetId}
              formErrors={formErrors}
              canSave={props.canSave}
              projectFileChanged={projectFileChanged}
            />
          );
        })}

        <div className="form-group">
          <div className="flex-between">
            <div>
              {props.canSave && !noEdit && (
                <button type="button" className="btn btn-secondary" onClick={save}>
                  <Icon type={IconType.save} /> {props.submitBtnText ?? "Save"}
                </button>
              )}
              {props.canRemove && props.remove && (
                <button type="button" className="btn btn-default" onClick={remove}>
                  <Icon type={IconType.trashO} addClass={'text-danger'} /> Remove
                </button>
              )}
            </div>
            <div>
              {props.copyItem && (
                <button className="btn btn-default" onClick={copyIt}>
                  <Icon type={IconType.copy} /> Copy
                </button>
              )}
              {props.linkToManager && (
                <a className="btn btn-primary" href={Server.BuildUrl(props.linkToManager)}>
                  <Icon type={IconType.cog} /> {props.linkToManagerText ? <span dangerouslySetInnerHTML={{__html:props.linkToManagerText}}></span>: <span>Manager</span>}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {props.show && !props.noModal && (
        <Modal setModalOpen={props.setShow} title={props.title} size={props.modalSize} noClose={props.modalNoClose}>
          {mapForms()}
        </Modal>
      )}
      {props.show && props.noModal && <>{mapForms()}</>}
    </>
  );
};

export default FormModal;
