import axios, { AxiosResponse } from "axios";
import { Interface } from "readline";
import redux from "../Redux/Redux";
import { IUser } from "../Types/basic";
import { IProject } from "../Types/Project";
import { Template } from "../Types/Template";

export type IFileInfo = {
  type: "pdf" | "image" | "video" | "text" | "other";
  path: string;
  ext: string;
};

export type ServerResponse<T> = {
  Success: boolean;
  LoginRequired: boolean;
  Message: string;
  Value: T;
};

export class ServerCallOptions {
  loadingScreenShow: boolean = true;
  loadingScreenText: string = "loading";
}

export class Server {
  loadingScreen: boolean;
  isDev: boolean = false;

  static iframeReady: boolean = false;
  static preMessageQueue: any = [];
  static messageQueue: any = {};
  static iframe: any;
  static loaded: boolean = false;
  static messageId: number = 0;
  static queryStringAddParam: any = null;
  static loadingFunction: Function;
  public static instance: Server;

  constructor(loadingScreen?: any) {
    this.loadingScreen = loadingScreen;

    let isDev = (this.isDev = window.location.host.indexOf("localhost:") > -1);
    let params = new URLSearchParams(window.location.search);
    let fairId = params.get("f");
    if (fairId) {
      Server.queryStringAddParam = `f=${fairId}`;
    }

    if (isDev && !Server.loaded) {
      window.addEventListener("message", Server.iframeResponse, false);      
      if (!Server.iframe) {
        Server.iframe = document.getElementById("fms-shim");
      }
    }

    if(!Server.loaded){
      Server.instance = this;
      this.loggedIn();
    }


    Server.loaded = true;
  }

  static FileInfo(filePath: string | undefined) {
    if (!filePath) return null;
    let ext = filePath.substring(filePath.lastIndexOf(".")).toLowerCase();
    let isPdf = ext === ".pdf";
    let isImage =
      ext === ".jpg" ||
      ext === ".jpeg" ||
      ext === ".png" ||
      ext === ".tif" ||
      ext === ".tiff" ||
      ext === ".gif";
    let isVideo = [".mov", ".mp4"].find((x) => x === ext);
    let isText = [".csv", ".xml", ".txt"].find((x) => x === ext);
    let type:"pdf" | "image" | "video" | "text" | "other" = isPdf
      ? "pdf"
      : isImage
      ? "image"
      : isVideo
      ? "video"
      : isText
      ? "text"
      : "other";

    let result: IFileInfo = { type: type, path: filePath, ext: ext };
    return result;
  }
  static BuildUrl(path: string) {
    if (path.indexOf("http:") === 0 || path.indexOf("https:") === 0)
      return path;
    let isDev = window.location.host.indexOf("localhost:") > -1;
    let host = isDev ? Server.GetDevHost(): window.location.origin;
    path = path.replace("../", "/");
    return `${host}${path}`;
  }
  public static FileUrl(fileKey: string, scale?:number) {
    let path = `../file/x/${fileKey}`;
    if(scale) path += `?x=${scale}`
    if (path.indexOf("http:") === 0 || path.indexOf("https:") === 0)
      return path;
    let isDev = window.location.host.indexOf("localhost:") > -1;
    let host = isDev ? Server.GetDevHost(): window.location.origin;
    path = path.replace("../", "/");
    return `${host}${path}`;
  }

  static BuildUrlWithFair(path: string) {
    let isDev = window.location.host.indexOf("localhost:") > -1;
    let host = isDev ? Server.GetDevHost() : window.location.origin;
    path = path.replace("../", "/");
    let hasQueryString = path.indexOf('?') > -1;
    
    return `${host}${path}${hasQueryString?'&':'?'}f=${this.GetQueryStringValue("f")}`;
  }

  public static GetDevHost(){
    
    let params = new URLSearchParams(window.location.search);
    const host = params.get('host') ?? 'http://localhost:9000';
    return host;
  }

  public static GetUrl(url:string){
    url = url.replace('../','');
    if(window.location.origin.indexOf('localhost') > -1){
      return `http://localhost:9000/${url}`;
    }
    return `${window.location.origin}/${url}`;
  }

  public loggedIn=()=>{
    this.getApi<IHelloResponse>('../UserApi/Hello').then(x=>{
      if(x.Value.loggedIn){
        console.log(x);
        redux.setUser(x.Value.user);
        redux.setBaseUser(x.Value.baseUser);
      } else {
        console.log('failed',x);
        redux.setUser(null);
        redux.setBaseUser(null);
      }
    });
  }

  public async GetDataUrl(url:string) {
    if (this.loadingScreen) {
      //this.startLoading();
    }
    if (this.isDev) {
      let result = await Server.callParentShim<any>(false, this.makeUrl(url), null).catch(error => { throw error })
      if (this.loadingScreen) {
        //this.endLoading();
      }
      return result;
    }

    let resp =  await axios
      .get(this.makeUrl(url));

      if(this.loadingScreen){
        //this.endLoading();
      }

      return URL.createObjectURL(resp.data);
  };


  public async getApi<T>(url: string): Promise<ServerResponse<T>> {
    if (this.loadingScreen) {
      this.startLoading();
    }
    if (this.isDev) {
      let result = await Server.callParentShim<T>(false, this.makeUrl(url), null).catch(error => { 
        console.log('ERROR!!!', error);
        throw error })
      if (this.loadingScreen) {
        this.endLoading();
      }
      return result;
    }

    let resp =  await axios
      .get<ServerResponse<T>>(this.makeUrl(url));

      if(this.loadingScreen){
        this.endLoading();
      }

      return resp.data;
  }

  
  public async getApiQuiet<T>(url: string): Promise<ServerResponse<T>> {
    if (this.isDev) {
      let result = await Server.callParentShim<T>(false, url, null).catch(error => { throw error })
      if (this.loadingScreen) {
        this.endLoading();
      }
      return result;
    }

    return await axios
      .get(this.makeUrl(url))
      .then((x: any) => {
        return x.data;
      })
      .catch((x: any) => {
        console.log(`Error on GET: ${url}`, x);
      });
  };


  public async postApi<T>(url: string, data: any, filesArray: File[] = []): Promise<ServerResponse<T>> {
    if (this.loadingScreen) {
      this.startLoading();
    }
    if (this.isDev) {
      let result = await Server.callParentShim<T>(true, this.makeUrl(url), data, filesArray);
      //console.log('post api shim', result);
      if (this.loadingScreen) {
        this.endLoading();
      }
      return result;
    }
    if (filesArray.length) {
      return await this.postWithFiles<T>(data, filesArray, url);
    } else {
      return await this.postNoFiles<T>(url, data);

    }
  }

  private async postWithFiles<T>(data: any, filesArray: File[], url: string) : Promise<ServerResponse<T>> {
    //TODO:  This needs to be tested.  Not sure if it will work.  Do we need to set content type for each item in the multi part form data?
    const formData = new FormData();
    Object.entries<any>(data).forEach((entry) => {
      formData.append(entry[0], JSON.stringify(entry[1]));
    });
    filesArray.forEach(f => {
      formData.append('file', f, f.name);
    });
    let result = await axios.post<ServerResponse<T>>(this.makeUrl(url), formData, { 
      headers:{"Content-Type": "multipart/form-data"},
      onUploadProgress:(event:ProgressEvent)=> {
        redux.uploadProgress(event);
      },
    })
    .catch((x) => {
      if (this.loadingScreen) {
        this.endLoading();
      }
      console.log(`Error on POST: ${url}`, x);
      throw x;
    });

    if (this.loadingScreen) {
      this.endLoading();
    }
    return result.data;
  }

  private async postNoFiles<T>(url: string, data: any) : Promise<ServerResponse<T>> {
    let result = await axios
      .post<ServerResponse<T>>(this.makeUrl(url), data, {
        headers: { "Content-Type": "application/json; charset=utf-8" },
      })
      .catch((x) => {
        if (this.loadingScreen) {
          this.endLoading();
        }
        console.log(`Error on POST: ${url}`, x);
        throw x;
      });
    if (this.loadingScreen) {
      this.endLoading();
    }
    return result.data;
  }

  
  public async postApiQuite<T>(url: string, data: any): Promise<ServerResponse<T>> {
    if (this.isDev) {
      let result = await Server.callParentShim<T>(true, this.makeUrl(url), data);
      return result;
    }
    let result = await axios
      .post<ServerResponse<T>>(this.makeUrl(url), data, {
        headers: { "Content-Type": "application/json; charset=utf-8" },
      })
      .catch((x) => {
        console.log(`Error on POST: ${url}`, x);
        throw x;
      });
    return result.data;
  }
  /**This overvariation of postApi handles server response errors.  If serverResponse.Success === false, it will throw an error. */
  public async postApiWithServerResponse<T>(url: string, data: any, filesArray: File[] = []): Promise<ServerResponse<T>> {
    let response = await this.postApi<T>(url, data, filesArray).catch(error => { throw (error); });
    if (!response.Success) {
      throw response.Message;
    }
    return response;
  }

  public makeUrl(url: string|null|undefined): string {
    if(!url) return '';
    if(url && url[0] === 'd' && url[1] === 'a'&& url[2] === 't'&& url[3] === 'a'&& url[4] === ':') return url;
    url = url.trim();

    if(url.toLocaleLowerCase().indexOf('http') === 0) return url;
    
    if (url.indexOf("/") === 0 || url.indexOf("\\") === 0) {
      url = url.substr(1);
    }

    if (this.isDev) {
      //return "https://national.zfairs.com/" + url;
      url = "http://localhost:9000/" + url;

      if (Server.queryStringAddParam) {
        if (url.indexOf("?") > -1) {
          url += `&${Server.queryStringAddParam}`;
        } else {
          url += `?${Server.queryStringAddParam}`;
        }
      }

      return url;
    } else {
      while(url[0] === '.' || url[0] === '/') url = url.substr(1);
      url = `${window.location.origin}/${url}`;
      if (Server.queryStringAddParam) {
        if (url.indexOf("?") > -1) {
          url += `&${Server.queryStringAddParam}`;
        } else {
          url += `?${Server.queryStringAddParam}`;
        }
      }
    }

    return url;
  }

  private static async iframeResponse(event: any) {
    if (event.data.type !== "shim") {
      if (event.data.type === "shim-ready") {
        Server.iframeReady = true;
        //send all messages that where waiting...
        Server.preMessageQueue.forEach((call: any) => {
          call();
        });
        Server.preMessageQueue = [];
      }
      else if (event.data.type === "progress"){                
        let data = await event.data;
        let {loaded,total} = data.msg;
        redux.uploadProgress(data.msg);
      }

      return;
    }
    let { msg, id } = await event.data;
    let callback = Server.messageQueue[id];
    if (callback) callback(msg);
  }

  private static callParentShim<T>(post: boolean, url: string, data: any, filesArray: File[] = []): Promise<ServerResponse<T>> {
    let id = Server.messageId + url;
    Server.messageId++;

    let promiseExecutor = (resolve: any, reject: any) => {
      let callback = (response: any) => {
        //console.log('callback response: ', response)
        resolve(response);
      };

      Server.messageQueue[id] = callback;
      if (!Server.iframe) {
        Server.iframe = document.getElementById("fms-shim");
      }
      let call = () => {
        Server.iframe.contentWindow.postMessage(
          {
            type: "shim",
            id,
            action: post ? "post" : "get",
            data,
            url: url,
            filesArray: filesArray
          },
          "*"
        );
      };

      if (Server.iframeReady) {
        call();
      } else {
        this.preMessageQueue.push(call);
        console.log(`Shim not ready`);
      }
    };


    return new window.Promise(promiseExecutor);
  }

  
 private endLoading = () => {
  Server.loadingFunction?.(false);
}

private startLoading = () => {
  Server.loadingFunction?.(true);
}

public static selectedTemplate:Template|undefined|null;
public static selectedProject:IProject|undefined|null;
public static SetCurrentProject(template: Template|undefined|null, project: IProject|undefined|null) {
  this.selectedProject = project;
  this.selectedTemplate = template;
}
public static GetQueryStringValue(key: string) {
  let params:any = new URLSearchParams(window.location.search);
  const newParams = new URLSearchParams();
  for (const [name, value] of params) {
    newParams.append(name.toLowerCase(), value);
  }
  return newParams.get(key.toLowerCase());
}

}

export const _server = new Server(true);

interface IHelloResponse {
  loggedIn:boolean,
  user:IUser,
  baseUser:IUser,
}