export interface IWorkbookTemplateConfigDetails {
  Name: string;
  Id: number;
  PublicId: string;
  Type: string;
  DataPointsSummary: IFormItemSummary[];
  ElementsSummary: IFormItemSummary[];
  FormGroupsSummary: IFormItemSummary[];
  MilestonesSummary: IFormItemSummary[];
  PagesSummary: IFormItemSummary[];
  SignersSummary: IFormItemSummary[];
  DataPointCalculationsSummary: IFormItemSummary[];
  NotificationsSummary: IFormItemSummary[];
  ThemesSummary: IFormItemSummary[];
  FaqsSummary: IFormItemSummary[];
  ReviewGroupsSummary: IFormItemSummary[];
  Faqs: IFaq[];
  ReviewGroups: IReviewGroups[];
  DataPoints: IDataPoint[];
  Elements: IElement[];
  FormGroups: IFormGroup[];
  Milestones: IMilestone[];
  Pages: IPage[];
  Signers: ISigner[];
  Themes: ITheme[];
  Sets: ISet[];
  DataPointCalculations: ICalculation[];
  Template: IWorkbookTemplate;
}
export interface IWorkbookTemplate {
  AllowDownloadOfPdf: boolean;
  ThumbnailImage: string;
  Content: string;
  CreatedAtUtc: string;
  Description: string;
  PublicId: string;
  Name: string;
  Id: number;
  ItemKey: string;
  MaxNumberOfOwners: number;
  Public: boolean;
  HideMessage: boolean;
  Preivew: string;
  Header: string;
  Footer: string;
  BackgroundImage: string;
  StyleTheme: string;
  DoneHeader: string;
  DoneFooter: string;
  MarkAsDone: boolean;
  MarkAsDoneBtnText: string;
  MarkAsDoneUndoBtnText: string;
  MarkAsDoneDescription: string;
  MarkAsDoneDescriptionUndo: string;
  MarkAsPublished: boolean;
  MarkAsPublishedBtnText: string;
  MarkAsPublishedUndoBtnText: string;
  MarkAsPublishedDescription: string;
  MarkAsPublishedDescriptionUndo: string;
}

export interface IFaq {
  Answer: string;
  SortOrder: number;
  Name: string;
  Color: string;
  PublicId: string;
}

export interface IReviewGroups {
  Answer: string;
  SortOrder: number;
  Name: string;
  Color: string;
  PublicId: string;
}

export interface IDataPoint {
  _childLink?: IObjectChild;
  WorkbookProperType: string;
  IsComputed: boolean;
  Calculation: string;
  Description: string;
  IsSet: boolean;
  ItemKey: string;
  Label: string;
  MaxLength: number;
  Name: string;
  PdfFieldKey?: string;
  PdfFieldOffValue?: string;
  PdfFileName?: string;
  PdfFileNameNoExt?: string;
  PublicId: string;
  Required: boolean;
  RequiredNotEnforced: boolean;
  Signer: string[];
  SortOrder: number | null | undefined;
  Type: string;
  WorkbookSpecialType: string;
  Options: string[];
  ValueOf?: string;
  ValueFromOwner?: string;
  Placeholder?: string;
  RegExFieldValidator?: string;
  RegExCustomFieldValidator?: string;
  RegExValidationFailedMessage?: string;
}
export interface ICalculation {
  Id: number;
  PublicId: string;
  ItemKey: string;
  CreatedAtUtc: string;
  WorkbookTemplateId: number;
  RemovedAtUtc?: string;
  SortOrder: number;
  Name: string;
  Color: string;
  Description: string;
  CompareToValue: string;
  Delimiter: string;
  BasicFunction: string;
  DataPoints: string[];
  TrueWhenTrue: string[];
  TrueWhenTrueOr: boolean;
  TrueWhenFalse: string[];
  TrueWhenFalseOr: boolean;
  CalculatedValue: string;
}
export interface IElement extends ICanShowElement {
  WorkbookProperType: string;
  Color: string;
  Content: string;
  ContentLabel: string;
  ContentFile: string;
  Description: string;
  ElementType: string;
  ItemKey: string;
  Name: string;
  PublicId: string;
  ReadOnly: boolean;
  SortOrder: number | null | undefined;
  IndentLevel: number;
}
export interface IFormGroup {
  WorkbookProperType: string;
  Children: IObjectChild[];
  Color: string;
  Description: string;
  Footer: string;
  Header: string;
  ItemKey: string;
  Name: string;
  PublicId: string;
  SortOrder: number | null | undefined;
}
export interface IMilestone {
  WorkbookProperType: string;
  AfterDate: string;
  Approvers: string[];
  BeforeDate: string;
  Color: string;
  Description: string;
  Footer: string;
  Header: string;
  ItemKey: string;
  Name: string;
  OnNudgeActions: string[];
  OnPassedActions: string[];
  OnReachActions: string[];
  PublicId: string;
  SortOrder: number | null | undefined;
  SubscriptionsNeeded: string[];
  StartPage: string;
  Type: 'approval' | 'reach';
}

export interface ICanShowElement {
  _validPath?: boolean;
  showWhenFalse: string[];
  showWhenTrue: string[];
  showWhenFalseOr: boolean;
  showWhenTrueOr: boolean;
}

export interface IPage extends ICanShowElement {
  _milestonesState: MilestoneStates;
  _notYet: boolean;
  _milestones: IMilestone[];
  WorkbookProperType: string;
  ThumbnailPath: string | null | undefined;
  ThumbnailImage?: string;
  _canAccess?: boolean;
  _nextPagesAll?: IPage[];
  _missingDataPoints?: IDataPoint[];
  _requiredDataPoints?: IDataPoint[];
  ChildrenPages: IObjectChild[];
  Elements: IObjectChild[];
  Color: string;
  CreatedAtUtc: string;
  Description: string;
  ItemKey: string;
  Name: string;
  ReadOnly: boolean;
  PublicId: string;
  confirmNextButtonText: string;
  nextButtonText: string;
  requiresSubscription: string[];
  selectPageButtonText: string;
  SortOrder: number | null | undefined;
  skipToNextWhenNotShown: boolean;
  Preview: string;
  Footer: string;
  Header: string;
  Signer: string;
  SetId?: string;
  _setGroupKey?: string;
  BackgroundImage?: string;
  StyleTheme?: string;
  NextPageIsDonePage?: boolean;
}
export interface ISigner {
  WorkbookProperType: string;
  Color: string;
  Description: string;
  OwnerInstructions: string;
  Instructions: string;
  IsSignerUser: boolean;
  IsSetBasedOnOwner: boolean;
  ItemKey: string;
  Name: string;
  PublicId: string;
  WorkbookTemplateSignerRoles: string[];
  SortOrder: number | null | undefined;
  StartPage: string;
  ReviewGroups: string[];
  _ownerId?: string;
}
export interface ITheme {
  WorkbookProperType: string;
  Color: string;
  Css: string;
  Description: string;
  ItemKey: string;
  Name: string;
  PublicId: string;
  SortOrder: number | null | undefined;
}
export interface ISet {
  WorkbookProperType: string;
  Color: string;
  Description: string;
  ItemKey: string;
  Name: string;
  PublicId: string;
  SortOrder: number | null | undefined;
  OwnersAreSet: boolean;
  Header: string;
  Content: string;
  Limit: number;
  AddLabel?: string;
  EditLabel?: string;
  CloseLabel?: string;
  SetNameFromDataPoint?: string;
  SetDeletedWhenDataPointTrue?: string;
}

export interface IFormItemSummary {
  Name?: string;
  Id?: string;
  Description?: string;
  SortBy?: string;
  NoEdit?: boolean;
}

export interface IDataPoints {
  Name?: string;
  Id?: string;
  Description?: string;
  SortBy?: string;
  NoEdit?: boolean;
}
export interface IElements {
  Name?: string;
  Id?: string;
  Description?: string;
  SortBy?: string;
  NoEdit?: boolean;
}
export interface IFormGroups {
  Name?: string;
  Id?: string;
  Description?: string;
  SortBy?: string;
  NoEdit?: boolean;
}
export interface IMilestones {
  Name?: string;
  Id?: string;
  Description?: string;
  SortBy?: string;
  NoEdit?: boolean;
}
export interface IPages {
  Name?: string;
  Id?: string;
  Description?: string;
  SortBy?: string;
  NoEdit?: boolean;
}
export interface ISigners {
  Name?: string;
  Id?: string;
  Description?: string;
  SortBy?: string;
  NoEdit?: boolean;
}
export interface IThemes {
  Name?: string;
  Id?: string;
  Description?: string;
  SortBy?: string;
  NoEdit?: boolean;
}

export interface IWorkbookInfo {
  LastPages: string[];
  Orgs: string[];
  Owners: IOwner[];
  SignRequests: ISignRequest[];
  ParentPages: string[];
  Preview: boolean;
  Template: IWorkbookTemplateConfigDetails;
  Values: Record<string, Record<string, IDataValue>>;
  VisitedPages: string[];
  WorkbookId: string;
  WorkbookTemplateId: string;
  TemplateDic: Record<string, any>;
  Signer: string;
  Name: string;
  SignerNotAllowedToSign: boolean;
  MarkedDoneAtUtc: string;
  PublishedAtUtc: string;
  ReadyOnlyAtUtc: string;
}

export interface IOwner {
  Email: string;
  FirstName: string;
  JsonDetails: string;
  LastName: string;
  PhoneDigits: string;
  PublicId: string;
  Sms: boolean;
  Id: number;
  PrimaryOwner: boolean;
}

export interface ISignRequest {
  Id?: number;
  PublicId?: string;
  SignerId?: string;
  OwnerId?: string;
  MilestoneId?: string;
  WorkbookId?: string;
  WorkbookTemplateId?: string;
  RequestMessage?: string;
  RequestTextMessage?: string;
  FirstName?: string;
  LastName?: string;
  Email?: string;
  Phone?: string;
  Sms?: boolean;
  RequestedAtUtc?: string;
  ApprovedAtUtc?: string;
  RemovedAtUtc?: string;
  MessageCount?: number;
  UnreadMessageCount?: number;
  Status?: string;
  EmailSent?: boolean;
  TextSent?: boolean;
  DirectSignLink?: string;
  RequestSeen?: string;
  RequestType?: string;
  SignedByUserPublicId?: string;
  ReviewGroupRequest?: boolean;
  ReviewGroupOrgPublicId?:string;
  ReviewGroupTemplatePublicId?:string;
}

export interface IWorkbookReviewGroupInfo {
  ReviewGroupId: number;
  ReviewGroupPublicId: string;
  ReviewGroupName: string;
  WorkbookId: number;
  WorkbookPublicId: string;
  OrgId: number;
  OrgPublicId: string;
  OrgName: string;
}

export interface IWorkbookSaveRequest {
  WorkbookId?: string;
  DataPointId?: string;
  Value?: string[];
  WorkbookTemplateId: string;
  SetId?: string;
  Files?: File[];
  SetGroupKey?: string;
  Done?: boolean;
  Publish?: boolean;
  Undo?: boolean;
}

export interface IWorkbookSaveResponse {
  WorkbookId?: string;
  DataPointId?: string;
  Value?: IDataValue;
  WorkbookTemplateId?: string;
  SetId?: string;
  SetGroupKey?: string;
  Files?: File[];
}

export interface IDataValue {
  Type?: string;
  Value: string;
}

export interface IObjectChild {
  Id: string;
  Type: string;
  Order: number;
  LabelOverride?: string;
}

export interface IWorkbookSummary {
  Id: number;
  PublicId: string;
  CreatedAtUtc: string;
  RemovedAtUtc: string;
  Name: string;
  UserId: number;
  PrimaryOwner: boolean;
  ViewOnly: boolean;
  TemplateName: string;
  TemplateId: string;
  TemplatePublicId: string;
  LastModifyAtUtc: string;
  NumberOfUpdates: number;
}

export interface IWorkbookTemplateSummary {
  Id: number;
  PublicId: string;
  CreatedAtUtc: string;
  RemovedAtUtc: string;
  Name: string;
  Description: string;
  MaxNumberOfOwners: number;
  MaxNumberOfWorkbooks: number;
  ThumbnailImage: string;
  WorkbookTemplateSigners: IWorkbookTemplateSigners[];
  WorkbookTemplateReviewGroups: IWorkbookTemplateReviewGroups[];
}

export interface IWorkbookTemplateSigners {
  Color: string;
  CreatedAtUtc: string;
  Description: string;
  Details: Record<string, string>;
  Id: number;
  Instructions: string;
  IsSetBasedOnOwner: string;
  IsSignerUser: string;
  ItemKey: string;
  JsonDetails: string;
  Name: string;
  OwnerInstructions: string;
  PublicId: string;
  RemovedAtUtc: string;
  SortOrder: string;
  StartPage: string;
  ReviewBoards: string[];
  WorkbookProperType: string;
  WorkbookSignRequests: string;
  WorkbookTemplate: string;
  WorkbookTemplateId: string;
  WorkbookTemplateSignerRoles: string;
}

export interface IWorkbookTemplateReviewGroups {
  Color: string;
  CreatedAtUtc: string;
  Description: string;
  Details: Record<string, string>;
  Id: number;
  PublicId: string;
  Name: string;
}

export enum MilestoneStates {
  NA = 0,
  NotStarted = 1,
  Started = 2,
  Completed = 3,
}

export interface IInviteOwnerResponse {
  Link: string;
  AccountFoundAndLinked: boolean;
  Name: string;
}


export interface IOrgSharedInfo {
  Name: string;
  PublicId: string;
  Url: string;
  Description:string;
  CreatedAtUtc:string;
  RemovedAtUtc:string;
}