import logo from './logo.svg';
import routes from './routes'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './Component.css';
import './theme.css';
import Loading from './Components/Loading/loading';
import { useEffect, useState } from 'react';
import { Server, _server } from './utils/Server';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-tabs/style/react-tabs.css";
import Menu from './Components/Menu/Menu';
import Icon, { IconType } from './Components/Icon/Icon';
import redux, { connectMap, IState } from './Redux/Redux';
import LoginOrRegister from './Components/User/LoginOrRegister';
import { IOrganizationInfo } from './Components/Organizations/OrganizationTypes';


function App(props:IState) {
  const [loading, setLoading] = useState(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);

  Server.loadingFunction = (loading: boolean) => {
    setLoading(loading);
  }

  useEffect(() => {
    const server = new Server();
    //server.loggedIn();
    loadOrgInfo();

    const terms = window.location.search?.toLowerCase().substring(1).split('&');
    if(terms && terms.length > 0){
      const register = terms.find(x=>x==='register') ? true: false;
      const signin = terms.find(x=>x==='signin') ? true: false;

      if(register){
        redux.showLogin(true);
      }
      else if(signin){
        redux.showLogin(true);
      }
    }

  }, []);

  const loadOrgInfo = () => {
    if(!props.OrgInfo)
    _server.getApi<IOrganizationInfo>(`../OrgApi/Info`)
    .then(x=>{
        if(x.Success){
          let info = x.Value;
            redux.setOrgInfo(x.Value);             
          if(info.SiteTitle) window.document.title = info.SiteTitle;
          if(info.MetaDescription) window.document.querySelector('meta[name="description"]')?.setAttribute("content", info.MetaDescription);
          if(info.MetaKeywords) window.document.querySelector('meta[name="keywords"]')?.setAttribute("content", info.MetaKeywords);
        } else {
            toast.error(x.Message, {autoClose:false});
        }
    });
  };

  useEffect(() => {
  }, [loading])

  return (
    <>
    {props.OrgInfo?.CssText && <style>{props.OrgInfo.CssText}</style>}
    <div className="orkboo container" style={{backgroundImage:props.OrgInfo?.BackgroundImage ? `url('${props.OrgInfo?.BackgroundImage}')` : `none`}}>
      {!props.HasNav && <div className="menu-switch">
        <span className="icon-btns" 
          title="view menu"
          onClick={()=>{redux.setShowNav(true)}}
        >
          <Icon type={IconType.bars}/>
        </span>
      </div>}
      {props.ShowNav && <Menu
        menuItems={[]}
        close={()=>{redux.setShowNav(false);}}
       />}
      {routes}
      {loading && <Loading /> }
      <ToastContainer position={'top-center'} />
      <LoginOrRegister lite={true} show={props.ShowLogin ?? false} canClose={true}></LoginOrRegister>
    </div>
    </>
  );
}

export default connectMap()(App);







