import { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import redux, { connectMap, IState } from '../../Redux/Redux';
import { Server, _server } from '../../utils/Server';
import { FormatDate } from '../../utils/Tools';
import AppName from '../BasicApp/AppName';
import Icon, { IconType } from '../../Components/Icon/Icon';
import { IWorkbookSaveRequest, IWorkbookSummary, IWorkbookTemplateSummary } from '../../Components/WorkbookTemplate/WorkbookTypes';
import OrgPageHeader from '../../Components/Organizations/OrgPageHeader';

export interface IMine {
  MyWorkbooks: IWorkbookSummary[];
  Templates: IWorkbookTemplateSummary[];
}

interface IUserLandingProps extends RouteComponentProps, IState {}

const UserLanding = (props: IUserLandingProps) => {
  useEffect(() => {
    _server.getApi<IMine>(`../WorkbookApi/Mine`).then((x) => {
      if (x.Success) {
        redux.setUsersWorkbooks(x.Value.MyWorkbooks);
        redux.setTemplates(x.Value.Templates);
      } else {
        toast.error(x.Message, { autoClose: false });
      }
    });
  }, [props.User]);

  const onOpen = (x: IWorkbookSummary) => {
    let url = `/workbook/${x.PublicId}`;
    props.history.push(url);
  };

  const onEdit = (x: IWorkbookSummary, e: any) => {
    e.stopPropagation();
    e.preventDefault();
    let url = `/workbook/start/${x.PublicId}`;
    props.history.push(url);
  };

  const startNew = (template: IWorkbookTemplateSummary) => {
    if (
      template.MaxNumberOfWorkbooks > 0 &&
      props.User &&
      (props.UsersWorkbooks?.filter((x) => !x.RemovedAtUtc && x.TemplatePublicId === template.PublicId).length ?? 0) >= template.MaxNumberOfWorkbooks
    ) {
      toast.dark(`${props.User.FirstName}, sorry you've reached your limit of these templates.`);
      return;
    }

    let url = `/workbook/new/${template.PublicId}`;
    props.history.push(url);

    return;
  };

  return (
    <>
    {!(props.OrgInfo ?? false) && (
      <>
        <div className="bumper flex-middle full-page">
          <div>
            <h3>Hold On!</h3>
            We're getting things ready.
            <hr />
          </div>
        </div>
      </>
    )}
    
    {props.OrgInfo && <OrgPageHeader state={props} org={props.OrgInfo} />}

      {props.OrgInfo && props.OrgInfo?.SubOrgHeader && <div className="org-header alert alert-default" dangerouslySetInnerHTML={{ __html: props.OrgInfo?.SubOrgHeader }}></div>}
      {props.OrgInfo && props.OrgInfo?.Header && <div className="org-header" dangerouslySetInnerHTML={{ __html: props.OrgInfo.Header }}></div>}
      
    
    { props.OrgInfo &&  !(props.OrgInfo.Header) && (
      <>
        <div className="org-header">
          <div>
            <h3>Welcome to OrkBoo</h3>
            Orkboo at it's simplest form is digital workbook. In fact the name OrkBoo is just everthing in side of wOrkBook.
            <br/>
            Below are listed some workbooks tiles. Click a workbook tile to learn more about that workbook. To start a new workbook or to continue an existing workbook you have. You'll need to sign in.
            <hr />
          </div>
        </div>
      </>
    )}


      <div className="workbook-preview-container">
        {props.UsersWorkbooks &&
          props.UsersWorkbooks.filter((x) => !x.RemovedAtUtc).map((x, i) => {
            let template = props.WorkbookTemplates?.find((t) => t.PublicId === x.TemplatePublicId);
            //return <div className="workbook-preview" key={`u-wb-p-${i}`} onTouchEnd={()=>{onOpen(x)}} onMouseUp={()=>{onOpen(x)}}>
            return (
              <div className="workbook-preview" key={`u-wb-p-${i}`} onTouchEnd={() => {}} onMouseUp={() => {}}>
                <div
                  className="wb-tile-edit click"
                  onTouchEnd={(e) => {
                    onEdit(x, e);
                  }}
                  onMouseUp={(e) => {
                    onEdit(x, e);
                  }}>
                  <Icon type={IconType.cog} addClass="fa-2x" />
                </div>

                {template?.ThumbnailImage && (
                  <div className="wb-thumbnail">
                    <img src={Server.BuildUrl(`../file/x/${template.ThumbnailImage}?x=200x125`)} />
                  </div>
                )}
                <h3>{x.Name}</h3>
                {/* <h4>{x.TemplateName}</h4> */}
                <div>Last Update: {FormatDate(x.LastModifyAtUtc)}</div>
                <div className="workbook-tile-button">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      onOpen(x);
                    }}>
                    <Icon type={IconType.folderOpen} /> Open
                  </button>
                </div>
                {x.RemovedAtUtc && <div className="top-of-tile">Deleted at {FormatDate(x.RemovedAtUtc)}</div>}
              </div>
            );
          })}
        {props.WorkbookTemplates && props.WorkbookTemplates.length > 0 &&
          props.WorkbookTemplates.map((x, i) => {
            //return <div className="workbook-preview new" key={`u-wb-n-${i}`} onTouchEnd={()=>{startNew(x)}} onMouseUp={()=>{startNew(x)}}>
            return (
              <div className="workbook-preview new" key={`u-wb-n-${i}`} onTouchEnd={() => {}} onMouseUp={() => {}}>
                {x.ThumbnailImage && (
                  <div className="wb-thumbnail">
                    <img src={Server.BuildUrl(`../file/x/${x.ThumbnailImage}?x=200x125`)} />
                  </div>
                )}
                {/* <div className="top-of-tile">New / Click to Start</div> */}
                {/* <h1><Icon type={IconType.new}/> New</h1> */}
                <h3 className="screen">{x.Name}</h3>
                {x.Description && <div className="screen" dangerouslySetInnerHTML={{ __html: x.Description }}></div>}
                <div className="workbook-tile-button">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      startNew(x);
                    }}>
                    <Icon type={IconType.new} /> Start Now
                  </button>
                </div>
              </div>
            );
          })}
      </div>

      {props.OrgInfo && props.OrgInfo?.Footer && <div className="org-footer" dangerouslySetInnerHTML={{ __html: props.OrgInfo.Footer }}></div>}
      {props.OrgInfo && props.OrgInfo.MissionStatement && <div className="org-mission-statement" dangerouslySetInnerHTML={{ __html: props.OrgInfo.MissionStatement }}></div>}
    </>
  );
};

export default connectMap()(withRouter(UserLanding));
