import React, { useState, useEffect, useRef } from "react";
import Icon, { IconType } from "../Icon/Icon";
import imgClouds from "../../Assets/default_thumbnail_image.jpg";
//import PdfViewer from "../PdfViewer/PdfViewer";
import { toast } from "react-toastify";
import Modal from "../Modal/Modal";
import { stringify } from "query-string";
import './Tools.css';
import { UiValuePairs } from "../FormManager/FormManagerTypes";
import { Server } from "../../utils/Server";
import PdfViewer from "./Pdf/PdfViewer";
import { connectMap, IState } from "../../Redux/Redux";

interface IExpandableDivProps {
  children?: React.ReactNode;
}

const ExpandableDiv = (props: IExpandableDivProps) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className={`expandable-div ${open ? "open" : "closed"}`}>
      {props.children}
      <div className="bottom-gradient">
        {open ? (
          <button
            type="button"
            onClick={() => {
              setOpen(false);
            }}
            className="more-button btn btn-default btn-xs"
          >
            <Icon type={IconType.up} /> See less
          </button>
        ) : (
          <button
            type="button"
            onClick={() => {
              setOpen(true);
            }}
            className="more-button btn btn-default btn-xs"
          >
            <Icon type={IconType.down} /> See more
          </button>
        )}
      </div>
    </div>
  );
};

export default ExpandableDiv;
interface IViewrContainerProps {
  children?: React.ReactNode;
}

export const ViewerContainer = (props: IViewrContainerProps) => {};

export interface IExpandableImgProps {
  imageSrc: IImage[];
  name?: string;
  onClick?:Function;
}

export interface IImage {
  path: string;
  alt: string;
}

export const ExpandableImg = (props: IExpandableImgProps) => {
  const [images, setImages] = useState<IImage[]>(props.imageSrc ?? []);
  const [index, setIndex] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [showFull, setShowFull] = useState<boolean>(false);
  const [showBig, setShowBig] = useState<boolean>(false);

  return (
    <div
      className={`expandable-img ${open ? "open" : ""}`}
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (open) {
          setOpen(false);
        }
      }}
    >
      {open && (
        <div
          className="close-btn"
          onClick={() => {
            setOpen(false);
          }}
        >
          <Icon type={IconType.close} />
        </div>
      )}
      <img
        alt={images[index]?.alt}
        src={images[index]?.path}
        onError={({ currentTarget: x }) => {
          const i = index + 1;
          if (i - 1 >= images.length || !images[i]) {
            x.onerror = null; // prevents looping
            x.src = imgClouds;
          } else {
            x.src = images[i].path;
            x.alt = images[i].alt;
          }
          setIndex(i);
        }}
        onClick={(e) => {
          if (!open) {
            if(props?.onClick){
                 props.onClick();
                return;    
            }
            setOpen(true);
          } else {
            console.log("stop");
            e.stopPropagation();
          }
        }}
      />
      {props?.name && open && <div>{props.name}</div>}
    </div>
  );
};

interface ILinkToView {
  type: "pdf" | "video" | "image";
  urlSource: string;
  name?: string;
  fill?: boolean;
  addClass?: string;
  hasThumbnail?:boolean;
  urlThumbnailArgs?:string;
}
export const LinkToView = (props: ILinkToView) => {
  const [open, setOpen] = useState<boolean>(false);
  const [url, setUrl] = useState<string>("");

  useEffect(() => {
    let u = props.urlSource;
    if (u.startsWith("https://localhost")) u = u.replace("https://", "http://");
    setUrl(getUrl());
  }, []);

  const renderIt = () => {
    switch (props.type) {
      case "video":
        return renderVideoClosed();
        break;
      case "pdf":
        return renderPdfClosed();
        break;
        
      case "image":
        return renderImageClosed();
        break;
    }
  };
  const renderItOpen = () => {
    switch (props.type) {
      case "video":
        return renderVideo();
        break;
      case "pdf":
        return renderPdf();
        break;
        case "image":
          return renderImage();
          break;
    }
  };

  const getUrl = (thumbnail?:boolean) =>{

    let url = props.urlSource;
    if(url.startsWith('http') || url.startsWith('data:')){

    } else {
      url = Server.BuildUrl(`../file/x/${url}`);
      if(thumbnail){
        if(url.indexOf('?') > -1){
          url += "&tn=1";
        } else {
          url += "?tn=1";
        }

        
        if(url.indexOf('&x=') === -1) url += "&x=100";

        if(props.urlThumbnailArgs)
        url += `&${props.urlThumbnailArgs}`;

      }
    }

    return url;
  }

  const renderPdfClosed = () => {
    if(props.hasThumbnail){
      let url = getUrl(true);
      return (<>
            <span
        className={`link-to-show ${props.fill ? "fill" : ""}`}
        title={`Click to view`}
        onClick={(e) => {
          setOpen(true);
        }}
      >
        <img src={url} className={props.addClass} /> 
      </span>
      
      </>);
    }
    return (
      <span
        className={`link-to-show ${props.fill ? "fill" : ""}`}
        title={`Click to view`}
        onClick={(e) => {
          setOpen(true);
        }}
      >
        <Icon type={IconType.filePdf} /> {props.name ?? props.urlSource}
      </span>
    );
  };
  
  const renderImageClosed = () => {
      let url = getUrl(true);
      return (<>
            <span
        className={`link-to-show ${props.fill ? "fill" : ""}`}
        title={`Click to view`}
        onClick={(e) => {
          setOpen(true);
        }}
      >
        <img src={url} className={props.addClass} /> 
      </span>
      
      </>);
  };

  const renderVideoClosed = () => {
    return (
      <span
        className={`link-to-show ${props.fill ? "fill" : ""}`}
        title={`Click to view`}
        onClick={(e) => {
          setOpen(true);
        }}
      >
        <Icon type={IconType.film} /> {props.name ?? props.urlSource}
      </span>
    );
  };

  const viewer = (children: React.ReactNode) => {
    return (
      <div
        className="easy-viewer-container"
        onMouseUp={(e) => {
          setOpen(false);
          e.stopPropagation();
        }}
        onTouchEnd={(e) => {
          setOpen(false);
          e.stopPropagation();
        }}
      >
        <div
          className="easy-viewer"
          onMouseUp={(e) => {
            e.stopPropagation();
          }}
          onTouchEnd={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="viewer-header">
            <div className="viewer-title">{props.name ?? props.urlSource}</div>
            <div>
              <span
                className="click"
                title="click to close"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <Icon type={IconType.close} />
              </span>
            </div>
          </div>
          <div className="viewer-body">{children}</div>
          <div className="viewer-footer">
            <hr />
            <a
              href={url}
              className="click"
              title="click to view file"
              target="_blank"
            >
              {props.type === "pdf" && <Icon type={IconType.filePdf} />}
              {props.type === "image" && <Icon type={IconType.image} />}
              {props.type === "video" && <Icon type={IconType.film} />}
              {/* {props.name ?? props.urlSource} */}
              Trouble Viewing? Open in new tab.
            </a>
          </div>
        </div>
      </div>
    );
  };

  const renderImage = () => {
    return (
      <>
        {viewer(
          <>
             <img src={url} className={`easy-view-image-full`} /> 
          </>
        )}
      </>
    );
  };

  const renderPdf = () => {
    return (
      <>
        {viewer(
          <>
             <PdfViewer
              id={"x"}
              source={url}
              signRequested={() => {}}
              viewOnly={true}
            /> 
          </>
        )}
      </>
    );
  };

  const renderVideo = () => {
    return (
      <>
        {viewer(
          <>
            <video width="100%" height="100%" controls>
              <source src={url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </>
        )}
      </>
    );
  };

  return (
    <>
      {!open && <>{renderIt()}</>}
      {open && <>{renderItOpen()}</>}
    </>
  );
};

interface IImageDetails {
  comments?: string;
  path: string;
}

interface ISlickImage {
  image: IImageDetails;
  noDefaultImage?: boolean;
}

export const SlickImage = (props: ISlickImage) => {
  const getImage = () => {
    let image = props.image;

    if (image) {
      return (
        <div className="tile-image">
          <img
            alt={image.comments}
            src={image.path}
            className="body-grow"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.style.display = "none";
            }}
          />
          <img
            alt={image.comments}
            src={image.path}
            className="body-grow img-back"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              if (props.noDefaultImage) {
                currentTarget.style.display = "none";
              } else {
                currentTarget.src = imgClouds;
              }
            }}
          />
        </div>
      );
    }

    return (
      <div className="tile-image">
        <img src={imgClouds} className="stock-image body-grow" />
      </div>
    );
  };

  return <>{getImage()}</>;
};

interface ISlickImageGroup {
  images: IImageDetails[];
  onErrorImage?: string;
}
export const SlickImageGroup = (props: ISlickImageGroup) => {
  const [gridSize, setGridSize] = useState<number>(1);

  useEffect(() => {
    setGridSize(getGridSize());
  }, []);

  const getGridSize = () => {
    const size = props.images.length;
    if (size > 9) return 5;
    if (size > 6) return 4;
    if (size > 4) return 3;
    // if(size > 2) return 2;
    if (size > 1) return 2;
    return 0;
  };
  return (
    <>
      <div className={`slick-image-group no-${gridSize}`}>
        {props.images.map((x, i) => {
          return (
            <img
              src={x.path}
              title={x.comments}
              key={`img-gr-${i}`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                if (props.onErrorImage) {
                  currentTarget.src = props.onErrorImage;
                } else {
                  currentTarget.style.display = "none";
                }
              }}
            />
          );
        })}
      </div>
    </>
  );
};

interface IEasyImageProps {
  alt?: string;
  src: string;
  missingImagePath?: string;
  showCloudsOnError?: boolean;
  className?: string;
}

export const EasyImage = (props: IEasyImageProps) => {
  const [src,setSrc] = useState<string>();
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(()=>{
    setSrc(props.src);
  },[props.src]);

  const buildImg=()=>{
    return <img
    alt={props.alt}
    src={props.src}
    className={`easy-image ${props.className ?? ""}`}
    onError={(x) => {
      const currentTarget = x.currentTarget;
      if (
        props.missingImagePath &&
        props.missingImagePath !== currentTarget.src
      ) {
        currentTarget.src = props.missingImagePath;
      } else if (props.showCloudsOnError) {
        currentTarget.src = imgClouds;
      } else {
        setHasError(true);
        //currentTarget.style.display = "none";
      }
    }}
  />
  }

  return (
    <>
      {src && <>{!hasError && buildImg()}</>}
    </>
  );
};

