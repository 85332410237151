import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';

import { Server } from '../../utils/Server';
import Icon, { IconType } from '../Icon/Icon';


const Login = (props: RouteComponentProps) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async () => {

    let rqst = {
      email,
      password
    }

    if(!rqst.email || rqst.email.indexOf('@') === -1 || rqst.email.indexOf('.') === -1) {
      toast.warning(`You will need to provide your email to sign-in.`);
      return;
    }
    else if (!rqst.password){
      toast.warning(`You will need to provide your password.`);
      return;
    }

    const _server = new Server(true);
    // ../User/Login
    let response = await _server.postApi('../UserApi/Login', rqst)
      if (response.Success) {
         //TODO: do we want to store the user data anywhere? nothing comes back from this call, if we do.
         _server.loggedIn();
          console.log('pushing to landing now');
          props.history.push('/app/landing');
        } else {
          toast.error(response.Message);
        }
      

  };

  const submitOnEnter = (e)=>{
    if(e.key === "Enter"){
      handleSubmit();
    } console.log(e);
  };

  return (
    <>
      <h2>Log in</h2>
      <Form className="form col">

        <div className="form-group">
          <label htmlFor="register-email">Email</label>
          <input id="register-email" type="email" className="form-control" autoFocus={true} value={email} onChange={(e) => { setEmail(e.target.value); }}></input>
        </div>
        <div className="form-group">
          <label htmlFor="register-password">Password</label>
          <input id="register-password" type="password" className="form-control" value={password} onKeyPress={(e)=>{submitOnEnter(e)}} onChange={(e) => { setPassword(e.target.value); }}></input>
          {/* <small className="form-text text-muted">Must be aleast 8 charaters and include numbers, upper and lower case letters, and a special charater.</small> */}
        </div>
        <div className="flex-between">
        <div>
          <button type="button" className="btn btn-secondary" onClick={handleSubmit}>
            <Icon type={IconType.signIn} /> Sign-in
          </button>
          <button type="button" className="btn btn-default" onClick={() => props.history.push('/app/user/register')}>
            <Icon type={IconType.userPlus} /> Register
          </button>
        </div>
        {/* help link goes here */}
        <button type="button" className="btn btn-default" onClick={() => props.history.push('/app/user/help')}>
            <Icon type={IconType.help} /> Help signing-in
          </button>
        </div>
      </Form>
    </>
  )
}

export default withRouter(Login); 