import { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { toast } from "react-toastify";
import { Server, ServerResponse, _server } from "../../utils/Server";
import Icon, { IconType } from "../Icon/Icon";
//import SetupWizard from "../SetupWizard";
import { ExpandableImg, LinkToView } from "../Tools/ExpandableDiv";
import ConfigFormEditor from "./ConfigFormEditor";
import { IConfigFormData, IConfigItemSummary } from "./FormManagerTypes";

interface IFormManagerLandingProps extends RouteComponentProps<MatchParams>{
  type?: string;
  subType?:string;
  id?:string|number;
  expandable?:boolean;
  startClosed?:boolean;
  onUpdate?:Function;
  isChild?:boolean;
  managerControler?:string;
}

interface MatchParams {
    type?: string;
    subType?:string;
    key?:string;
  }

interface ISearch {
  text?: string;
}

const FormManagerLanding = (props: IFormManagerLandingProps) => {
  const [type, setType] = useState<string>();
  const [subType, setSubType] = useState<string>();
  const [key, setKey] = useState<string|number>();

  const [data, setData] = useState<IConfigFormData>();
  const [search, setSearch] = useState<ISearch>({});
  const [filteredItems, setFilteredItems] = useState<IConfigItemSummary[]>([]);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<IConfigItemSummary>();
  const [expanded, setExpanded] = useState<boolean>(!(props.startClosed ?? false));
  const searchLength = 15;



  useEffect(() => {
    if(props.type ?? props.match.params.type){
        setType(props.type ?? props.match.params.type ?? "Unknown");
        setSubType(props.subType ?? props.match.params.subType);
        setKey(props.id ?? props.match.params.key);
    }
  }, []);

  useEffect(()=>{
    if(type) getFormData();
  },[type]);

  useEffect(() => {
    // let items = [...(data?.Items ?? [])];

    // items = items.sort((a, b) => {
    //   return (a.SortBy ?? a.Name).toUpperCase() >
    //     (b.SortBy ?? b.Name).toUpperCase()
    //     ? 1
    //     : -1;
    // });

    // setFilteredItems(items);
  }, [data?.Items]);

  useEffect(() => {
    let text = search.text?.toLowerCase();
    if (!data || !data.Items || !text || data.Items.length < searchLength)
      setFilteredItems(data?.Items ?? []);

    setFilteredItems(
      data?.Items.filter(
        (x) => x.Name.toLowerCase().indexOf(text ?? "") > -1
      ) ?? []
    );
  }, [search,data?.Items]);


  const getFormData = ()=>{
    _server
    .postApi<IConfigFormData>(`../${props.managerControler ?? 'FormManager'}/FormData`, {
      type: type,
      subType:subType,
      key:key
    })
    .then((x) => {
      if (x.Success) {
        setData(x.Value);
      } else {
        toast.error(`${x.Message}`, { autoClose: false });
      }
    });
  };
  const save = (fields: Record<string, string[]>) => {};

  const onSave = (item: IConfigItemSummary) => {
    setSelectedItem(item);

    let items = [...(data?.Items ?? [])];
    let found = false;
    for (let i = 0; i < items.length; i++) {
      if (items[i].Id === item.Id) {
        found = true;
        items[i] = item;
        break;
      }
    }

    if (!found) items.push(item);
    if(data) setData({ ...data, Items: [...items] });
  };

  const onRemove = (item: IConfigItemSummary) => {
    let items = data?.Items.filter((x) => x.Id !== item.Id) ?? [];
    if(data) setData({ ...data, Items: items });
    setSelectedItem(undefined);
    setShowEditModal(false);
  };

  const addNew = () => {
    setSelectedItem({ Name: "", Id: "-1", Type: type, SubType: subType });
    setShowEditModal(true);
  };

  const manage = (item: IConfigItemSummary) => {
    setSelectedItem(item);
    setShowEditModal(true);
  };

  const addSearchFilter = () => {
    if (!data || data?.Items.length < searchLength) {
      return <></>;
    }
    return (
      <>
        <div className="form-horizontal">
          <div className="form-group">
            <label className="control-label col-sm-3" htmlFor="x-search">
              Search
            </label>
            <div className="col-sm-7">
              <input
                type="text"
                placeholder="search"
                onChange={(e) => {
                  setSearch({ ...search, text: e.target.value });
                }}
                value={search.text}
                className="form-control"
                autoFocus={true}
              />
            </div>
            <div className="col-sm-2">
              showing {filteredItems.length} of {data.Items.length}
            </div>
          </div>
        </div>
      </>
    );
  };

  const nameOrFile = (x: IConfigItemSummary) => {
    let file = Server.FileInfo(x.FilePath);
    if (file) {
      switch (file.type) {
        case "image":
          return <div className="flex-between">
            <div className="thumbnail-1 click"><ExpandableImg imageSrc={[{alt:x.Name, path:file.path}]} name={x.Name} /></div>
            <span className="bumper">{x.Name}</span>
          </div>
        case "pdf":
          return <LinkToView type="pdf" urlSource={file.path}  name={x.Name}/>
        case "video":
          return <LinkToView type="video" urlSource={file.path} name={x.Name} />
        case "text":
          break;
        case "other":
          break;
      }
    }
    return <strong dangerouslySetInnerHTML={{__html:x.Name}}></strong>;
  };

  const mapItems = () => {
    if (filteredItems.length === 0) {
      return (
        <>
          <div className="alert alert-info">
            <h4>No Items</h4>
          </div>
        </>
      );
    }
    return (
      <>
        {data &&
          filteredItems.map((x, i) => {
            let style: any = {};
            if (x.Color) style.borderLeft = `10px solid ${x.Color}`;
            return (
              <div key={`cfi:${i}`} className="config-form-item" style={style}>
                <div className="flex-between">
                  {nameOrFile(x)}
                  {data.IsStaff && !x.NoEdit && (
                    <div>
                      <button
                        type="button"
                        className="btn btn-default btn-xs"
                        onClick={() => {
                          manage(x);
                        }}
                      >
                        <Icon type={IconType.edit} /> Manage
                      </button>
                    </div>
                  )}
                </div>
                {x.Description && (
                  <div
                    className="config-form-item-desc"
                    dangerouslySetInnerHTML={{ __html: x.Description }}
                  ></div>
                )}
              </div>
            );
          })}
      </>
    );
  };

  const mapConfigGroups = () => {
    if (data?.ConfigGroups && data?.ConfigGroups?.length > 0) {
      return (
        <>
          {data.ConfigGroups.map((x, i) => {
            return (
                <h4>Not supported</h4>
            //   <SetupWizard key={`cgw-${i}`} wizardKey={x} autoSave={false} />
            );
          })}
        </>
      );
    }
    return <></>;
  };

  const wrapInTabOrNot = () => {
    if (!data) return <></>;

    if (data.ConfigGroups && data.ConfigGroups.length > 0) {
      return (
        <>
          <div className="flex-between">
            <h3>{data.Title}</h3>
            {props.expandable && <div className="click fa-2x" onClick={()=>{setExpanded(!expanded)}}>
              {expanded ? <><Icon type={IconType.expandDown}/></>:
              <><Icon type={IconType.expandUp}/></>
              }
              </div>}
          </div>
          {expanded && <>
          {data.Description && (
            <div
              dangerouslySetInnerHTML={{ __html: data.Description }}
              className="bumper-bottom"
            ></div>
          )}
          <Tabs>
            <TabList>
              <Tab>Items</Tab>
              <Tab>Additional Configs</Tab>
            </TabList>
            <TabPanel>
              <div className="flex-between">
                <div></div>
                {data.IsStaff && (
                  <div className="bumper-bottom">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={addNew}
                    >
                      <Icon type={IconType.plus} /> Add
                    </button>
                  </div>
                )}
              </div>
              {addSearchFilter()}
              <div className="config-form-landing-container">{mapItems()}</div>
            </TabPanel>
            <TabPanel>
              <div className="config-form-landing-container">
                {mapConfigGroups()}
              </div>
            </TabPanel>
          </Tabs>
        </>}
        </>
      );
    } else {
      return (
        <>
          <div className="flex-between">
            <h3>{data.Title}</h3>
            {data.IsStaff && (
              <div className="flex-between">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={addNew}
                >
                  <Icon type={IconType.plus} /> Add
                </button>
                {props.expandable && <div className="click fa-2x" onClick={()=>{setExpanded(!expanded)}}>
              {expanded ? <><Icon type={IconType.expandDown}/></>:
              <><Icon type={IconType.expandUp}/></>
              }
              </div>}
              </div>
            )}
          </div>
            {expanded && <>
          {data.Description && (
            <div dangerouslySetInnerHTML={{ __html: data.Description }}></div>
          )}
          <hr />
          {addSearchFilter()}
          <div className="config-form-landing-container">{mapItems()}</div>
          </>  }
        </>
      );
    }
  };

  const onUpdate = ()=>{
    getFormData();
    if(props.onUpdate) props.onUpdate();
  };

  return (
    <div className="col-sm-12">
      {data && <div className="config-form-landing">{wrapInTabOrNot()}</div>}

      {selectedItem && showEditModal && type && (
        <ConfigFormEditor
          type={type}
          item={selectedItem}
          itemKey={key}
          canSave={data?.IsStaff ?? false}
          noEdit={data?.NoEdit ?? false}
          itemChildren={data?.ItemsChildTypes}
          onClose={() => {
            setShowEditModal(false);
          }}
          onSave={onSave}
          onRemove={onRemove}
          onUpdate={onUpdate}
          dontRefreshOnSaveOrRemove={props.isChild ? true:false}
          managerControler={props.managerControler}
        />
      )}
    </div>
  );
};

export default withRouter(FormManagerLanding);
