import { useEffect, useState } from 'react';
import { IState } from '../../../Redux/Redux';
import { IWorkbookTemplateSummary } from '../../WorkbookTemplate/WorkbookTypes';
import OrganizationWorkbookTemplateSearchableList from '../OrganizationWorkbookTemplateSearchableList';
import Icon, { IconType } from '../../Icon/Icon';
import { _server } from '../../../utils/Server';
import { toast } from 'react-toastify';

interface IProps {
  state: IState;
  close?: () => {};
  userPublicId: string;
  orgId?:string;
}

const UserSignerAccessManager = (props: IProps) => {
  const [workbook, setWorkbook] = useState<IWorkbookTemplateSummary>();
  const [reviewGroups, setReviewGroups] = useState<Record<string,boolean>>({});

  useEffect(()=>{

    setReviewGroups({});

    let url = `../OrgApi/OrgUsersReviewGroups`;
    let data = {userId:props.userPublicId, orgId: props.orgId};

    _server.postApi<string[]>(url, data)
    .then((x) => {
      if (x.Success) {
        let groups:Record<string,boolean> = {};
        x.Value.forEach(g=>{
          groups[g] = true;
        });

        setReviewGroups(groups);
      } else {
        toast.error(`Failed to load review groups`);
      }
    });


  },[props.userPublicId]);

  const saveReviewGroups = ()=>{
    let url = `../OrgApi/OrgUsersSaveReviewGroups`;
    let data = {userId:props.userPublicId, groups:reviewGroups, orgId: props.orgId};
    _server.postApi<string[]>(url, data)
    .then((x) => {
      if (x.Success) {
        let groups:Record<string,boolean> = {};
        x.Value.forEach(g=>{
          groups[g] = true;
        });

        setReviewGroups(groups);
        toast.success('Saved');
      } else {
        toast.error(`Failed to save review groups`, {autoClose:false});
      }
    });
  };


  return (
    <>
      {!workbook ? (
        <>
          <h3>Select a Workbook</h3>
          <OrganizationWorkbookTemplateSearchableList
            onSelect={(wb: IWorkbookTemplateSummary) => {
              setWorkbook(wb);
            }}
            state={props.state}
          />
        </>
      ) : (
        <>
          <div className="flex-between">
            <h3>Workbook: {workbook.Name}</h3>
            <div>
              <button
                type="button"
                className="btn btn-default"
                onClick={() => {
                  setWorkbook(undefined);
                }}>
                <Icon type={IconType.close} /> Switch Workbook
              </button>
            </div>
          </div>
          <div className="">
            Is part of the following groups: 
            <div className=''>
            {workbook.WorkbookTemplateReviewGroups?.map((x, i) => {
              return <div className="" key={`wts-x-${i}`}>
                <div>
                <input type={"checkbox"} id={`x-${x.PublicId}`}
                  checked={reviewGroups[x.PublicId] ?? false}
                  onChange={(e)=>{
                    let rg = {...reviewGroups};
                    rg[x.PublicId] = e.target.checked;
                    setReviewGroups(rg);
                  }}
                />
                <label htmlFor={`x-${x.PublicId}`}>{x.Name}</label>
                </div>
              </div>;
            })}
            </div>
          </div>
      <div>
        <button type='button' className='btn btn-secondary' onClick={saveReviewGroups}><Icon type={IconType.save}/> Save</button>
      </div>
        </>
      )}
      <div className="form-horizontal"></div>
    </>
  );
};

export default UserSignerAccessManager;
