import { useEffect } from "react";
import { connectMap, IState } from "../../../Redux/Redux";
import FormManagerLanding from "../../FormManager/FormManagerLanding";

interface IOrganizationManagerProps extends IState{

}

const OrganizationManager = (props:IOrganizationManagerProps) =>{
    
    useEffect(()=>{},[]);

    const init = ()=>{

    };
    return (<>
        <div className="org-manager">
            <FormManagerLanding type={"organizations"} />
        </div>
    </>);
};


export default connectMap()(OrganizationManager);