import { useState } from "react";
import OrgPageHeader from "../../Components/Organizations/OrgPageHeader";
import { IState, ReduxMapper } from "../../Redux/Redux";

interface IProps extends IState{

}

const AboutThisApp = (props:IProps) => {
  const [name, setName] = useState("zFairs Project App");

  return (
    <>
    <OrgPageHeader state={props} />
      <div className="document">
        <h3>About zFairs Project App</h3>
        <hr />
        <h4>What is the Project App?</h4>
        <div>
          The Project App is a content delivery platform geared towards helping
          Event Participants complete a series of prebuilt guided steps with the
          end goal being to simplify creating, organizing, documenting and
          presenting Science Fair Projects, Invention Convention Logbooks, NHD
          entries, book reports, training courses, etc.
        </div>
        <hr />

        <h4>Who does it benefit?</h4>
        <div>
          <p>
            The Project App benefits a wide variety of individuals albeit in
            very different ways.
          </p>
          <h6>Students</h6>
          <ul>
            <li>
              Students receive their benefit from having a tool to help walk
              them through each process and attain the best end result possible.
              They'll utilize features such as Brainstorming Sessions, Forms
              Wizard, Data Docker and Chart / Presentation Builder to come up
              with the best ideas for each project, fill out and know they have
              all of the paperwork in order, store all of the data they collect
              during testing / throughout the process and create & present
              informative and concise presentation boards / PDF displays that
              will show their efforts and highlight their newfound knowledge and
              skills.
            </li>
          </ul>
          <h6>Teachers</h6>
          <ul>
            <li>
              Teachers are in the unique position of being able to subscribe to
              various different templates that can assist their students with
              the various event projects (Science Fair, Invention Convention,
              National History Day, etc) but will also be able to assign Book
              Reports, Essays and Papers to their students in the same platform.
              Their students will then be guided through the necessary steps to
              complete each assignment in the best way possible.
            </li>
          </ul>
          <h6>Event Coordinators</h6>
          <ul>
            <li>
              You can know that your event participants are following the
              correct steps and flows so that there is a much reduced chance of
              needing to scramble at the end because they didn't get everything
              accounted for that they should have.
            </li>
          </ul>
          <h6>Board of Trustees</h6>
          <ul>
            <li>
              Board Members or any vested party interested in the anonymous
              statistics of their users will have access to a plethora of
              helpful reports. These reports will help them see which areas are
              utilizing the software more than others (thus having the ability
              to target marketing to the areas, regions, states and countries
              that use the platform the least). The Project App will also give
              them real time numbers on just how many participants they have at
              any given event and see what the Category, Ethnicity, Grade Level,
              etc breakdown is for all users as a whole or for any one event
              they have an interest in.
            </li>
          </ul>
        </div>
        <hr />
      </div>
    </>
  );
};

export default ReduxMapper(AboutThisApp);
