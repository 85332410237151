import { useState } from "react";
import { propTypes } from "react-bootstrap/esm/Image";
import { RouteComponentProps, withRouter } from "react-router";
import { toast } from "react-toastify";
import { connectMap, IState } from "../../../Redux/Redux";
import { _server } from "../../../utils/Server";
import Icon, { IconType } from "../../Icon/Icon";
import { ISigner, ISignRequest, IWorkbookInfo } from "../WorkbookTypes";

interface IApproveProps extends RouteComponentProps<MatchParams>, IState {
    info:IWorkbookInfo;
    request:ISignRequest;
    signer:ISigner;
    onSign?:(request:ISignRequest)=>void;
}
interface MatchParams {}

interface IForm{
    comments?:string;
    signRequestId?:string;
}

const Approve = (props:IApproveProps)=>{
    const [form, setForm] = useState<IForm>({});

    const onSign = ()=>{
        let request = {...form};
        request.signRequestId = props.request.PublicId;
        _server.postApi<ISignRequest>(`../SignRequestApi/Sign`, request)
        .then((x)=>{
            if(x.Success){
                if(props.onSign) props.onSign(x.Value);
            } else {
                toast.error(x.Message, {autoClose:false});
            }
        });
    };
    
    return <>
        <div className="headroom">
        <h3>{props?.User?.FirstName}'s Electronic Signing & Approval</h3>
          <div>
            Signing electronicly is a valid form of signature and is considered
            the same as signing using pen and paper. By procceding you
            understand that your signature is binding, and that the miss use of
            electronic signatures is fruad.
          </div>
          <h4>Signer Information</h4>
            <div>Name: {props.User?.FirstName} {props.User?.LastName}</div>
            <div>Email: {props.User?.Email}</div>
            <div>Phone: {props.User?.Phone}</div>

          <h4>Approve & Sign</h4>
          Click the Approve & Sign buton to complete your signature. 
          <div className="headroom">
            <label className="control-label" htmlFor="app-comments">Comments</label>
            <textarea className="form-control" id="app-comments" value={form.comments ?? ''} onChange={(e)=>{setForm({...form,comments:e.target.value});}} rows={4}></textarea>
          </div>
          <div className="headroom">
            <button type="button" className="btn btn-secondary" onClick={onSign}><Icon type={IconType.signature} /> Sign & Approve!</button>
          </div>
        </div>
    </>;
};

export default connectMap()(withRouter(Approve));