import { _server } from "../../utils/Server";
import { RunCalculations } from "./WorkbookCalculations";
import { v4 } from "uuid";
import { ICanShowElement, IDataPoint, IDataValue, IElement, IFormGroup, IMilestone, IPage, ISigner, ISignRequest, IWorkbookInfo, IWorkbookSaveRequest, IWorkbookSaveResponse, MilestoneStates } from "./WorkbookTypes";

export const buildTemplateDic = (info: IWorkbookInfo) => {
    info.TemplateDic = {};
    let pagesToAdd: IPage[] = [];
    RunCalculations(info, 'base');
    info.Template.Pages?.forEach((c) => {
      info.TemplateDic[c.PublicId] = c;
      if(c.SetId && !info.Preview){
        let set = info.Template.Sets.find(x=>x.PublicId === c.SetId);
        if(set){
          if(set.OwnersAreSet){
            //create page for each...
            let childrent = [...c.ChildrenPages];
            c.ChildrenPages = [];
            
            let ownerPages: IPage[] = [];
            info.Owners.forEach((o,i)=>{
              let np:IPage = {...c,  Name: `${o.FirstName}'s ${c.Name}`, PublicId: `${c.PublicId}|${set?.PublicId}${o.PublicId}`, selectPageButtonText : `${o.FirstName}'s ${c.Name}`};
              np.Header = ` <h3>${o.FirstName} ${o.LastName}'s Page</h3>` +(np.Header ?? '');
              np.ChildrenPages = [];     
              np._setGroupKey = o.PublicId;         
              ownerPages.push(np);
            });

            c.skipToNextWhenNotShown = true;
            c.showWhenTrue = ['hide'];
            c.showWhenTrueOr = false;

            ownerPages.forEach((p, i)=>{
              if(i===0){
                c.ChildrenPages.push({Id:p.PublicId, Order:i, Type:'set-page'});
                info.TemplateDic[c.PublicId] = p;
              }
              else {
                ownerPages[i-1].ChildrenPages.push({Id:p.PublicId, Order:i, Type:'set-page'})
              }
              pagesToAdd.push(p);
            });
            if(ownerPages.length > 0) ownerPages[ownerPages.length -1].ChildrenPages = childrent;
          }
        }
      }
    });
    pagesToAdd.forEach(p=>{
      info.Template.Pages.push(p);
    })

    info.Template.Elements?.forEach((c) => {
      info.TemplateDic[c.PublicId] = c;
    });
    info.Template.DataPoints?.forEach((c) => {
      info.TemplateDic[c.PublicId] = c;
    });
    info.Template.FormGroups?.forEach((c) => {
      info.TemplateDic[c.PublicId] = c;
    });
    info.Template.Milestones?.forEach((c) => {
      info.TemplateDic[c.PublicId] = c;
    });
    info.Template.Signers?.forEach((c) => {
      info.TemplateDic[c.PublicId] = c;
    });
    info.Template.Themes?.forEach((c) => {
      info.TemplateDic[c.PublicId] = c;
    });
    info.Template.Sets?.forEach((c) => {
      info.TemplateDic[c.PublicId] = c;
    });
    info.Template.ReviewGroups?.forEach((c) => {
      info.TemplateDic[c.PublicId] = c;
    });
    //console.log('values',info.Values);
  };
  
  export const workbookOnValueChanged = (
    info: IWorkbookInfo,
    request: IWorkbookSaveRequest,
    files: File[]
  ) => {
    let infoX = { ...info };
    let dp: IDataPoint = info.TemplateDic[request.DataPointId ?? ""];
    
  
    if (
      dp?.WorkbookSpecialType === "WorkbooksName" &&
      request.Value &&
      request.Value[0]
    ) {
      infoX.Name = request.Value[0];
    }
  
    let call = new Promise<IWorkbookInfo>((reslove, reject)=>{
      _server
      .postApi<IWorkbookSaveResponse>(`../WorkbookApi/Save`, request, files)
      .then((x) => {
        if (x.Success) {
          let values: Record<string, Record<string, IDataValue>> = {...(infoX?.Values ?? {})};
          let valueDic = {...values[request.SetId ? request.SetId : 'base'] ?? {}};
          values[request.SetId ? request.SetId : 'base']=valueDic;

          valueDic[`${x.Value?.DataPointId}${x.Value?.SetGroupKey??''}`] = {
            Value: x.Value.Value?.Value ?? '',
            Type: x.Value.Value?.Type
          };
            reslove( {
              ...infoX,
              WorkbookId: x.Value.WorkbookId ?? infoX.WorkbookId,
              WorkbookTemplateId: x.Value.WorkbookTemplateId ?? infoX.WorkbookTemplateId,
              Values: values,
            });
        } else {
          reject(info);
        }
      });
    });
    
    return call;
   
  };
  
  export const canShow = (info:IWorkbookInfo, el:ICanShowElement, setId?:string, setKey?:string) =>{
  
    let validPath = true;
    let access = true;
    let validPathOr = false;

    if (el.showWhenTrue && el.showWhenTrue.length > 0) {
      el.showWhenTrue.forEach((s) => {
        if (s) {
          let valuesDic = info?.Values[setId ? setId : 'base'] ?? {};
          
          let value = valuesDic[`${s}${setKey??''}`]?.Value.toUpperCase();
          if (
            !value ||
            value === "0" ||
            value === "F" ||
            value === "FALSE" ||
            value === "N" ||
            value === "NO"
          ) {
            validPath = false;
          } else {
            validPathOr = true;
          }
        }
      });
    }

    let validPathFalse = true;
    let validPathFalseOr = false;
    if (el.showWhenFalse && el.showWhenFalse.length > 0) {
      el.showWhenFalse.forEach((s) => {
        if (s) {
          
          let valuesDic = info?.Values[setId ? setId : 'base'] ?? {};
          let value = valuesDic[s]?.Value.toUpperCase();
          if (
            value === undefined ||
            value === "0" ||
            value === "F" ||
            value === "FALSE" ||
            value === "N" ||
            value === "NO"
          ) {
            validPathFalseOr = true;
          } else {
            validPathFalse = false;
          }
        }
      });
    }
    el._validPath =
    (validPath || (validPathOr && el.showWhenTrueOr)) &&
    (validPathFalse || (validPathFalseOr && el.showWhenFalseOr));
    return el._validPath;
  };
  
  export const calculatePageAccess = (info:IWorkbookInfo, preview:boolean, setId:string) => {
    let template = info?.Template;
    if (template) {
      RunCalculations(info, 'base');
      let dic = info?.TemplateDic ?? {};
      let pageDic: Record<string, IPage> = {};
      template.Pages.forEach((x) => {
        pageDic[x.PublicId] = x;
      });

      template.Elements.forEach((x)=>{
        x._validPath = canShow(info, x, setId);
      });
  

      template.Pages.forEach((x) => {
        let access = true;
        if (x.requiresSubscription && x.requiresSubscription.length > 0) {
          x.requiresSubscription.forEach((s) => {
            if (s) {
              
              let valuesDic = info?.Values[setId ? setId : 'base'] ?? {};
              let value = valuesDic[s]?.Value.toUpperCase();
              access = false;
            }
          });
        }
  
        x._canAccess = access;

        x._validPath = canShow(info, x, setId);
        x._nextPagesAll = x.ChildrenPages.sort((a, b) => {
          return a.Order > b.Order ? 1 : -1;
        }).map((x) => {
          let page = pageDic[x.Id];
          return page;
        }).filter(x=>x);
        if (info) setPagesMissingDataPoints(x, info, setId);
        x._milestones = getPagesMilestones(info, x, setId, false, preview) ?? [];
        x._milestonesState = getStateOfMilestones(info, x._milestones);
        dic[x.PublicId] = x;
      });
  
      console.log("Calculating Page Access");
      
    if(info.ParentPages?.length === 0){
      info.ParentPages = [info.Template.Pages[0]?.PublicId]
    }
      return {...info};
    }
  };
  
  export const setPagesMissingDataPoints = (page: IPage, info: IWorkbookInfo, setId:string) => {
    setId = page.SetId ?? setId ?? 'base';
    let dataPoints = getAllRequiredDataPoints(page, info, setId);
    let missingPoints: IDataPoint[] = [];
    if(page.ReadOnly){
      console.log("Page is read only")
      page._missingDataPoints = missingPoints;
      page._requiredDataPoints = dataPoints;
      return;
    }
    for (let i = 0; i < dataPoints.length; i++) {
      let point = dataPoints[i];
      let valueDic = info.Values[setId ? setId : 'base'] ?? {};
      let value = valueDic[`${point.PublicId}${page._setGroupKey ?? ''}`];
      if (value === undefined || !value.Value || !value.Value.length) {
        missingPoints.push(point);
        continue;
      }
      if (point.Type.toLowerCase() === "checkbox") {
        if (value.Value?.toLowerCase() !== "true") {
          console.log(value);
          missingPoints.push(point);
        }
      } else {
        if (!value.Value[0]) missingPoints.push(point);
      }
    }
    page._missingDataPoints = missingPoints;
    page._requiredDataPoints = dataPoints;
  };
 
  
  const getAllRequiredDataPoints = (page: IPage, info: IWorkbookInfo, setId:string) => {
    let elements: IElement[] = page.Elements.map((x) => {
      return info.TemplateDic[x.Id];
    }).filter(x=> !x.ReadOnly && canShow(info,x, setId));
    let groups: IFormGroup[] = elements
      .map((x) => {
        return info.TemplateDic[x.ElementType];
      })
      .filter((x: IFormGroup) => x && x.Children && x.Children.length > 0);
    let points: IDataPoint[] = [];
    groups.forEach((g) => {
      let dataPoints: IDataPoint[] = g.Children.map((x) => {
        return info.TemplateDic[x.Id];
      });
      dataPoints
        .filter((x) => x && x.Required)
        .forEach((x) => {
          points.push(x);
        });
    });
    return points;
  };

  export const getPagesMilestones = (info:IWorkbookInfo, page:IPage, setId:string, approvalOnly?:boolean, preview?:boolean) =>{
    let milestones:IMilestone[] = page.Elements.map(x=>info.TemplateDic[x.Id])
      .filter(x=>preview || canShow(info,x, setId))
      .map(x=>info.TemplateDic[x.ElementType])
      .filter(x=>x && x.WorkbookProperType === "milestone" && (!approvalOnly || x.Type === "approval"))      
      ;
      return milestones ?? [];
  };

  export const getStateOfMilestones=(info:IWorkbookInfo, milestones:IMilestone[])=>{
    let requests:ISignRequest[] = [];
    let stones = milestones.filter(x=>x.Type === "approval");
    
    if(stones.length == 0) return MilestoneStates.NA;
    stones.forEach(m=>{
      m.Approvers?.forEach(a=>{
        let signRequests = info.SignRequests?.filter(s=>s.SignerId === a && s.MilestoneId === m.PublicId) ?? [];
        signRequests.forEach(s=>{requests.push(s)});
      });
    });

    if(requests.length === 0) return MilestoneStates.NotStarted;
    if(requests.length < stones.length) return MilestoneStates.Started;

    let allComplete = true;
    requests.forEach(x=>{
      if(!x.ApprovedAtUtc) allComplete = false;
    });

    return allComplete ? MilestoneStates.Completed : MilestoneStates.Started;
  }

  export const NewGuid = ()=>{
    return v4();
  };

  const regExTokens = /(\[key\:)([A-z0-9-\.]+)(\|)?([\w\d\s\.!@#\(\)\$]*)(\])/g

export const regexReplace = (info:IWorkbookInfo, content:string) => {
  if(!content) return content;
  let valueDic = info.Values;
  let values = info.Values?[`base`]:{}
  
  return content.replace(regExTokens, (match, part1, key)=>{ 
    
    return getReplaceValue(key, values, info);
  })
}

const getReplaceValue =(key:string, values:Record<string,IDataValue>, info:IWorkbookInfo)=>{
  console.log("Key", key);
  let value = values[key]?.Value ?? info.TemplateDic[key]?.Label ?? 'Unknown';

  return value;
}