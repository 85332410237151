import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { IUserGroupSummary } from "../../Types/User";
import { Server } from "../../utils/Server";
import FormManagerLanding from "../FormManager/FormManagerLanding";
import Icon, { IconType } from "../Icon/Icon";

interface IUsersProps {}

const Users = (props: IUsersProps) => {
  const [searchString, setSearchString] = useState<string>();


  return (
    <>
    <div className="headroom"></div>
      <FormManagerLanding type={"users"} managerControler={`NonAdminDataManager`}  />
    </>
  );
};

export default Users;
